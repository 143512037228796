import React, { Component } from 'react'
import { connect } from 'react-redux'
import StatsRow from '../../components/molecules/StatsRow/StatsRow'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import AdminPageAside from '../../containers/admin/AdminPageAside/AdminPageAside'
import Page from '../../containers/global/Page/Page'
 
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import { actions as StatsActions, selectors as StatsSelectors } from '../../redux/StatsRedux'

const mapStateToProps = (state) => ({
  stats: StatsSelectors.admin(state),
})

const mapDispatchToProps = (dispatch) => ({
  getAdminStats: () => dispatch(StatsActions.getAdminStats()),
  setAdminStats: (stats) => dispatch(StatsActions.setAdminStats(stats)),
})

class AdminStatsPage extends Component {

  renderTabs() {
    const links = [{ id: 'stats', label: "Statistiques MyTalents" }]

    return <LineMenu value={links[0].id} links={links} />
  }

  componentDidMount() {
    this.props.getAdminStats()
  }

  componentWillUnmount() {
    this.props.setAdminStats(null)
  }

  formatStats = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  renderContent = () => {
    const { stats } = this.props

    return (
      <>
        <StatsRow label={"Nombre total de comptes clients"} stats={this.formatStats(stats.no_offices)} />
        <StatsRow
          label={"Nombre total de consultants"}
          stats={this.formatStats(stats.no_consultants)}
        />
        <StatsRow
          label={"Nombre total de talents"}
          stats={this.formatStats(stats.no_beneficiaries)}
        />
      </>
    )
  }

  render() {
    if (!this.props.stats) return null

    const aside = <AdminPageAside />

    const content = (
      <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
    )

    return (
      <div className="admin-offices-page">
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(AdminStatsPage))

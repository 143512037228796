import React, { useEffect } from "react";
import Icon from "../Icon/Icon";

import { toasterStates } from "./ToasterStates";
import './Toaster.scss';

export const Toaster = ({ title, message, type, onClose }) => {
    const { icon, color } = toasterStates[type];

    useEffect(() => {
        const timer = setTimeout(() => {
          onClose();
        }, 4000); 
    
        return () => {
          clearTimeout(timer); 
        };
      }, [onClose]);

    return (
        <div className={`toaster-mytalents ${type}`}>
            <div className={`toaster-mytalents-icon toaster-mytalents-${color}`}>
                <Icon icon={icon} color={"white"}/>
            </div>
            <div className="toaster-mytalents-content">
                <h2>{title}</h2>
                <p>{message}</p>
            </div>
            <div>

                <button className="toaster-mytalents-close-button" onClick={onClose}>
                    <Icon icon="close" color={"grey1"} />
                </button>
            </div>
        </div>
    );
}
import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_MY_TALENTS_EVOLUTION,
  headers: {
  //   'Authorization': 'Bearer 457f4be3-878c-494f-b109-0a428bfd65d2',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});


export const axiosInstanceBigBoss = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Icon from '../../../components/atoms/Icon/Icon'
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton'
import UserCard from '../../../components/organisms/UserCard/UserCard'
import { actions as NavigationActions } from '../../../redux/NavigationRedux'
import { actions as UserActions } from '../../../redux/UserRedux'
import FileUpload from '../../global/FileUpload/FileUpload'
 

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  resetPassword: (email) => dispatch(UserActions.forgotPassword(email)),
  sendInvitation: (id) => dispatch(UserActions.sendInvitation(id)),
})

class ManagerConsultantEdit extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    consultant: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      image_url: PropTypes.string,
      phone: PropTypes.string,
    }).isRequired,
  }

  handleChange = ({ id, value }) => {
    const map = {
      name: 'last_name',
      firstName: 'first_name',
    }

    this.props.onChange({
      id: map[id] || id,
      value,
    })
  }

  handleFileChange = ({ file }) => {
    this.handleChange({ id: 'image_url', value: file.url })
  }

  handleResetPassword = () => {
    const { consultant, resetPassword } = this.props
    resetPassword(consultant.email)
  }

  handleSendInvitation = () => {
    const { consultant, sendInvitation } = this.props
    sendInvitation(consultant.id)
  }

  renderResetPasswordButton() {
    const { consultant } = this.props
    const { id, activated_at } = consultant

    if (!id || !activated_at) {
      return null
    }

    return (
      <PrimaryButton
        id="reset"
        label={"Reset le mot de passe"}
        icon={Icon.icon.Key}
        onClick={this.handleResetPassword}
      />
    )
  }

  renderSendInvitationButton() {
    const { consultant } = this.props
    const { id, activated_at } = consultant

    if (!id || activated_at) {
      return null
    }

    return (
      <>
        <PrimaryButton
          id="send-invitation"
          label={"Renvoyer l'invitation"}
          icon={Icon.icon.Mail}
          onClick={this.handleSendInvitation}
        />

        <span>{"Le consultant n'a pas encore activé son compte"}</span>
      </>
    )
  }

  render() {
    const { consultant } = this.props
    const { first_name = '', last_name = '', phone = '', email = '', image_url = '' } = consultant

    return (
      <>
        <div className="action-button">
          {this.renderResetPasswordButton()}
          {this.renderSendInvitationButton()}
        </div>

        <FileUpload id="image_url" accept="image/*" onChange={this.handleFileChange}>
          {({ onClick, uploading }) => (
            <UserCard
              title={"Sa présentation"}
              firstName={first_name}
              name={last_name}
              phone={phone}
              email={email}
              photoUrl={image_url}
              onChange={this.handleChange}
              onFileClick={onClick}
              uploading={uploading}
              locale={{
                name: "Nom",
                namePlaceHolder: "Nom",
                firstName: "Prénom",
                firstNamePlaceHolder: "Prénom",
                phone: "Téléphone",
                phoneNumberPlaceHolder: "Téléphone",
                email: "Email",
                emailPlaceHolder: "Email",
              }}
            />
          )}
        </FileUpload>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerConsultantEdit)
/* eslint-disable no-unused-vars */
import React, {useState} from "react";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";


const TestDragNDrop = () => {
    // État local pour stocker les données des chapitres et des parties
    const [chapters, setChapters] = useState([
        {
            id: "chapter-1",
            title: "Chapitre 1",
            parts: [
                { id: "part-1", title: "Partie 1" },
                { id: "part-2", title: "Partie 2" },
                { id: "part-3", title: "Partie 3" },
            ],
        },
        {
            id: "chapter-2",
            title: "Chapitre 2",
            parts: [
                { id: "part-4", title: "Partie 4" },
                { id: "part-5", title: "Partie 5" },
            ],
        },
        {
            id: "chapter-3",
            title: "Chapitre 3",
            parts: [{ id: "part-6", title: "Partie 6" }],
        },
    ]);

    // Fonction de rappel pour gérer le glisser-déposer
    // const onDragEnd = (result) => {
    //     // Vérifie si l'opération de glisser-déposer a réussi
    //     if (!result.destination) {
    //         return;
    //     }

    //     // Vérifie si l'élément glissé est un chapitre ou une partie
    //     if (result.type === "CHAPTER") {
    //         // Met à jour l'ordre des chapitres
    //         const newChapters = Array.from(chapters);
    //         const [reorderedChapter] = newChapters.splice(result.source.index, 1);
    //         newChapters.splice(result.destination.index, 0, reorderedChapter);
    //         setChapters(newChapters);
    //     } else {
    //         // Récupère les identifiants des chapitres source et de destination
    //         const sourceChapterId = result.source.droppableId;
    //         const destinationChapterId = result.destination.droppableId;

    //         // Récupère les chapitres source et de destination
    //         const sourceChapter = chapters.find(
    //             (chapter) => chapter.id === sourceChapterId
    //         );
    //         const destinationChapter = chapters.find(
    //             (chapter) => chapter.id === destinationChapterId
    //         );

    //         // Met à jour l'ordre des parties dans le chapitre source
    //         const newParts = Array.from(sourceChapter.parts);
    //         const [reorderedPart] = newParts.splice(result.source.index, 1);

    //         // Met à jour le chapitre source avec les parties réordonnées
    //         const newSourceChapter = {
    //             ...sourceChapter,
    //             parts: newParts,
    //         };

    //         // Vérifie si la partie est déplacée dans un autre chapitre
    //         if (sourceChapterId !== destinationChapterId) {

    //             const newDestinationParts = Array.from(destinationChapter.parts);
    //             newDestinationParts.splice(result.destination.index, 0, reorderedPart);

    //             // Met à jour le chapitre de destination avec les parties réordonnées
    //             const newDestinationChapter = {
    //                 ...destinationChapter,
    //                 parts: newDestinationParts,
    //             };

    //             // Met à jour les chapitres avec les modifications
    //             const newChapters = Array.from(chapters);
    //             const sourceChapterIndex = newChapters.findIndex(
    //                 (chapter) => chapter.id === sourceChapterId
    //             );
    //             const destinationChapterIndex = newChapters.findIndex(
    //                 (chapter) => chapter.id === destinationChapterId
    //             );
    //             newChapters[sourceChapterIndex] = newSourceChapter;
    //             newChapters[destinationChapterIndex] = newDestinationChapter;
    //             setChapters(newChapters);
    //         }
    //     };
    // }

    const onDragEnd = (result) => {
    }
    return (
        <div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="math-course" type="CHAPTER">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {chapters.map((chapter, index) => (
                                <Draggable key={chapter.id} draggableId={chapter.id} index={index}>
                                    {(provided) => (
                                        <div
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                        >
                                            <h2>{chapter.title}</h2>
                                            <Droppable droppableId={chapter.id} type="PART">
                                                {(provided) => (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {chapter.parts.map((part, index) => (
                                                            <Draggable
                                                                key={part.id}
                                                                draggableId={part.id}
                                                                index={index}
                                                            >
                                                                {(provided) => (
                                                                    <div
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        ref={provided.innerRef}
                                                                    >
                                                                        <p>{part.title}</p>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    )
                                                                }

 export default TestDragNDrop;
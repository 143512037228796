import Icon from '../../atoms/Icon/Icon'
import IconModuleType from '../../atoms/IconModuleType/IconModuleType'
import IconResourceType from '../../atoms/IconResourceType/IconResourceType'

export default {
  module: {
    label: 'Label',
    icon: IconModuleType.moduleType.rdv,
  },
  resource: {
    label: 'Label',
    icon: IconResourceType.resourceType.document,
  },
  editable: {
    label: 'Label',
    editable: true,
    icon: IconResourceType.resourceType.video,
    actions: [
      {
        id: 'delete',
        icon: Icon.icon.Trash,
      },
    ],
    onActionClick: (e, idAction) => {},
  },
  withActions2: {
    label: 'Label',
    icon: IconModuleType.moduleType.quiz,
    actions: [
      {
        id: 'delete',
        icon: Icon.icon.Trash,
      },
      {
        id: 'edit',
        icon: Icon.icon.Pencil,
      },
    ],
    onActionClick: (e, idAction) => {},
  },
}

const ModuleTypes = {
  document: "Document",
  document_to_return: "Document à retourner",
  feedback: "Feedback",
  synthesis: "Synthèse",
  form: "Formulaire",
  appointmentEvo: "Rendez-vous"
};

const DocumentSubTypes = {
  file: "Document",
  video: "Vidéo",
  link: "Lien"
};


export { ModuleTypes, DocumentSubTypes };

import { axiosInstance } from "./baseApi";

export const generateCalendarSyncUrl = async (user_id) => {
    const response = await axiosInstance.post('calendars/auth', user_id);
    return response.data;
}

/* Data :
user_id
code
token_refresh [default = true]
*/
export const storeToken = async (data) => {
    const response = await axiosInstance.post('calendars/token-store', data);
    return response.data;
}

export const generateVisioSyncUrl = async (user_id) => {
    const response = await axiosInstance.post('calendars/conferencing-services', user_id);
    return response.data;
}

/* Data : 
[user_id, calendar_id, start, end, event_id, summary]
[description, attendees_invite, attendees_remove, conferencing, provider_description, join_url] 
*/
export const addEvent = async (data) => {
    const response = await axiosInstance.post('calendars/add-event', data);
    return response.data;
}

export const getUserCalendar = async (user_id) => {
    const response = await axiosInstance.get(`calendars/user/${user_id}`);
    return response.data;
}

export const checkCalendarSync = async (user_id) => {
    const response = await axiosInstance.get(`calendars/user/${user_id}/check-sync`);
    return response.data;
}

/* Data :
user_id
calendar_id
event_id [OPTIONAL]
*/
export const getCalendarEvents = async (data) => {
    const response = await axiosInstance.post('calendars/read-events', data);
    return response.data
}

/* Data :
user_id
calendar_id
*/
export const getUserCronofyProfile = async (data) => {
    const response = await axiosInstance.post('calendars/user-info', data);
    return response.data;
}
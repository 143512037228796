import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
// import _mapValues from 'lodash/mapValues'ConsultantBeneficiaryCvsContainer

import { actions as NotesActions, selectors as NotesSelectors } from '../../../redux/NotesRedux'
import { selectors as UserSelectors } from '../../../redux/UserRedux'

import SharedNotes from '../../../components/organisms/SharedNotes/SharedNotes'
import {
  actions as BeneficiaryActions,
} from '../../../redux/BeneficiaryRedux'
import { selectors as WSSelectors } from '../../../redux/WSRedux'


const mapStateToProps = (state, props) => ({
  user: UserSelectors.user(state),
  notes: NotesSelectors.notes(state),
  otherNotes: NotesSelectors.otherNotes(state),
  consultantNotes: NotesSelectors.consultantNotes(state),
  saveBeneficiaryAction: WSSelectors.action(state, 'beneficiary.saveBeneficiary'),
  saveNotesAction: WSSelectors.action(state, 'notes.save'),
})

const mapDispatchToProps = (dispatch) => ({
  getNotes: () => dispatch(NotesActions.getNotes()),
  setNotes: (notes) => dispatch(NotesActions.setNotes(notes)),
  saveBeneficiary: (beneficiary) => dispatch(BeneficiaryActions.saveBeneficiary(beneficiary)),
  saveNotes: () => dispatch(NotesActions.saveNotes(true)),
})

class BeneficiaryPageAside extends PureComponent {
  

  handleHeaderClick = ({ id }) => {
    if (this.state.expandedId === id) {
      this.setState({
        expandedId: null,
      })
    } else {
      this.setState({
        expandedId: id,
      })
    }
  }

  handleChange = ({ value }) => {
    let updated = false

    const newNotes = this.props.notes.map((note) => {
      if (note.consultant.id === this.props.user.id) {
        updated = true
        return {
          ...note,
          notes: value,
        }
      }

      return { ...note }
    })

    if (!updated) {
      newNotes.push({
        consultant: {
          id: this.props.user.id,
        },
        notes: value,
      })
    }

    this.props.setNotes(newNotes)
    const { saveNotes } = this.props
    saveNotes();
  }

  constructor(props) {
    super(props)

    this.state = {
      expandedId: 'consultant-notes',
    }
  }

  componentDidMount() {
    this.props.getNotes()
  }

  render() {
    const { consultantNotes, otherNotes, user } = this.props

    if (!consultantNotes || !otherNotes) {
      return null
    }

    const notes =
      !this.props.newBeneficiary && consultantNotes && consultantNotes.length > 0
        ? consultantNotes[0]
        : { notes: '' }
    const additionalNotes = this.props.newBeneficiary ? [] : otherNotes

    return (
      <div className="consultant-beneficiary-aside">
        {user.role === 'consultant' && (
          <SharedNotes
            id="consultant-notes"
            label={"Mes notes"}
            isExpanded={this.state.expandedId === 'consultant-notes'}
            hasPhoto={false}
            value={notes.notes}
            onHeaderClick={this.handleHeaderClick}
            editable
            onChange={this.handleChange}
            placeholder={"Ecrivez vos notes ici..."}
          />
        )}
        {additionalNotes.length > 0 &&
          additionalNotes.map((otherNote) => (
            <SharedNotes
              key={otherNote.consultant.id}
              id={`consultant-notes-${otherNote.consultant.id}`}
              label={
                otherNote.is_primary ? "Mes notes" : "Notes partagées"
              }
              isExpanded={this.state.expandedId === `consultant-notes-${otherNote.consultant.id}`}
              hasPhoto
              photoUrl={otherNote.consultant.image_url}
              value={otherNote.notes}
              onHeaderClick={this.handleHeaderClick}
            />
          ))}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryPageAside)
import React from 'react';
import './UserLeftColumnLayout.scss';

const UserLeftColumnLayout = ({header, menu, footer}) => {
  return (
    <aside className="layout user-left-column-layout">
      <header>{header}</header>
      <section>{menu}</section>
      <footer>{footer}</footer>
    </aside>
  )
}
export default UserLeftColumnLayout;
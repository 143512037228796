import React, { useEffect, useState } from "react";
import Icon from "../atoms/Icon/Icon";
import IconButton from "../atoms/IconButton/IconButton";

export default function MessageItem(props) {

    const [typeMessage, setTypeMessage] = useState("");
    const [message, setMessage] = useState([]);
    const [date, setDate] = useState("");
    const [visioLink, setVisioLink] = useState("");
    const [visioMessage, setVisioMessage] = useState("");
    const [messageId, setMessageId] = useState(null);

    useEffect(() => {
        setTypeMessage(props.type);
        setMessage(props.message);
        setDate(props.message.date_emitted.slice(0, -3));
        setMessageId(props.messageId);

        // Séparer le message de visio pour récupérer le lien et le rendre cliquable
        if (props.message.type === "visio") {
            const string = props.message.content;
            const startIndex = string.indexOf("http");
            setVisioMessage(startIndex !== -1 ? string.substring(0, startIndex) : "");
            setVisioLink(startIndex !== -1 ? string.substring(startIndex) : "");
        }
    }, [props]);

    const handleDeleteMessage = () => {
        
        if (messageId === undefined) {
            props.onDeleteMessage(null, message.random_key);
        } else {
            props.onDeleteMessage(messageId, null);
        }
    }

    return (
        <>
        <div className={`message message-${typeMessage}`}>
            <div className="message-infos">
                <p>{message.author_name}&nbsp;:</p>
                <p className="message-date"><img src={`../static/icons/clock-${typeMessage}.svg`} alt="clock"/>{date}</p>
                {typeMessage === "in" &&
                <IconButton
                    icon={Icon.icon.Close}
                    color="white"
                    onClick={handleDeleteMessage}
                    tooltip="Supprimer"
                />
                }
            </div>
            {message.type === "text" ? (
                <p className={`message-content message-content-${typeMessage}`}>{message.content}</p>
            ) : message.type === "image" ? (
                <div className={`div-message-image message-content message-content-${typeMessage}`}>
                    <img src={message.content} alt="Problème lors du chargement du fichier" />
                </div>
            ) : message.type === "visio" ? (
                <p className={`message-content message-content-${typeMessage}`}>{visioMessage}<a href={visioLink} target="_blank">{visioLink}</a></p>
            ) : (
                <p className={`message-content message-content-${typeMessage}`}><a href={message.content} download>Télécharger le fichier <span className={message.type === "" ? "d-none" : ""}>.{message.type}</span></a></p>
            )}
        </div>
        </>
    )
}
import _keyBy from 'lodash/keyBy'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import SectionTitle from '../../../components/molecules/SectionTitle/SectionTitle'
import StatsTable from '../../../components/organisms/StatsTable/StatsTable'
 
import { selectors as StatsSelectors } from '../../../redux/StatsRedux'

const mapStateToProps = (state) => ({
  managerOfficeStats: StatsSelectors.managerOfficeStats(state),
})

class ManagerAppraisalTypesStatistics extends Component {
  static propTypes = {

    managerOfficeStats: PropTypes.objectOf(PropTypes.any),
  }

  static defaultProps = {
    managerOfficeStats: null,
  }

  renderAppraisalTypePoleEmploi = () => {
    const { managerOfficeStats } = this.props

    const columns = [
      {
        id: 'tax_excluded',
        title: "Montant hors taxe (€)",
        selector: 'tax_excluded',
      },
      {
        id: 'accompaniment_count',
        title: "Nombre",
        selector: 'accompaniment_count',
      },
      {
        id: 'hour_count',
        title: "Nombre d'heures",
        selector: 'hour_count',
      },
    ]

    let data = []
    if (managerOfficeStats.bilanPoleEmploi.length > 0) {
      const defaultData = {
        tax_excluded: 0,
        accompaniment_count: 0,
        hour_count: 0,
      }

      data = managerOfficeStats.bilanPoleEmploi.map((item, index) => ({
        ...defaultData,
        ...item,
        id: index,
      }))
    }

    return (
      <StatsTable
        title={"Bilan France travail"}
        columns={columns}
        data={data}
        dataIdSelector="id"
      />
    )
  }

  renderAppraisalTypeFormationPlanBeneficiaryCount = () => {
    const { managerOfficeStats } = this.props

    const columns = [
      {
        id: 'label',
        title: "Par tranche d'effectif",
        selector: 'label',
        highlight: true,
      },
      {
        id: 'cursus_formation_I',
        title: "I",
        selector: 'cursus_formation_I',
      },
      {
        id: 'cursus_formation_II',
        title: "II",
        selector: 'cursus_formation_II',
      },
      {
        id: 'cursus_formation_III',
        title: "III",
        selector: 'cursus_formation_III',
      },
      {
        id: 'cursus_formation_IV',
        title: "IV",
        selector: 'cursus_formation_IV',
      },
      {
        id: 'cursus_formation_V',
        title: "V",
        selector: 'cursus_formation_V',
      },
      {
        id: 'cursus_formation_Vbis',
        title: "Vbis",
        selector: 'cursus_formation_Vbis',
      },
      {
        id: 'cursus_formation_VI',
        title: "VI",
        selector: 'cursus_formation_VI',
      },
    ]

    let data = []
    if (managerOfficeStats.bilanFormationPlanBeneficiaryCount.length > 0) {
      const dataValues = _keyBy(managerOfficeStats.bilanFormationPlanBeneficiaryCount, 'label')
      const defaultData = {
        cursus_formation_I: 0,
        cursus_formation_II: 0,
        cursus_formation_III: 0,
        cursus_formation_IV: 0,
        cursus_formation_V: 0,
        cursus_formation_Vbis: 0,
        cursus_formation_VI: 0,
      }

      const companySizes = [
        {key: "xs", value: "Entreprise de - de 10 salariés"},
        {key: "s", value: "10 à 49 salariés"},
        {key: "m", value: "50 à 199 salariés"},
        {key: "l", value: "200 à 499 salariés"},
        {key: "xl", value: "500 salariés ou +"},
        {key: "other", value: "Autre"},
    ]

      const companySizesData = companySizes.map((company, index) => {
        let values = defaultData;
        if(dataValues[company.key]) {
          values = {...values, ...dataValues[company.key]}
        }

        return {
          ...values,
          id: `companySizeKey_${index}`,
          label: company.value,
        }
      })

      const nonCompanyActivitySectors = [
        {key: "state", value: "L'Etat"},
        {key: "territorial_collectivity", value: "Collectivité territoriale"},
        {key: "public_institution", value: "Etablissement public à caractère administratif"},
        {key: "other", value: "Autre"},
      ]
      const nonCompanyActivitySectorsData = nonCompanyActivitySectors.map(
        (nonCompanyActivitySectorKey, index) => {
          let values = defaultData
          if (dataValues[nonCompanyActivitySectorKey.key]) {
            values = {
              ...values,
              ...dataValues[nonCompanyActivitySectorKey.key],
            }
          }

          return {
            ...values,
            id: `nonCompanyActivitySectorKey_${index}`,
            label: nonCompanyActivitySectorKey.value,
          }
        },
      )

      data = companySizesData.concat(nonCompanyActivitySectorsData)
    }

    return (
      <StatsTable
        className="collapseTable"
        title={"Total talent par niveau de formation"}
        columns={columns}
        data={data}
        dataIdSelector="id"
      />
    )
  }

  renderAppraisalTypeFormationPlan = () => {
    const { managerOfficeStats } = this.props

    const columns = [
      {
        id: 'label',
        title: "Par tranche d'effectif",
        selector: 'label',
        highlight: true,
      },
      {
        id: 'tax_excluded',
        title: "Montant hors taxe (€)",
        selector: 'tax_excluded',
      },
      {
        id: 'accompaniment_count',
        title: "Total bilans",
        selector: 'accompaniment_count',
      },
      {
        id: 'hour_count',
        title: "Nombre d'heures",
        selector: 'hour_count',
      },
      {
        id: 'men',
        title: "Homme",
        selector: 'men',
      },
      {
        id: 'women',
        title: "Femme",
        selector: 'women',
      },
    ]

    let data = []
    if (managerOfficeStats.bilanFormationPlan.length > 0) {
      const dataValues = _keyBy(managerOfficeStats.bilanFormationPlan, 'label')
      const defaultData = {
        tax_excluded: 0,
        accompaniment_count: 0,
        hour_count: 0,
        men: 0,
        women: 0,
      }

      const companySizes = [
        {key: "xs", value: "Entreprise de - de 10 salariés"},
        {key: "s", value: "10 à 49 salariés"},
        {key: "m", value: "50 à 199 salariés"},
        {key: "l", value: "200 à 499 salariés"},
        {key: "xl", value: "500 salariés ou +"},
        {key: "other", value: "Autre"},
    ]

      const companySizesData = companySizes.map((company, index) => {
        let values = defaultData;
        if(dataValues[company.key]) {
          values = {...values, ...dataValues[company.key]}
        }

        return {
          ...values,
          id: `companySizeKey_${index}`,
          label: company.value,
        }
      })

      const nonCompanyActivitySectors = [
        {key: "state", value: "L'Etat"},
        {key: "territorial_collectivity", value: "Collectivité territoriale"},
        {key: "public_institution", value: "Etablissement public à caractère administratif"},
        {key: "other", value: "Autre"},
      ]
      const nonCompanyActivitySectorsData = nonCompanyActivitySectors.map(
        (nonCompanyActivitySectorKey, index) => {
          let values = defaultData
          if (dataValues[nonCompanyActivitySectorKey.key]) {
            values = {
              ...values,
              ...dataValues[nonCompanyActivitySectorKey.key],
            }
          }

          return {
            ...values,
            id: `nonCompanyActivitySectorKey_${index}`,
            label: nonCompanyActivitySectorKey.value,
          }
        },
      )

      data = companySizesData.concat(nonCompanyActivitySectorsData)
    }

    return (
      <>
        <StatsTable
          className="statsTable"
          title={"Bilans entreprise plan de développement des compétences"}
          columns={columns}
          data={data}
          dataIdSelector="id"
        />
        {this.renderAppraisalTypeFormationPlanBeneficiaryCount()}
      </>
    )
  }

  renderAppraisalTypeLeaveOfAbsenceByOpacif = () => {
    const { managerOfficeStats } = this.props

    const columns = [
      {
        id: 'label',
        title: '',
        selector: 'label',
        highlight: true,
      },
      {
        id: 'tax_excluded',
        title: "t('statistics.bilanLeaveOfAbsence.tax_excluded')",
        selector: 'tax_excluded',
      },
      {
        id: 'accompaniment_count',
        title: "t('statistics.bilanLeaveOfAbsence.accompaniment_count')",
        selector: 'accompaniment_count',
      },
      {
        id: 'hour_count',
        title: "t('statistics.bilanLeaveOfAbsence.hour_count')",
        selector: 'hour_count',
      },
    ]

    let data = []
    if (managerOfficeStats.bilanLeaveOfAbsenceByOpacif.length > 0) {
      const opacifTypes = "t('beneficiary_financer_opacif_types')"
      const dataValues = _keyBy(managerOfficeStats.bilanLeaveOfAbsenceByOpacif, 'label')
      const defaultData = {
        tax_excluded: 0,
        accompaniment_count: 0,
        hour_count: 0,
      }
      data = Object.keys(opacifTypes).map((opacifTypeKey, index) => {
        let values = defaultData
        if (dataValues[opacifTypeKey]) {
          values = {
            ...values,
            ...dataValues[opacifTypeKey],
          }
        }

        return {
          ...values,
          id: index,
          label: opacifTypes[opacifTypeKey],
        }
      })
    }

    return (
      <StatsTable
        className="collapseTable"
        title={"t('statistics.bilanLeaveOfAbsenceByOpacif.title')"}
        columns={columns}
        data={data}
        dataIdSelector="id"
      />
    )
  }

  renderAppraisalTypeLeaveOfAbsenceByFormation = () => {
    const { managerOfficeStats } = this.props

    const columns = [
      {
        id: 'label',
        title: '',
        selector: 'label',
        highlight: true,
      },
      {
        id: 'tax_excluded',
        title: "t('statistics.bilanLeaveOfAbsence.tax_excluded')",
        selector: 'tax_excluded',
      },
      {
        id: 'accompaniment_count',
        title: "t('statistics.bilanLeaveOfAbsence.accompaniment_count')",
        selector: 'accompaniment_count',
      },
      {
        id: 'hour_count',
        title: "t('statistics.bilanLeaveOfAbsence.hour_count')",
        selector: 'hour_count',
      },
    ]

    let data = []
    if (managerOfficeStats.bilanLeaveOfAbsenceByFormation.length > 0) {
      const beneficiaryFormations = "t('statistics.bilanLeaveOfAbsenceByFormation.beneficiary_formations')"
      const dataValues = _keyBy(managerOfficeStats.bilanLeaveOfAbsenceByFormation, 'label')
      const defaultData = {
        tax_excluded: 0,
        accompaniment_count: 0,
        hour_count: 0,
      }
      data = Object.keys(beneficiaryFormations).map((beneficiaryFormationKey, index) => {
        let values = defaultData
        if (dataValues[beneficiaryFormationKey]) {
          values = {
            ...values,
            ...dataValues[beneficiaryFormationKey],
          }
        }

        return {
          ...values,
          id: index,
          label: beneficiaryFormations[beneficiaryFormationKey],
        }
      })
    }

    return (
      <StatsTable
        className="collapseTable"
        title={"t('statistics.bilanLeaveOfAbsenceByFormation.title')"}
        columns={columns}
        data={data}
        dataIdSelector="id"
      />
    )
  }

  renderAppraisalTypeLeaveOfAbsence = () => {
    const { managerOfficeStats } = this.props

    const columns = [
      {
        id: 'label',
        title: '',
        selector: 'label',
        highlight: true,
      },
      {
        id: 'tax_excluded',
        title: "t('statistics.bilanLeaveOfAbsence.tax_excluded')",
        selector: 'tax_excluded',
      },
      {
        id: 'accompaniment_count',
        title: "t('statistics.bilanLeaveOfAbsence.accompaniment_count')",
        selector: 'accompaniment_count',
      },
      {
        id: 'hour_count',
        title: "t('statistics.bilanLeaveOfAbsence.hour_count')",
        selector: 'hour_count',
      },
    ]

    let data = []
    if (managerOfficeStats.bilanLeaveOfAbsence.length > 1) {
      const bilanLeaveOfAbsenceLabels = "t('statistics.bilanLeaveOfAbsence.labels')"
      const dataValues = _keyBy(managerOfficeStats.bilanLeaveOfAbsence, 'label')
      const defaultData = {
        tax_excluded: 0,
        accompaniment_count: 0,
        hour_count: 0,
      }
      data = Object.keys(bilanLeaveOfAbsenceLabels).map((bilanLeaveOfAbsenceLabelKey, index) => {
        let values = defaultData
        if (dataValues[bilanLeaveOfAbsenceLabelKey]) {
          values = {
            ...values,
            ...dataValues[bilanLeaveOfAbsenceLabelKey],
          }
        }

        return {
          ...values,
          id: index,
          label: bilanLeaveOfAbsenceLabels[bilanLeaveOfAbsenceLabelKey],
        }
      })
    }

    return (
      <>
        <StatsTable
          className="statsTable"
          title={"t('statistics.bilanLeaveOfAbsence.title')"}
          columns={columns}
          data={data}
          dataIdSelector="id"
        />
        {this.renderAppraisalTypeLeaveOfAbsenceByOpacif()}
        {this.renderAppraisalTypeLeaveOfAbsenceByFormation()}
      </>
    )
  }

  renderAppraisalTypeCPFByFormation = () => {
    const { managerOfficeStats } = this.props

    const columns = [
      {
        id: 'label',
        title: '',
        selector: 'label',
        highlight: true,
      },
      {
        id: 'tax_excluded',
        title: "Montant hors taxe (€)",
        selector: 'tax_excluded',
      },
      {
        id: 'accompaniment_count',
        title: "Nombre",
        selector: 'accompaniment_count',
      },
      {
        id: 'hour_count',
        title: "Nombre d'heures",
        selector: 'hour_count',
      },
    ]

    let data = []
    if (managerOfficeStats.bilanCPFByFormation.length > 0) {
      const beneficiaryFormations = ["I", "II", "III", "IV", "V", "VBis", "VI"]
      const dataValues = _keyBy(managerOfficeStats.bilanCPFByFormation, 'label')
      const defaultData = {
        tax_excluded: 0,
        accompaniment_count: 0,
        hour_count: 0,
      }
      data = Object.keys(beneficiaryFormations).map((beneficiaryFormationKey, index) => {
        let values = defaultData
        if (dataValues[beneficiaryFormationKey]) {
          values = {
            ...values,
            ...dataValues[beneficiaryFormationKey],
          }
        }

        return {
          ...values,
          id: index,
          label: beneficiaryFormations[beneficiaryFormationKey],
        }
      })
    }

    return (
      <StatsTable
        className="collapseTable"
        title={"Total talent par niveau de formation"}
        columns={columns}
        data={data}
        dataIdSelector="id"
      />
    )
  }

  renderAppraisalTypeCPF = () => {
    const { managerOfficeStats } = this.props

    const columns = [
      {
        id: 'label',
        title: '',
        selector: (item) => `${item.label}`,
        highlight: true,
      },
      {
        id: 'tax_excluded',
        title: "Montant hors taxe (€)",
        selector: 'tax_excluded',
      },
      {
        id: 'accompaniment_count',
        title: "Nombre",
        selector: 'accompaniment_count',
      },
      {
        id: 'hour_count',
        title: "Nombre d'heures",
        selector: 'hour_count',
      },
    ]

    let data = []
    if (managerOfficeStats.bilanCPF.length > 1) {
      const bilanCPFLabels = ["Total", "Durant le temps de travail", "Hors du temps de travail", "Hommes", "Femmes"]
      const dataValues = _keyBy(managerOfficeStats.bilanCPF, 'label')
      const defaultData = {
        tax_excluded: 0,
        accompaniment_count: 0,
        hour_count: 0,
      }
      data = Object.keys(bilanCPFLabels).map((bilanCPFLabelKey, index) => {
        let values = defaultData
        if (dataValues[bilanCPFLabelKey]) {
          values = {
            ...values,
            ...dataValues[bilanCPFLabelKey],
          }
        }

        return {
          ...values,
          id: index,
          label: bilanCPFLabels[bilanCPFLabelKey],
        }
      })
    }

    return (
      <>
        <StatsTable
          className="statsTable"
          title={"Bilans compte personnel formation"}
          columns={columns}
          data={data}
          dataIdSelector="id"
        />
        {this.renderAppraisalTypeCPFByFormation()}
      </>
    )
  }

  renderAppraisalTypeIndividual = () => {
    const { managerOfficeStats } = this.props

    const columns = [
      {
        id: 'tax_excluded',
        title: "Montant hors taxe (€)",
        selector: 'tax_excluded',
      },
      {
        id: 'accompaniment_count',
        title: "Nombre",
        selector: 'accompaniment_count',
      },
      {
        id: 'hour_count',
        title: "Nombre d'heures",
        selector: 'hour_count',
      },
    ]

    const defaultData = {
      tax_excluded: 0,
      accompaniment_count: 0,
      hour_count: 0,
    }

    let data = []
    if (managerOfficeStats.bilanIndividual.length > 0) {
      data = managerOfficeStats.bilanIndividual.map((item, index) => ({
        ...defaultData,
        ...item,
        id: index,
      }))
    }

    return (
      <StatsTable
        className="statsTable"
        title={"Bilans réalisés à titre individuel et financés par les intéressés"}
        columns={columns}
        data={data}
        dataIdSelector="id"
      />
    )
  }

  render() {
    return (
      <div className="statistics-section">
        <SectionTitle label={"Statistiques par type de bilan"} />
        {this.renderAppraisalTypePoleEmploi()}
        {this.renderAppraisalTypeFormationPlan()}
        {this.renderAppraisalTypeCPF()}
        {this.renderAppraisalTypeIndividual()}
      </div>
    )
  }
}

export default connect(mapStateToProps)(ManagerAppraisalTypesStatistics)
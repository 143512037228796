import { axiosInstance } from "./baseApi";

export const getAllGroups = async() => {
    const response = await axiosInstance.get('/groups/all');
    return response.data;
}

export const getGroup = async(group_id) => {
    const response = await axiosInstance.get(`groups/${group_id}`);
    return response.data;
}

export const getGroupByConsultant = async(consultant_id, group_id) => {
    const response = await axiosInstance.get(`groups/${group_id}/user/${consultant_id}`);
    return response.data;
}

export const getTalentsGroup = async(consultant_id, group_id) => {
    const response = await axiosInstance.get(`groups/${group_id}/user/${consultant_id}/contacts`);
    return response.data;
}

export const addGroup = async(new_group) => {
    const response = await axiosInstance.post(`groups/add`, new_group);
    return response.data;
}

export const addTalentGroup = async (group_id, new_talent) => {
    const response = await axiosInstance.post(`groups/${group_id}/user/add`, new_talent);
    return response.data;
}

export const updateGroup = async (group_id, group_updated) => {
    const response = await axiosInstance.put(`groups/${group_id}/update`, group_updated);
    return response.data;
}

export const deleteGroup = async (group_id) => {
    const response = await axiosInstance.delete(`groups/${group_id}/delete`);
    return response.data;
}

export const deleteTalentGroup = async (group_id, talent_id) => {
    const response = await axiosInstance.delete(`groups/${group_id}/user/${talent_id}/delete`);
    return response.data;
}
import { axiosInstance } from "./baseApi";

export const getUserCVDesignrInfos = async(user_id, destination) => {
    const response = await axiosInstance.get(`cvdesignr/user/${user_id}/${destination}`);
    return response.data;
}

export const startCorrectionCVDesignr = async(user_id, cv_id) => {
    const response = await axiosInstance.get(`/cvdesignr/user/${user_id}/cv/${cv_id}/correction`);
    return response.data;
}

export const commentCVDesignr = async(user_id, cv_id) => {
    const response = await axiosInstance.get(`/cvdesignr/user/${user_id}/cv/${cv_id}/commentary`);
    return response.data;
}
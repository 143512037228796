import _keyBy from 'lodash/keyBy'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ComboField from '../../../components/molecules/ComboField/ComboField'
import StatsTable from '../../../components/organisms/StatsTable/StatsTable'
 
import { actions as StatsActions, selectors as StatsSelectors } from '../../../redux/StatsRedux'

const mapStateToProps = (state) => ({
  consultantStats: StatsSelectors.consultantStats(state),
  consultants: StatsSelectors.consultants(state),
  currentConsultant: StatsSelectors.consultantStatsCurrentConsultant(state),
})

const mapDispatchToProps = (dispatch) => ({
  getConsultantStats: () => dispatch(StatsActions.getConsultantStats()),
  setCurrentConsultant: (consultant) =>
    dispatch(StatsActions.setConsultantStatsCurrentConsultant(consultant)),
})

class ManagerConsultantAppointmentTime extends Component {
  static propTypes = {
    currentConsultant: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    consultantStats: PropTypes.arrayOf(PropTypes.object),
    consultants: PropTypes.arrayOf(PropTypes.object),

    getConsultantStats: PropTypes.func.isRequired,
    setCurrentConsultant: PropTypes.func.isRequired,
  }

  static defaultProps = {
    currentConsultant: null,
    consultantStats: null,
    consultants: null,
  }

  handleConsultantChange = ({ value }) => {
    const { setCurrentConsultant } = this.props

    setCurrentConsultant(value)
  }

  renderConsultantStats = () => {
    const { consultantStats } = this.props

    const columns = [
      {
        id: 'month',
        title: '',
        selector: 'month',
        highlight: true,
      },
      {
        id: 'hour_count',
        title: "Total d'heures d'entretiens en RDV",
        selector: 'hour_count',
      },
    ]

    let data = []
    if (consultantStats.length > 1) {
      const monthLabels = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
      const dataValues = _keyBy(consultantStats, 'month')
      const defaultData = {
        tax_excluded: 0,
        accompaniment_count: 0,
        hour_count: 0,
      }

      data = Object.values(monthLabels).map((monthLabelKey, index) => {
        let values = defaultData
        if (dataValues[index + 1]) {
          values = {
            ...values,
            ...dataValues[index + 1],
          }
        }

        return {
          ...values,
          id: index,
          month: monthLabelKey,
        }
      })
    }

    return (
      <>
        <StatsTable className="statsTable" columns={columns} data={data} dataIdSelector="id" />
      </>
    )
  }

  render() {
    const { currentConsultant, consultants } = this.props

    const options = consultants.map((consultant) => ({
      label: `${consultant.first_name} ${consultant.last_name}`,
      value: consultant.id,
    }))

    return (
      <div className="statistics-section">
        <div className="consultant-filter">
          <ComboField
            value={currentConsultant}
            name="consultant"
            title={"Consultant"}
            options={options}
            onChange={this.handleConsultantChange}
          />
        </div>
        {this.renderConsultantStats()}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerConsultantAppointmentTime)
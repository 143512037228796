import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment/moment'

 
import Page from '../../global/Page/Page'

import LineMenu from '../../../components/organisms/LineMenu/LineMenu'
import TabbedContentLayout from '../../../layouts/TabbedContentLayout/TabbedContentLayout'
import ManagerPageAside from '../ManagerPageAside/ManagerPageAside'
import LeftAsideLayout from '../../../layouts/LeftAsideLayout/LeftAsideLayout'
import ComboFilterField from '../../../components/molecules/ComboFilterField/ComboFilterField'
import "./ManagerStatistics.scss";

import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux'
import { actions as StatsActions, selectors as StatsSelectors } from '../../../redux/StatsRedux'

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  managerOfficeStats: StatsSelectors.managerOfficeStats(state),
  statsYear: StatsSelectors.managerOfficeStatsYear(state),
})

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  getOfficeStats: () => dispatch(StatsActions.getManagerOfficeStats()),
  setStatsYear: (year) => dispatch(StatsActions.setManagerOfficeStatsYear(year)),
})

class ManagerStatistics extends Component {
  static propTypes = {
    redirect: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,

    managerOfficeStats: PropTypes.objectOf(PropTypes.any),
    statsYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

    setStatsYear: PropTypes.func.isRequired,
  }

  static defaultProps = {
    managerOfficeStats: null,
  }

  componentDidMount() {
    const { managerOfficeStats, getOfficeStats } = this.props

    if (managerOfficeStats === null) {
      getOfficeStats()
    }
  }

  handleTabChange = (tabId) => {
    const { pathname, redirect } = this.props

    if (/\/office/.test(pathname) && tabId !== 'office') {
      redirect(pathname.replace(/\/office(.*)/, '/beneficiaries'))
    }

    if (/\/beneficiaries/.test(pathname) && tabId !== 'beneficiaries') {
      redirect(pathname.replace('/beneficiaries', '/office'))
    }
  }

  renderTabs = () => {
    const { pathname } = this.props
    const links = [
      { id: 'office', label: "Statistiques du cabinet" },
      { id: 'beneficiaries', label: "Statistiques talents" },
    ]

    return (
      <LineMenu
        value={/\/office/.test(pathname) ? 'office' : 'beneficiaries'}
        links={links}
        onChange={this.handleTabChange}
      />
    )
  }

  handleFilter = ({ value }) => {
    const { setStatsYear } = this.props

    setStatsYear(value)
  }

  renderFilter = () => {
    const { statsYear } = this.props

    const startYear = 2018
    const currentYear = moment().year()
    const diffFromStart = currentYear - startYear

    const options = [
      {
        label: "Année en cours",
        value: currentYear,
      },
    ]
    for (let prevYear = currentYear - 1; prevYear >= currentYear - diffFromStart; prevYear--) {
      options.push({
        label : `Année ${prevYear}`,
        value: prevYear,
      })
    }

    return (
      <div className="year-filter">
        <ComboFilterField
          id="year"
          options={options}
          value={statsYear}
          title={"Choisir une année : "}
          onChange={this.handleFilter}
        />
      </div>
    )
  }

  renderContent = () => {
    const { managerOfficeStats, children } = this.props
    return (
      <div className="stats-wrapper">
        {managerOfficeStats !== null && this.renderFilter()}
        {children}
      </div>
    )
  }

  render() {
    const aside = <ManagerPageAside />

    const content = (
      <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
    )

    return (
      <div className="manager-statistics">
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Page(ManagerStatistics))
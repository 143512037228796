import React, { Component } from 'react'
import { connect } from 'react-redux'
import Page from '../../containers/global/Page/Page'
import OngoingStepModuleWrapper from '../../containers/consultant/OngoingStepModuleWrapper/OngoingStepModuleWrapper'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import ParcoursHeader from '../../components/molecules/ParcoursHeader/ParcoursHeader'
import ParcoursStepHeader from '../../components/molecules/ParcoursStepHeader/ParcoursStepHeader'

import { actions as CourseActions, selectors as CourseSelectors } from '../../redux/CourseRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import Icon from '../../components/atoms/Icon/Icon'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import { selectors as UserSelectors } from '../../redux/UserRedux'
import { actions as OfficeActions, selectors as OfficeSelectors } from '../../redux/OfficeRedux'
import './BeneficiaryExportCoursePage.scss';

const mapStateToProps = (state, props) => ({
  module_id: props.match.params.module_id || NavigationSelectors.query(state, 'module_id'),
  courseId: Number(props.match.params.course_id) || null,
  course: CourseSelectors.course(state),
  pathname: NavigationSelectors.pathname(state),
  user: UserSelectors.user(state),
  office: OfficeSelectors.office(state),
})

const mapDispatchToProps = (dispatch) => ({
  getCourse: (id) => dispatch(CourseActions.getCourse(id)),
  updateCourseValue: (key, value) => dispatch(CourseActions.updateCourseValue(key, value)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  back: () => dispatch(NavigationActions.back()),
  getOffice: () => dispatch(OfficeActions.getCurrentUserOffice()),
})

class BeneficiaryExportCoursePage extends Component {

  state = {
    courseToggled: true,
    openStep: 0,
  }

  constructor(props) {
    super(props)

    const { course } = props

    if (course) {
      this.state.openStep = course.openStep || 0
    }
  }

  /**
   * This cover render does not work either way, as the page is printed with background graphics disabled
   * we need to update this to just show an <img> and place it as a background for the content
   */
  renderHeaderCover = () => {
    return (
      <div
        className="cover-photo"
        style={{ backgroundImage: `url(${this.props.course.cover_url})` }}
      />
    )
  }

  handleParcourToggle = () => {
    this.setState(({ courseToggled }) => ({
      courseToggled: !courseToggled,
    }))
  }

  handleBack = () => {
    this.props.back()
  }

  handleStepToggle = ({ id }) => {
    const openStep = this.state.openStep === id ? '' : id

    this.props.updateCourseValue('openStep', openStep)
    this.setState(({ openStep }) => ({
      openStep,
    }))
  }

  renderCounts = () => {
    const { course } = this.props
    const { steps } = course
    const moduleCount = steps.reduce((sum, { modules }) => sum + modules.length, 0)
    return (
      <div className="course-count">
        <div>
          {steps.length} <span>{steps.length > 1 ? "Etapes" : "Etape"}</span>
        </div>
        <div>
          {moduleCount} <span>{moduleCount > 1 ? "Modules" : "Module"}</span>
        </div>
      </div>
    )
  }

  renderDescription = () => {
    return <div className="course-description">{this.props.course.description}</div>
  }

  renderStepContent = (stepIndex, step) => {
    return (
      <OngoingStepModuleWrapper
        step={step}
        module_id={this.props.module_id}
        stepIndex={stepIndex}
        isBeneficiary
        locked={false}
        exportView={true}
      />
    )
  }

  checkProgress = (values) => {
    return values.every((value) => {
      return value.done === false
    })
      ? 'untouched'
      : 'ongoing'
  }

  renderSteps = () => {
    const { course } = this.props

    return course.steps.map((step, i) => {
      const { title, is_visible } = step
      let progState = 'finish'

      return (
        <ParcoursStepHeader
          key={i}
          id={i}
          open={true}
          isActivated={true}
          progState={progState}
          step={`Etape ${ i + 1}`}
          title={title}
          collapsable={false}
          // onCollapseClick={this.handleStepToggle}
          deletable={false}
          isEditable={false}
          isVisible={is_visible}
        >
          <div className="course-content-list">{this.renderStepContent(i, step)}</div>
        </ParcoursStepHeader>
      )
    })
  }

  renderBackButton = () => {
    return (
      <div className="back-button">
        <CircleButton icon={Icon.icon.Back} onClick={this.handleBack} />
      </div>
    )
  }

  renderCourseContent = () => {
    const { steps, id, title } = this.props.course
    const count = steps.reduce((acc, step) => acc + (step.done ? 1 : 0), 0)
    let icon = 'ongoing'

    if (count === steps.length) {
      icon = 'finish'
    } else if (count === 0) {
      icon = this.checkProgress(steps[0].modules)
    }

    return (
      <ParcoursHeader progState={icon} id={id} open={this.state.courseToggled} title={title}>
        <div className="course-content">
          {this.renderCounts()}
          {this.renderDescription()}
          {this.renderSteps()}
        </div>
      </ParcoursHeader>
    )
  }

  renderContent = () => {
    if (!this.props.course) return

    const { user, office } = this.props

    return (
      <div className="course-layout">
        <img
          className="office-logo"
          src={`${process.env.REACT_APP_ASSETS_API_STATIC_URL}${(office.image_url).replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '')}`}
          alt={office.name}
        />
        <p className="beneficiary-info">
          {user.first_name} {user.last_name}
        </p>
        {/*{this.renderHeaderCover()}*/}
        {this.renderCourseContent()}
      </div>
    )
  }

  componentDidMount() {
    const { course, courseId: id } = this.props

    if (!course || course.id !== id) {
      this.props.getCourse(id)
    }

    this.props.getOffice()
  }

  componentDidUpdate() {
    const { course } = this.props
    const { openStep: prevOpenStep } = this.state
    let openStep = ''

    if (course) {
      openStep = typeof course.openStep === 'undefined' ? 0 : course.openStep

      if (prevOpenStep !== openStep) {
        this.setState({
          openStep,
        })
      }
    }
  }

  render() {
    return (
      <div className="beneficiary-export-course-page">
        <LeftAsideLayout content={this.renderContent()} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(BeneficiaryExportCoursePage))
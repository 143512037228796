import React from "react";
import PropTypes from 'prop-types';

import IconCardTitle from "../../molecules/IconCardTitle/IconCardTitle";
import InputField from "../../molecules/InputField/InputField";
import DateField from "../../molecules/DateField/DateField";
import "./CurrentJobCard.scss";

const CurrentJobCard = ({ title, company, startDate, location, seniority, remuneration, readOnly, locale, onChange }) => {

    const handleChange = (newValue) => {
        if (!readOnly) {
            onChange(newValue)
        }
    }

    const { companyLabel, startDateLabel, locationLabel, seniorityLabel, remunerationLabel, monthLabels, dayShortLabels } = locale
    return (
        <div className="accomp-collective-current-job-card">
            <IconCardTitle icon="clipboard" color="accent" label={title} />

            <div className="accomp-collective-current-job-card-content">
                <InputField
                    title={companyLabel}
                    name={company.id}
                    value={company.value}
                    onChange={handleChange}
                    type="string"
                    readonly={readOnly}
                />
                <DateField
                    title={startDateLabel}
                    name={startDate.id}
                    value={startDate.value}
                    calendarLabels={{ dayShortLabels, monthLabels }}
                    onChange={handleChange}
                    locale={locale}
                    readOnly={readOnly}
                />

                <InputField
                    title={seniorityLabel}
                    name={seniority.id}
                    value={seniority.value}
                    onChange={handleChange}
                    type="string"
                    readonly={readOnly}
                />



                <InputField
                    title={locationLabel}
                    name={location.id}
                    value={location.value}
                    onChange={handleChange}
                    type="string"
                    readonly={readOnly}
                />

                <div className="accomp-collective-current-job-card-content-flex">

                    <InputField
                        title={remunerationLabel}
                        name={remuneration.id}
                        value={remuneration.value}
                        onChange={handleChange}
                        type="string"
                        readonly={readOnly}
                    />

                    <span>K€</span>

                </div>
            </div>
        </div>
    )
}

CurrentJobCard.propTypes = {
    title: PropTypes.string,
    company: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
    }),
    startDate: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.number,
    }),
    location: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.number,
    }),
    seniority: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.number,
    }),
    remuneration: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.number,
    }),
}
export default CurrentJobCard
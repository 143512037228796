import React from "react";
import {IconBackground} from "../../atoms/IconBackground/IconBackground";
import "./ParcoursInfos.scss";

export const ParcoursInfos = ({icon, label, count, colorBackground}) => {
    return (
    <div className="parcours-informations">
        <IconBackground icon={icon} background={colorBackground} color={"accent"}/> 
        <div>
            <label>{label}</label>
            <p>{count}</p>
        </div>
    </div>
    )
}
import { axiosInstance } from "./baseApi";

export const getCountries = async() => {
    const response = await axiosInstance.get('/utils/countries');
    return response.data;
}

export const getAPE = async() => {
    const response = await axiosInstance.get('/utils/ape');
    return response.data;
}

export const setJWT = async (data) => {
    const response = await axiosInstance.post('/utils/auth', data);
    return response.data;
}

export const deleteJWT = async () => {
    const response = await axiosInstance.delete('/utils/logout');
    return response.data;
}
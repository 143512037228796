import React from "react";
import PropTypes from 'prop-types';

import IconCardTitle from "../../molecules/IconCardTitle/IconCardTitle";
import ComboField from "../../molecules/ComboField/ComboField";

import './FollowupProjectCard.scss';

const FollowupProjectCard = ({ title, readOnly, onChange, locale, numberOfRdv, numberOfJobOffer, numberOfWorkshop }) => {
    const handleChange = (newValue) => {
        if (!readOnly) {
            onChange(newValue)
        }
    }


    const { numberOfRdvLabel, numberOfJobOfferLabel, numberOfWorkshopLabel } = locale
    return (
        <div className="accomp-collective-followup-project-card">
            <IconCardTitle icon="clipboard" color="accent" label={title} />
            <div className="accomp-collective-followup-project-card-content">


                <div className="accomp-collective-followup-project-numbers">
                    <ComboField
                        title={numberOfRdvLabel}
                        name={numberOfRdv.id}
                        key={numberOfRdv.id}
                        value={numberOfRdv.value}
                        options={numberOfRdv.options}
                        onChange={handleChange}
                        readOnly={readOnly}
                    />
                    <ComboField
                        title={numberOfJobOfferLabel}
                        name={numberOfJobOffer.id}
                        key={numberOfJobOffer.id}
                        value={numberOfJobOffer.value}
                        options={numberOfJobOffer.options}
                        onChange={handleChange}
                        readOnly={readOnly}
                    />
                    <ComboField
                        title={numberOfWorkshopLabel}
                        name={numberOfWorkshop.id}
                        key={numberOfWorkshop.id}
                        value={numberOfWorkshop.value}
                        options={numberOfWorkshop.options}
                        onChange={handleChange}
                        readOnly={readOnly}
                    />
                </div>
            </div>
        </div>
    )
}
FollowupProjectCard.propTypes = {
    title: PropTypes.string,
    situations: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.object),
    }),
    numberOfRdv: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.object),
    }),
    numberOfJobOffer: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.object),
    }),
    numberOfWorkshop: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.object),
    })
}

export default FollowupProjectCard;
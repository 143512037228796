
import React, { useEffect, useState } from 'react'
import SideResourceItem from '../../molecules/SideResourceButton/SideResourceButton'
import LineMenu from '../LineMenu/LineMenu'
import { Droppable, Draggable } from "react-beautiful-dnd";
import BigCombo from '../../molecules/BigCombo/BigCombo';
import { getRessourcesType } from '../../../api/TemplateCourseApi';
import SearchField from '../../molecules/SearchField/SearchField';

import "./SideResourceSelector.scss";
import IconButton from '../../atoms/IconButton/IconButton';
import Icon from '../../atoms/Icon/Icon';

const SideResourceSelector = ({ id, newRessources, tabs, existingRessources }) => {
  const [tab, setTab] = useState();
  const [existingResources, setExistingResources] = useState([]);
  const [ressourceOwnerFilter, setRessourceOwnerFilter] = useState("all");
  const ressourcesOwnersFilters = [{ label: "Mes ressources", value: "user" }, { label: "Nos ressources", value: "office" }, { label: "Ressources My Talents", value: "mytalents" }, { label: "Toutes les ressources", value: "all" }]
  const [ressourcesTypesFilters, setRessourcesTypesFilters] = useState([]);
  const [ressourceTypeFilter, setRessourceTypeFilter] = useState("all");
  const [search, setSearch] = useState('');

  const handleMenuChange = (id) => {
    setTab(id)
  }

  const handleFiltersChange = (filter) => {
    if(filter.id === "ressources_type") {
      setRessourceTypeFilter(filter.value.value)
      filterTypes(filter.value.value, ressourceOwnerFilter, search)
    }
    else if(filter.id === "ressources_owner") {
      setRessourceOwnerFilter(filter.value.value);
      setRessourceTypeFilter(ressourceTypeFilter)
      filterTypes(ressourceTypeFilter, filter.value.value, search)
    }
    else {
      setSearch(filter.value)
      filterTypes(ressourceTypeFilter, ressourceOwnerFilter, filter.value)
    }
  }

  const filterTypes = (type, owner, searchValue) => {
    if(type !== "all" && type !== "file" && type !== "video" && type !== "link") {
      const filteredResources = Object.fromEntries(
        Object.entries(existingRessources).reduce((acc, [key, value]) => {
          if (value) {
              const filteredValues = value.filter(element => element.type === type);
    
              if (filteredValues.length > 0) {
                acc.push([key, filteredValues]);
              }
              else {
                acc.push([key, []]);
              }
            
          }
          return acc;
        }, [])
      );

      filterOwnerRessource(owner, filteredResources, searchValue);
    }
    else if(type === "file" || type === "video" || type === "link") {
      const filteredResources = Object.fromEntries(
        Object.entries(existingRessources).reduce((acc, [key, value]) => {
          if (value) {
            const filteredValues = value.filter(element => element.type === "document");
            const filteredDocument = filteredValues.filter(element => element.data[0].type === type);
    
              if (filteredDocument.length > 0) {
                acc.push([key, filteredDocument]);
              }
              else {
                acc.push([key, []]);
              }
            
          }
          return acc;
        }, [])
      );
      filterOwnerRessource(owner, filteredResources, searchValue);
    }
    else {
      filterOwnerRessource(owner, existingRessources, searchValue);
    }
  };

  const filterOwnerRessource = (owner, obj, searchValue) => {
    if(owner !== "all") {
      const objectExistingRessource = Object.keys(obj).length === 0 ? existingRessources : obj;
      const filteredResources = Object.keys(objectExistingRessource).map(key => {
        if (key !== owner) {
          return { [key]: [] };
        }
        return { [key]: objectExistingRessource[key] };
      }).reduce((acc, curr) => Object.assign(acc, curr), {});
      filterByTitle(searchValue, filteredResources)
    }
    else {
      const objectExistingRessource = Object.keys(obj).length === 0 ? existingRessources : obj;
      filterByTitle(searchValue, objectExistingRessource)
    }
  }


  const filterByTitle = (title, object) => {
    const lowerCaseTitle = (title ?? '').toLowerCase(); 
  
    const filteredResources = Object.fromEntries(
      Object.entries(object).reduce((acc, [key, value]) => {
        if (value) {
          const filteredValues = value.filter(element => {
            const lowerCaseElementTitle = (element.title ?? '').toLowerCase(); 
            return lowerCaseElementTitle.includes(lowerCaseTitle);
          });
  
          if (filteredValues.length > 0) {
            acc.push([key, filteredValues]);
          }
          else {
            acc.push([key, []]);
          }
        }
        return acc;
      }, [])
    );
    setExistingResources(filteredResources);
  };
  

  useEffect(() => {
    setTab(id);
    getRessourcesType().then((types) => {
      const subTypesDocument = [
        { label: "Document", value: "file" },
        { label: "Lien", value: "link" },
        { label: "Vidéo", value: "video" }
      ];
    
      const ressourceTypes = types.map((element) => {
        let names = element.name;
        if (names.length === 0) {
          names = ['Type non défini'];
        }
    
        return names.map((name) => {
          let valueType = element.type === "document" ? "file" : element.type;
          
          subTypesDocument.forEach((subType) => {
            if (subType.label === name) {
              valueType = subType.value;
            }
          });
    
          return {
            label: name,
            value: valueType
          };
        });
      }).flat();
    
      const allOption = {
        label: "Tous les types de ressource",
        value: "all"
      }
    
      const ressourceTypesWithAll = [allOption, ...ressourceTypes]
      setRessourcesTypesFilters(ressourceTypesWithAll);
    });

  }, [id])

  useEffect(() => {
    tab === undefined ? setTab(tabs[0].id) : setTab(tab);
    setExistingResources(existingRessources)
  }, [tabs, tab, existingRessources])


  const renderFiltersExistingResourcesItems = () => {
    return (
      <div className='filters-ressources'>
        <div className='filters-ressources-title'>
          <IconButton icon={Icon.icon.Settings} />
          <p>FILTRES</p>
        </div>
        <SearchField placeholder={"Rechercher par titre..."} onChange={handleFiltersChange} value={search} />
        <BigCombo id={"ressources_owner"} options={ressourcesOwnersFilters} value={ressourceOwnerFilter} onChange={handleFiltersChange} />
        <BigCombo id={"ressources_type"} options={ressourcesTypesFilters} value={ressourceTypeFilter} onChange={handleFiltersChange} />
      </div>
    )
  }

  const renderExistingResourcesItems = () => {
    return (
      <div>
        {renderFiltersExistingResourcesItems()}
        {Object.keys(existingResources)[0] === "user" &&
          <div className='all-ressources'>
            <label>Mes ressources</label>
            <Droppable droppableId={'user-existing-ressources'} type="RESSOURCE" >
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="droppable">
                  {existingResources["user"]?.map((ressource, index) => (
                    <Draggable key={ressource.id} draggableId={`user-ressource-${ressource.id}`} index={index}>
                      {(provided) => (
                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                          <SideResourceItem
                            key={index}
                            id={ressource.type}
                            label={!ressource.title ? 'Aucun titre' : ressource.title}
                            icon={ressource.type === "document" ? ressource.data[0].type : ressource.type}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        }

        {existingResources.hasOwnProperty("office") && existingResources["office"]?.length !== 0 &&
          <div className='all-ressources'>
            <label>Nos ressources</label>
            <Droppable droppableId={'office-existing-ressources'} type="RESSOURCE">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="droppable">

                  {existingResources["office"]?.map((ressource, index) => (
                    <Draggable key={ressource.id} draggableId={`office-ressource-${ressource.id}`} index={index}>
                      {(provided) => (
                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                          <SideResourceItem
                            key={index}
                            id={ressource.type}
                            label={!ressource.title ? 'Aucun titre' : ressource.title}
                            icon={ressource.type === "document" ? ressource.data[0].type  : ressource.type}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        }

        {Object.keys(existingResources)[1] === "mytalents" && 
          <div className='all-ressources'>
            <label>Ressources My Talents</label>
            <Droppable droppableId={'mytalents-existing-ressources'} type="RESSOURCE">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="droppable">
                  {existingResources["mytalents"]?.map((ressource, index) => (
                    <Draggable key={ressource.id} draggableId={`mytalents-ressource-${ressource.id}`} index={index}>
                      {(provided) => (
                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                          <SideResourceItem
                            key={index}
                            id={ressource.type}
                            label={!ressource.title ? 'Aucun titre' : ressource.title}
                            icon={ressource.type === "document" ? ressource.data[0].type  : ressource.type}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        }
      </div>
    )
  }

  const renderNewResourcesGroups = () => {
    return (
      <Droppable droppableId={'new-ressources'} type="RESSOURCE">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}> 
            {newRessources.map((ressource, index) => (
              ressource.name.length === 1 ?
                <Draggable key={index} draggableId={`ressource-${ressource.type}`} index={index}>
                  {(provided) => (
                    <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} key={index}>
                      <SideResourceItem
                        key={index}
                        id={ressource.type}
                        label={ressource.name[0]}
                        icon={ressource.type}
                      />
                    </div>
                  )}
                </Draggable>
                : ressource.name.map((item, index) => (
                  <Draggable key={index} draggableId={`ressource-${ressource.type} ${item}`} index={index}>
                    {(provided) => (
                      <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} key={index}>
                        <SideResourceItem
                          key={`${index}-${ressource.type}`}
                          id={ressource.type}
                          label={item}
                          icon={item === "Fichier" ? ressource.type : item.toLowerCase()}
                        />
                      </div>
                    )}
                  </Draggable>
                ))
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    )
  }

  return (
    <div className="side-resource-selector">
      <LineMenu links={tabs} value={tab ? tab : ''} onChange={handleMenuChange} />

      <div className="side-resource-selector-content">

        {tab === 'existing' ? renderExistingResourcesItems() : renderNewResourcesGroups()}
      </div>
    </div>
  )
}

export default SideResourceSelector;
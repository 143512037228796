import React from "react";
import { Modal } from "../../../components/atoms/Modal/Modal";

export const DeleteDocumentAccompanimentPopin = ({ open, onClose, handleDeleteDocument }) => {

    const renderContent = () => {
        return (
            <div>
               <p>Êtes-vous sûr(e) de vouloir supprimer ce document ?</p>
            </div>
        )
    }

    return (
        <Modal
            onClosePopin={onClose}
            openPopin={open}
            title={"Supprimer un document"}
            content={renderContent()}
            cancelButton={"Annuler"}
            actionButton={"Supprimer"}
            handleActionButton={handleDeleteDocument}
        />
    )
}
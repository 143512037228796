import React  from "react";
import Icon from "../Icon/Icon";
import { useHistory } from 'react-router-dom';
import './IconBack.scss';

export const IconBack = ({isText, iconColor, data }) => {
    const history = useHistory();
    const handleGoBack = () => {
        if (data) {
            history.push({
                pathname: data.pathname,
                state: data.state,
            });
        } else {
            history.goBack();
        }
    };

    return (
        <div className="icon-back" onClick={handleGoBack}>
            <Icon icon="back" color={iconColor}/>
            {isText && <p>Retour</p>}
        </div>
    )
}
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Icon from '../../atoms/Icon/Icon'
import './InfoPage.scss';

const propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'info']),
  onClick: PropTypes.func,
}

const defaultProps = {
  type: 'info',
  onClick: () => undefined,
}

const icons = {
  success: Icon.icon.Finish,
  error: Icon.icon.Error,
}

const InfoPage = ({ text, type, onClick }) => (
  <div className={classnames('info-page', type)} onClick={onClick}>
    {icons[type] && <Icon icon={icons[type]} />}

    <p>{text}</p>
  </div>
)

InfoPage.propTypes = propTypes
InfoPage.defaultProps = defaultProps

export default InfoPage

/* eslint-disable no-unused-vars */
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import ConversationList from "./ConversationList";
import ChatInput from "./ChatInput";
import ActiveConversation from "./ActiveConversation";
import MessageList from "./MessageList";
import React, { useEffect, useState } from "react";
import Icon from '../atoms/Icon/Icon';

//import { setGlobalSocket, setWebSocket } from "../../redux/ChatRedux";
import { connect, useDispatch } from "react-redux";
import { selectors as UserSelectors } from "../../redux/UserRedux";
import { actions as ChatActions } from "../../redux/ChatRedux";

function IdUserComponent(props) {

    const { idUser } = props;

    // Fonction pour ajouter la prop 'idUser' & 'photo' à tous les enfants et descendants
    const addIdUserToProps = (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { idUser });
      }
      return child;
    };
  
    const traverseChildren = (children) => {
      return React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          if (child.props.children) {
            return React.cloneElement(child, {
              children: traverseChildren(child.props.children),
            });
          }
          return addIdUserToProps(child);
        }
        return child;
      });
    };
  
    return (
      <div>
        {traverseChildren(props.children)}
      </div>
    );
}

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
})

const mapDispatchToProps = (dispatch) => ({
  setWebSocket: (websocket) => dispatch(ChatActions.setWebSocket(websocket)),
  setGlobalSocket: (globalWebsocket) => dispatch(ChatActions.setGlobalSocket(globalWebsocket))
})

function ChatContainer(props) {

    const [activeConversation, setActiveConversation] = useState(null);
    const [nameActiveConversation, setNameActiveConversation] = useState("");
    const [socketReady, setSocketReady] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      const protocol = window.location.protocol;
      let ws1 = "";
      let ws2 = "";

      if (protocol === "https:") {
        ws2 = process.env.REACT_APP_SOCKET_CHAT_HTTPS + "/wss2?room_key=";
        ws1 = process.env.REACT_APP_SOCKET_CHAT_HTTPS + "/wss1";
      } else {
        ws2 = process.env.REACT_APP_SOCKET_CHAT + ":" + process.env.REACT_APP_RATCHET_PORT + "?room_key=";
        ws1 = process.env.REACT_APP_SOCKET_CHAT + ":" + process.env.REACT_APP_GLOBAL_PORT + "";
      }

      const websocket = new WebSocket(ws2 + (activeConversation === null ? '' : activeConversation.room_key));
      const globalSocket = new WebSocket(ws1);

      props.setWebSocket(websocket);
      props.setGlobalSocket(globalSocket);

      // Envoi périodique de messages de ping
      const pingInterval = setInterval(() => {

        if (websocket.readyState === WebSocket.OPEN) {
          websocket.send(JSON.stringify({ type: "ping" }));
        }

        if (globalSocket.readyState === WebSocket.OPEN) {
          globalSocket.send(JSON.stringify({ type: "ping" }));
        }

      }, 20000); // Envoyer un ping toutes les 20 secondes

      //dispatch(setWebSocket(websocket));
      //dispatch(setGlobalSocket(globalSocket));
      
      websocket.onopen = (e) => {
          setSocketReady(true);
      }
      
      return () => {
          websocket.close();
      }
    }, [dispatch, activeConversation, props])

    const pullData = (data) => {
        setActiveConversation(data);
    }

    const pullName = (name) => {
        setNameActiveConversation(name);
    }

    return (
        <>
        <IdUserComponent idUser={props.user.id}>
            <div className="container chat-container mt-2 mb-2">
                <ConversationList onData={pullData} activeName={pullName} socketReady={socketReady} userrole={props.user.role} />
                {activeConversation !== null ?
                <>
                    <div className="container container-full-conversation">
                        <ActiveConversation activeConversation={activeConversation} nameActiveConversation={nameActiveConversation} />
                        <MessageList room={activeConversation.room_key} />
                        <ChatInput activeConversation={activeConversation} />
                    </div>
                </>
                :
                <>
                  <div className="container container-full-conversation">
                    <div className="no-conversation-selected">
                      <Icon icon={Icon.icon.Mail} />
                      <p>Sélectionnez une<br/>conversation</p>
                    </div>
                  </div>
                </>
                }
            </div>
        </IdUserComponent>
        </>

    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatContainer);
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { actions as ColorThemeActions, selectors as ColorThemeSelectors } from "../../../redux/ColorThemeRedux";

import Icon from "../Icon/Icon";
import './DropdownColors.scss';

import { updateThemeColor } from "../../../api/officeApi";

const options = [
    { label: "MyTalents", value: "mytalents" },
    { label: "Violet", value: "purple" },
    { label: "Orange", value: "orange" },
    { label: "Jaune", value: "yellow" },
    { label: "Rouge", value: "red" },
    { label: "Vert", value: "green" },
    { label: "Malvoyant", value: "color-blind" }
];

export const DropdownColors = ({office_id}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();
    const currentTheme = useSelector(ColorThemeSelectors.getTheme);



    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        window.addEventListener("click", handleOutsideClick);

        return () => {
            window.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        dispatch(ColorThemeActions.setTheme(option.value));

        const data = {
            office_id: office_id.office_id,
            theme_color: option.value
        }

        updateThemeColor(data);
    };

    useEffect(() => {
        const theme = localStorage.getItem('theme') || 'mytalents';
        setSelectedOption(options.find(option => option.value === theme));
        dispatch(ColorThemeActions.setTheme(theme));
    }, [dispatch]);

    useEffect(() => {
        if (!currentTheme) {
            setSelectedOption(options.find(option => option.value === "mytalents"));
            dispatch(ColorThemeActions.setTheme("mytalents"));
        } else {
            setSelectedOption(options.find(option => option.value === currentTheme));
        }
    }, [currentTheme, dispatch]);


    return (
        <div className="dropdown-colors" ref={dropdownRef}>
            <div className="dropdown-colors-toggle" onClick={toggleDropdown}>
                {selectedOption ?
                    <div className="selected-color-theme">
                        <div className={`option-color ${selectedOption.value}`} style={{ backgroundColor: `var(--${selectedOption.value}-theme)` }}></div>
                        <p className="option-label">{selectedOption.label}</p>
                    </div>
                    : <p>Sélectionnez un thème...</p>
                }
                <Icon icon={Icon.icon.Combo} color={Icon.color.Accent} />
            </div>
            {isOpen && (
                <ul className="dropdown-colors-options">
                    {options.map((option) => (
                        <li key={option.value} onClick={() => handleOptionClick(option)} className={selectedOption && selectedOption.value === option.value ? "selected" : ""}>
                            <div className={`option-color ${option.value}`} style={{ backgroundColor: `var(--${option.value}-theme)` }}></div>
                            <p className="option-label">{option.label}</p>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import moment from 'moment/moment'
import { connect } from 'react-redux'
import { isValid } from 'redux-form'
import Icon from '../../components/atoms/Icon/Icon'
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton'
import ActionPopinHeader from '../../components/molecules/ActionPopinHeader/ActionPopinHeader'
import AlertPopin from '../../components/organisms/AlertPopin/AlertPopin'
import Page from '../../containers/global/Page/Page'
import { Popin } from '../../containers/global/Popin/Popin'
 
import PopinLayout from '../../layouts/PopinLayout/PopinLayout'
import {
  actions as BeneficiaryActions,
  selectors as BeneficiarySelectors,
} from '../../redux/BeneficiaryRedux'
import {
  actions as ConsultantActions,
  selectors as ConsultantSelectors,
} from '../../redux/ConsultantRedux'
import {
  actions as CourseTemplateActions,
  selectors as CourseTemplateSelectors,
} from '../../redux/CourseTemplateRedux'
import {
  actions as ModuleTemplateActions,
  selectors as ModuleTemplateSelectors,
} from '../../redux/ModuleTemplateRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import { selectors as UserSelectors } from '../../redux/UserRedux'
import ModuleAppointmentEditForm from './ModuleAppointmentEditForm'
import './ModuleAppointmentEditPage.scss';

const mapStateToProps = (state, props) => ({
  id: Number(props.match.params.module_id),
  valid: isValid('module-appointment')(state),
  user: UserSelectors.user(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
  consultants: ConsultantSelectors.consultants(state),
  course: CourseTemplateSelectors.template(state),
  template: ModuleTemplateSelectors.template(state),
  hasChanged: ModuleTemplateSelectors.hasChanged(state),
  pathname: NavigationSelectors.pathname(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  back: (prefix = '/modules') =>
    dispatch(NavigationActions.back(prefix, props.match.params.module_id || prefix === '')),
  getConsultants: () => dispatch(ConsultantActions.getConsultants()),
  loadCourse: () => dispatch(CourseTemplateActions.getTemplate(props.match.params.course_id)),
  loadBeneficiary: () => dispatch(BeneficiaryActions.getBeneficiary(props.match.params.user_id)),
  saveTemplate: (template) => dispatch(ModuleTemplateActions.saveTemplate(template)),
  saveCourseTemplate: (template = null) => dispatch(CourseTemplateActions.saveTemplate(template)),
  setTemplate: (template) => dispatch(ModuleTemplateActions.setTemplate(template)),
  replace: (pathname) => dispatch(NavigationActions.replace(pathname)),
  updateTemplate: (template) => dispatch(ModuleTemplateActions.updateTemplate(template)),
  updateTemplateDeep: (template) => dispatch(ModuleTemplateActions.updateTemplateDeep(template)),
  updateModuleAtIndex: (stepIndex, moduleIndex, module) =>
    dispatch(CourseTemplateActions.updateModuleAtIndex(stepIndex, moduleIndex, module)),
  shareBeneficiary: (beneficiaryId, consultantId) =>
    dispatch(BeneficiaryActions.share(beneficiaryId, consultantId, true)),
})

 
class ModuleAppointmentEditPage extends PureComponent {
  static propTypes = {
    saveCourseTemplate: PropTypes.func.isRequired,
    course: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }

  state = {
    confirmBack: false,
    popinIsOpen: false,
    sharedDone: false,
  }

  handleBack = () => {
    this.props.back()
  }

  handleSave = () => {
    const { course, updateModuleAtIndex, template, beneficiary } = this.props
    const { id, data, stepIndex, moduleIndex } = template
    const isTemplate = this.isTemplate()
    let userFromInBeneficiaryConsultants = false

    if (!isTemplate) {
      if (data.rdv_at) {
        if (!data.status || data.status === 'pending') {
          data.status = 'ok'
        } else if (['ok', 'ko'].includes(data.status)) {
          data.status = 'rescheduled'
        }
      }

      userFromInBeneficiaryConsultants = beneficiary.consultants.reduce(
        (res, consultant) => res || consultant.id.toString() === data.user_from.toString(),
        false,
      )
    }

    if (isTemplate || userFromInBeneficiaryConsultants || this.state.sharedDone) {
      if (!course || id) {
        this.props.saveTemplate(template)
      } else {
        if (typeof stepIndex === 'number' && typeof moduleIndex === 'number') {
          updateModuleAtIndex(stepIndex, moduleIndex, template)
        }

        this.props.saveCourseTemplate()
      }

      updateModuleAtIndex(stepIndex, moduleIndex, template)
      this.props.setTemplate(template)
    } else {
      this.setState({
        popinIsOpen: true,
      })
    }
  }

  handleTitleChange = ({ value }) => {
    this.handleChange({ id: 'title', value })
  }

  handleChange = ({ id, value }) => {
    if (!id) {
      return
    }

    const firstLevel = ['title', 'description']

    firstLevel.includes(id)
      ? this.props.updateTemplate({ [id]: value })
      : this.props.updateTemplateDeep({ data: { [id]: value } })
  }

  handleCancelPopinClick = () => {
    this.setState({
      popinIsOpen: false,
    })
  }

  handleConfirmPopinClick = () => {
    const { template, beneficiary, shareBeneficiary } = this.props

    this.setState(
      {
        popinIsOpen: false,
        sharedDone: true,
      },
      () => {
        this.handleSave()
        shareBeneficiary(beneficiary.id, template.data.user_from)
      },
    )
  }

  handleCloseClick = () => {
    this.props.hasChanged
      ? this.setState(({ confirmBack }) => ({ confirmBack: !confirmBack }))
      : this.handleConfirmLeaveWithoutSave()
  }

  handleConfirmLeaveWithoutSave = () => {
    this.handleBack()
  }

  isTemplate() {
    return !this.props.beneficiary
  }

  renderHeader() {
    const { hasChanged, template } = this.props
    const disableSave = !hasChanged

    return (
      <ActionPopinHeader
        iconButton={Icon.icon.Back}
        boldTitle={template ? template.title : "Rendez-vous"}
        editable
        onIconClick={this.handleCloseClick}
        onTitleChange={this.handleTitleChange}
      >
        <PrimaryButton
          id="save"
          label={"Sauvegarder"}
          disabled={disableSave}
          onClick={this.handleSave}
        />
      </ActionPopinHeader>
    )
  }

  renderContent() {
    const { template, consultants } = this.props
    const isTemplate = template && this.isTemplate()
    const isModuleReady = template?.data?.user_from && consultants.length > 0
    const isReady = isTemplate || isModuleReady
    let users = []

    if (!isReady) {
      return null
    }

    if (consultants) {
      users = consultants.map(({ id, first_name, last_name }) => ({
        value: Number(id),
        label: `${first_name} ${last_name}`,
      }))
    }

    return (
      <ModuleAppointmentEditForm
        onChange={this.handleChange}
        isTemplate={isTemplate}
        consultants={users}
        module={{
          status: template.data.status,
          title: template.title,
          description: template.description,
          rdv_at: template.data.rdv_at,
          rdv_to: template.data.rdv_to,
          user_from: template.data.user_from,
          private_notes: template.data.private_notes,
          public_notes: template.data.public_notes,
          isNew: template.data.isNew || false,
          is_videocall: template.data.is_videocall || false,
        }}
      />
    )
  }

  componentDidMount() {
    const { id, beneficiary, course, template, back, user } = this.props

    if (!template && !id) {
      return back()
    }

    if (!beneficiary && this.props.match?.params?.user_id) {
      this.props.loadBeneficiary()
    }

    if (!course) {
      this.props.loadCourse()
    }

    this.props.getConsultants()

    if (!template || template.data.user_from) {
      return
    }

    this.props.updateTemplate({
      data: {
        ...template.data,
        user_from: user.id,
        ...(beneficiary?.id && {
          user_to: beneficiary?.id,
        }),
        rdv_at:
          beneficiary?.status === 'follow_up'
            ? moment().add(6, 'M')
            : new Date(Date.now() + 24 * 3600000).toISOString(), // + 24 hours
        rdv_to:
          beneficiary?.status === 'follow_up'
            ? moment().add(6, 'M').add(1, 'hours')
            : new Date(Date.now() + 26 * 3600000).toISOString(), // + 2 hours
        isNew: true,
      },
    })
  }

  componentDidUpdate(props) {
    const { id, course, template } = props

    if (id && course) {
      if (!template) {
        let module = null

        for (let i = 0; !module && i < course.steps.length; i++) {
          module = course.steps[i].modules.find((current) => current.id === id)
        }

        this.props.setTemplate(module)
      }
    }
  }

  componentWillUnmount() {
    this.props.setTemplate(null)
  }

  render() {
    return (
      <div className="module-appointment-edit-page">
        <PopinLayout header={this.renderHeader()} content={this.renderContent()} />
        <Popin onClose={this.handleCloseClick} open={this.state.confirmBack}>
          <AlertPopin
            label={"Quittez sans sauvegarder"}
            text={"Voulez-vous quitter sans sauvegarder ? Vos données seront perdues"}
            labelCancelButton={"Non"}
            labelConfirmButton={"Oui"}
            onCancelButtonClick={this.handleCloseClick}
            onConfirmButtonClick={this.handleConfirmLeaveWithoutSave}
          />
        </Popin>

        <Popin onClose={this.handleCancelPopinClick} open={this.state.popinIsOpen}>
          <AlertPopin
            label={"Partager le talent ?"}
            text={"Ce talent n'est actuellement pas partagé avec ce consultant. Affecter ce RDV va automatiquement partagé le talent. Souhaitez-vous continuer ?"}
            labelCancelButton={"Non"}
            labelConfirmButton={"Oui"}
            onCancelButtonClick={this.handleCancelPopinClick}
            onConfirmButtonClick={this.handleConfirmPopinClick}
          />
        </Popin>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(ModuleAppointmentEditPage))
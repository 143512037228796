import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { selectors as UserSelectors } from '../../../redux/UserRedux';
import { selectors as ChatSelectors } from '../../../redux/ChatRedux';

import AvatarCircle from "../AvatarCircle/AvatarCircle";
import Icon from "../Icon/Icon";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useNavigation } from "../../../hooks/useNavigation";
import { selectors as OfficeSelectors } from '../../../redux/OfficeRedux'
import { selectors as portalSelectors } from '../../../redux/PortalRedux'
import { getLinks } from '../../../containers/consultant/ConsultantPageAside/ConsultantPageAside'
import axios from "axios";
import './Navbar.scss';

export const Navbar = ({leftComponentNavbar, aside}) => {
    const u = useSelector(UserSelectors.user);
    const globalSocket = useSelector(ChatSelectors.setGlobalSocket);
    const location = useLocation();
    const { redirect } = useNavigation();
    const [notification, setNotification] = useState(false);
    const pathSegments = location.pathname.split("/");
    const firstPartUrl = pathSegments[1];
    const [user, setUser] = useState({name: "", avatar: "", role: ""});
    const office = useSelector(OfficeSelectors.office)
    const isActive = useSelector(portalSelectors.isActive)
    const [mobileActive, setMobileActive] = useState(false);


    const handleClickProfile = () => {
        u.role === "manager" ? redirect("/" + firstPartUrl + "/office") :  redirect("/" + firstPartUrl + "/profile");
       
    }

    const handleClickMessage = () => {
        setNotification(false);
        redirect("/" + firstPartUrl + "/messages");
    }

    const handleClickBurgerMenu = () => {
        setMobileActive(!mobileActive);
    }

    const getTitleLink = () => {
        let links = getLinks();
        links.push({
            id: '/beneficiary/courses',
            label: "Parcours",
            icon: Icon.icon.Paperboard,
        })
        links.push({
            id: '/beneficiary/calendar',
            label: "Agenda",
            icon: Icon.icon.Calendar,
        })
        links.push({
            id: '/manager/consultants',
            label: "Consultants",
            icon: Icon.icon.People,
        });
        
        links.push({
            id: '/manager/beneficiaries/me',
            label: "Talents",
            icon: Icon.icon.Free,
        });
        
        links.push({
            id: '/manager/statistics',
            label: "Statistiques",
            icon: Icon.icon.Piechart,
        });
        
        links.push({
            id: '/manager/office',
            label: "Cabinet",
            icon: Icon.icon.Building,
        });
        
        const link = links.find(link => location.pathname.includes(link.id));
        return link ? link.label : "";
    }

    useEffect(() => {
        switch(u.role) {

            case 'manager': 
                setUser({name: u.office.name, avatar: u.office.image_url, role: u.role})
            break;

            case 'consultant':
            case'beneficiary': 

                setUser({name: `${u.first_name} ${u.last_name}`, avatar: u.image_url ? u.image_url : null, role: u.role})
            break;

            default:
                setUser({name: "", avatar: "", role: ""})
            break;
        }
    }, [u])

    
    useEffect(() => {

        axios.get(process.env.REACT_APP_API_MY_TALENTS_CHAT + "/chat/room/check-notification", {
            params: {
                id_user: u.id
            }
        }).then(function(res) {

            if (location.pathname !== ("/" + firstPartUrl + "/messages")) {
                if (res.data[0].notification !== null && res.data[0].id_receiver === u.id ) {
                    setNotification(true);

                    const existingData = localStorage.getItem("mtChatNotification");

                    if (existingData === null) {
                        const obj = {
                            room_keys: [res.data[0].cm_room_key],
                            id_receiver: res.data[0].id_receiver,
                            notification: true
                        }
                        localStorage.setItem("mtChatNotification", JSON.stringify(obj));
                    }
                }
            }
        }).catch(function(err) {
        })

    }, [firstPartUrl, location.pathname, u.id])
    

    useEffect(() => {
        const handleGlobalState = (e) => {
            const res = JSON.parse(e.data);
            const id_receiver = res.id_receiver;

            if (location.pathname !== ("/" + firstPartUrl + "/messages")) {
                if (id_receiver === u.id) {
                    setNotification(true);

                    const storedData = localStorage.getItem("mtChatNotification");
                    const parsedStoredData = JSON.parse(storedData);
                    let roomKeysArray = [];

                    if (storedData !== null) {
                        roomKeysArray = parsedStoredData.room_keys;
                    }

                    roomKeysArray.push(res.room_key);

                    const obj = {
                        room_keys: roomKeysArray,
                        id_receiver: res.id_receiver,
                        notification: true
                    }
                    localStorage.setItem("mtChatNotification", JSON.stringify(obj));

                    axios.get(process.env.REACT_APP_API_MY_TALENTS_CHAT + "/chat/room/update-notification", {
                        params: {
                            room_key: res.room_key,
                            notification: true
                        }
                    }).then(function(res) {
                    }).catch(function(err) {
                    })
                }
            }
        }

        if (globalSocket !== undefined && globalSocket !== null) {
            globalSocket.addEventListener("message", handleGlobalState);
        }

        return () => {
            if (globalSocket !== undefined && global !== null) {
                globalSocket.removeEventListener("message", handleGlobalState);
            }
        };
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [u, globalSocket]);

    const messagerie = (number = "!") => {
        const localStorageNotification = localStorage.getItem("mtChatNotification");
        if (localStorageNotification !== null || notification) {
            return (
                <>
                    <div className="red-dot" />
                    <Icon icon={Icon.icon.Message} color={Icon.color.Accent} />
                    <p className="message-alert">Nouveau message</p>
                    <p className="message-number">{number}</p>
                </>
            )
        } else {
            return (
                <>
                    <Icon icon={Icon.icon.Message} color={Icon.color.Accent} />
                    <p>Messagerie</p>
                </>
            )
        }
    }

    const alert = (notification) => {
        if (notification) {
            return (
                <>
                    <div className="red-dot" />
                    <Icon icon={Icon.icon.Ring} color={Icon.color.Accent} />
                </>
            )
        } else {
            return (
                <Icon icon={Icon.icon.Ring} color={Icon.color.Accent} />
            )
        }
    }

    const renderHeader = () => {
        if (!office || !user) {
          return null
        }
        return (
          <>
            <div className="logos" style={{display: 'flex', alignItems: 'center'}}>
              {(!isActive && user.role !== "beneficiary") && (
                <>
                  <img
                    className="mytalents-logo"
                    alt="Logo"
                    src="/static/logos/logo-my-talents-dark-mobile.svg"
                    width={60}
                    height={60}
                  />
                  <div className="separator" />
                  
                </>
              )}
              {office.image_url && <img className='office-logo' src={`${process.env.REACT_APP_ASSETS_API_STATIC_URL}${(office?.image_url).replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '')}`} alt={office.name} />}
              {/* <img className='office-logo' src={`${process.env.REACT_APP_ASSETS_API_STATIC_URL}${(office?.image_url).replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '')}`} alt={office.name} /> */}
            </div>
          </>
        )
      }
    
    return (
        <>
            <div className="menu-mobile" style={{ left: mobileActive ? "0" : "-100%"}}>
                <div className="left-aside-layout">
                    <div className="aside open mobile">
                        {aside}
                    </div>
                </div>
            </div>
            <div className={leftComponentNavbar ? "navbar-space-between" : "navbar"}>
                {leftComponentNavbar &&
                    <>
                        {leftComponentNavbar}
                    </>
                }
                <div className="resources">
                    <div className="resources-header-mobile">
                        {renderHeader()}
                    </div>
                    <h3 className="resources-title-mobile">{getTitleLink()}</h3>
                    <div className="resources-btns-wrapper">
                        <div type="button" className="resources-btn resources-profile" onClick={handleClickProfile}>
                            <div className="resources-avatar">
                                <AvatarCircle photoUrl={user.avatar} />
                            </div>
                            <p>{user.name}</p>
                        </div>
                        <div type="button" className="resources-btn resources-msg" onClick={handleClickMessage}>
                            {/*messagerie(notification, "!")*/}
                            {messagerie()}
                        </div>
                        <div type="button" className="resources-btn resources-alert">
                            {alert(true)}
                        </div>
                        <div type="button" className="resources-btn resources-burger" onClick={handleClickBurgerMenu}>
                            {/*messagerie(notification, "!")*/}
                            <Icon icon={Icon.icon.BurgerMenu} color={Icon.color.Accent} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle';
import ComboField from '../../molecules/ComboField/ComboField';
import InputField from '../../molecules/InputField/InputField';
import DateField from '../../molecules/DateField/DateField';
import DescriptionTextArea from '../../molecules/DescriptionTextArea/DescriptionTextArea';

import { Modal } from '../../atoms/Modal/Modal';
import CircleButton from '../../atoms/CircleButton/CircleButton';
import moment from 'moment';
import './SyntheseCoCard.scss';
import Icon from '../../atoms/Icon/Icon';
// import { Descriptions } from "antd";

export const SyntheseCoCard = ({ title, locale, readOnly, onChange, syntheses }) => {
  const handleChange = (newValue) => {
    if (!readOnly) {
      //   onChange(newValue);
      //   onChange({
      //     "id": 1,
      //     "user_id": 7531,
      //     "title": "test title222",
      //     "edition_date": "2024-01-11 15:37:26",
      //     "content": "caca"
      // },)
    }
  };

  const convertirFormatDate = (dateString) => {
    // Convertir la date string en objet Date
    const dateObj = new Date(dateString);

    // Extraire les composants de la date (année, mois, jour, heure, minute, seconde)
    const annee = dateObj.getFullYear();
    const mois = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const jour = ('0' + dateObj.getDate()).slice(-2);
    const heures = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);
    const secondes = ('0' + dateObj.getSeconds()).slice(-2);

    // Créer la chaîne de date dans le nouveau format
    const nouveauFormatDate = `${annee}-${mois}-${jour} ${heures}:${minutes}:${secondes}`;

    return nouveauFormatDate;
  };

  const handleCreateSynthese = (title, date, description) => {
    date = convertirFormatDate(date);
    const newVal = {
      id: '',
      user_id: '',
      title: title,
      edition_date: date,
      content: description,
    };
    onChange({
      id: 'synthesis',
      value: [...syntheses, newVal],
    });
    setCreateTitle('');
    setCreateDate('');
    setCreateDescription('');
    setIsOpenEdit(false);
  };

  const handleEditSynthese = (id, title, date, description) => {
    // date = convertirFormatDate(date);
    syntheses.find((synthese) => {
      if (synthese.id === id) {
        synthese.title = title;
        synthese.edition_date = date;
        synthese.content = description;
      }
    });
    onChange({
      id: 'synthesis',
      value: [...syntheses],
    });
    setIsOpenView(false);
  };

  const {
    titleSyntheseLabel,
    dateSyntheseLabel,
    descriptionSyntheseLabel,
    monthLabels,
    dayShortLabels,
  } = locale;

  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenView, setIsOpenView] = useState(false);
  const [selectedSynthese, setselectedSynthese] = useState([]);
  const [createTitle, setCreateTitle] = useState('');
  const [createDate, setCreateDate] = useState('');
  const [createDescription, setCreateDescription] = useState('');

  const handleChangeCreateSynthese = (event) => {
    if (event.id === 'title') {
      setCreateTitle(event.value);
    } else if (event.id === 'date') {
      setCreateDate(moment(event.value, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss'));
    } else if (event.id === 'description') {
      setCreateDescription(event.value);
    }
  };

  const handleChangeEditSynthese = (event) => {
    if (event.id === 'title') {
      setselectedSynthese({
        id: selectedSynthese.id,
        user_id: selectedSynthese.user_id,
        title: event.value,
        edition_date: selectedSynthese.edition_date,
        content: selectedSynthese.content,
      });
    } else if (event.id === 'date') {
      setselectedSynthese({
        id: selectedSynthese.id,
        user_id: selectedSynthese.user_id,
        title: selectedSynthese.title,
        edition_date: moment(event.value, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss'),
        content: selectedSynthese.content,
      });
    } else if (event.id === 'description') {
      setselectedSynthese({
        id: selectedSynthese.id,
        user_id: selectedSynthese.user_id,
        title: selectedSynthese.title,
        edition_date: selectedSynthese.edition_date,
        content: event.value,
      });
    }
  };

  const handleDeleteSynthese = (synthese) => {
    onChange({
        id: 'synthesis',
        value: syntheses.filter(item => item.id !== synthese.id),
      });
  }

  const renderEditSynthesePopin = () => {
    return (
      <div className="accomp-collective-synthese-co-popin">
        <InputField
          title={titleSyntheseLabel}
          name={'title'}
          value={createTitle}
          onChange={handleChangeCreateSynthese}
          type="string"
          readonly={readOnly}
        />
        <DateField
          title={dateSyntheseLabel}
          name={'date'}
          value={createDate}
          calendarLabels={{ dayShortLabels, monthLabels }}
          onChange={handleChangeCreateSynthese}
          locale={locale}
          readOnly={readOnly}
        />
        <DescriptionTextArea
          // title={descriptionSyntheseLabel}
          name={'description'}
          placeholder={descriptionSyntheseLabel}
          value={createDescription}
          onChange={handleChangeCreateSynthese}
          type="text"
          readonly={readOnly}
          maxLength={null}
        ></DescriptionTextArea>
      </div>
    );
  };

  const renderViewSynthesePopin = () => {
    return (
      <div className="accomp-collective-synthese-co-popin">
        <InputField
          title={titleSyntheseLabel}
          name={'title'}
          value={selectedSynthese.title}
          onChange={handleChangeEditSynthese}
          type="string"
          readonly={readOnly}
        />
        <DateField
          title={dateSyntheseLabel}
          name={'date'}
          value={moment(selectedSynthese.edition_date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')}
          calendarLabels={{ dayShortLabels, monthLabels }}
          onChange={handleChangeEditSynthese}
          locale={locale}
          readOnly={readOnly}
        />
        <DescriptionTextArea
          // title={descriptionSyntheseLabel}
          name={'description'}
          placeholder={descriptionSyntheseLabel}
          value={selectedSynthese.content}
          onChange={handleChangeEditSynthese}
          type="text"
          readonly={readOnly}
          maxLength={null}
        ></DescriptionTextArea>
      </div>
    );
  };

  const handleSelectSynthese = (synthese) => {
    setIsOpenView(true);
    setselectedSynthese(synthese);
  };

  return (
    <div className="accomp-collective-synthese-co-card">
      <div className="accomp-collective-synthese-co-card-header">
        <IconCardTitle icon="clipboard" color="accent" label={title} />
        {!readOnly && 
        
        <div className="circle-button-wrapper">
          <CircleButton icon="plus" onClick={() => setIsOpenEdit(true)} />
        </div>
        }
      </div>

      <div className="accomp-collective-synthese-co-card-content">
        {syntheses?.map((synthese) => (
          <div className="accomp-collective-synthese-co-card-item">
            <p>{synthese.title}</p>
            <div className="accomp-collective-synthese-co-card-item-actions">
              <CircleButton
                icon="edit"
                color={'white'}
                onClick={() => handleSelectSynthese(synthese)}
              ></CircleButton>

              <CircleButton
                icon="trash"
                color={'white'}
                onClick={() => handleDeleteSynthese(synthese)}
              ></CircleButton>
            </div>
          </div>
        ))}
        {/* <DateField
          title={dateSyntheseLabel}
          name={dateSynthese.id}
          value={dateSynthese.value}
          calendarLabels={{ dayShortLabels, monthLabels }}
          onChange={handleChange}
          locale={locale}
          readOnly={readOnly}
        />
        <InputField
          title={descriptionSyntheseLabel}
          name={descriptionSynthese.id}
          value={descriptionSynthese.value}
          onChange={handleChange}
          type="string"
          readonly={readOnly}
        /> */}
        <Modal
          onClosePopin={() => setIsOpenEdit(false)}
          openPopin={isOpenEdit}
          title={"Création d'une synthèse de suivi"}
          content={renderEditSynthesePopin()}
          cancelButton={'Annuler'}
          disabledActionButton={false}
          actionButton={'Valider'}
          handleActionButton={() =>
            handleCreateSynthese(createTitle, createDate, createDescription)
          }
          // loading={loading}
        />
        <Modal
          onClosePopin={() => setIsOpenView(false)}
          openPopin={isOpenView}
          title={`Modification de la synthèse de suivi "${selectedSynthese?.title}"`}
          content={renderViewSynthesePopin()}
          cancelButton={'Annuler'}
          disabledActionButton={false}
          actionButton={'Valider'}
          handleActionButton={() =>
            handleEditSynthese(
              selectedSynthese?.id,
              selectedSynthese?.title,
              selectedSynthese?.edition_date,
              selectedSynthese?.content,
            )
          }
          // loading={loading}
        />
      </div>
    </div>
  );
};

SyntheseCoCard.propTypes = {
  title: PropTypes.string,
  dateSynthese: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  descriptionSynthese: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default SyntheseCoCard;

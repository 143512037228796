/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { actions as VideoCallActions } from '../../redux/VideocallRedux';
import { connect, useSelector } from "react-redux";
import Icon from "../atoms/Icon/Icon";
import IconButton from "../atoms/IconButton/IconButton";
import { addEvent, checkCalendarSync, getCalendarEvents } from "../../api/CronofyApi";
import { selectors as ChatSelectors } from "../../redux/ChatRedux";
import { selectors as UserSelectors } from '../../redux/UserRedux';
import { generateTwilioToken, createVisioRoom } from "../../api/TwilioApi";

const mapStateToProps = (state, props) => ({
    socket: ChatSelectors.setWebSocket(state),
    globalSocket: ChatSelectors.setGlobalSocket(state),
    globalUser: UserSelectors.user(state)
})

const mapDispatchToProps = (dispatch) => ({
    createInvite: (payload) => dispatch(VideoCallActions.createInvite(payload)),
})

function ConversationItem(props) {

    const [lastMessage, setLastMessage] = useState("\u00A0");
    const [redDot, setRedDot] = useState(false);
    const [notificationLocalStorage, setNotificationLocalStorage] = useState(localStorage.getItem("mtChatNotification"));
    const [superUserCalendar, setSuperUserCalendar] = useState(null);
    const [displayVisio, setDisplayVisio] = useState(true);
    const [visioDisabled, setVisioDisabled] = useState(false);
    const [visioRoom, setVisioRoom] = useState(null);
    const socket = props.socket;

    const handleClick = (event) => {
        props.onData(props.conversation);
        props.activeName(props.name);

        const conversationItems = document.querySelectorAll(".conversation-item");
        const conversationList = document.getElementsByClassName("conversation-container")[0];
        const conversationListMobile = document.getElementsByClassName("conversation-container-mobile")[0];

        conversationList.classList.remove("conversation-mobile");
        conversationListMobile.classList.remove("conversation-mobile");

        if (redDot) {
            setRedDot(false);
            const storedData = localStorage.getItem("mtChatNotification");
            const parsedLocalStorage = JSON.parse(storedData);
            const localIdReceiver = parsedLocalStorage.id_receiver
            let roomKeysArray = [];

            if (storedData !== null) {
                roomKeysArray = parsedLocalStorage.room_keys;
            }

            const filteredRoomKeyArray = roomKeysArray.filter((item) => item !== props.roomKey);

            const obj = {
                room_keys: filteredRoomKeyArray,
                id_receiver: localIdReceiver,
                notification: true
            }

            if (filteredRoomKeyArray.length === 0) {
                localStorage.removeItem("mtChatNotification");
            } else {
                localStorage.setItem("mtChatNotification", JSON.stringify(obj));
            }

            axios.get(process.env.REACT_APP_API_MY_TALENTS_CHAT + "chat/room/update-notification", {
                params: {
                    room_key: props.roomKey,
                    notification: false
                }
            }).then(function(res) {
            }).catch(function(err) {
            })
        }
    }

    const formatDate = (date, addHour) => {

        if (addHour) {
            date.setHours(date.getHours() + 1);
        }

        let year = date.getFullYear();
        let month = ('0' + (date.getMonth() + 1)).slice(-2); // Les mois commencent à 0
        let day = ('0' + date.getDate()).slice(-2);
        let hours = ('0' + date.getHours()).slice(-2);
        let minutes = ('0' + date.getMinutes()).slice(-2);
        let seconds = ('0' + date.getSeconds()).slice(-2);

        return (year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds);
    }

    const handleVideoCallInviteClick = (room_key) => {
        
        setVisioDisabled(true);
        
        createVisioRoom(room_key);

        const join_url = process.env.REACT_APP_VISIO_FRONT_URL + room_key
        
        const message = {
            random_key: (Math.random() * (40000 - 20000) + 20000) + "_" + (Math.random() * (20000 - 10000) + 10000),
            id_room: props.conversation.id,
            room_key: room_key,
            id_author: props.conversation.user_id,
            id_receiver: props.conversation.acquaintance_id,
            content: "Rejoignez-moi en visio grâce au lien suivant : " + join_url,
            date_emitted: new Date().toLocaleString(),
            type: "visio",
            author_name: props.conversation.user_name
        };

        socket.send(JSON.stringify(message));
        
        setTimeout(() => {
            setVisioDisabled(false);
        }, 5000);
    }

    useEffect(() => {

        // get last message
        let isMounted = true;

        const fetchData = async() => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_MY_TALENTS_CHAT + "chat/room/messages", {
                    params: {
                        room_key: props.roomKey,
                        last: true
                    }
                }).then(function(res) {
                    if (isMounted) {
                    if (res.data[0] !== undefined) {
                        if (!res.data[0].is_deleted) {
                            if (res.data[0].type !== "text" && res.data[0].type !== "visio") {
                                setLastMessage("<Fichier...>");
                            } else {
                                setLastMessage(res.data[0].content);
                            }
                        } else {
                            setLastMessage("<Supprimé...>");
                        }
                    } else {
                        setLastMessage("");
                    }
                    }
                }).catch(function(err) {
                })
            } catch (error) {
            }
        };

        fetchData();
    
        return() => {
            isMounted = false;
        }
    }, [props])

    useEffect(() => {

        const parsedLocalStorage = JSON.parse(notificationLocalStorage);

        if (notificationLocalStorage !== null) {
            if (parsedLocalStorage.notification === true) {
                addRedDot(parsedLocalStorage.room_keys, parsedLocalStorage.id_receiver);
            }
        }
    }, [notificationLocalStorage]);

    useEffect(() => {

        checkCalendarSync(7811).then((response) => {
            setSuperUserCalendar(response);
        })
    }, [superUserCalendar]);

    useEffect(() => {

        if (props.globalUser.role !== "consultant" && props.globalUser.role !== "manager" && props.globalUser.role !== "admin") {
            setDisplayVisio(false);
        }
    }, [props.globalUser.role]);

    const addRedDot = (room_keys, id_receiver) => {
        if (room_keys.includes(props.roomKey) && id_receiver === props.idUserToNotify) {
            setRedDot(true);
        }
    }

    return (
        <div className="conversation-item mt-4" onClick={(event) => handleClick(event)}>
            <img src={props.photo === null || props.photo === undefined ? "../static/icons/person-missing.svg" : `${process.env.REACT_APP_ASSETS_API_STATIC_URL}${(props.photo).replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '')}`} alt="user" />
            <div className="conversation-item-content">
                <p className={`conversation-item-p conversation-item-name`}>{props.name !== null ? props.name : "\u00A0"}</p>
                <div className="conversation-item-separator"></div>
                <p className={`conversation-item-p conversation-item-last-message text-muted`}>{ lastMessage !== "" ? lastMessage : "Nouveau chat"}</p>
                {displayVisio &&
                <div className={`button-wrap-visio ${visioDisabled ? "button-wrap-visio-disabled" : ""}`}>
                  <IconButton
                    icon={Icon.icon.Visio}
                    onClick={() => handleVideoCallInviteClick(props.roomKey)}
                    tooltip="Appel vidéo"
                    color={visioDisabled ? "grey1" : "accent"}
                  />
                </div>
                }
                {redDot &&
                    <div className="red-dot-conversation-item"></div>
                }
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationItem);
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './RightAsideLayout.scss';

export default class RightAsideLayout extends PureComponent {
  static propTypes = {
    aside: PropTypes.node,
    content: PropTypes.node,
  }

  render() {
    const { aside, content } = this.props

    return (
      <div className="layout right-aside-layout">
        <section>{content}</section>
        {aside && <aside>{aside}</aside>}
      </div>
    )
  }
}

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

 

import { Popin } from '../../global/Popin/Popin'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'

 
export default class RemoveQuestionFromFormModulePopin extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
  }

  static defaultProps = {
    open: false,
  }

  handleConfirm = () => {
    this.props.onConfirm()
    this.props.onClose()
  }

  render() {
    const { onClose, open } = this.props

    return (
      <Popin onClose={onClose} open={open}>
        <AlertPopin
          label={"Supprimer la question"}
          text={"Voulez - vous vraiment supprimer cette question ?"}
          labelCancelButton={"Annuler"}
          labelConfirmButton={"Supprimer"}
          onCancelButtonClick={onClose}
          onConfirmButtonClick={this.handleConfirm}
        />
      </Popin>
    )
  }
}

import { I18n } from 'react-redux-i18n'
import { call, delay, put, select } from 'redux-saga/effects'
import { actions as NavigationActions } from '../redux/NavigationRedux'
import { actions as NotificationActions } from '../redux/NotificationRedux'
import { actions as UserActions, selectors as UserSelectors } from '../redux/UserRedux'
import { services } from '../services'
import { selectors as PortalSelectors } from '../redux/PortalRedux'
import { getThemeColor } from '../api/officeApi'

import { actions as ColorThemeActions } from "../redux/ColorThemeRedux"
import { deleteJWT, setJWT } from '../api/utils'


export function* login({ payload }) {
  const { email, password, persist } = payload
  yield call(
    services.auth.login,
    { email, password },
    function* ({ ok, data }) {
      const { user, token } = data

      yield put(UserActions.setIsLoading(false))

      ok
        ? yield put(UserActions.loginSuccess(user, token, persist))
        : yield put(UserActions.loginFailure(data))
    },
    true,
  ) 
}

export function* loginFailure({ payload }) {
  const { data } = payload
  const { message } = data[0]

  if (!message) {
    return
  }

  if (message.startsWith('E_USER_DISABLED')) {
    yield put(NavigationActions.push('/auth/disable-account'))
    return
  }

  const errors = {
    E_USER_NOT_FOUND: 'user_not_found',
    E_PASSWORD_MISMATCH: 'incorrect_password',
  }

  const key = Object.keys(errors).find((key) => message.startsWith(key))

  if (key) {
    yield put(NotificationActions.add('error', I18n.t(`form_errors.${errors[key]}`)))
  }
}


export function* loginSuccess({ payload }) {
  const { persist, token, user } = payload;

  const data = {
    user_id: payload.user.id
  }
  setJWT(data);

  try {
    if(user.role !== "beneficiary") {

      const response = yield call(getThemeColor, user.office_id);
      yield put(ColorThemeActions.setTheme(response.theme_color.theme_color));
    }
    else {
      const response = yield call(getThemeColor, user.consultants[0].office_id);
      yield put(ColorThemeActions.setTheme(response.theme_color.theme_color));
    }

  } catch (error) {
  }

  yield put(NavigationActions.evaluate());

  if (persist) {
    yield put(UserActions.setToken(token));
  }
}

export function* refreshToken() {
  const wrapper = yield select(UserSelectors.token)

  if (!wrapper) {
    return yield call(logout, {
      payload: { force: true },
    })
  }

  const { refresh_token, token } = wrapper

  yield call(
    services.auth.refreshToken,
    { token, refresh_token },
    function* ({ ok, data }) {
      yield put(UserActions.setToken(ok ? data : null))
    },
    true,
  )
}

export function* autoRefreshToken() {
  const wrapper = yield select(UserSelectors.token)

  if (!wrapper) {
    return
  }

  const { expires_at } = wrapper
  const margin = 10000
  const expiresIn = expires_at - Date.now()
  const ms = Math.min(7200 * 1000, Math.max(0, expiresIn - margin))

  yield delay(ms)
  yield put(UserActions.refreshToken())
}

export function* logout({ payload }) {
  const { force } = payload
  const token = yield select(UserSelectors.token)

  if (token && !force) {
    yield call(services.auth.logout)
  }

  deleteJWT().then((response) => {
  });

  const localstorageItems = ['_theme', '_assets', '_labels']

  localstorageItems.forEach((e) => {
    localStorage.removeItem(e)
  })

  yield put(UserActions.setToken(null))
  yield put(UserActions.logoutSuccess())

  const slug = yield select(PortalSelectors.slug)

  // replaced push with location reset so to force a refresh and clear the theme css variables that were overwritten
  window.location.href = `/${slug && `${slug}/`}auth/login`
}

export function* forgotPassword({ payload }) {
  yield call(services.auth.forgotPassword, payload, null, true)
}

export function* resetPassword({ payload }) {
  yield call(
    services.auth.resetPassword,
    payload,
    function* ({ ok, data, status }) {
      if (ok) {
        yield put(NavigationActions.replace('/'))
      } else if (status === 401) {
        yield put(NotificationActions.add('error', I18n.t('reset_password.unknown_token')))
        yield put(NavigationActions.replace('/auth/forgot-password'))
      } else {
        yield put(NotificationActions.status({ ok, data }))
      }
    },
    true,
  )
}

export function* sendInvitation({ payload }) {
  const result = yield call(services.auth.sendInvitation, payload)
  yield put(NotificationActions.status(result))
}

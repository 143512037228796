import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import Icon from '../../atoms/Icon/Icon'
import './DropStepZone.scss';

export default class DropStepZone extends Component {
  static propTypes = {
    label: PropTypes.string,
    fileName: PropTypes.string,
    disabled: PropTypes.bool,
    onDrop: PropTypes.func,
  }

  static defaultProps = {
    label: '',
    fileName: '',
    disabled: false,
    onDrop: ({ file }) => undefined,
  }

  state = {
    file: '',
  }

  onDrop = ([file]) => {
    this.props.onDrop({ file })
  }

  renderUploaded = () => {
    return (
      <div className="file-success">
        <Icon icon="document" color="accent" />
        <p>{this.state.file}</p>
      </div>
    )
  }

  render() {
    const { label, disabled } = this.props

    return (
      <div>

        <Dropzone
          className="drop-step-zone"
          activeClassName="drop-step-zone--active"
          onDrop={this.onDrop}
          multiple={false}
          disabled={disabled}
          children={this.state.file ? this.renderUploaded() : label}
        />
      </div>
    )
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Icon from '../../atoms/Icon/Icon'
import IconFormQuestionType from '../../atoms/IconFormQuestionType/IconFormQuestionType'
import './SideQuestionTypeItem.scss'
export default class SideQuestionTypeItem extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.oneOf(Object.values(IconFormQuestionType.questionType)),
  }

  render() {
    const { id, label, icon } = this.props
    return (
      <div className="side-question-type-item" id={id}>
        <IconFormQuestionType questionType={icon} color={Icon.color.Grey3} />
        <p className="label">{label}</p>
      </div>
    )
  }
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isValid, reduxForm } from 'redux-form';
import { getValidationRules, profileFieldsForConsultant } from '../../../helpers/beneficiaryProfileForm';
import { asyncValidate } from '../../../helpers/form/FormValidator/FormValidator';
import { selectors as NavigationSelectors } from '../../../redux/NavigationRedux';
import { selectors as UserSelectors } from '../../../redux/UserRedux';
import { selectors as BeneficiarySelectors } from '../../../redux/BeneficiaryRedux';
import ConsultantBeneficiaryAside from '../ConsultantBeneficiaryAside/ConsultantBeneficiaryAside';
import ConsultantBeneficiary from '../ConsultantBeneficiary/ConsultantBeneficiary';

import NoCommentCvPopin from '../../popins/NoCommentCvPopin/NoCommentCvPopin';

import moment from 'moment';
import 'moment/locale/fr';

import Icon from '../../../components/atoms/Icon/Icon';

import { getUserCVDesignrInfos, startCorrectionCVDesignr, commentCVDesignr } from '../../../api/CVDesignrApi';
import './ConsultantBeneficiaryCvsContainer.scss';

const mapStateToProps = (state, props) => ({
  valid: isValid('beneficiary-profile')(state),
  user: UserSelectors.user(state),
  pathname: NavigationSelectors.pathname(state),
  beneficiary: BeneficiarySelectors.beneficiary(state)
});


const ConsultantBeneficiaryCvsContainer = ({ beneficiary }) => {
  const [talentID, setTalentID] = useState(null);
  const [cvs, setCvs] = useState([]);
  const [showModalNoCommentAllowed, setShowModalNoCommentAllowed] = useState(false);
  const [messageModalNoCommentAllowed, setMessageShowModalNoCommentAllowed] = useState(false);


  useEffect(() => {
    beneficiary && setTalentID(beneficiary.id)
  }, [beneficiary])

  useEffect(() => {
    talentID && userCVDesignr("resumes")
  }, [talentID])

  const userCVDesignr = (destination) => {
    getUserCVDesignrInfos(talentID, destination).then((response) => {
        const cv = response.cvs;
        cv && cv.length > 0 && setCvs(cv)
    })
  }


  const handleCommentClick = (cvId) => {
    if(talentID && cvId) {
      commentCVDesignr(talentID, cvId).then((response) => {
        if (response.editor_url) {
          window.open(response.editor_url, "_blank", "noreferrer");
        } else {
          setShowModalNoCommentAllowed(true)
          if (response.code === 404) {
            if (response.reason === "User or consultant not found") {
              setMessageShowModalNoCommentAllowed("Utilisateur introuvable");
            } else {
              setMessageShowModalNoCommentAllowed("Document non disponible");
            }
          } else if (response.code === 403) {
            setMessageShowModalNoCommentAllowed("L'utilisateur n'a pas encore repris le contrôle de son document");
          } else {
            setMessageShowModalNoCommentAllowed("Une erreur est survenue");
          }
        }
      })
    }
    // setShowModalNoCommentAllowed(true)
    // userCVDesignr(cvId)
  };

  const handleEditClick = (id) => {
    if(cvs && talentID) {
      const currentCV = cvs.find(cv => cv.id === id);
      startCorrectionCVDesignr(talentID, currentCV.id).then((response) => {
        if (response.editor_url) {
          window.open(response.editor_url, "_blank", "noreferrer");
        } else {
          setShowModalNoCommentAllowed(true)
          if (response.code === 404) {
            if (response.reason === "User or consultant not found") {
              setMessageShowModalNoCommentAllowed("Utilisateur introuvable");
            } else {
              setMessageShowModalNoCommentAllowed("Document non disponible");
            }
          } else if (response.code === 403) {
            setMessageShowModalNoCommentAllowed("L'utilisateur n'a pas encore repris le contrôle de son document");
          } else {
            setMessageShowModalNoCommentAllowed("Une erreur est survenue");
          }
        }
      });
      // currentCV.magic_link_edit && window.open(currentCV.magic_link_edit.url, "_blank", "noreferrer");
    }
  };

  const handlePdfClick = (id) => { 
    if(cvs) {
      const currentCV = cvs.find(cv => cv.id === id);
      currentCV.magic_link_download && window.open(currentCV.magic_link_download.url, "_blank", "noreferrer");
    }
    else return null
  };


  const renderAside = () => {
    return <ConsultantBeneficiaryAside />;
  };

  return (
    <ConsultantBeneficiary renderAside={renderAside} disableSave>
      <div className="consultant-beneficiary-cvs-page">
        {cvs && cvs.length === 0
          ? <p>Le talent n'a pas encore ajouté de CV.</p>
          : <table>
            <thead>
              <tr>
                <th>Nom</th>
                <th>Souhait</th>
                <th>Dernière édition</th>
                <th>Commenter</th>
                <th>Editer</th>
                <th>Voir</th>
              </tr>
            </thead>
            <tbody>
              {cvs.map((cv, index) => (
                <tr key={index}>
                  <td>{cv.name}</td>
                  <td>{cv.job_sought}</td>
                  <td>{cv.updated_at ? moment.unix(cv.updated_at).format('DD-MM-YYYY à HH:mm:ss') : "N/A"}</td>
                  <td className="action" onClick={() => handleCommentClick(cv.id)}><Icon icon='edit' color="grey1"/></td>
                  <td className="action" onClick={() => handleEditClick(cv.id)}><Icon icon='edit' color="grey1"/></td>
                  <td className="action" onClick={() => handlePdfClick(cv.id)}><Icon icon='preview' color="grey1"/></td>
                </tr>
              ))}
            </tbody>
          </table>
        }
        {showModalNoCommentAllowed && <NoCommentCvPopin onClose={() => setShowModalNoCommentAllowed(false)} isOpen={showModalNoCommentAllowed} text={messageModalNoCommentAllowed}/>}
      </div>
    </ConsultantBeneficiary>
  );
};



export default connect(mapStateToProps, null)(
  reduxForm({
    form: 'beneficiary-profile',
    asyncBlurFields: profileFieldsForConsultant,
    asyncChangeFields: profileFieldsForConsultant,
    asyncValidate: (values, dispatch, props) => {
      const validationRules = getValidationRules(props.beneficiary, values, true);
      return asyncValidate(values, validationRules);
    },
  })(ConsultantBeneficiaryCvsContainer)
);

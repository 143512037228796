import React, { useState, useEffect } from 'react';

const IframeChecker = ({ url }) => {
  const [iframeLoaded, setIframeLoaded] = useState(null);

  useEffect(() => {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none'; // Ne pas afficher l'iframe
    iframe.src = url;

    const handleLoad = () => {
      setIframeLoaded(true);
      document.body.removeChild(iframe);
    };

    const handleError = () => {
      setIframeLoaded(false);
      document.body.removeChild(iframe);
    };

    iframe.onload = handleLoad;
    iframe.onerror = handleError;

    document.body.appendChild(iframe);

    // Nettoyage
    return () => {
      if (document.body.contains(iframe)) {
        document.body.removeChild(iframe);
      }
    };
  }, [url]);

  if (iframeLoaded === null) {
    return <p>Checking iframe...</p>;
  }

  return (
    <div>
      {iframeLoaded ? (
        <iframe src={url} width="600" height="400" title="Content" style={{width: '100%', height: "400px"}} />
      ) : (
        <p>The URL does not allow embedding in an iframe.</p>
      )}
    </div>
  );
};

export default IframeChecker;
import React  from "react";
import { Popin } from '../../global/Popin/Popin'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'

export const RemoveStepPopin = ({open, onClose, handleRemove}) => {
    return (
        <Popin onClose={onClose} open={open}>
        <AlertPopin
          label={"Supprimer cette étape"}
          text={"Êtes-vous sûr de vouloir supprimer cette étape ?"}
          labelCancelButton={"Annuler"}
          labelConfirmButton={"Supprimer"}
          onCancelButtonClick={onClose}
          onConfirmButtonClick={handleRemove}
        />
      </Popin>
    )
}
import React, { useState } from "react";
import PropTypes from 'prop-types';

import IconCardTitle from "../../molecules/IconCardTitle/IconCardTitle";
import InputField from "../../molecules/InputField/InputField";
import ComboField from "../../molecules/ComboField/ComboField";

import './SolutionCard.scss';

const SolutionCard = ({ title, locale, readOnly, onChange, solutions, newJob, newRemuneration, compensationRemuneration, newActivitySector, onChangeSolution, otherSolution }) => {

    const [showOtherField, setShowOtherField] = useState(false);

    const handleChange = (newValue) => {
        if (!readOnly) {
            onChange(newValue)
        }
    }

    const handleChangeSolution = (newSolutions) => {
        if (newSolutions.id === "others") {
            setShowOtherField(true)
        }
        // if (newSolutions.target.id === "others") {
        //     setShowOtherField(true)
        // }
        if (!readOnly) {
            onChange(newSolutions)
        }
    }

    const { sublabel, newJobLabel, newRemunerationLabel, differentialRemunerationLabel, newActivitySectorLabel } = locale
    return (
        <div className="accomp-collective-solution-card">
            <IconCardTitle icon="clipboard" color="accent" label={title} />
            <div className="accomp-collective-solution-card-content">

                <div className="accomp-collective-identified-solution">
                    <ComboField
                        title={sublabel}
                        name={solutions.id}
                        key={solutions.id}
                        value={solutions.value}
                        options={solutions.options}
                        onChange={handleChangeSolution}
                        readOnly={readOnly}
                    />
                    {showOtherField &&
                        <InputField
                            title={"Autre"}
                            name={otherSolution.id}
                            value={otherSolution.value}
                            onChange={handleChange}
                            type="string"
                            readonly={readOnly}
                        />
                    }
                </div>

                <div className="accomp-collective-solution-card-content-others">
                    <InputField
                        title={newJobLabel}
                        name={newJob.id}
                        value={newJob.value}
                        onChange={handleChange}
                        type="string"
                        readonly={readOnly}
                    />

                    <InputField
                        title={newRemunerationLabel}
                        name={newRemuneration.id}
                        value={newRemuneration.value}
                        onChange={handleChange}
                        type="string"
                        readonly={readOnly}
                    />

                    <ComboField
                        title={differentialRemunerationLabel}
                        name={compensationRemuneration.id}
                        key={compensationRemuneration.id}
                        value={compensationRemuneration.value}
                        options={compensationRemuneration.options}
                        onChange={handleChange}
                        readOnly={readOnly}
                    />

                    <ComboField
                        title={newActivitySectorLabel}
                        name={newActivitySector.id}
                        key={newActivitySector.id}
                        value={newActivitySector.value}
                        options={newActivitySector.options}
                        onChange={handleChange}
                        readOnly={readOnly}
                    />
                </div>
            </div>
        </div>
    )
}


SolutionCard.propTypes = {
    title: PropTypes.string,
    solutions: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.object),
    }),
    newJob: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
    }),
    newRemuneration: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
    }),
    compensationRemuneration: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.object),
    }),
    newActivitySector: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.object),
    }),
    otherSolution: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
    }),
}

export default SolutionCard;
import { Map } from 'immutable';
import { createAction, createReducer } from 'redux-act';

const initialState = Map({
  theme: localStorage.getItem('theme') || 'mytalents',
});

export const actions = {
  setTheme: createAction('setTheme', (theme) => ({ theme })),
};

export const reducer = createReducer(
  {
    [actions.setTheme]: (state, { theme }) => {
      localStorage.setItem('theme', theme);
      return state.set('theme', theme);
    },
  },
  initialState,
);

export const selectors = {
  getTheme: (state) => state.colorTheme.get('theme'),
};

import React, { Component } from 'react'
import FieldContainer from '../FieldContainer/FieldContainer'
import DescriptionTextArea from './DescriptionTextArea'

class DescriptionTextAreaContainer extends Component {
  render() {
    return <DescriptionTextArea {...this.props} />
  }
}

export default FieldContainer({ type: 'text' })(DescriptionTextAreaContainer);
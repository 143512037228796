import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconResourceType from '../../atoms/IconResourceType/IconResourceType'
import Icon from '../../atoms/Icon/Icon'
import CircleButton from '../../atoms/CircleButton/CircleButton'
import './ParcoursModuleContentFileReturnBeneficiary.scss'
export default class ParcoursModuleContentFileReturnBeneficiary extends Component {
  static propTypes = {
    id: PropTypes.any,
    consultantFileName: PropTypes.string.isRequired,
    isFinished: PropTypes.bool,
    isActivated: PropTypes.bool,
    onDownloadClick: PropTypes.func,
    onUploadClick: PropTypes.func,
    locale: PropTypes.shape({
      returnDocument: PropTypes.string.isRequired,
      completedDocument: PropTypes.string.isRequired,
      dropFile: PropTypes.string.isRequired,
    }).isRequired,
    url: PropTypes.string,
    urlUploaded: PropTypes.string,
  }

  static defaultProps = {
    isFinished: false,
    isActivated: false,
    onDownloadClick: ({ id }) => undefined,
    onUploadClick: ({ id }) => undefined,
    urlUploaded: '',
  }

  handleDownloadClick = () => {
    this.props.onDownloadClick({
      id: this.props.id,
    })
  }

  handleUploadClick = () => {
    this.props.onUploadClick({
      id: this.props.id,
    })
  }

  truncateString = (fullStr) => {
    if (!fullStr) return
    const strLen = 40
    if (fullStr.length <= strLen) return fullStr

    const separator = '...'
    const sepLen = separator.length
    const charsToShow = strLen - sepLen
    const frontChars = Math.ceil(charsToShow / 2)
    const backChars = Math.floor(charsToShow / 2)

    return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars)
  }

  render() {
    const {
      consultantFileName,
      isFinished,
      isActivated,
      locale,
      url,
      typeUploaded,
      urlUploaded,
    } = this.props

    return (
      <div
        className={`parcours-module-content-file-return-beneficiary ${
          isActivated ? 'activated' : ''
        }`}
      >
        <div className="consultant">
          <div className="text">
            <p className="label">{locale.returnDocument}</p>
            <IconResourceType
              resourceType={IconResourceType.resourceType.fileReturn}
              color={isActivated ? Icon.color.Accent : Icon.color.Grey2}
            />
            <a href={url} target="_blank" className="file-name">
              {consultantFileName}
            </a>
          </div>

          {!isFinished && (
            <a href={url} target="_blank" download>
              <CircleButton
                icon={Icon.icon.Download}
                backgroundColor={isActivated ? Icon.color.Accent : Icon.color.Grey2}
                onClick={this.handleDownloadClick}
                isActivated={isActivated}
              />
            </a>
          )}
        </div>

        <span className="separator" />

        <div className="beneficiary">
          <div className="text">
            <p className="label">{locale.completedDocument}</p>
            <p className="file-name">
              {urlUploaded ? (
                <a href={urlUploaded} target="_blank">
                  {this.truncateString(urlUploaded)}
                </a>
              ) : (
                locale.dropFile
              )}
            </p>
          </div>

          {urlUploaded && (
            <a href={urlUploaded} target="_blank" download>
              <CircleButton
                icon={typeUploaded === 'link' ? Icon.icon.Preview : Icon.icon.Download}
                backgroundColor={isActivated ? Icon.color.Accent : Icon.color.Grey2}
                onClick={this.handleDownloadClick}
                isActivated={isActivated}
              />
            </a>
          )}

          {!isFinished && (
            <CircleButton
              icon={Icon.icon.Upload}
              backgroundColor={isActivated ? Icon.color.Accent : Icon.color.Grey2}
              onClick={this.handleUploadClick}
            />
          )}
        </div>
      </div>
    )
  }
}

import config from '../config/BeneficiaryConfig'

/* Status when a consultant is creating beneficiary */
export const duringCreation = (user) => {
  return user.status == null || user.status === ''
}

/* Status after beneficiary added by a consultant */
export const inCreation = (user) => {
  if (user.status === config.statuses.created) {
    const creationFormInputs = ['phone_mobile', 'cursus_professional_situation']
    let checkInputs = creationFormInputs.reduce(
      (res, key) => res && user[key] != null && user[key] !== '',
      true,
    )

    if (
      checkInputs &&
      (user.cursus_professional_situation === 'cdd' || user.cursus_professional_situation === 'cdi')
    ) {
      checkInputs = user.cursus_company_activity_sector != null
    }

    return !checkInputs
  }

  return false
}

/* Status after beneficiary fill "BeneficiaryCreationPage" form and before consultant checked info */
export const waitingCreationValidation = (user) => {
  if (user.status === config.statuses.created) {
    const creationFormInputs = ['phone_mobile', 'cursus_professional_situation']
    let checkInputs = creationFormInputs.reduce(
      (res, key) => res && user[key] != null && user[key] !== '',
      true,
    )

    if (
      checkInputs &&
      (user.cursus_professional_situation === 'cdd' || user.cursus_professional_situation === 'cdi')
    ) {
      checkInputs = user.cursus_company_activity_sector != null
    }

    return checkInputs
  }

  return false
}

/* Only check Wait Financer Status */
export const hasWaitFinancerStatus = (user) => {
  return user.status === config.statuses.wait_financer
}

/* Status when beneficiary need to fill financer info */
export const needToFillFinancerInfo = (user) => {
  return (
    user.status === config.statuses.wait_financer &&
    user.financer_state === config.financer_states.to_fill
  )
}

/* Status after beneficiary fills financer info and before consultant checked info */
export const waitingFinancerInfoValidation = (user) => {
  return (
    user.status === config.statuses.wait_financer &&
    (user.financer_state === config.financer_states.pending ||
      user.financer_state === config.financer_states.done)
  )
}

/* Status after financer validation and beneficiary need to complete personnal info */
export const needToCompletePersonnalInfo = (user) => {
  if (user.status === config.statuses.in_validation) {
    const personnalInfoFormInputs = [
      'birth_date',
      'address',
      'zip_code',
      'city',
      'cursus_professional_situation',
    ]
    let checkInputs = personnalInfoFormInputs.reduce(
      (res, key) => res && user[key] != null && user[key] !== '',
      true,
    )

    if (
      checkInputs &&
      (user.cursus_professional_situation === 'cdd' || user.cursus_professional_situation === 'cdi')
    ) {
      const cddCdiInfoFormInputs = [
        'cursus_socio_professional_category',
        'cursus_company_activity_sector',
        'diploma_name',
        'cursus_function',
      ]
      checkInputs = cddCdiInfoFormInputs.reduce(
        (res, key) => res && user[key] != null && user[key] !== '',
        true,
      )
    }

    return !checkInputs
  }

  return false
}

/* Status when beneficiary filled personnal info and wait consultant for validation */
export const waitingPersonnalInfoValidation = (user) => {
  if (user.status === config.statuses.in_validation) {
    const personnalInfoFormInputs = [
      'birth_date',
      'address',
      'zip_code',
      'city',
      'cursus_professional_situation',
    ]
    let checkInputs = personnalInfoFormInputs.reduce(
      (res, key) => res && user[key] != null && user[key] !== '',
      true,
    )

    if (
      checkInputs &&
      (user.cursus_professional_situation === 'cdd' || user.cursus_professional_situation === 'cdi')
    ) {
      const cddCdiInfoFormInputs = [
        'cursus_socio_professional_category',
        'cursus_company_activity_sector',
        'diploma_name',
        'cursus_function',
      ]
      checkInputs = cddCdiInfoFormInputs.reduce(
        (res, key) => res && user[key] != null && user[key] !== '',
        true,
      )
    }

    return checkInputs
  }

  return false
}

/* Status when beneficiary has is 'In validation' */
export const hasInValidationStatus = (user) => {
  return user.status === config.statuses.in_validation
}

/* Status when beneficiary is in progress */
export const inProgress = (user) => {
  return user.status === config.statuses.in_progress
}

/* Status when beneficiary is follow up */
export const inFollowUp = (user) => {
  return user.status === config.statuses.follow_up
}

/* Status when beneficiary has follow up */
export const hasFollowUpStatus = (user) => {
  return user.status === config.statuses.follow_up
}

/* Status when beneficiary is archived */
export const isArchived = (user) => {
  return user.status === config.statuses.archived
}

import React from "react";
import { Modal } from "../../../components/atoms/Modal/Modal";

import './DeleteGroupPopin.scss';
export const DeleteGroupPopin = ({ group, open, onClose, onDeleteGroup }) => {

    const renderContent = () => {
        const { name } = group
        return (
            <div className="delete-group-popin">
                <p>Voulez vous définitivement supprimer le groupe <span>{name}</span>  ? Cette action est irréversible et entrainera la suppression de ce groupe dans la base de donnée.</p>
            </div>
        )
    }
    return (
        <Modal
            onClosePopin={onClose}
            openPopin={open}
            title={"Suppression du groupe"}
            content={renderContent()}
            cancelButton={"Annuler"}
            actionButton={"Supprimer"}
            handleActionButton={onDeleteGroup}
        />
    )
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CircleButton from '../../atoms/CircleButton/CircleButton'
import Icon from '../../atoms/Icon/Icon'
import './EmptyCard.scss';

export default class EmptyCard extends Component {
  static propTypes = {
    id: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string = '',
  }

  static defaultProps = {
    onClick: () => {},
  }

  onClick = (e, id) => {
    this.props.onClick({ id })
  }

  render() {
    const { id, className, onClick } = this.props

    return (
      <div className={`empty-card ${className && className}`} onClick={onClick}>
        <CircleButton icon={Icon.icon.PersonPlus} id={id} onClick={onClick} />
        <div className="border-top" />
        <div className="border-right" />
        <div className="border-bottom" />
        <div className="border-left" />
      </div>
    )
  }
}

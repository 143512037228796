import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isValid } from 'redux-form'

import { selectors as NavigationSelectors } from '../../../redux/NavigationRedux'
import { actions as OfficeActions, selectors as OfficeSelectors } from '../../../redux/OfficeRedux'
import { actions as WSActions, selectors as WSSelectors } from '../../../redux/WSRedux'
import { actions as UserActions } from '../../../redux/UserRedux'
import france_region from "../../../static/json/regions.json"
import { Popin } from '../../global/Popin/Popin'
import EditCustomerPopin from '../../../components/organisms/EditCustomerPopin/EditCustomerPopin'

const mapStateToProps = (state) => ({
  valid: isValid('office')(state),
  id: NavigationSelectors.lastParam(state),
  office: OfficeSelectors.office(state),
  saved: WSSelectors.resolved(state, 'office.save'),
})

const mapDispatchToProps = (dispatch) => ({
  getOffice: (id) => dispatch(OfficeActions.getOffice(id)),
  setOffice: (office) => dispatch(OfficeActions.setOffice(office)),
  saveOffice: (office, dontSet) => dispatch(OfficeActions.saveOffice(office, dontSet)),
  sendInvitation: (id) => dispatch(UserActions.sendInvitation(id)),
  resetSaveAction: () => dispatch(WSActions.reset('office.save')),
})

class AdminOfficeEdit extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  }

  state = {
    emailValue: '',
  }

  handleClose = () => {
    this.props.setOffice(null)
    this.props.onClose()
  }

  handleSubmit = (office) => {
    this.props.saveOffice(office, true)
  }

  handleSendInvitation = () => {
    const {
      office: { manager },
    } = this.props

    this.props.sendInvitation(manager.id)
  }

  componentDidMount() {
    const { id, getOffice } = this.props

    if (/\d+/.test(id)) {
      getOffice(id)
    }
  }

  componentDidUpdate() {
    if (this.props.saved) {
      this.props.resetSaveAction()
      this.handleClose()
    }
  }

  render() {
    const { id, valid } = this.props
    const office = this.props.office || {
      name: '',
      phone: '',
      email: '',
      region: '',
      no_consultants: 5,
      no_consultants_allocated: 0,
    }

    if (!office.id && /\d+/.test(id)) {
      return null
    }

    return (
      <Popin onClose={this.handleClose} open>
        <EditCustomerPopin
          key={office.id}
          onSubmit={this.handleSubmit}
          onClose={this.handleClose}
          onResendInvitation={this.handleSendInvitation}
          office={office}
          valid={valid}
          locale={{
            title: office ? 'Edition client' : 'Création client',
            cta: "Valider",
            notActivatedYet: "Le manager n'a pas encore activé son compte",
            presentationTitle: "Présentation",
            subscriptionTitle: "Abonnement",
            validateResendInvitation: "Renvoyer l'invitation",
            resendInvitation: "Renvoyer l'invitation",
            nameTitle: "Nom de l'entreprise",
            namePlaceholder: "Nom de l'entreprise",
            emailTitle: "Email",
            emailPlaceholder: "Email",
            phoneTitle: "Téléphone",
            phonePlaceholder: "Téléphone",
            regionTitle: "Localisation",
            regionPlaceholder: "Localisation",
            regions: france_region,
            noConsultantsAvailable: "Total d'accès consultant disponibles",
            noConsultantsAllocated: "Nombre d'accès consultant utilisés",
          }}
        />
      </Popin>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminOfficeEdit)
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModuleRow from '../../../components/molecules/ModuleRow/ModuleRow'
import SectionTitle from '../../../components/molecules/SectionTitle/SectionTitle'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux'
import { actions as ResourceTemplateActions } from '../../../redux/ResourceTemplateRedux'
import { selectors as UserSelectors } from '../../../redux/UserRedux'
import { Popin } from '../../global/Popin/Popin'
import './ResourceList.scss'

// const resourceTypes = {
//   document: 'document',
//   document_to_return: 'document_to_return',
//   video: 'video',
//   link: 'link',
//   form: 'form',
//   feedback: 'feedback'
// }

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  pathname: NavigationSelectors.pathname(state),
})

const mapDispatchToProps = (dispatch) => ({
  removeTemplate: (id) => dispatch(ResourceTemplateActions.removeTemplate(id)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})

class ResourceList extends Component {
  static propTypes = {
    resources: PropTypes.array.isRequired,
    editable: PropTypes.bool,
    onEditClick: PropTypes.func,
    onVideoClicked: PropTypes.func.isRequired,
  }

  state = {
    openRow: '',
    showConfirm: false,
    onEditClick: ({ id, type, title, url }) => undefined,
  }

  isEditable({ template_owner }) {
    if (!template_owner) {
      return false
    }

    const { user } = this.props
    const { role, profile_id } = user

    switch (role) {
      case 'admin':
        return true
      case 'consultant':
        return template_owner.consultant_id === profile_id
      default:
        return false
    }
  }

  handleChevron = ({ id }) => {
    this.state.openRow === id ? this.setState({ openRow: '' }) : this.setState({ openRow: id })
  }

  removePopin = () => {
    this.setState({ showConfirm: false, currentId: null })
  }

  handleConfirmRemove = () => {
    this.props.removeTemplate(this.state.currentId)
    this.removePopin()
  }

  handleEdit = ({ id }) => {
    const resource = this.props.resources.find((resource) => resource.id === id)

    this.props.onEditClick(resource)
  }

  renderConfirmClose = () => {
    const { removing } = this.props

    return (
      <Popin onClose={this.removePopin} open={this.state.showConfirm}>
        <AlertPopin
          disabled={removing}
          label={"Supprimer une ressource"}
          text={"Voulez-vous vraiment supprimer cette ressource ?"}
          labelCancelButton={"Annuler"}
          labelConfirmButton={"Supprimer"}
          onCancelButtonClick={this.removePopin}
          onConfirmButtonClick={this.handleConfirmRemove}
        />
      </Popin>
    )
  }

  handleRemove = ({ id }) => {
    this.setState({ showConfirm: true, currentId: id })
  }

  renderResources = (resources) => {
    const mapType = {
      link: 'file',
      document_to_return: 'file-return',
    }

    return resources.map((resource, i) => {
      const { id, type, title, url } = resource;
      let resourceType = mapType[type] || type;

      if (type === "document" && resource.data && resource.data.length > 0) {
        const subType = resource.data[0].type;
        if (subType) {
          resourceType = subType;
        }
      } else {
        resourceType = type;
      }
      return (
        <ModuleRow
          key={i}
          id={id}
          moduleType={resourceType}
          editable={this.isEditable(resource)}
          label={title}
          onEditClick={this.handleEdit}
          onRemoveClick={this.handleRemove}
          onChevronClick={this.handleChevron}
          onVideoClicked={this.props.onVideoClicked}
          url={url}
        />
      )
    })
  }

  getSubType = (resource) => {
    if (resource.type === "document" && resource.data && resource.data.length > 0) {
      return resource.data[0].type;
    }
    return resource.type;
  };

  renderResourceCategory = ({ type, resources }) => {
    const order = resources.length === 0 ? 4 : null;

    let labelType = '';
    if (type === "file") {
      labelType = "Fichiers";
    } else if (type === "video") {
      labelType = "Vidéos";
    } else if (type === "link") {
      labelType = "Liens";
    } else if (type === "feedback") {
      labelType = "Feedback";
    } else if (type === "document_to_return") {
      labelType = "Documents à retourner";
    } else if (type === "form") {
      labelType = "Formulaire";
    } 

    else {
      // Pour les autres types de ressources, nous utilisons simplement le type lui-même comme libellé
      labelType = type.charAt(0).toUpperCase() + type.slice(1);
    }

    return (
      <div style={{ order }} key={type}>
        <SectionTitle label={labelType} />
        {this.renderResources(resources)}
      </div>
    );
  };

  renderSections = (types) => {
    return Object.keys(types).map((type) => {
      return this.renderResourceCategory({ type, resources: types[type] });
    });
  };

  renderModuleCollections = () => {
    const categories = {
      file: [],
      video: [],
      link: [],
      feedback: [],
      document_to_return: [],
    };

    this.props.resources.forEach((resource) => {
      const subType = this.getSubType(resource);
      if (categories[subType]) {
        categories[subType].push(resource);
      } else {
        categories[subType] = [resource];
      }
    });

    return this.renderSections(categories);
  };

  render() {
    
    return (
      <>
        {this.renderConfirmClose()}
        <div className="resource-list">{this.renderModuleCollections()}</div>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceList)

import CircleButton from '../../atoms/CircleButton/CircleButton'
import Icon from '../../atoms/Icon/Icon'
import PropTypes from 'prop-types'
import React from 'react'
import './PasswordPopin.scss'
const PasswordPopin = ({ title, description, done = false, onClose = () => {}, children }) => {
  return (
    <div className="password-popin">
      <div className="close-button">
        <CircleButton icon={Icon.icon.Close} onClick={onClose} />
      </div>

      <div className="title">{title}</div>
      <div className="description">{description}</div>

      {!done && children}
    </div>
  )
}

PasswordPopin.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  done: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default PasswordPopin

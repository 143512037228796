import React from "react";
import CircleButton from "../../../../atoms/CircleButton/CircleButton";
import './SynthesisRessource.scss';
import { useSelector } from "react-redux";
import { selectors as UserSelectors } from "../../../../../redux/UserRedux";

export const SynthesisRessource = ({ressource = null, isExport=false}) => {
    const user = useSelector(UserSelectors.user);
    return (
        <div>
            <div className="synthesis-ressource">
                <p>Synthèse d'étape</p>
                <CircleButton icon={"edit"}/>
            </div>
            {isExport && 
            <div>
                <p>Consultant : {ressource.consultant_notes}</p>
                {user.role !== 'beneficiary' && <p>Consultant note privée : {ressource.consultant_private_notes}</p>}
                <p>Beneficiaire :{ressource.beneficiary_notes}</p>
                </div>
            }
        </div>
    )
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Logo from '../../static/assets/logo-my-talents.svg'
import Icon from '../../components/atoms/Icon/Icon';
import './DisableAccountPage.scss'

import { actions as NavigationActions } from '../../redux/NavigationRedux'

const mapDispatchToProps = (dispatch) => ({
  backLogin: () => dispatch(NavigationActions.push('/auth/login')),
})

class DisableAccountPage extends Component {
  static propTypes = {
    backLogin: PropTypes.func.isRequired,
  }

  handleBackLogin = () => {
    const { backLogin } = this.props

    backLogin()
  }

  render() {

    return (
      <div className="disable-account-page">
        <Logo className="logo" />

        <div className="content">
          <div className="title">{"Bonjour,"}</div>
          <div className="message">{"Votre compte a été désactivé. Veuillez contacter votre manager pour de plus amples informations."}</div>
          <div className="back-login-button" onClick={this.handleBackLogin}>
            <Icon icon={Icon.icon.Back} color={Icon.color.Grey1} />
            <div className="label">{"Retour à la page de connexion"}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(DisableAccountPage)
import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectors as AppSelectors } from '../../../redux/AppRedux'
import { Popin } from '../Popin/Popin'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'

const LeavePopin = ({ cancel, confirm }) => {
  const displayLeavePopin = useSelector(AppSelectors.displayLeavePopin)
  return (
    <Popin onClose={cancel} open={displayLeavePopin}>
      <AlertPopin
        label={"Quitter sans sauvegarder ?"}
        text={"Voulez-vous quitter sans sauvegarder ? Vos données seront perdues"}
        labelCancelButton={"Non"}
        labelConfirmButton={"Oui"}
        onCancelButtonClick={cancel}
        onConfirmButtonClick={confirm}
      />
    </Popin>
  )
}

LeavePopin.propTypes = {
  cancel: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
}

export default LeavePopin

import { axiosInstance } from "./baseApi";


export const getAllMissions = async(user_id) => {
    const response = await axiosInstance.get(`missions/user/${user_id}/all`);
    return response.data;
}

export const getMission = async(mission_id) => {
    const response = await axiosInstance.get(`missions/${mission_id}`);
    return response.data;
}

export const getMissionsByClient = async(client_id) => {
    const response = await axiosInstance.get(`missions/client/${client_id}`);
    return response.data;
}

export const getMissionsByConsultant = async(consultant_id) => {
    const response = await axiosInstance.get(`missions/user/${consultant_id}`);
    return response.data;
}

export const getMissionsByTalent = async (talent_id) => {
    const response = await axiosInstance.get(`missions/user/${talent_id}`);
    return response.data;
}

export const addMission = async(new_mission) => {
    const response = await axiosInstance.post(`missions/add`, new_mission);
    return response.data;
}

export const addTalentMission = async(mission_id, new_talent) => {
    const response = await axiosInstance.post(`missions/${mission_id}/beneficiary/add`, new_talent);
    return response.data;
}

export const addConsultantMission = async(mission_id, new_consultant) => {
    const response = await axiosInstance.post(`missions/${mission_id}/consultant/add`, new_consultant);
    return response.data;
}

export const updateMission= async (mission_id, mission_updated) => {
    const response = await axiosInstance.put(`missions/${mission_id}/update`, mission_updated);
    return response.data;
}

export const updateConsultantMission= async (mission_id, consultant_id, mission_updated) => {
    const response = await axiosInstance.put(`missions/${mission_id}/consultant/${consultant_id}/update`, mission_updated);
    return response.data;
}

export const updateTalentMission= async (mission_id, talent_id, mission_updated) => {
    const response = await axiosInstance.put(`missions/${mission_id}/beneficiary/${talent_id}/update`, mission_updated);
    return response.data;
}

export const deleteMission = async (mission_id) => {
    const response = await axiosInstance.delete(`missions/${mission_id}/delete`);
    return response.data;
}

export const deleteConsultantMision = async(mission_id, consultant_id) => {
    const response = await axiosInstance.delete(`missions/${mission_id}/consultant/${consultant_id}/delete`);
    return response.data;
}

export const deleteTalentMission = async(mission_id, talent_id) => {
    const response = await axiosInstance.delete(`missions/${mission_id}/beneficiary/${talent_id}/delete`);
    return response.data;
}

export const dowloadMission = async(mission_id) => {
    const response = await axiosInstance.get(`missions/${mission_id}/download/excel`);
    return response.data;
}

export const getAccompagnements = async () => {
    const response = await axiosInstance.get(`/users/profiles_types_infos`);
    return response.data;
}
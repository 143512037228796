import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle'
import ComboField from '../../molecules/ComboField/ComboField'
import Icon from '../../atoms/Icon/Icon'
import InputField from '../../molecules/InputField/InputField'
import CircleButton from '../../atoms/CircleButton/CircleButton'
import InfoField from '../../molecules/InfoField/InfoField'
import FileUploadField from '../../molecules/FileUploadField/FileUploadField'
import './FinancialEditionCard.scss'
import { DocumentAccCard } from '../DocumentsAccCard/DocumentsAccCard'
class FinancialEditionCard extends Component {
  static propTypes = {
    financerState: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    financerStateColor: PropTypes.string.isRequired,
    financerType: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    financerOpacifType: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    financerName: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    financerSupportRequestFile: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    financerSupportAgreementFile: PropTypes.string,
    financialHT: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.number,
    }).isRequired,
    financialTVA: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.number,
      options: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    totalTVA: PropTypes.string,
    totalTTC: PropTypes.string,
    locale: PropTypes.shape({
      title: PropTypes.string.isRequired,
      financerStateLabel: PropTypes.string.isRequired,
      financerTypeLabel: PropTypes.string.isRequired,
      OpacifValue: PropTypes.string.isRequired,
      financerOpacifTypeLabel: PropTypes.string.isRequired,
      financerNameLabel: PropTypes.string.isRequired,
      financerSupportRequestFileLabel: PropTypes.string.isRequired,
      financerSupportAgreementFileLabel: PropTypes.string.isRequired,
      financialHTLabel: PropTypes.string.isRequired,
      financialTVALabel: PropTypes.string.isRequired,
      totalTVALabel: PropTypes.string.isRequired,
      totalTTCLabel: PropTypes.string.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    toFillStateOptionId: PropTypes.string.isRequired,
    pendingStateOptionId: PropTypes.string.isRequired,
    getNewFinancialDocument: PropTypes.func.isRequired,
    handleDeleteFinancialDocument: PropTypes.func.isRequired,
    financialDocuments: PropTypes.arrayOf(PropTypes.object),
  }

  static defaultProps = {
    financerSupportAgreementFile: '',
    totalTVA: '',
    totalTTC: '',
    readOnly: false,
  }

  truncateString = (fullStr) => {
    if (!fullStr) return
    const strLen = 45
    if (fullStr.length <= strLen) return fullStr

    const separator = '...'
    const sepLen = separator.length
    const charsToShow = strLen - sepLen
    const frontChars = Math.ceil(charsToShow / 2)
    const backChars = Math.floor(charsToShow / 2)

    return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars)
  }

  handleChange = (newValue) => {
    if (!this.props.readOnly) {
      this.props.onChange(newValue)
    }
  }

  handleDeleteFile = ({ id }) => {
    const newValues = [{ id, value: '' }]

    const { financerSupportRequestFile, financerState, toFillStateOptionId } = this.props
    if (id === financerSupportRequestFile.id) {
      newValues.push({ id: financerState.id, value: toFillStateOptionId })
    }

    this.handleChange(newValues)
  }

  handleFileChange = ({ id, file }) => {
    const newValues = [{ id, value: file.url }]
    const { financerSupportRequestFile, financerState, pendingStateOptionId } = this.props

    if (id === financerSupportRequestFile.id) {
      newValues.push({ id: financerState.id, value: pendingStateOptionId })
    }
    
    this.handleChange(newValues)
  }

  render() {
    const {
      locale,
      financerState,
      financerType,
      financerOpacifType,
      financerName,
      financerStateColor,
      financerSupportRequestFile,
      financerSupportAgreementFile,
      financialHT,
      financialTVA,
      totalTVA,
      totalTTC,
      readOnly,
    } = this.props
    const {
      title,
      financerStateLabel,
      financerTypeLabel,
      OpacifValue,
      financerOpacifTypeLabel,
      financerNameLabel,
      financerSupportRequestFileLabel,
      financerSupportAgreementFileLabel,
      financialHTLabel,
      financialTVALabel,
      totalTVALabel,
      totalTTCLabel,
    } = locale

    const financerStateColorNode = <Icon icon={Icon.icon.Dot} color={financerStateColor} />
    return (
      <div className="financial-edition-card">
        <IconCardTitle label={title} icon="card" />
        <div className="financial-edition-card-content">
          <ComboField
            value={financerState.value}
            title={financerStateLabel}
            name={financerState.id}
            options={financerState.options}
            onChange={this.handleChange}
            prefixNode={financerStateColorNode}
            readOnly={readOnly}
          />
          <ComboField
            value={financerType.value}
            title={financerTypeLabel}
            name={financerType.id}
            options={financerType.options}
            onChange={this.handleChange}
            readOnly={readOnly}
          />
          {financerType.value === OpacifValue && (
            <ComboField
              value={financerOpacifType.value}
              title={financerOpacifTypeLabel}
              name={financerOpacifType.id}
              options={financerOpacifType.options}
              onChange={this.handleChange}
              readOnly={readOnly}
            />
          )}
          <InputField
            title={financerNameLabel}
            name={financerName.id}
            value={financerName.value}
            onChange={this.handleChange}
            readonly={readOnly}
          />
          <DocumentAccCard documents={this.props.financialDocuments} newDocument={this.props.getNewFinancialDocument} deleteDocument={this.props.handleDeleteFinancialDocument} title={"document de prise en charge"} readOnly={readOnly} />



          {/* <FileUploadField
            id={financerSupportRequestFile.id}
            value={financerSupportRequestFile.value}
            label={financerSupportRequestFileLabel}
            name={financerSupportRequestFile.id}
            handleDeleteFile={this.handleDeleteFile}
            handleFileChange={this.handleFileChange}
            readOnly={this.readOnly}
          />
          <div className="download-input">
            <InfoField
              lightLabel={financerSupportAgreementFileLabel}
              boldLabel={this.truncateString(financerSupportAgreementFile.value)}
            />
            {financerSupportAgreementFile !== '' && (
              <>
              <div className="download-icon">
                <a href={financerSupportAgreementFile} download target="_blank" rel="noreferrer noopener">
                  <CircleButton icon={Icon.icon.Download} />
                </a>
              </div>
              </>
            )}
          </div> */}
          <div className="financial-ht">
            <InputField
              title={financialHTLabel}
              name={financialHT.id}
              value={financialHT.value}
              onChange={this.handleChange}
              type="number"
              step={0.01}
              min={0}
              readonly={readOnly}
            />
            <div>€</div>
          </div>
          <div className="financial-tva">
            <ComboField
              value={financialTVA.value}
              title={financialTVALabel}
              name={financialTVA.id}
              options={financialTVA.options}
              onChange={this.handleChange}
              readOnly={readOnly}
            />
            <InfoField lightLabel={totalTVALabel} boldLabel={totalTVA} />
          </div>
          <InfoField lightLabel={totalTTCLabel} boldLabel={totalTTC} />
        </div>
      </div>
    )
  }
}

export default FinancialEditionCard

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

 
import ComboFilterField from '../../../components/molecules/ComboFilterField/ComboFilterField'
import ManagerConsultantBeneficiaryAttribute from '../ManagerConsultantBeneficiaryAttribute/ManagerConsultantBeneficiaryAttribute'
import CandidatCard from '../../../components/organisms/CandidatCard/CandidatCard'
import { connect } from 'react-redux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux'
import { actions as ResourceTemplateActions } from '../../../redux/ResourceTemplateRedux'
import { selectors as WSSelectors } from '../../../redux/WSRedux'
import './ManagerConsultantBeneficiaries.scss';

const accompanimentTypes = {
  appraisal: 'appraisal',
  outplacement_individual: 'outplacement_individual',
  outplacement_group: 'outplacement_group',
  formation: 'formation',
  vae: 'vae',
  coaching: 'coaching',
  orientation_review: 'orientation_review',
  business_creation: 'business_creation',
  collective_scheme: 'collective_scheme',
  other: 'other',
}

const financerState = {
  to_fill: 'to_fill',
  pending: 'pending',
  done: 'done',
}

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  pending: WSSelectors.pending(state, 'beneficiary.attribute'),
  resolved: WSSelectors.resolved(state, 'beneficiary.attribute'),
})

const mapDispatchToProps = (dispatch) => ({
  removeTemplate: (id) => dispatch(ResourceTemplateActions.removeTemplate(id)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})

class ManagerConsultantBeneficiaries extends PureComponent {
  static propTypes = {
    beneficiaries: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
        image_url: PropTypes.string,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }

  state = {
    beneficiary: null,
    attributeResolved: false,
    status: ''
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.resolved && this.state.beneficiary) {
      this.setState({ attributeResolved: true })
    }
  }

  handleFilter = ({ value }) => {
    this.setState({ status: value })
  }

  calculateProgress = (start, end, today) => {
    if (start > today) {
      return 0
    }

    if (end < today) {
      return 100
    }

    return Number.parseInt(((end - today) / (end - start)) * 100, 10)
  }

  checkAccompanimentType = (type, t) => {
    switch (type) {
      case accompanimentTypes.appraisal:
        return "Bilan de compétences"
      case accompanimentTypes.outplacement_individual:
        return "Outplacement individuel"
      case accompanimentTypes.vae:
        return "VAE"
      case accompanimentTypes.coaching:
        return "Coaching"
      case accompanimentTypes.business_creation:
        return "Création d'entreprise"
      case accompanimentTypes.orientation_review:
        return "Bilan orientation"
      case accompanimentTypes.collective_scheme:
        return "Dispositif collectif"
      case accompanimentTypes.outplacement_group:
        return "Outplacement collectif"
      case accompanimentTypes.formation:
        return "Formation"
      case accompanimentTypes.other:
      default:
        return "Autre type d'accompagnement"
    }
  }

  checkFinancerState = (state, t) => {
    switch (state) {
      case financerState.to_fill:
        return { label: "A renseigner", color: 'red1' }
      case financerState.pending:
        return { label: "En attente", color: 'orange1' }
      case financerState.done:
      default:
        return { label: "Validé", color: 'green1' }
    }
  }

  handleSelectBeneficiary = (value) => {
    const { beneficiaries } = this.props
    const selectedId = value && Number(value.id)
    const beneficiary = value ? beneficiaries.find(({ id }) => id === selectedId) : null

    this.setState({ beneficiary, attributeResolved: false })
  }

  renderFilter() {
    const { status } = this.state
    const options = [
      {value: "in_progress", label: "En cours"},
      {value: "created", label: "Prospect"},
      {value: "wait_financer", label: "En attente de financement"},
      {value: "in_validation", label: "En attente de validation"},
      {value: "follow_up", label: "Suivi professionel"},
      {value: "archived", label: "Historique"},
      {value: "", label: "Tous"},
    ]

    return (
      <div className="status-filter">
        <ComboFilterField
          id="status"
          options={options}
          value={status}
          title={"Etat actuel : "}
          onChange={this.handleFilter}
        />
      </div>
    )
  }

  handleBeneficiaryClick = (id) => {
    this.props.redirect(this.props.pathname + `/beneficiaries/${id}/courses`)
  }

  renderBeneficiaries() {
    const { beneficiaries } = this.props
    const locale = {
      financeStateLabel: "Etat de financement",
      lengthLabel: "Durée",
      reasonLabel: "Raison",
      typeOfAccompanimentLabel: "Type d'accompagnement",
      programFollowedLabel: "Parcours suivi",
      nextAppointmentLabel: "Prochain RDV",
    }
    const { status } = this.state

    const rows = beneficiaries
      .filter((beneficiary) => !status || beneficiary.status === status)
      .map((beneficiary, i) => {
        const { isPrimary } = this.props
        const {
          status,
          first_name,
          last_name,
          email,
          phone_mobile,
          image_url,
          accompaniment_start_date,
          accompaniment_end_date,
          accompaniment_duration,
          financer_state,
          accompaniment_type,
          phone_home,
          archived_reason = null,
          id,
          next_rdv,
          is_primary,
        } = beneficiary

        const primaryConsultant =
          beneficiary.consultants && beneficiary.consultants.find(({ is_primary }) => is_primary)

        const { course, count_notifications, count_messages } = beneficiary
        const startDate = new Date(accompaniment_start_date)
        const endDate = new Date(accompaniment_end_date)
        const todayDate = new Date()
        const percentage = accompaniment_start_date
          ? this.calculateProgress(startDate, endDate, todayDate)
          : 0
        const courseLength = `${Number.parseInt(accompaniment_duration, 10)} heures`
        const typeOfAccompaniment = this.checkAccompanimentType(accompaniment_type)
        const financerState = this.checkFinancerState(financer_state)
        
      let reason_of_archived = ""
       if(archived_reason === "completed") reason_of_archived = "Accompagnement terminée"
       else if(archived_reason === "beneficiary_abandonment") reason_of_archived = "Abandon du talent"
       else if (archived_reason === "budget_not_accepted")  reason_of_archived = "Budget non accepté"
       else if(archived_reason === "beneficiary_refusal") reason_of_archived = "Refus talent"
       else reason_of_archived = "Autre"


       let label_status = ""
       if(status === "created") label_status = "Prospect"
       else if(status === "wait_financer") label_status = "En attente de financement"
       else if(status === "in_validation") label_status = "En attente de validation"
       else if(status === "in_progress") label_status = "En cours"
       else if(status === "follow_up") label_status = "Suivi professionnel"
       else if(status === "archived") label_status = "Historique"


        return (
          <CandidatCard
            key={i}
            id={id}
            status={{status, label: label_status}}
            name={`${first_name} ${last_name}`}
            email={email}
            phone={phone_mobile || phone_home || ''}
            avatar={image_url || ''}
            locale={locale}
            color={financerState.color}
            length={courseLength}
            accompaniment_type={typeOfAccompaniment}
            financer_state={financerState.label}
            percentage={percentage}
            programFollowed={course && course.title}
            mail={!!count_messages}
            notification={!!count_notifications}
            reason={archived_reason ? `${reason_of_archived}` : ''}
            onClick={this.handleBeneficiaryClick}
            onNotificationsClick={this.handleNotificationsClick}
            onMessagesClick={this.handleMessagesClick}
            onDeleteClick={this.handleDeleteClick}
            nextAppointment={next_rdv}
            isDeletable={isPrimary}
            isManager
            onAttributeClick={this.handleSelectBeneficiary}
            attributeLabelButton={"Attribuer"}
            attributeReadonly={is_primary}
            primaryConsultant={primaryConsultant}
          />
        )
      })

    return rows.length ? (
      <div className="beneficiaries">{rows}</div>
    ) : (
      <div className="no-result">{"Il n'y a aucun talent possédant ce statut."}</div>
    )
  }

  renderAttributePopin() {
    const { beneficiary, attributeResolved } = this.state

    return (
      <ManagerConsultantBeneficiaryAttribute
        isOpen={!!beneficiary}
        beneficiary={beneficiary}
        onClose={this.handleSelectBeneficiary}
        resolved={attributeResolved}
        pending={this.props.pending}
      />
    )
  }

  render() {
    return (
      <div className="manager-consultant-beneficiaries">
        {this.renderFilter()}
        {this.renderBeneficiaries()}
        {this.renderAttributePopin()}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerConsultantBeneficiaries)
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CircleButton from '../../atoms/CircleButton/CircleButton'
import AvatarCircle from '../../atoms/AvatarCircle/AvatarCircle'
import LoadingSpinner from '../../atoms/LoadingSpinner/LoadingSpinner'
import Icon from "../../atoms/Icon/Icon";
import './AvatarField.scss'

export default class AvatarField extends Component {
  static propTypes = {
    photoUrl: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    uploading: PropTypes.bool,
  }

  static defaultProps = {
    photoUrl: null,
    readOnly: false,
    uploading: false,
  }

  onClick = (e) => {
    e.preventDefault()
    if (!this.props.readOnly) {
      this.props.onClick()
    }
  }

  circleStyle() {
    return this.props.photoUrl ? { background: 'none' } : {}
  }

  render() {
    const { photoUrl, readOnly, uploading } = this.props

    return (
      <div className="avatar-field" onClick={this.onClick}>
        <div className="circle" style={this.circleStyle()}>
          {photoUrl ? (
            <AvatarCircle photoUrl={photoUrl} />
          ) : (
            <div className='circle-avatar'>
              <Icon icon={Icon.icon.Person} color={Icon.color.Accent}/>
            </div>
          )}
        </div>
        {!readOnly && !uploading && <CircleButton id="upload" icon="image" />}
        {uploading && <LoadingSpinner />}
      </div>
    )
  }
}

/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectors as UserSelectors } from '../../../redux/UserRedux';
import { getConsultantInfos } from '../../../api/ConsultantApi';

import Icon from '../../../components/atoms/Icon/Icon';

import { useNavigation } from '../../../hooks/useNavigation';

import './ClientsList.scss';

export const ClientsList = ({ clients, handleDeleteClient }) => {
  const user = useSelector(UserSelectors.user);
  const { pathname, redirect } = useNavigation();
  const [, setConsultantInfo] = useState({ first_name: '', last_name: '' });

  const fetchConsultantInfo = (id) => {
    getConsultantInfos(id).then((response) => {
      const consultant = response.consultant.user;
      setConsultantInfo({
        first_name: consultant.first_name,
        last_name: consultant.last_name,
      });
    });
  };

  const renderNumberTalents = (client) => {
    let nbTalents = 0;
    if (client && client.missions) {
      client.missions.map((mission) => {
        if (mission.talents && mission.talents.length > 0) {
          let talents_id = [];
          mission.talents.map((talent) => {
            if (!talents_id.includes(talent.id)) {
              nbTalents += 1;
              talents_id.push(talent.id);
            }
          });
        }
      });
      return <p>{nbTalents}</p>;
    } else {
      return <p>0</p>;
    }
  };

  useEffect(() => {
    clients.forEach((client) => {
      fetchConsultantInfo(client.user_id);
    });
  }, [clients]);

  return (
    <div className="clients-list-wrapper">
      <table className="clients-list">
        <thead>
          <tr>
            <th>Nom du client</th>
            <th>Nombre de talents</th>
            <th>Nombre de missions</th>
            <th>Responsable client</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {clients.map((client, index) => (
            <tr key={index}>
              <td className="client-name" onClick={() => redirect(`${pathname}/${client.id}`)}>
                {client.name}
              </td>
              <td onClick={() => redirect(`${pathname}/${client.id}`)}>
                {renderNumberTalents(client)}
              </td>

              <td onClick={() => redirect(`${pathname}/${client.id}`)}>
                {client.missions ? client.missions.length : 0}
              </td>
              <td onClick={() => redirect(`${pathname}/${client.id}`)}>
                {client.responsable &&
                  client.responsable.map((r, index) => (
                    <p key={index}>
                      {r.first_name} {r.last_name}
                    </p>
                  ))}
              </td>

              <td>
                {client.responsable && client.responsable[0].id === user.id && (
                  <div className="actions">
                    <div className="div-icon" onClick={() => redirect(`${pathname}/${client.id}`)}>
                      <Icon icon="pencil" color={'grey3'} />
                    </div>
                    <div className="div-icon" onClick={() => handleDeleteClient(client)}>
                      <Icon icon="trash2" color={'grey3'} />
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

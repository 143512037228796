import React from 'react'
import PropTypes from 'prop-types'

import IconCardTitle from '../IconCardTitle/IconCardTitle'
import Icon from '../../atoms/Icon/Icon'
import "./NotesDisplay.scss";

const propTypes = {
  isPrivate: PropTypes.bool,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  synthesisNote: PropTypes.bool,
}

const defaultProps = {
  synthesisNote: false,
  text: null,
  isPrivate: false,
}

const NotesDisplay = ({ isPrivate, title, text, synthesisNote }) => (
  <div className={`notes-display ${isPrivate ? 'private' : null}`}>
    <div className="header">
      <IconCardTitle label={title} icon={Icon.icon.Pencil} />
      {isPrivate && synthesisNote && <Icon icon={Icon.icon.Private} />}
    </div>
    <p className="text">{text || 'Pas de note renseignée'}</p>
  </div>
)

NotesDisplay.propTypes = propTypes
NotesDisplay.defaultProps = defaultProps

export default NotesDisplay

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './StatsBarChart.scss'
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryTheme,
} from 'victory'


export default class StatsBarChart extends Component {
  static propTypes = {
    title: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        xValue: PropTypes.string,
        yValue: PropTypes.number,
      }),
    ).isRequired,
    displayYPercents: PropTypes.bool,
    ratio: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }).isRequired,

    className: PropTypes.string,
  }

  static defaultProps = {
    title: null,
    displayYPercents: false,

    className: '',
  }

  render() {
    const { title, data, displayYPercents, ratio, className } = this.props

    let displayData = data.map((d) => ({
      ...d,
      label: d.yValue,
    }))

    let maxValue = displayData.reduce((res, d) => (d.yValue > res ? d.yValue : res), 0)

    if (displayYPercents) {
      const maxCount = data.reduce((res, d) => res + d.yValue, 0)

      displayData = data.map((d) => ({
        ...d,
        label: d.yValue,
        yValue: Math.round((d.yValue * 100) / maxCount),
      }))
      maxValue = 100
    }

    const tickValues = [0, Math.round(maxValue / 2), maxValue]

    const labelsStyle = {
      stroke: '#5066EF',
      fill: '#5066EF',
      fontFamily: 'SourceSansPro-Semibold',
      fontWeight: '600',
      fontSize: '10px',
      textTransform: 'uppercase',
    }

    let tickLabelsStyle = labelsStyle
    if (!displayYPercents) {
      tickLabelsStyle = {
        ...tickLabelsStyle,
        stroke: 'transparent',
        fill: 'transparent',
      }
    }

    let displayNode = <span className="no-data">{"Aucune donnée pour l'année selectionnée"}</span>
    if (displayData.length !== 0) {
      displayNode = (
        <VictoryChart
          theme={VictoryTheme.material}
          domainPadding={{ x: 35 }}
          padding={30}
          width={ratio.width}
          height={ratio.height}
        >
          <VictoryAxis
            crossAxis
            style={{
              axis: { stroke: '#D8D8D8', fill: '#D8D8D8', strokeWidth: '1px' },
              axisLabel: labelsStyle,
              grid: { stroke: 'transparent', fill: 'transparent', strokeDasharray: '0' },
              ticks: { stroke: 'transparent', fill: 'transparent' },
              tickLabels: labelsStyle,
            }}
            tickLabelComponent={
              <VictoryLabel
                renderInPortal
                text={(datum) => displayData[datum - 1].xValue.split(' ').slice(0, 2).join('\n')}
              />
            }
          />
          <VictoryAxis
            dependentAxis
            fixLabelOverlap
            style={{
              axis: { stroke: '#D8D8D8', fill: '#D8D8D8', strokeWidth: '1px' },
              axisLabel: labelsStyle,
              grid: { stroke: 'transparent', fill: 'transparent', strokeDasharray: '0' },
              ticks: { stroke: 'transparent', fill: 'transparent' },
              tickLabels: tickLabelsStyle,
            }}
            tickValues={tickValues}
            tickFormat={(t, tIndex) => ['0%', '50%', '100%'][tIndex]}
          />
          <VictoryGroup>
            <VictoryBar
              data={displayData}
              x="xValue"
              y="yValue"
              style={{
                data: {
                  fill: (d) => (d.group % 2 === 0 ? '#5066EF' : '#B7C0F9'),
                  width: 30,
                },
                labels: { ...labelsStyle, fill: 'var(--text-primary-dark)', stroke: 'var(--text-primary-dark)' },
              }}
            />
          </VictoryGroup>
        </VictoryChart>
      )
    }

    return (
      <div className={classnames('stats-bar-chart', className)}>
        {title !== null && <div className="title">{title}</div>}
        <div className="chart-wrapper">{displayNode}</div>
      </div>
    )
  }
}

import React from "react";

import { DropdownColors } from "../../atoms/DropdownColors/DropdownColors";

import './WhiteBrandCard.scss';

export const WhiteBrandCard = (office_id) => {
    return (
        <div className="white-brand-card">
            <h2>Personnalisation de MyTalents</h2>
            <div className="white-brand-card-content">
                <span>Votre palette de couleurs :  </span>
                <DropdownColors office_id={office_id}/>
            </div>
        </div>
    )
}

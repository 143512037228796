import _transform from 'lodash/transform'
import ApiService from './ApiService'

const api = ApiService

export const getAll = ({ token, filter }) => {
  const query = _transform(filter, (query, value, key) => {
    if (value) {
      query[`filter[${key}]`] = value
    }
  })
  return api.get('/appointments', query, { headers: { Authorization: `Bearer ${token}` } })
}


export const exportAll = ({ token, filter }) => {
  const query = _transform(filter, (query, value, key) => {
    if (value) {
      query[`filter[${key}]`] = value
    }
  })

  return api.get('/appointments/export', query, { headers: { Authorization: `Bearer ${token}` } })
}

export const postpone = ({ token, id }) => {
  return api.put(`/module-appointments/${id}/postpone`, null, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const confirm = ({ token, id }) => {
  return api.put(`/module-appointments/${id}/confirm`, null, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

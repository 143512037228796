// Libs
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Icons
import Icon from '../Icon/Icon'
import './IconResourceType.scss';

// resourceType
const resourceType = {
  link: Icon.icon.File,
  fileReturn: Icon.icon.FileReturn,
  document: Icon.icon.Document,
  video: Icon.icon.Video,
  calendar: Icon.icon.Calendar,
  book: Icon.icon.Book,
  free: Icon.icon.Free,
  quiz: Icon.icon.Quiz,
}

// Proptypes
const propTypes = {
  resourceType: PropTypes.oneOf(Object.values(resourceType)).isRequired,
  color: PropTypes.oneOf(Object.values(Icon.color)),
}

const defaultProps = {
  color: null,
}

class IconResourceType extends Component {
  icon() {
    switch (this.props.resourceType) {
      case resourceType.link:
        return <Icon icon={resourceType.link} color={this.props.color} />
      case resourceType.fileReturn:
        return <Icon icon={resourceType.fileReturn} color={this.props.color} />
      case resourceType.document:
        return <Icon icon={resourceType.document} color={this.props.color} />
      case resourceType.video:
        return <Icon icon={resourceType.video} color={this.props.color} />
      case resourceType.calendar:
        return <Icon icon={resourceType.calendar} color={this.props.color} />
      case resourceType.book:
        return <Icon icon={resourceType.book} color={this.props.color} />
      case resourceType.free:
        return <Icon icon={resourceType.free} color={this.props.color} />
      case resourceType.quiz:
        return <Icon icon={resourceType.quiz} color={this.props.color} />
      default:
        break
    }
  }

  render() {
    const { resourceType } = this.props
    return <div className={`icon-resource-type-${resourceType}`}>{this.icon()}</div>
  }
}

IconResourceType.propTypes = propTypes
IconResourceType.resourceType = resourceType
IconResourceType.defaultProps = defaultProps

export default IconResourceType

import React, { useState } from 'react'
import CircleButton from '../../atoms/CircleButton/CircleButton'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import ModuleCentralTestSelectPopin from '../ModuleCentralTestSelectPopin/ModuleCentralTestSelectPopin'
import ModuleCentralTestConfirmationPopin from '../ModuleCentralTestConfirmationPopin/ModuleCentralTestConfirmationPopin'
import Icon from '../../atoms/Icon/Icon'
import ToggleButton from '../ToggleButton/ToggleButton'
import { useDispatch } from 'react-redux'
import { actions } from '../../../redux/CentralTestRedux'
import './CentralTestModuleConsultant.scss'
const labels = {
  noTestSelected: 'Aucune évaluation séléctionnée',
  status: {
    pending: 'Invitation à envoyer',
    invited: 'Invitation envoyée',
    completed: 'Module terminé',
  },
}

export const statusTypes = {
  pending: 'pending',
  invited: 'invited',
  done: 'done',
}

const CentralTestModuleConsultant = ({ centralTestModule }) => {
  const [isSelectionModalOpen, setIsSelectionModalOpen] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const dispatch = useDispatch()

  let status

  if (!centralTestModule.data.assessment_id) {
    status = statusTypes.pending
  } else if (centralTestModule.data.assessment_id && !centralTestModule.done) {
    status = statusTypes.invited
  } else {
    status = statusTypes.done
  }

  const statusOptions = () => {
    if (status === statusTypes.pending) {
      return {
        icon: Icon.icon.RoundMinus,
        label: 'Invitation à envoyer',
      }
    }

    if (status === statusTypes.invited) {
      return {
        icon: Icon.icon.Refresh,
        label: 'En attente',
      }
    }

    if (status === statusTypes.done) {
      return {
        icon: Icon.icon.Check,
        label: 'Passation terminée',
      }
    }
  }

  const openSelectionModal = () => {
    setIsSelectionModalOpen(true)
  }

  const openConfirmationModal = () => {
    setIsConfirmationModalOpen(true)
  }

  const closeSelectionModal = () => {
    setIsSelectionModalOpen(false)
  }

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false)
  }

  const { icon, label } = statusOptions()

  const renderSelectionModal = () =>
    isSelectionModalOpen && (
      <ModuleCentralTestSelectPopin
        centralTestModule={centralTestModule}
        stepIndex={centralTestModule.stepIndex}
        moduleIndex={centralTestModule.moduleIndex}
        isModalOpen={isSelectionModalOpen}
        onClose={closeSelectionModal}
      />
    )

  const renderConfirmationModal = () =>
    isConfirmationModalOpen && (
      <ModuleCentralTestConfirmationPopin
        module={centralTestModule}
        isModalOpen={isConfirmationModalOpen}
        onClose={closeConfirmationModal}
      />
    )

  const isDisabled =
    status !== statusTypes.pending ||
    !centralTestModule.data.test_id ||
    !centralTestModule.data.module_id

  const [isAllowedToViewReport, setIsAllowedToViewReport] = useState(
    centralTestModule.data.show_report,
  )

  const toggleReport = () => {
    dispatch(actions.toggleReport(centralTestModule.data.module_id))

    setIsAllowedToViewReport(!isAllowedToViewReport)
  }

  return (
    <>
      <div className="centraltest-module-consultant">
        <div className="selection">
          <div className="details">
            <CircleButton icon={Icon.icon.CentralTest} color={Icon.color.White} />
            <span className="label">
              {centralTestModule.data.test_name || labels.noTestSelected}
            </span>
          </div>
          {!centralTestModule.done && (
            <PrimaryButton
              title="Modifier"
              outline
              id="edit-centraltest"
              label={centralTestModule.data.test_name ? 'Modifier' : 'Sélectionner'}
              onClick={openSelectionModal}
              disabled={status !== statusTypes.pending}
            />
          )}
        </div>
        <div className="status">
          <div className="details">
            <Icon icon={icon} />
            <span className={`label--${status}`}>{label}</span>
          </div>
          {!centralTestModule.done && (
            <PrimaryButton
              title="Envoyer l'invitation"
              id="confirm-centraltest"
              label="Envoyer l'invitation"
              onClick={openConfirmationModal}
              icon={Icon.icon.PersonPlus}
              disabled={isDisabled}
            />
          )}
        </div>
      </div>

      {centralTestModule.done && (
        <div className="centraltest-module-controls">
          <span>Autoriser le bénéficiaire à consulter son rapport pour cette évaluation ?</span>
          <ToggleButton
            id="on-off-report"
            labelOn="Oui"
            labelOff="Non"
            onChange={toggleReport}
            isOn={isAllowedToViewReport}
          />
        </div>
      )}

      {renderSelectionModal()}

      {renderConfirmationModal()}
    </>
  )
}

export default CentralTestModuleConsultant

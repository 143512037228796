import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { actions as CourseTemplateActions, selectors as CourseTemplateSelectors } from '../../../redux/CourseTemplateRedux'
import { actions as NavigationActions, selectors as NavigationSelectors } from '../../../redux/NavigationRedux'
import { selectors as BeneficiarySelectors } from '../../../redux/BeneficiaryRedux'
import { selectors as UserSelectors } from "../../../redux/UserRedux"
import { Popin } from '../../global/Popin/Popin'
import ParcoursAttribPopin from '../../../components/organisms/ParcoursAttribPopin/ParcoursAttribPopin'
import "./AttributeCoursePopin.scss"
import { getTemplatesCourses, getTemplatesOfficeCourses, getTemplatesMyTalentsCourses } from '../../../api/TemplateCourseApi';
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton'
import './AttributeCoursePopin.scss'
import { assignCourse, getCourseById } from '../../../api/CourseApi'

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  pathname: NavigationSelectors.pathname(state),
  templates: CourseTemplateSelectors.templates(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
})

const mapDispatchToProps = (dispatch) => ({
  getTemplates: (filter) => dispatch(CourseTemplateActions.getTemplates(filter)),
  assignTemplate: (ids) => dispatch(CourseTemplateActions.assignTemplate(ids)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})


const AttributeCoursePopin = ({ open, beneficiary, user, newCourse }) => {
  const [title, setTitle] = useState('');
  const [scope, setScope] = useState('consultant');
  const [course_id, setCourse_id] = useState(null);
  const [courses, setCourses] = useState([]);
  const options = [ { label: "Mes parcours", value: 'consultant' }, { label: "Nos parcours", value: 'office' }, { label: "Les parcours My Talents", value: 'mytalents' } ]
  const locale = { title: "Attribuer un parcours", cta: "Attribuer", step: { step: "Etapes", steps: "Etapes" }, module: { module: "Modules", modules: "Modules" } }
  const [isOpen, setIsOpen] = useState(open);

  const handleAddComboChange = ({ value }) => {
    setScope(value)
  }

  const handleSearchChange = ({ value }) => {
    setTitle(value);
    const lowercaseValue = value.toLowerCase();
    const filterCourses = courses.filter(course => course.title.includes(lowercaseValue));
    setCourses(filterCourses);
    if(value.length > 0) {
      const lowercaseValue = value.toLowerCase();
      const filterCourses = courses.filter(course => course.title.includes(lowercaseValue));
      setCourses(filterCourses);
    }
    else {
      getTemplates();
    }
  }

  const handleClosePopin = () => {
    setTitle('');
    setScope('consultant');
    setCourse_id('');
    setIsOpen(false)
  }

  const handleSubmitAttribute = () => {
    const consultant_id = user.profile_id;
    const beneficiary_id = beneficiary.profile_id;
    getCourseById(course_id).then((response) => {
      const attributeCourse = {consultant: {id: consultant_id}, beneficiary: {id: beneficiary_id}, course: response.course}
      assignCourse(attributeCourse).then((response) => {
        newCourse(response.course);
      });
    })
    handleClosePopin();
  }

  const handleOnCourseSelect = (id) => {
    setCourse_id(id)
  }

  const getTemplates = () => {
    getConsultantCourses();
  }

  const getOfficeCourses = () => {
    getTemplatesOfficeCourses(user.id).then((response) => {
      setCourses(response.courses)
    })
  }

  const getMyTalentsCourses = () => {
    getTemplatesMyTalentsCourses().then((response) => {
      setCourses(response.courses)
    })
  }

  const getConsultantCourses = () => {
    getTemplatesCourses(user.id).then((response) => {
      setCourses(response.courses)
    })
  }

  useEffect(() => {
    if(scope === "consultant") {
      getConsultantCourses();
    }
    else if(scope === "office") {
      getOfficeCourses();
    }
    else if(scope === "mytalents") {
      getMyTalentsCourses();
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope])

  useEffect(() => {
    getTemplates();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  return (
    <Popin onClose={handleClosePopin} open={isOpen}>
    <div className="attribute-course-popin">
      <ParcoursAttribPopin
        options={options}
        onSubmit={handleSubmitAttribute}
        onBack={handleClosePopin}
        locale={locale}
        searchValue={title}
        onSearchChange={handleSearchChange}
        courses={courses}
        onComboChange={handleAddComboChange}
        comboValue={scope}
        onCourseSelect={handleOnCourseSelect}
        selectedCourse={course_id}
        disableButton={!course_id}
      />
      <div className='attribute-course-popin-btn'>
        <PrimaryButton label={"Attribuer"} disabled={!course_id} onClick={handleSubmitAttribute}/>
      </div>
    </div>
  </Popin>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AttributeCoursePopin)
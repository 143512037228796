import React, { useEffect, useState, useRef } from "react";
import MessageItem from "./MessageItem";
import axios from "axios";
import { connect } from "react-redux";
//import { SelectSocket } from "../../redux/ChatSelectorRedux";
import { selectors as ChatSelectors } from "../../redux/ChatRedux";
import LoadingSpinner from "../atoms/LoadingSpinner/LoadingSpinner";

const mapStateToProps = (state, props) => ({
    socket: ChatSelectors.setWebSocket(state),
    globalSocket: ChatSelectors.setGlobalSocket(state)
})

function MessageList(props) {

    const containerMessage = useRef(null);
    const socket = props.socket;
    const globalSocket = props.globalSocket;
    const [messagesList, setMessagesList] = useState([]);
    const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        axios.get(process.env.REACT_APP_API_MY_TALENTS_CHAT + "chat/room/messages", {
            params: {
                room_key: props.room
            }
        }).then(function(res) {
            setMessagesList(res.data);
            scrollToBottom("smooth", 100);
            setLoading(false);
        }).catch(function(err) {
        })

    }, [props]);

    useEffect(() => {
        const handleSocketMessage = function(e) {
            const message = JSON.parse(e.data);
            
            if (message.type !== "pong" && message.type !== "deletion") {
                // Mettre à jour l'interface utilisateur avec le message reçu
                setMessagesList((messageList) => [...messageList, message]);
                
                if (shouldScrollToBottom) {
                    scrollToBottom("smooth", 100);
                }
            }

            if (message.type === "deletion") {

                let updatedMessages = [];

                if (message.id_message != null) {
                    updatedMessages = messagesList.filter(mess => mess.id !== message.id_message);
                }
                if (message.random_key != null) {
                    updatedMessages = messagesList.filter(mess => mess.random_key !== message.random_key);
                }
    
                setMessagesList(updatedMessages);
            }
        };

        socket.addEventListener("message", handleSocketMessage);

        return () => {
            socket.removeEventListener("message", handleSocketMessage);
        };
    }, [socket, shouldScrollToBottom, messagesList]);

    const scrollToBottom = (behavior, delay) => {
        if (containerMessage.current) {
            const container = containerMessage.current;
            //container.scrollTop = container.scrollHeight;
            setTimeout(() => {
                container.scrollTo({
                    top: container.scrollHeight,
                    behavior: behavior,
                });
            }, delay)
        }
    };

    const handleScroll = () => {
        const container = containerMessage.current;
        const { scrollTop, scrollHeight, clientHeight } = container;
      
        // Calcule la distance depuis le bas de la liste
        const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);
      
        // Vérifie si l'utilisateur est proche du bas de la liste (moins de 10% de la hauteur totale)
        const isNearBottom = distanceFromBottom <= scrollHeight * 0.1;
      
        // Met à jour l'état pour indiquer si le défilement automatique vers le bas devrait être effectué
        setShouldScrollToBottom(isNearBottom);
    };

    const handleDeleteMessageFromList = (id_message = null, random_key_message = null) => {

        axios.delete(process.env.REACT_APP_API_MY_TALENTS_CHAT + "chat/message/delete", {
            params: {
                id_message: id_message,
                random_key_message: random_key_message
            }
        }).then(function(res) {
            
            const message = {
                room_key: props.room,
                type: "deletion",
                id_message: id_message,
                random_key: random_key_message
            };
    
            socket.send(JSON.stringify(message));
            globalSocket.send(JSON.stringify(message));

        }).catch(function(err) {
        })
    }

    // EDIT TYPE AVEC L'ID USER DE L'UTILISATEUR ( GLOBAL STATE ? )
    return (
        <div onScroll={handleScroll} ref={containerMessage} className="container container-message ms-4 mt-4">
            {loading && <LoadingSpinner />}
            {messagesList.map(function(message) {
                return (
                    <MessageItem key={message.id === undefined ? message.random_key : message.id} messageId={message.id} message={message} type={parseInt(message.id_author) === parseInt(props.idUser) ? "in" : "out"} onDeleteMessage={handleDeleteMessageFromList} />
                )
            })}
        </div>
    )
}

export default connect(mapStateToProps, null)(MessageList);
// React & Libs
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Icon
import IconResourceType from '../../atoms/IconResourceType/IconResourceType'
import Icon from '../../atoms/Icon/Icon'
import './ParcoursModuleContentLink.scss'
// Proptypes
const propTypes = {
  url: PropTypes.string,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  isActivated: PropTypes.bool,
}

// defaultProps
const defaultProps = {
  url: '',
  isActivated: false,
}

// Component
class ParcoursModuleContentLink extends Component {
  handleClick = () => {
    this.props.onClick()
  }

  render() {
    const { label, isActivated, url } = this.props

    return (
      <div className={`parcours-module-content-link ${isActivated ? 'activated' : ''}`}>
        <IconResourceType
          resourceType={IconResourceType.resourceType.link}
          color={isActivated ? Icon.color.Accent : Icon.color.Grey3}
        />
        <a href={url} target="_blank" className="label">
          {label}
        </a>
      </div>
    )
  }
}

ParcoursModuleContentLink.propTypes = propTypes
ParcoursModuleContentLink.defaultProps = defaultProps

export default ParcoursModuleContentLink

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

 
import {
  actions as ConsultantActions,
  selectors as ConsultantSelectors,
} from '../../../redux/ConsultantRedux'
import { actions as BeneficiaryActions } from '../../../redux/BeneficiaryRedux'
import BeneficiaryAttribPopin from '../../../components/organisms/BeneficiaryAttribPopin/BeneficiaryAttribPopin'
import { Popin } from '../../global/Popin/Popin'
// import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton'

const mapStateToProps = (state) => ({
  consultant: ConsultantSelectors.consultant(state),
  consultants: ConsultantSelectors.consultants(state),
})

const mapDispatchToProps = (dispatch) => ({
  getConsultants: () => dispatch(ConsultantActions.getConsultants()),
  attribute: (beneficiaryId, consultantId) =>
    dispatch(BeneficiaryActions.attribute(beneficiaryId, consultantId)),
})

class ManagerConsultantBeneficiaryAttribute extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    pending: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    consultants: PropTypes.array,
    beneficiary: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    _consultant: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }

  static defaultProps = {
    resolved: false,
    pending: false,
    _consultant: null,
  }

  state = {
    test: "",
  }

  handleSubmit = ({ id }) => {
    const { beneficiary } = this.props
    this.props.attribute(beneficiary.id, id)
    this.props.onClose();
    this.setState({test: "Sarah"})
    // this.setState({ _consultant: updatedConsultant });
  }

  componentDidMount() {
    this.props.getConsultants()
  }

  render() {
    const { consultant, _consultant, consultants, onClose, pending, isOpen } = this.props

    if (!consultants || (!consultant && !_consultant)) {
      return null
    }

    const currentConsultant = consultant || _consultant

    const options = consultants
    .filter(({ id }) => id !== currentConsultant.id)
    .map(consultant => {
      return {
        label: `${consultant.first_name} ${consultant.last_name}`,
        value: consultant.id
      }
    })


    if (!options || options.length === 0) {
      return null
    }

    return (
      <Popin onClose={onClose} open={isOpen}>
        <BeneficiaryAttribPopin
          options={options}
          onBack={onClose}
          disabled={pending}
          onSubmit={this.handleSubmit}
          locale={{
            title: "Réatribution du talent",
            cta: "Valider",
            selectLabel: "Choisir un consultant",
          }}
        />
      </Popin>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerConsultantBeneficiaryAttribute)
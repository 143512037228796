/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import './SorterButton.scss'

export const SorterButton = ({ value, label, prefix, suffix, sendValue, active, setActive }) => {

    const toggleSorter = () => {
      setActive(current => !current); 
    };
  
    useEffect(() => {
      if (active) {
        sendValue({ value: value, label: label });
      }
    }, [active]);


    return (
      <div className={active ? 'sorter-button active' : 'sorter-button inactive'} onClick={toggleSorter} id={value}>
        {prefix && <div>{prefix}</div>}
        <p>{label}</p>
        {suffix && <div>{suffix}</div>}
      </div>
    );
  };
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

// import { getContactsAppointments } from "../../../api/AppointmentsApi";

import BigCombo from "../../../components/molecules/BigCombo/BigCombo";
// import InputField from "../../../components/molecules/InputField/InputField";
import DropStepZone from "../../../components/molecules/DropStepZone/DropStepZone";
import { Modal } from "../../../components/atoms/Modal/Modal";
import { NewTag } from "../../../components/atoms/NewTag/NewTag";
// import PrimaryButton from "../../../components/atoms/PrimaryButton/PrimaryButton";
// import RadioField from "../../../components/molecules/RadioField/RadioField";
import { SorterButton } from "../../../components/atoms/SorterButton/SorterButton";
import Icon from "../../../components/atoms/Icon/Icon";

import './AddGroupPopin.scss';
import { getTalentsGroup } from "../../../api/groupsApi";

export const AddGroupPopin = ({ open, onClose, user, setMembers, group_id }) => {
    const [isOpen, setIsOpen] = useState(open);
    const [newTalent, setNewTalent] = useState({ comments: "", email: "", first_name: "", last_name: "", gender: "", image_url: "" });
    const [talents, setTalents] = useState([]);
    const [newMembers, setNewMembers] = useState([]);
    const [disabledButtonCreate, setDisabledButtonCreate] = useState(true);
    const [isImport, setIsImport] = useState(false);
    const [isIndiv, setIsIndiv] = useState(true);
    const [, setDisabledAddNewTalent] = useState(true);
    const [titleActionButton, setTitleActionButton] = useState('');
    const [isFile, setIsFile] = useState(false);

    // methods
    const handleAddMember = () => { 
        setMembers(newMembers);
        setIsOpen(false);
    }

    const getValueSorter = (filter) => {
        const { value } = filter;
        value === "indiv" ? setTitleActionButton('Ajouter') : setTitleActionButton('Importer');
    }

    // const handleInputChange = (input) => {
    //     const { id, value } = input;
    //     setNewTalent({ ...newTalent, [id]: value });
    // }

    // const handelRadioChange = (radio) => {
    //     const { value } = radio;
    //     setNewTalent({ ...newTalent, gender: value });
    // }

    const handleExistingTalentsDropdown = (dropdown) => {
        const { value } = dropdown;
        const talent = value.value;
        if (!checkedMemberHasAlreadyBeenAdded(talent)) {
            setNewMembers([...newMembers, talent]);
        }
    }

    // const handleNewTalentClick = () => {
    //     if (!checkedMemberHasAlreadyBeenAdded(newTalent)) {
    //         setNewMembers([...newMembers, newTalent]);
    //     }

    //     setNewTalent({ comments: "", email: "", first_name: "", last_name: "", gender: "", image_url: "" })
    // }

    const checkedMemberHasAlreadyBeenAdded = (member) => {
        return newMembers.includes(member);
    }

    const handleDeleteMemberClick = (member) => {
        const filterMembers = newMembers.filter((m => m !== member));
        setNewMembers(filterMembers);
    }

    const handleOnDropFile = (file) => {
        file && setIsFile(true)
    }

    const handleDeleteFile = () => {
        setIsFile(false);
    }

    // use effects
    useEffect(() => {
        getTalentsGroup(user.id, group_id).then((response) => {
            const array = [];
            response.map(user => {
                const talents = { label: `${user.first_name} ${user.last_name}`, value: user }
                array.push(talents)
            })
            setTalents(array)
        })
    }, [group_id, user.id])

    useEffect(() => {
        const { gender, first_name, last_name, email } = newTalent;

        if (!gender || !first_name || !last_name || !email) {
            setDisabledAddNewTalent(true)
        }
        else {
            setDisabledAddNewTalent(false)
        }
    }, [newTalent])

    useEffect(() => {
        newMembers && newMembers.length > 0 && setDisabledButtonCreate(false);
    }, [newMembers])

    useEffect(() => {
        isFile && setDisabledButtonCreate(false);
    }, [isFile])

    // render components 
    const renderFilters = () => {
        return (
            <div className="popin-add-group-filters">
                <SorterButton
                    label={"Ajouter individuellement"}
                    value={"indiv"}
                    sendValue={getValueSorter}
                    active={isIndiv}
                    setActive={() => {
                        setIsIndiv(true);
                        setIsImport(false);
                    }} />
                {/* <SorterButton
                    label={"Importer une liste"}
                    value={"list"}
                    sendValue={getValueSorter}
                    active={isImport}
                    setActive={() => {
                        setIsImport(true);
                        setIsIndiv(false);
                    }} /> */}
            </div>
        )
    }

    const renderNewMembers = () => {
        const countNewMembers = newMembers.length
        return (
            <div className="new-members">
                <label>Nouveaux membres à ajouter ({countNewMembers}) </label>
                <div className="new-members-container">
                    {newMembers.map((member) => (
                        <NewTag title={`${member.first_name} ${member.last_name}`} icon={"close"} onClickIcon={() => handleDeleteMemberClick(member)} />
                    ))}
                </div>
            </div>
        )
    }

    const renderAddMember = () => {
        // const { gender, first_name, last_name, email } = newTalent
        // const genders = [{ id: "male", value: "male", label: "M" }, { id: "female", value: "female", label: "Mme" }];

        return (
            <div className="add-new-members">
                <div className="choose-existing-talent">
                    <label>Ajouter un talent existant</label>
                    <BigCombo title={""} options={talents ? talents : []} value={"Mes talents"} onChange={handleExistingTalentsDropdown} />
                </div>
                {/* <div className="add-new-talent">
                    <label>Créer un nouveau talent</label>
                    <RadioField title={""} name={"civility"} inputList={genders} value={gender} onChange={handelRadioChange} />
                    <InputField title={"Adresse e-mail"} value={email} name={"email"} required onChange={handleInputChange} />
                    <div className="add-new-talent-info">
                        <InputField title={"Prénom"} value={first_name} name={"first_name"} required onChange={handleInputChange} />
                        <InputField title={"Nom"} value={last_name} name={"last_name"} required onChange={handleInputChange} />
                    </div>
                    <div className="add-new-talent-action">
                        <PrimaryButton label={"Envoyer l'invitation"} onClick={handleNewTalentClick} disabled={disabledAddNewTalent} />
                    </div>
                </div> */}
                {newMembers && newMembers.length > 0 && renderNewMembers()}

            </div>
        )
    }

    const renderImportMembers = () => {
        return (
            <div className="import-members">
                <div className="import-members-export-document">
                    <h2>Etape 1 : document à exporter et à remplir</h2>
                    <span>
                    Nam in auctor lacus, at consequat tortor. Etiam at rhoncus arcu. Morbi vestibulum gravida lectus. Morbi laoreet arcu nisl, vel commodo libero interdum at. Nullam volutpat orci elit, a commodo magna lobortis ut. Aliquam blandit nulla sed est mollis consequat. Sed eu turpis odio.                    </span>
                    <div className="export-document">
                        <Icon icon="export" color={"accent"} />
                        <a href="#" download>Nom du document</a>
                    </div>
                </div>
                <div className="import-members-import-document">
                    <h2>Etape 2 : importer votre document rempli </h2>
                    {isFile
                        ? <div className="import-document">
                            <div className="document-name">
                                <Icon icon="upload" color={"accent"} />
                                <p>Nom du document</p>
                            </div>
                            <div onClick={handleDeleteFile}>
                            <Icon icon="trash2" />
                            </div>
                        </div>
                        : <DropStepZone
                            label={"Cliquez ici ou glissez votre document"}
                            onDrop={handleOnDropFile}
                        />
                    }
                </div>
            </div>
        )
    }

    const renderContent = () => {
        return (
            <div>
                {renderFilters()}
                <div className="add-group-popin-content">
                    {isImport ? renderImportMembers() : renderAddMember()}
                </div>
            </div>
        )
    }
    return (
        <Modal
            onClosePopin={onClose}
            openPopin={isOpen}
            title={"Ajouter des membres"}
            content={renderContent()}
            cancelButton={"Annuler"}
            actionButton={titleActionButton}
            handleActionButton={handleAddMember}
            disabledActionButton={disabledButtonCreate}
        />
    )
}
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { Tag } from '../../atoms/Tag/Tag';
import QuizTextArea from '../../molecules/QuizTextArea/QuizTextArea';
import { ParcoursConsultantSynthesis } from '../../molecules/ParcoursConsultantSynthesis/ParcoursConsultantSynthesis';
// import PrimaryButton from "../../atoms/PrimaryButton/PrimaryButton";
import { updateModule } from '../../../api/CourseApi';
import { selectors as UserSelectors } from '../../../redux/UserRedux';
import { useSelector } from 'react-redux';

import './ParcoursSynthesis.scss';

export const ParcoursSynthesis = ({ synthesis }) => {
  const user = useSelector(UserSelectors.user);
  const [contentSynthesis, setContentSynthesis] = useState();

  const onChangeSynthesis = (event) => {
    setContentSynthesis(event.value);
    synthesis.data[0].beneficiary_notes = event.value;
    if (event.value > 0) {
    }
  };

  const sendSynthesis = () => {
    if (contentSynthesis) {
      synthesis.done = 3;
      synthesis.data[0].beneficiary_notes = contentSynthesis;
    }
    updateModule(synthesis);
  };

  useEffect(() => {
    const debouncedUpdateModule = debounce(sendSynthesis, 700);
    debouncedUpdateModule();
    return () => debouncedUpdateModule.cancel();
  }, [contentSynthesis]);

  useEffect(() => {
    if (synthesis.data[0].beneficiary_notes === '') {
      synthesis.done = 1;
      updateModule(synthesis);
    } else {
      sendSynthesis();
      synthesis.done = 3;
      updateModule(synthesis);
    }
  }, [synthesis]);

  return (
    <div className="parcours-synthesis">
      <Tag state={synthesis.done} />
      <h1>
        {synthesis.title}{' '}
      </h1>
      <p>{synthesis.data[0].description}</p>

      {synthesis.data[0].consultant_notes !== null && (
        <ParcoursConsultantSynthesis
          type={synthesis.type}
          notes={synthesis.data[0].consultant_notes}
        />
      )}

      <QuizTextArea
        placeholder={
          user.role !== 'beneficiary'
            ? synthesis.type === 'synthesis'
              ? 'Ecrivez votre syntèse ici...'
              : 'Ecrivez votre feedback ici...'
            : synthesis.type === 'synthesis'
            ? 'Aucune synthèse'
            : 'Aucun feedback'
        }
        label={synthesis.type === 'synthesis' ? 'Ma synthèse *' : 'Mon feedback'}
        onChange={onChangeSynthesis}
        value={
          synthesis.data[0].beneficiary_notes === null
            ? contentSynthesis
            : synthesis.data[0].beneficiary_notes
        }
        disabled={user.role === 'beneficiary' && synthesis.type !== 'synthesis'}
      />
    </div>
  );
};

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './IconFormQuestionType.scss'
import Icon from '../Icon/Icon'

const questionType = {
  Text: Icon.icon.Text,
  Select: Icon.icon.Select,
  MultipleChoice: Icon.icon.MultipleChoice,
  Group: Icon.icon.Group,
  YesNo: Icon.icon.YesNo,
  Rating: Icon.icon.Rating,
  OpinionScale: Icon.icon.OpinionScale,
  Date: Icon.icon.Date,
}

class IconFormQuestionType extends Component {
  static propTypes = {
    questionType: PropTypes.oneOf(Object.values(questionType)).isRequired,
    color: PropTypes.oneOf(Object.values(Icon.color)),
  }

  static defaultProps = {
    color: null,
  }

  icon() {
    return <Icon icon={this.props.questionType} color={this.props.color} />
  }

  render() {
    const { questionType } = this.props
    return (
      <div
        className={classnames('icon-form-question-type', `icon-form-question-type-${questionType}`)}
      >
        {this.icon()}
      </div>
    )
  }
}

IconFormQuestionType.questionType = questionType

export default IconFormQuestionType

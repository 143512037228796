import React from "react";
import './ParcoursConsultantSynthesis.scss';
export const ParcoursConsultantSynthesis = ({type, notes}) => {
    return (
        <div className="parcours-consultant-synthesis">
            <div className="header-parcours-consultant-synthesis">
                <h3>{type === "synthesis" ? "La synthèse de votre consultant" : "Le feedback de votre consultant"}</h3>
            </div>
            <p>{notes}</p>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import './QuizRessource.scss';

export const QuizRessource = ({ setState, ressources, title, isExport = false }) => {
  const [stateQuestions, setStateQuestions] = useState();

  // useEffect(() => {
  //   const questions = ressources.module_form_questions;

  //   const allAnswersAdded = questions.every((question) => question.answer !== '');

  //   if (allAnswersAdded) {
  //     setStateQuestions(3);
  //     setState(3);
  //   } else if (questions.some((question) => question.answer !== '')) {
  //     setStateQuestions(2);
  //     setState(2);
  //   } else {
  //     setStateQuestions(1);
  //     setState(1);
  //   }
  // }, [ressources, setState]);

  const renderQuizToDo = () => {
    return (
      <div className="quiz-todo">
        <p>{title}</p>
        {ressources.module_form_questions?.length > 1 ? (
          <span>{ressources.module_form_questions?.length} questions</span>
        ) : (
          <span>{ressources.module_form_questions?.length} question</span>
        )}
        {isExport && (
          <div>
            {ressources.module_form_questions.map((question, index) => {
              let answerContent;
              try {
                const parsedAnswer = JSON.parse(question.answer);
                answerContent = (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      fontSize: '1.3rem',
                      paddingBottom: '1rem',
                      fontWeight: 'initial',
                    }}
                    
                  >
                        <div className="page-break" />

                    <p style={{ fontWeight: 'initial' }}>
                      <b>Question :</b> {question.title}
                    </p>
                    <p style={{ fontWeight: 'initial' }}>
                      <b>Résponses :</b>
                    </p>
                    {parsedAnswer
                      .filter((answer) => answer.isSelected === true)
                      .map((answer, index) => (
                        <p style={{ fontWeight: 'initial' }} key={index}>
                          {' '}
                          - {answer.label}
                        </p>
                      ))}
                  </div>
                );
              } catch (error) {
                answerContent = (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      fontSize: '1.3rem',
                      paddingBottom: '1rem',
                      fontWeight: 'initial',
                    }}
                  >
                        <div className="page-break" />

                    <p style={{ fontWeight: 'initial' }}>
                      <b>Question :</b> {question.title}
                    </p>
                    <p style={{ fontWeight: 'initial' }}>
                      <b>Réponse :</b> {question.answer}
                    </p>
                  </div>
                );
              }

              return (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '1.3rem',
                    paddingBottom: '1rem',
                    fontWeight: 'initial',
                  }}
                >
                        <div className="page-break" />

                  {answerContent}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderQuizInProgress = () => {
    const questions = ressources.module_form_questions;

    const questionsDone = questions.filter((question) => question.answer?.length > 0).length;
    const pourcent = (questionsDone / ressources.module_form_questions.length) * 100;
    return (
      <div>
        <p className="quiz-title">{title}</p>
        <div className="quiz-inprogress">
          <p>
            {questionsDone}/{ressources.module_form_questions?.length}
          </p>
          <div className="progression-bar">
            <div className="progression-done-bar" style={{ width: `${pourcent}%` }}></div>
          </div>
        </div>
        {isExport && (
          <div>
            {questions.map((question, index) => (
              <div style={{ display: 'flex', flexDirection: 'column', fontSize: '1.3rem', paddingBottom: '1rem', }}>
                <p>
                  <b>Question :</b> {question.title}
                </p>
                <p>
                  <b>Réponse :</b> {question.answer}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderQuizDone = () => {
    return <div className="quiz-done">{renderQuizToDo()}</div>;
  };

  const renderQuizLocked = () => {
    return (
      <div className="quiz-locked">
        {ressources.module_form_questions?.length > 1 ? (
          <p>{ressources.module_form_questions?.length} questions</p>
        ) : (
          <p>{ressources.module_form_questions?.length} question</p>
        )}
      </div>
    );
  };

  const rendezQuiz = () => {
    switch (stateQuestions) {
      case 0:
        return renderQuizLocked();
      case 1:
        return renderQuizToDo();
      case 2:
        return renderQuizInProgress();
      case 3:
        return renderQuizDone();
      default:
        return renderQuizDone();
    }
  };

  return <>{rendezQuiz()}</>;
};

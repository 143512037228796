import React from 'react'
import { connect } from 'react-redux'
import BeneficiaryPageAside from '../../containers/beneficiary/BeneficiaryPageAside/BeneficiaryPageAside'
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside'
import Page from '../../containers/global/Page/Page'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import { selectors as UserSelectors } from '../../redux/UserRedux';
import ChatContainer from '../../components/ChatComponents/ChatContainer';
import LineMenu from '../../components/organisms/LineMenu/LineMenu';

import './ChatPage.scss';
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import ManagerPageAside from '../../containers/manager/ManagerPageAside/ManagerPageAside'

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
})

const mapDispatchToProps = (dispatch) => ({
})

const MessagesPage = (props) => {
  const checkUser = () => {
    const { user } = props
    if (user.role === 'beneficiary') {
      return <BeneficiaryPageAside />
    }
    if (user.role === 'consultant') {
      return <ConsultantPageAside />
    }
    if (user.role === 'manager') {
      return <ManagerPageAside />
    }
    return null
  }

  const renderTabs = () => {
    return <LineMenu value={"message"} links={[{id: "message", label: "Message"}]} />
  }

  const renderContent = () => {
    return (
      <TabbedContentLayout header={renderTabs()} content={<ChatContainer/>} />
    )
  }
  const aside = checkUser();
  const content = renderContent();
  return (
    
      <div className="messages-page">
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    )
  
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(MessagesPage))
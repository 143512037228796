/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useHistory } from 'react-router-dom';

import { addMission } from "../../../api/MissionsApi";
import { IconBack } from "../../../components/atoms/IconBack/IconBack";
import { MissionContainer } from "../MissionContainer/MissionContainer";
import PopinLayout from "../../../layouts/PopinLayout/PopinLayout";
import PrimaryButton from "../../../components/atoms/PrimaryButton/PrimaryButton";

import './AddMissionLayout.scss';

export const AddMissionLayout = () => {
    const [isDisabledButtonSave, setIsDisabledButtonSave] = useState();
    const [newMission, setNewMission] = useState({id: null, name: null, user_id: null, description: null, client_id: null, mission_id: null, consultants: []});
    const [updatedMission, setUpdatedMission] = useState();
    const history = useHistory();

    const handleSaveClientOnClick = () => {
        if(!newMission.id) {
            addMission(newMission).then((response) => {
                setNewMission(response.mission)
            });
        } else {
            history.push({ pathname: '/consultant/clients-missions/missions', state: { newMission} });
        }
    }

    const handleCreateMission = (isComplete) => {
        setIsDisabledButtonSave(!isComplete)
    }

    const getNewMission = (mission) => {
        setNewMission(mission)
    }

    const renderContent = () => {
        return <MissionContainer missionProps={newMission} isMissionCompleted={handleCreateMission} sendMission={getNewMission} updateMission={updatedMission}/>
    }

    const renderHeader = () => {
        return (
            <div className="mission-header">
                <div className="mission-title">
                    <IconBack iconColor={"accent"}/>
                    <p>Nouvelle mission</p>
                </div>
                <div className="mission-actions">
                    <PrimaryButton id={"save"} label={"Sauvegarder"} onClick={handleSaveClientOnClick} disabled={isDisabledButtonSave}/>
                </div>
            </div>
        )
    }

    return (
        <PopinLayout header={renderHeader()} content={renderContent()} />
    )
}
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classnames from 'classnames'
import './CronofyConnect.scss'

class CronofySync extends Component {
  static propTypes = {
    error: PropTypes.string,
    help: PropTypes.string,
    url: PropTypes.string,
    onChange: PropTypes.func, // {name, value, event}
    onClick: PropTypes.func,
    width: PropTypes.number,
    height: PropTypes.number,
    popupTitle: PropTypes.string,
    buttonLabel: PropTypes.string,
    unlinkLabel: PropTypes.string,
    editLabel: PropTypes.string,
    pendingLabel: PropTypes.string,
    successLabel: PropTypes.string,
    isPending: PropTypes.bool,
    isSuccess: PropTypes.bool,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    error: null,
    help: null,
    onChange: () => undefined,
    onClick: () => undefined,
    url: '',
    width: 375,
    height: 790,
    popupTitle: undefined,
    buttonLabel: '',
    unlinkLabel: '',
    editLabel: '',
    pendingLabel: '',
    successLabel: '',
    isPending: false,
    isSuccess: false,
    disabled: false,
  }

  render() {
    const { buttonLabel, disabled, onClick } = this.props
    const className = classnames('primary-button outline', {
      disabled,
    })

    return (
      <div className="cronofy-connect-container">
        <div className="cronofy-connect-wrapper">
            <button type="button" className={className} onClick={onClick} disabled={disabled}>
              <div className="label">{buttonLabel}</div>
            </button>
        </div>
      </div>
    )
  }
}

export default CronofySync

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { selectors as ConsultantSelectors } from '../../../redux/ConsultantRedux'
import { actions as BeneficiaryActions } from '../../../redux/BeneficiaryRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux'

import AvatarCard from '../../../components/organisms/AvatarCard/AvatarCard'

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  consultant: ConsultantSelectors.consultant(state),
})

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  getMyConsultant: () => dispatch(BeneficiaryActions.getMyConsultant()),
})

class BeneficiaryConsultant extends PureComponent {

  handleSendMessage = () => {
    this.props.redirect(this.props.pathname.replace(/\/profile\/consultant$/, '/messages/new'))
  }

  componentDidMount() {
    this.props.getMyConsultant()
  }

  render() {
    const { consultant } = this.props

    if (!consultant) {
      return null
    }

    const cardAttr = {
      name: `${consultant.first_name} ${consultant.last_name}`,
      job: consultant.function || '',
      phone: consultant.phone || '',
      photoUrl: consultant.image_url || '',
      // labelButton: "Envoyer un message",
      // onButtonClick: this.handleSendMessage,
    }

    return (
      <div className="beneficiary-consultant">
        <AvatarCard {...cardAttr} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryConsultant)
import ApiService from './ApiService'

const api = ApiService

export const getAll = ({ token }) => {
  return api.get('/consultants', null, { headers: { Authorization: `Bearer ${token}` } })
}

export const get = ({ token, id }) => {
  return api.get(`/consultants/${id}`, null, { headers: { Authorization: `Bearer ${token}` } })
}

export const save = ({ token, id, ...data }) => {
  return id
    ? api.patch(`/consultants/${id}`, data, { headers: { Authorization: `Bearer ${token}` } })
    : api.post(`/consultants`, data, { headers: { Authorization: `Bearer ${token}` } })
}

export const remove = ({ token, id }) => {
  return api.delete(`/consultants/${id}`, [], { headers: { Authorization: `Bearer ${token}` } })
}

export const archive = ({ token, id }) => {
  return api.put(`/consultants/${id}/archive`, [], {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const activate = ({ token, id }) => {
  return api.put(`/consultants/${id}/enable`, [], {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const syncCronofy = ({ token, id, ...data }) => {
  return api.patch(`/consultants/${id}/sync-cronofy`, data, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

import { all, call, put, select, take } from 'redux-saga/effects'

import { I18n } from 'react-redux-i18n'

import { services } from '../services'
import { actions as MessageActions, selectors as MessageSelectors } from '../redux/MessageRedux'
import { actions as NotificationActions } from '../redux/NotificationRedux'
import { selectors as UserSelectors } from '../redux/UserRedux'

export function* getMessage({ payload }) {
  const { id } = payload

  yield call(services.message.get, { id }, function* ({ ok, data }) {
    if (ok) {
      yield put(MessageActions.setMessage(data))
    }
  })
}

export function* setRead({ payload }) {
  const { id } = payload

  yield call(services.message.setRead, { id }, function* ({ ok, data }) {
    if (ok) {
      yield put(MessageActions.setMessage(data))
    }
  })
}

export function* getMessages({ payload }) {
  const { filter } = payload
  const me = yield select(UserSelectors.user)

  yield call(services.message.getAll, { filter }, function* ({ ok, data }) {
    if (ok) {
      yield put(MessageActions.setMessages(data.data))

      if (filter.type === 'inbox') {
        yield put(
          MessageActions.setUnreadMessages(
            data.data.reduce((acc, { user_tos }) => {
              const recipient = user_tos[0]
              const isMe = recipient.user.id === me.id
              const increment = isMe || recipient.read_at ? 0 : 1

              return acc + increment
            }, 0),
          ),
        )
      }
    }
  })
}

export function* getUnreadMessages() {
  yield put(MessageActions.getMessages({ type: 'inbox' }))
}

export function* removeMessages({ payload }) {
  const { type, ids } = payload
  const calls = ids.map((id) => call(services.message.remove, { id }))
  const results = yield all(calls)
  const ok = results.every(({ ok }) => ok)

  if (ok) {
    yield put(
      NotificationActions.add(
        'success',
        I18n.t(calls.length > 1 ? 'form_success.remove_messages' : 'form_success.remove_message'),
      ),
    )
  }

  if (type) {
    yield put(MessageActions.getMessages({ type }))
  }
}

export function* sendMessage({ payload }) {
  const { message } = payload

  yield call(services.message.send, message, function* ({ ok, data }) {
    if (ok) {
      yield put(NotificationActions.add('success', "OK"))
    } else {
      yield put(NotificationActions.status({ ok, data }))
    }
  })
}

export function* getMessageAndMarkAsRead({ payload }) {
  const { id } = payload

  yield put(MessageActions.getMessage(id))
  yield take(MessageActions.setMessage.getType())

  const user = yield select(UserSelectors.user)
  const message = yield select(MessageSelectors.message)

  if (message.user_from.id !== user.id && !message.user_tos[0].read_at) {
    yield put(MessageActions.setRead(id))
  }
}

/* eslint-disable jsx-a11y/alt-text */
import "./BeneficiaryJobPage.scss"
import React from 'react';
import { selectors as UserSelectors } from '../../redux/UserRedux'
import { useSelector } from 'react-redux'
import ConsultantPageAside from "../../containers/consultant/ConsultantPageAside/ConsultantPageAside";
import ManagerPageAside from "../../containers/manager/ManagerPageAside/ManagerPageAside";
import BeneficiaryPageAside from "../../containers/beneficiary/BeneficiaryPageAside/BeneficiaryPageAside";
import LeftAsideLayout from "../../layouts/LeftAsideLayout/LeftAsideLayout";
import { getUserCVDesignrInfos, } from "../../api/CVDesignrApi";

const BeneficiaryJobPage = () => {
    const user = useSelector(UserSelectors.user);

    const userCVDesignr = (destination) => {

        getUserCVDesignrInfos(user.id, destination).then((response) => {
  
          if (response.magic_link) {
  
            window.open(response.magic_link, "_blank", "noreferrer");
          } 

          return false;
        })
  
        return false;
    }

    let aside;
    switch (user.role) {
        case "consultant":
            aside = <ConsultantPageAside />
            break;
        case "beneficiary":
            aside = <BeneficiaryPageAside />
            break;
        case "manager":
            aside = <ManagerPageAside />
            break;
        default:
            return null
    }

    let backgroundColor = "var(--red-medium)";

    const content = (
        <div className="beneficiary-job-page">
            <div className="button-job">
                <img src="/static/illustrations/CV.svg" style={{backgroundColor: '#7082F3'}}></img>
                <div className="job-info">
                    <div className="title-div-job">
                        <h4>Mes CV</h4>
                        {/* <p>info</p> */}
                    </div>
                    <p className="description-job">Créer, importer, modifier vos CV</p>
                    <button onClick={() => userCVDesignr("resumes")}>Consulter</button>
                    
                </div>
            </div>
            <div className="button-job">
                <img src="/static/illustrations/illustration_jobs.svg" style={{backgroundColor}}></img>
                <div className="job-info">
                    <div className="title-div-job">
                        <h4>Mes offres d'emploi</h4>
                        {/* <p>info</p> */}
                    </div>
                    <p className="description-job">Trouvez l'offre d'emploi qu'il vous faut</p>
                    <button onClick={() => userCVDesignr("job-listing")}>Consulter</button>
                    
                </div>
            </div>
            <div  className="button-job">
                <img src="/static/illustrations/illustration_jobs_application.svg" style={{backgroundColor: "var(--orange-medium)"}}></img>
                <div className="job-info">
                    <div className="title-div-job">
                        <h4>Mes tableaux de candidatures</h4>
                        {/* <p>info</p> */}
                    </div>
                    <p className="description-job">Organisez votre recherche d'emploi</p>
                    <button onClick={() => userCVDesignr("maestro")}>Consulter</button>
                </div>
            </div>
        </div>
    )


    return (
        <LeftAsideLayout aside={aside} content={content} />
    );
}

export default BeneficiaryJobPage;
import {
  actions as BeneficiaryActions,
  selectors as BeneficiarySelectors,
} from '../../redux/BeneficiaryRedux'
import {
  actions as CentralTestActions,
  selectors as CentralTestSelectors,
} from '../../redux/CentralTestRedux'
import React, { Component } from 'react'

import ConsultantBeneficiary from '../../containers/consultant/ConsultantBeneficiary/ConsultantBeneficiary'
import ConsultantBeneficiaryAside from '../../containers/consultant/ConsultantBeneficiaryAside/ConsultantBeneficiaryAside'
import Icon from "../../components/atoms/Icon/Icon";
import { selectors as NavigationSelectors } from '../../redux/NavigationRedux'
import Page from '../../containers/global/Page/Page'
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton'
import { Table } from 'antd'
import { connect } from 'react-redux'
import moment from "moment/moment";
import "./ConsultantBeneficiaryAssessmentsPage.scss";

const mapStateToProps = (state, props) => ({
  id: props.match.params.user_id,
  pathname: NavigationSelectors.pathname(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
  assessments: CentralTestSelectors.assessments(state),
})

const mapDispatchToProps = (dispatch) => ({
  getBeneficiary: (id) => dispatch(BeneficiaryActions.getBeneficiary(id)),

  getAssessments: (user_id) => dispatch(CentralTestActions.getAssessments(user_id)),
})

class ConsultantBeneficiaryAssessmentsPage extends Component {
  componentDidMount() {
    this.props.getBeneficiary(this.props.id)
    this.props.getAssessments(this.props.id)
  }

  renderAside = () => {
    return <ConsultantBeneficiaryAside />
  }

  render() {
    const { beneficiary, assessments } = this.props

    if (beneficiary === null) {
      return null
    }

    let data = []

    const renderButton = (link) => (
      <PrimaryButton
        label="Consulter"
        onClick={() => window.open(link, '_blank')}
        id={Math.random()}
        icon={Icon.icon.Preview}
      />
    )

    if (assessments) {
      data = assessments
        .filter((assessment) => !!assessment.finished_at)
        .map((assessment) => ({
          finished_at: moment(assessment.finished_at).format('DD MMM YYYY à HH[h]mm'),
          company_pdf: renderButton(assessment.company_pdf_link),
          candidate_pdf: renderButton(assessment.candidate_pdf_link),
        }))
    }

    const columns = [
      {
        title: 'Terminé le',
        dataIndex: 'finished_at',
        key: 'finished_at',
      },
      {
        title: 'Rapport Entreprise',
        dataIndex: 'company_pdf',
        key: 'company_pdf',
      },
      {
        title: 'Rapport Candidat',
        dataIndex: 'candidate_pdf',
        key: 'candidate_pdf',
      },
    ]

    return (
      <ConsultantBeneficiary renderAside={this.renderAside}>
        <div className="consultant-beneficiary-assessments-page">
          <form noValidate>
            <Table
              dataSource={data}
              columns={columns}
              loading={assessments === null}
              pagination={false}
            />
          </form>
        </div>
      </ConsultantBeneficiary>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(ConsultantBeneficiaryAssessmentsPage))
import { call, put, race, select, take } from 'redux-saga/effects'
import * as RouterActions from 'connected-react-router'
// import { I18n } from 'react-redux-i18n'

import { services } from '../services'
import { actions as UserActions, selectors as UserSelectors } from '../redux/UserRedux'
import {
  actions as BeneficiaryActions,
  selectors as BeneficiarySelectors,
} from '../redux/BeneficiaryRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../redux/NavigationRedux'
import { actions as NotesActions } from '../redux/NotesRedux'
import {
  actions as ConsultantActions,
  selectors as ConsultantantSelectors,
} from '../redux/ConsultantRedux'
import { actions as NotificationActions } from '../redux/NotificationRedux'
import { actions as AppActions } from '../redux/AppRedux'

import {
  hasFollowUpStatus,
  inCreation,
  inProgress,
  isArchived,
  needToCompletePersonnalInfo,
  needToFillFinancerInfo,
  waitingCreationValidation,
  waitingFinancerInfoValidation,
  waitingPersonnalInfoValidation,
} from '../helpers/beneficiaryStatus'
import {
  actions as CourseTemplateActions,
  selectors as CourseTemplateSelectors,
} from '../redux/CourseTemplateRedux'
import { s2ab } from '../helpers/buffer'

export function* attribute({ payload }) {
  yield call(services.beneficiary.attribute, payload, function* ({ ok, data }) {
    if (ok) {
      let consultant = yield select(ConsultantantSelectors.consultant)

      if (consultant) {
        consultant.beneficiaries = consultant.beneficiaries.filter(
          (beneficiary) => beneficiary.id !== payload.beneficiary_id,
        )

        yield put(ConsultantActions.setConsultant(consultant))
        yield put(BeneficiaryActions.setBeneficiary(data))
      } else {
        let beneficiaries = yield select(BeneficiarySelectors.beneficiaries)
        yield call(services.beneficiary.getBeneficiary, { id: payload.beneficiary_id }, function* ({
          ok,
          data,
        }) {
          for (let i = 0; i < beneficiaries.length; i++) {
            if (beneficiaries[i].id === data.id) {
              beneficiaries[i] = data
            }
          }
          yield put(BeneficiaryActions.setBeneficiaries(beneficiaries))
        })
      }
    }

    yield put(NotificationActions.status({ ok, data }))
  })
}

export function* share({ payload }) {
  const { beneficiary_id, consultant_id, disableNotification } = payload

  yield call(services.beneficiary.share, { beneficiary_id, consultant_id }, function* ({
    ok,
    data,
  }) {
    if (ok) {
      yield put(BeneficiaryActions.setBeneficiary(data))
    }

    if (!disableNotification) {
      yield put(NotificationActions.status({ ok, data }))
    }
  })
}

export function* unshare({ payload }) {
  const { beneficiary_id, consultant_id, disableNotification } = payload
  yield call(services.beneficiary.unshare, { beneficiary_id, consultant_id }, function* ({
    ok,
    data,
  }) {
    if (ok) {
      yield put(BeneficiaryActions.setBeneficiary(data))
    }

    if (!disableNotification) {
      yield put(NotificationActions.status({ ok }))
    }
  })
}

export function* manageStatus() {
  const user = yield select(UserSelectors.user)
  const pathname = yield select(NavigationSelectors.pathname)

  let redirectionDone = false

  const userInCreation = inCreation(user)

  if (userInCreation) {
    redirectionDone = true
    yield put(NavigationActions.push('/beneficiary/creation'))
  } else if (pathname === '/beneficiary/creation') {
    redirectionDone = true
    yield put(NavigationActions.push('/beneficiary/profile'))
  }

  const userNeedToFillFinancerInfo = needToFillFinancerInfo(user)

  if (userNeedToFillFinancerInfo) {
    const displayWaitingPage = yield select(BeneficiarySelectors.displayWaitingPage)

    if (displayWaitingPage) {
      redirectionDone = true
      yield put(NavigationActions.push('/beneficiary/waiting'))
    }
  } else if (pathname === '/beneficiary/waiting') {
    redirectionDone = true
    yield put(NavigationActions.push('/beneficiary/profile'))
  }

  const userNeedToCompletePersonnalInfo = needToCompletePersonnalInfo(user)

  if (userNeedToCompletePersonnalInfo) {
    redirectionDone = true
    yield put(NavigationActions.push('/beneficiary/invalidation'))
  } else if (pathname === '/beneficiary/invalidation') {
    redirectionDone = true
    yield put(NavigationActions.push('/beneficiary/profile'))
  }

  if (!redirectionDone) {
    if (
      waitingCreationValidation(user) ||
      waitingFinancerInfoValidation(user) ||
      waitingPersonnalInfoValidation(user) ||
      (!inProgress(user) && !hasFollowUpStatus(user) && !isArchived(user))
    ) {
      // TODO: wait financer status...
      yield put(NavigationActions.push('/beneficiary/profile'))
    } else if (pathname.startsWith(`/${user.role}`) === false) {
      yield put(NavigationActions.push(`/${user.role}`))
    }
  }
}

export function* saveCurrentBeneficiary({ payload }) {
  yield call(services.beneficiary.saveCurrentBeneficiary, payload, function* ({ ok, data }) {
    if (ok) {
      yield put(UserActions.getCurrentUser())
      yield take(UserActions.setUser)
    }

    yield put(NotificationActions.status({ ok, data }))
  })
}

export function* saveBeneficiary({ payload }) {
  const { beneficiary } = payload

  yield call(services.beneficiary.saveBeneficiary, beneficiary, function* ({ ok, data }) {
    if (ok) {
      yield put(BeneficiaryActions.setBeneficiary(data))

      if (!beneficiary.id) {
        const pathname = yield select(NavigationSelectors.pathname)
        yield put(NavigationActions.push(pathname.replace('/new', `/${data.id}/profile`)))
      }

      yield put(NotesActions.saveNotes())
      yield take(NotesActions.setNotes.getType())
    }

    yield put(NotificationActions.status({ ok, data }))
  })
}

export function* removeBeneficiary({ payload }) {
  const { id } = payload

  yield call(services.beneficiary.remove, { id }, function* ({ ok, data }) {
    yield put(NotificationActions.status({ ok, data }))
  })
}

export function* getMyConsultant() {
  yield call(services.beneficiary.getMyConsultant, null, function* ({ ok, data }) {
    ok
      ? yield put(ConsultantActions.setConsultant(data))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* getBeneficiaries({ payload }) {
  //Check if user is not beneficiary
  const user = yield select(UserSelectors.user)
  if (user.role === 'beneficiary') {
    return
  }

  let { filter } = payload
  if (!filter && payload.beneficiariesFilter) {
    filter = payload.beneficiariesFilter
  }

  yield call(services.beneficiary.getBeneficiaries, { filter }, function* ({ ok, data }) {
    ok
      ? yield put(BeneficiaryActions.setBeneficiaries(data))
      : yield put(NotificationActions.status({ ok, data }))
  })
}

export function* exportBeneficiaries({ payload }) {
  let { filter } = payload
  if (!filter && payload.beneficiariesFilter) {
    filter = payload.beneficiariesFilter
  }

  yield call(services.beneficiary.exportBeneficiaries, { filter }, function* ({ ok, data }) {
    if (ok) {
      const blob = new Blob([s2ab(atob(data))], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      })
      // const blob = new Blob([data])
      yield put(BeneficiaryActions.setExportBlob(blob))
    }
  })
}

export function* getBeneficiary({ payload }) {
  const { id } = payload

  yield call(services.beneficiary.getBeneficiary, { id }, function* ({ ok, data }) {
    ok
      ? yield put(BeneficiaryActions.setBeneficiary(data))
      : yield put(NotificationActions.status({ ok, data }))
  })

  yield call(services.beneficiary.getCvDesignrData, { id }, function* ({ ok, data }) {
    if (ok) {
      yield put(BeneficiaryActions.setCvs(data.cvs))
      yield put(BeneficiaryActions.setMaestros(data.maestros))
    }
  })
}

export function* getCvDesignrLink({ payload }) {
  yield put(BeneficiaryActions.setLoadingCvDesignrLink(true))
  const { type } = payload
  const user = yield select(UserSelectors.user)
  yield call(services.beneficiary.getCvDesignrAuthLink, { id: user.id, type }, function* ({
    ok,
    data,
  }) {
    yield put(BeneficiaryActions.setCvDesignrExternalLink(data))
  })
  yield put(BeneficiaryActions.setLoadingCvDesignrLink(false))
}

export function* getCvDesignrPdfLink({ payload }) {
  yield put(BeneficiaryActions.setLoadingCvDesignrPdfLink(true))
  const { cvId } = payload
  const beneficiary = yield select(BeneficiarySelectors.beneficiary)
  yield call(services.beneficiary.getCvDesignrPdfLink, { id: beneficiary.id, cvId }, function* ({
    ok,
    data,
  }) {
    yield put(BeneficiaryActions.setCvDesignrPdfExternalLink(data))
  })
  yield put(BeneficiaryActions.setLoadingCvDesignrPdfLink(false))
}

export function* getCvDesignrEditLink({ payload }) {
  yield put(BeneficiaryActions.setLoadingCvDesignrEditLink(true))
  const { cvId } = payload
  const beneficiary = yield select(BeneficiarySelectors.beneficiary)
  yield call(services.beneficiary.getCvDesignrEditLink, { id: beneficiary.id, cvId }, function* ({
    ok,
    data,
  }) {
    if (data === 403) {
      yield put(NotificationActions.add('error', "Le document ne peut pas être modifié"))
      yield put(BeneficiaryActions.setCvDesignrEditExternalLink(false))
    } else if (data === 409) {
      yield put(NotificationActions.add('error', "I18n.t('consultant.comment_waiting')"))
      yield put(BeneficiaryActions.setCvDesignrCommentExternalLink(false))
    } else {
      yield put(BeneficiaryActions.setCvDesignrEditExternalLink(data))
    }
  })
  yield put(BeneficiaryActions.setLoadingCvDesignrEditLink(false))
}

export function* getCvDesignrCommentLink({ payload }) {
  yield put(BeneficiaryActions.setLoadingCvDesignrCommentLink(true))
  const { cvId } = payload
  const beneficiary = yield select(BeneficiarySelectors.beneficiary)
  yield call(
    services.beneficiary.getCvDesignrCommentLink,
    { id: beneficiary.id, cvId },
    function* ({ ok, data }) {
      if (data === 403) {
        yield put(NotificationActions.add('error', "Le document ne peut pas être modifié"))
        yield put(BeneficiaryActions.setCvDesignrCommentExternalLink(false))
      } else if (data === 409) {
        yield put(NotificationActions.add('error', "I18n.t('consultant.edition_waiting')"))
        yield put(BeneficiaryActions.setCvDesignrCommentExternalLink(false))
      } else {
        yield put(BeneficiaryActions.setCvDesignrCommentExternalLink(data))
      }
    },
  )
  yield put(BeneficiaryActions.setLoadingCvDesignrCommentLink(false))
}

export function* onBack({ payload }) {
  const { disableSave } = payload

  const beneficiary = yield select(BeneficiarySelectors.beneficiary)
  const template = yield select(CourseTemplateSelectors.template)
  const pathname = yield select(NavigationSelectors.pathname)

  if (template != null && template.id != null && /\/courses/.test(pathname)) {
    yield put(NavigationActions.push(pathname.replace(`/courses/${template.id}`, '/courses')))
  } else if (/\/(profile|courses)/.test(pathname) && beneficiary.id) {
    yield put(NavigationActions.back(`/${beneficiary.id}`))
  } else {
    yield put(NavigationActions.back())
  }

  let confirmDoActions = disableSave
  if (!disableSave) {
    const { confirmGoBack } = yield race({
      confirmGoBack: take(RouterActions.LOCATION_CHANGE),
      cancelGoBack: take(AppActions.hideLeavePopin.getType()),
    })

    confirmDoActions = confirmGoBack
  }

  if (confirmDoActions) {
    if (template != null && template.id != null && /\/courses/.test(pathname)) {
      yield put(CourseTemplateActions.setTemplate(null))
      return
    }

    if (/\/(profile|courses)/.test(pathname) && beneficiary.id) {
      yield put(BeneficiaryActions.setBeneficiary(null))
    }
  }
}

import React, { useEffect, useState } from 'react'
import ActionPopinHeader from '../ActionPopinHeader/ActionPopinHeader'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import { Popin } from '../../../containers/global/Popin/Popin'
import ComboField from '../ComboField/ComboField'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors as CentralTestSelectors } from '../../../redux/CentralTestRedux'
import { actions as CourseTemplateActions } from '../../../redux/CourseTemplateRedux'
import Spinner from '../../atoms/LoadingSpinner/spinner.svg'
import { useParams } from 'react-router'
import './ModuleCentralTestSelectPopin.scss'
const ModuleCentralTestSelectPopin = ({
  centralTestModule,
  stepIndex,
  moduleIndex,
  isModalOpen,
  onClose,
  isTemplate = false,
}) => {
  const dispatch = useDispatch()
  const [selected, setSelected] = useState(null)
  const { course_id, user_id } = useParams()

  const handleOnChange = ({ _label, value }) => {
    setSelected(value)
  }

  const onSubmit = () => {
    if (selected) {
      centralTestModule = {
        ...centralTestModule,
        data: {
          ...centralTestModule.data,
          test_id: selected,
          test_name: tests.find((test) => test.id === selected).name,
        },
      }

      dispatch(
        CourseTemplateActions.updateModuleAtIndex(
          stepIndex,
          moduleIndex,
          centralTestModule,
          true,
          true,
          {
            course_id,
            user_id,
          },
        ),
      )
    }

    onClose()
  }

  const tests = useSelector(CentralTestSelectors.tests)

  useEffect(() => {
    if (tests === null) {
      dispatch(actions.fetchTests())
    }
  }, [tests, dispatch])

  const isLoading = tests === null

  const options = () => {
    if (tests === null) {
      return []
    }

    return tests
      .filter((test) => test.name)
      .map((test) => ({
        label: test.name,
        value: test.id,
      }))
  }

  return (
    <>
      <Popin open={isModalOpen} focusTrapped={false}>
        <div className="centraltest-selection-popin">
          <ActionPopinHeader lightTitle="Choix de l'évaluation à assigner" />
          {isLoading ? (
            <div className="loader">
              <Spinner />
              <small>Récupération des informations depuis Central Test</small>
            </div>
          ) : (
            <div className="content">
              <ComboField
                options={options()}
                name="Choix"
                id="1"
                onChange={handleOnChange}
                className="centraltest-selection-dropdown"
                placeholder="Veuillez choisir"
                value={selected}
                disabled={isLoading}
              />

              <div className="buttons">
                <PrimaryButton label="Annuler" id="cancel-button" outline onClick={onClose} />

                <PrimaryButton
                  label="Confirmer"
                  id="confirm-button"
                  disabled={selected === null}
                  onClick={onSubmit}
                />
              </div>
            </div>
          )}
        </div>
      </Popin>
    </>
  )
}

export default ModuleCentralTestSelectPopin

import {put} from 'redux-saga/effects'
import {I18n} from 'react-redux-i18n'

import {actions as NotificationActions} from '../redux/NotificationRedux'

export function* status({payload}) {
  const {ok} = payload
  const type = ok ? 'success' : 'error'
  const text = getStatusMessage(payload)

  yield put(NotificationActions.add(type, text))
}

export function getStatusMessage({data, ok}) {
  if (data && data.message) {
    return data.message
  }

  return ok
    ? "OK"
    : getErrorMessage(data) || I18n.t("Une erreur est survenue")
}

export function getErrorMessage(data) {
  const [error] = Array.isArray(data) ? data : []
  const {validation, field, message} = error || {}

  if (validation) {
    switch (validation) {
      case 'unique':
        return field === 'email' && "Cette adresse email est déjà utilisée"
      default:
        return "Une erreur est survenue"
    }
  }

  if (message) {
    if(message.startsWith('centraltest')){
      return I18n.t(message)
    }

    switch (message) {
      case 'manager/too-many-consultants':
        return "Vous avez enregistré trop de consultants"
      case 'module/module-already-done':
        return "Le module est déjà terminé"
      case 'upload/forbidden':
        return "Impossible d'effectuer le téléchargement"
      case 'module-template/not-a-template':
        return "Le parcours selectionné n'est pas un modèle"
      case 'auth/revoked':
        return "Connexion impossible"
      case 'beneficiary/forbidden':
        return "Accès non autorisé pour le talent"
      case 'file/forbidden':
        return "Accès au fichier non autorisé"
      case 'course-template/forbidden':
        return "Accès au parcours modèle non autorisé"
      case 'module-done/remain-required-questions':
        return "Des questions obligatoires restent à repondre dans ce module"
      default:
        return "Une erreur est survenue"
    }
  }
}

import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import { selectors as UserSelectors } from "../../redux/UserRedux"
import { actions as NavigationActions, selectors as NavigationSelectors } from '../../redux/NavigationRedux'

import { getAllPublishModulesContainer, getAllPublishMyTalentsModulesContainer, getAllPublishOfficeModulesContainer } from '../../api/TemplateCourseApi'

import {AddModulePopin} from '../../containers/module/AddModulePopin/AddModulePopin'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import ModuleList from '../../containers/module/ModuleList/ModuleList'
import Page from '../../containers/global/Page/Page'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import TopSearch from '../../containers/global/TopSearch/TopSearch'
// import VideoPopin from '../../components/organisms/VideoPopin/VideoPopin'

import "./ConsultantModulesPage.scss"

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
})

const ConsultantModulesPage = ({ user }) => {
  const dispatch = useDispatch()
  const [scope, setScope] = useState('consultant');
  // const [title, setTitle] = useState('');
  const [openAddNew, setOpenAddNew] = useState(false);
  // const [videoPopin, setVideoPopin] = useState('');
  // const [videoTitle, setVideoTitle] = useState('');
  const [modules, setModules] = useState([])
  const pathname = useSelector(NavigationSelectors.pathname)
  const currentTab = useSelector((state) => NavigationSelectors.query(state, 'tab') || 'consultant')

  // call api
  const getConsultantModules = () => {
    const { id } = user;
    getAllPublishModulesContainer(id).then((response) => {
      setModules(response.modules_containers);
    })
  }

  const getOfficeModules = () => {
    const { id } = user;
    getAllPublishOfficeModulesContainer(id).then((response) => {
      setModules(response.modules_containers)
    })
  }

  const getMyTalentsModules = () => {
    getAllPublishMyTalentsModulesContainer().then((response) => {
      setModules(response.modules_containers)
    })
  }

  // use effect
  useEffect(() => {
    getTemplates();
  // eslint-disable-next-line no-use-before-define
  }, [getTemplates])

  useEffect(() => {
    getTemplates()

    if (scope !== currentTab) {
      handleTabChange(currentTab)
    }
  // eslint-disable-next-line no-use-before-define
  }, [scope, currentTab, getTemplates, handleTabChange])

  // methods
  const getTemplates = useCallback(
    () => {
      if (scope === "mytalents") {
        getConsultantModules();
      }
      else if (scope === "office") {
        getOfficeModules();
      }
      else if (scope === "consultant") {
        getMyTalentsModules();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scope]
  )

  const redirect = useCallback(
    (destination) => {
      dispatch(NavigationActions.push(destination))
    },
    [dispatch],
  )

  const handleTabChange = useCallback(
    (scope) => {
      setScope(scope)
      redirect(`${pathname}?tab=${scope}`)
    },
    [pathname, redirect],
  )

  const handleAddPopin = () => {
    setOpenAddNew(true);
   }

  const handleVideoClicked = () => { }

  const handleSearch = () => { }

  // render components
  const renderSearchBar = () => {
    return <TopSearch onSearch={handleSearch} />
  }

  const renderTabs = () => {
    const tabs = [
      { id: 'consultant', label: "Mes modules" },
      { id: 'office', label: "Nos modules" },
      { id: 'mytalents', label: "MyTalents" },
    ]
    return <LineMenu value={scope} links={tabs} onChange={handleTabChange} components={renderSearchBar()} />
  }

  const renderContent = () => (
    <>
      <div className="add-button-wrapper">
        { scope === 'consultant' && <CircleButton icon="plus" onClick={handleAddPopin} /> }
      </div>
      <ModuleList modules={modules} onVideoClicked={handleVideoClicked} />
      <AddModulePopin onClose={handleAddPopin} open={openAddNew} />
      {/* {this.renderVideo()} */}
    </>
  ) 

  const aside = <ConsultantPageAside />
  const content = <TabbedContentLayout header={renderTabs()} content={renderContent()} />

  return (
    <div className="consultant-modules-page">
      <LeftAsideLayout aside={aside} content={content} />
    </div>
  )
}
// class ConsultantModulesPage extends Component {
//   state = {
//     scope: 'consultant',
//     title: '',
//     openAddNew: false,
//     videoPopin: '',
//     videoTitle: '',
//   }

//   handleSearch = (value) => {
//     this.setState({ title: value }, this.getTemplates)
//   }

//   handleTabChange = (scope) => {
//     this.setState({ scope }, this.getTemplates)
//     this.props.redirect(`${this.props.location.pathname}?tab=${scope}`)
//   }

//   renderSearchBar() {
//     return <TopSearch onSearch={this.handleSearch} />
//   }

//   renderTabs = () => {
//     const links = [
//       { id: 'consultant', label: "Mes modules" },
//       { id: 'office', label: "Nos modules" },
//       { id: 'mytalents', label: "Les modules My Talents" },
//     ]

//     return (
//       <LineMenu
//         value={this.state.scope}
//         links={links}
//         onChange={this.handleTabChange}
//         components={this.renderSearchBar()}
//       />
//     )
//   }

//   handleAddPopin = () => {
//     this.setState(({ openAddNew }) => ({ openAddNew: !openAddNew }))
//   }

//   renderVideo = () => {
//     const { videoPopin, videoTitle } = this.state

//     return (
//       <VideoPopin
//         title={videoTitle}
//         videoId={videoPopin}
//         onClose={this.handleCloseVideoPopin}
//         open={!!videoPopin}
//       />
//     )
//   }

//   handleVideoClicked = (url, title) => {
//     this.setState({ videoPopin: url, videoTitle: title })
//   }

//   handleCloseVideoPopin = () => {
//     this.setState({ videoPopin: '', videoTitle: '' })
//   }

//   renderContent = () => (
//     <>
//       <div className="add-button-wrapper">
//         {this.state.scope === 'consultant' && (
//           <CircleButton icon="plus" onClick={this.handleAddPopin} />
//         )}
//       </div>
//       <ModuleList modules={this.props.moduleTemplate} onVideoClicked={this.handleVideoClicked} />
//       <AddModulePopin onClose={this.handleAddPopin} open={this.state.openAddNew} />
//       {this.renderVideo()}
//     </>
//   )

//   getTemplates = () => {
//     const { scope, title } = this.state
//     this.props.getTemplates({ scope, title })
//   }

//   componentDidMount() {
//     const { currentTab } = this.props

//     this.getTemplates()

//     if (this.state.scope !== currentTab) {
//       this.handleTabChange(currentTab)
//     }
//   }

//   render() {
//     const aside = <ConsultantPageAside />
//     const content = (
//       <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
//     )

//     return (
// <div className="consultant-modules-page">
//   <LeftAsideLayout aside={aside} content={content} />
// </div>
//     )
//   }
// }

export default connect(mapStateToProps, null)(Page(ConsultantModulesPage))
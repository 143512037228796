import React, { useState } from 'react'
import IconButton from '../IconButton/IconButton'
import './IconChevron.scss';

const IconChevron = ({open, color, sendIsOpen}) => {
  const [isOpen, setIsOpen] = useState(true)

  const handleClick = () => {
   setIsOpen(open => !open);
   sendIsOpen(isOpen)
  }

  return (
    <IconButton
      icon={isOpen ? 'chevron-down' : 'chevron-up'}
      color={color}
      onClick={handleClick}
    />
  )
}

IconChevron.defaultProps = {
  color: 'accent'
}

export default IconChevron;
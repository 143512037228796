import { axiosInstance } from "./baseApi";

export const addBeneficiaries = async(beneficiaries) => {
    const response = await axiosInstance.post('beneficiaries/add', beneficiaries);
    return response.data;
}

export const deleteBeneficiary = async(beneficiaries) => {
    const response = await axiosInstance.delete('beneficiaries/add', beneficiaries);
    return response.data;
}

// POST : /beneficiaries/add
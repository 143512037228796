import React from "react";
import { GroupCard } from "../../../components/organisms/GroupCard/GroupCard";
import './GroupsList.scss'

export const GroupsList = ({groups, onDeleteGroup}) => {
    return (
        <div className="groups-list">
            {groups.map((group, index) => (
                <div className="group-item" key={index}>
                    <GroupCard group={group} onDeleteGroup={onDeleteGroup}/>
                </div>
            ))}
        </div>
    )
} 
import React from "react";
import Icon from "../Icon/Icon";
import './Tag.scss';

export const Tag = ({ label, icon, state, color, isBackgroundColor }) => {
    switch (state) {
        case 0:
            label = "A débloquer";
            color = "red";
            icon = "lock";
            break;

        case 1:
            label = "A faire";
            color = "grey";
            icon = "start";
            break;
        case 2:
            label = "En cours";
            color = "orange";
            icon = "clock";
            break;
        case 3:
            label = "Terminé"
            color = "green";
            icon = "circle_check"
            break;
        default:
            return null;
    }

    return (
        <span className="tag-container" style={isBackgroundColor ? { backgroundColor: `var(--${color}-light)` } : { backgroundColor: `var(--pure-white)` }}>
            {icon && <span style={{ color: color }}> <Icon icon={icon} color={color} /></span>}
            <label style={color === "grey" ? { color: `var(--text-secondary)` } : { color: `var(--${color})` }}>{label}</label>
        </span>
    )
}
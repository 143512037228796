/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
// import IconButton from '../../components/atoms/IconButton/IconButton'
import { Navbar } from '../../components/atoms/Navbar/Navbar'
import Icon from '../../components/atoms/Icon/Icon'
import { selectors as UserSelectors } from "../../redux/UserRedux"
import './LeftAsideLayout.scss'
// import PropTypes from 'prop-types'
const mapStateToProps = (state) => ({
  user: UserSelectors.user(state)
})

const LeftAsideLayout = ({ aside, content, user, leftComponentNavbar, showNavBar }) => {
  const [isOpen, setIsOpen] = useState(JSON.parse(localStorage.getItem('sidebar')));
  const asideRef = useRef();
  const [isBeneficiary, setIsBeneficiary] = useState(false);
  const [isNavbar, setIsNavbar] = useState(true);

  const toggleButton = () => {
    setIsOpen(open => !open);
    localStorage.setItem('sidebar', !isOpen);
  }

  useEffect(() => {
    user.role === "beneficiary" ? setIsBeneficiary(true) : setIsBeneficiary(false)
    if (user.role === "beneficiary") {
      asideRef.current.classList.toggle('open', isOpen);
      asideRef.current.classList.toggle('close', !isOpen);
    }
  }, [isOpen, user])


  useEffect(() => {
    user.role === "admin" ? setIsNavbar(false) : setIsNavbar(showNavBar)
  }, [showNavBar])

  useEffect(() => {
    if(user.role !== "beneficiary") {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'hs-script-loader';
      script.async = true;
      script.defer = true;
      script.src = '//js-eu1.hs-scripts.com/26716166.js';
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <div className="layout left-aside-layout">
      <div ref={asideRef} className={isBeneficiary && isOpen ? 'aside open' : (isBeneficiary && !isOpen ? 'aside close' : 'aside open')}>
        {isBeneficiary &&
          <button className="toggle-btn" onClick={toggleButton}>
            {
              isOpen
                ? <Icon icon="chevron_left" color={Icon.color.White} />
                : <Icon icon="chevron_right" color={Icon.color.White} />
            }
          </button>
        }
        {aside}
      </div>
      <section className='content' >
        {(isNavbar) &&
          <div className='navbar'>
            <Navbar leftComponentNavbar={leftComponentNavbar} aside={aside} />
          </div>
        }
        <div className='content-section'>
          {content}
        </div>
        {!isBeneficiary &&
          <div className='chatbot'>
            <a href="#!"></a>
            {/* <a href="javascript:void(0);"></a> */}

          </div>
        }
      </section>
    </div>
  )
}

LeftAsideLayout.defaultProps = {
  showNavBar: true
}

export default connect(mapStateToProps, null)(LeftAsideLayout);

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'
import QuizStep from '../QuizStep/QuizStep'
import './QuizOpinionScale.scss'
class QuizOpinionScale extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    stepLabel: PropTypes.string,
    stepOutline: PropTypes.bool,
    scale: PropTypes.number.isRequired,
    value: PropTypes.number,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    image: PropTypes.string,
  }

  static defaultProps = {
    onChange: () => undefined,
    stepLabel: null,
    value: -1,
    disabled: false,
  }

  manageActiveClass = (cellIndex) => {
    if (this.state.hovering) {
      if (cellIndex < this.state.hoveredCell + 1) {
        return 'active'
      }
      return ''
    }

    const { value = -1 } = this.props

    if (cellIndex < value + 1) {
      return 'active'
    }
  }

  renderCells = () => {
    const { scale } = this.props

    if (scale > 0) {
      return Array(scale + 1)
        .fill({})
        .map((cell, key) => (
          <span
            key={key}
            className={classnames('cell', this.manageActiveClass(key))}
            onMouseEnter={() => this.onCellEnter(key)}
            onMouseLeave={this.onCellLeave}
            onClick={() => this.onCellClick(key)}
          >
            <span>{key}</span>
          </span>
        ))
    }

    return null
  }

  onCellEnter = (cellIndex) => {
    if (!this.props.disabled)
      this.setState({
        hovering: true,
        hoveredCell: cellIndex,
      })
  }

  onCellLeave = () => {
    if (!this.props.disabled)
      this.setState({
        hovering: false,
        hoveredCell: -1,
      })
  }

  onCellClick = (cellIndex) => {
    if (!this.props.disabled) this.props.onChange({ id: this.props.id, value: cellIndex })
  }

  constructor(props) {
    super(props)

    this.state = {
      hovering: false,
      hoveredCell: -1,
    }
  }

  render() {
    const { stepLabel, label, stepOutline, image } = this.props

    return (
      <div className="quiz-opinion-scale">
        <QuizStep stepLabel={stepLabel} stepOutline={stepOutline} label={label} />
        {image && <img src={image} alt='question_illu' className='question-illustration' />}

        <div className="cells-container">{this.renderCells()}</div>
      </div>
    )
  }
}

export default QuizOpinionScale

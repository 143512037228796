import React from "react";
import { Popin } from "../../../containers/global/Popin/Popin";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";


import './Modal.scss';

export const Modal = ({ onClosePopin, openPopin, title, content, cancelButton, actionButton, handleActionButton, disabledActionButton, loading = false }) => {
    const modalStyles = {
        // minWidth: "670px",
    };

    const renderActions = () => {
        return (
            <div className="modal-footer">
                {cancelButton && <PrimaryButton label={cancelButton} onClick={onClosePopin} cancel={true} id={"cancel"} />}
                {actionButton && <PrimaryButton label={actionButton} disabled={disabledActionButton} onClick={handleActionButton} id={"submit"} />}
            </div>
        )
    }

    return (
        <Popin onClose={onClosePopin} open={openPopin} focusTrapped={false} overrideModalStyles={modalStyles} classes={"responsive-modal"}>
            <div>
            {loading && <LoadingSpinner />}

                <div className="modal-header">
                    <h1>{title}</h1>
                </div>
                <div className="modal-content">{content}</div>
                {renderActions()}
            </div>

        </Popin>
    )
}

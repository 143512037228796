import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Icon from '../../atoms/Icon/Icon'
import IconFormQuestionType from '../../atoms/IconFormQuestionType/IconFormQuestionType'
import IconButton from '../../atoms/IconButton/IconButton'
import './FormQuestionRow.scss';

export default class FormQuestionRow extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    questionType: PropTypes.oneOf(Object.values(IconFormQuestionType.questionType)).isRequired,
    questionNumber: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    onMoveClick: PropTypes.func,
    onCopyClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
  }

  static defaultProps = {
    onMoveClick: null,
    onCopyClick: null,
    onEditClick: null,
    onDeleteClick: null,
  }

  handleMoveClick = () => {
    const { onMoveClick = null, id } = this.props
    if (onMoveClick !== null) {
      onMoveClick({ id })
    }
  }

  handleCopyClick = () => {
    const { onCopyClick = null, id } = this.props
    if (onCopyClick !== null) {
      onCopyClick({ id })
    }
  }

  handleEditClick = () => {
    const { onEditClick = null, id } = this.props
    if (onEditClick !== null) {
      onEditClick({ id })
    }
  }

  handleDeleteClick = () => {
    const { onDeleteClick = null, id } = this.props
    if (onDeleteClick !== null) {
      onDeleteClick({ id })
    }
  }

  truncateString = (fullStr) => {
    if (!fullStr) return
    const strLen = 100
    if (fullStr.length <= strLen) return fullStr

    const separator = '...'
    const sepLen = separator.length
    const charsToShow = strLen - sepLen

    return fullStr.substr(0, charsToShow) + separator
  }

  render() {
    const { questionType, questionNumber, title } = this.props
    return (
      <div className="form-question-row">
        <div className="question-number">
          <span>{questionNumber}</span>
        </div>
        <div className="question-infos">
          <div className="question-icon">
            <IconFormQuestionType questionType={questionType} color={Icon.color.Accent} />
          </div>
          <div className="question-title">{this.truncateString(title)}</div>
          <div className="question-actions">
            <IconButton
              icon={Icon.icon.Drag}
              color={Icon.color.Grey2}
              onClick={this.handleMoveClick}
            />
            <IconButton
              icon={Icon.icon.Copy}
              color={Icon.color.Grey2}
              onClick={this.handleCopyClick}
            />
            <IconButton
              icon={Icon.icon.Edit2}
              color={Icon.color.Grey2}
              onClick={this.handleEditClick}
            />
            <IconButton
              icon={Icon.icon.Trash2}
              color={Icon.color.Grey2}
              onClick={this.handleDeleteClick}
            />
          </div>
        </div>
      </div>
    )
  }
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Transition from 'react-transition-group/Transition'
import SideMenuLinkPanel from './SideMenuLinkPanel'
import Icon from '../../atoms/Icon/Icon';
import { NavLink } from "react-router-dom";
import './SideMenuLink.scss';


const SideMenuLink = ({ icon, label, id, pathname, options, onClick, onDropdownOpen, openDropdownId }) => {
  const localStorageKey = `sideMenuLinkState_${id}`;

  const [isOpen, setIsOpen] = useState(() => {
    const storedState = JSON.parse(localStorage.getItem(localStorageKey));
    return storedState ? storedState.isOpen : false;
  });

  const openDropdown = (event) => {
    event.preventDefault();
    setIsOpen(open => !open);
    onDropdownOpen(id);
  }

  const handleClickOption = ({id}) => {
    onClick({id})
  }

  useEffect(() => {
    const sideMenuLinkState = { label: label, pathname: pathname, isOpen: isOpen };
    localStorage.setItem(localStorageKey, JSON.stringify(sideMenuLinkState));
  }, [isOpen, pathname, localStorageKey]);

  const getArrowIcon = () => {
    const icon = isOpen ? Icon.icon.ChevronUp : Icon.icon.ChevronDown
    const color = isOpen ? Icon.color.Accent :  Icon.color.Accent

    return <Icon icon={icon} color={color} />
  }

  const renderOptions = () => {
    return (
      <Transition in={isOpen} timeout={250}>
        {(status) => (
          <SideMenuLinkPanel
            onClick={handleClickOption}
            options={options}
            pathname={pathname}
            status={status}
          />
        )}
      </Transition>
    )
  }

  return (
    <>
      <li className='side-menu-link'>
        <NavLink to={id} activeClassName={"active"} onClick={options && openDropdown}>
          <Icon icon={icon} color="grey1" />
          <span>{label}</span>
          <div className='side-menu-link-icon-dropdown'>
          {options && getArrowIcon()}
          </div>
        </NavLink>
      </li>
        {options && renderOptions()}
    </>
  )
}
export default SideMenuLink;
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";

import { getAllConsultants } from "../../../api/ConsultantApi";
import InputField from "../../../components/molecules/InputField/InputField";
import BigCombo from "../../../components/molecules/BigCombo/BigCombo";
// import PrimaryButton from "../../../components/atoms/PrimaryButton/PrimaryButton";
import CircleButton from "../../../components/atoms/CircleButton/CircleButton";
import { MemberGroupCard } from "../../../components/organisms/MemberGroupCard/MemberGroupCard";
import Icon from "../../../components/atoms/Icon/Icon";

import './GroupContainer.scss';
import { getAllClients } from "../../../api/ClientsApi";
import { getMissionsByClient } from "../../../api/MissionsApi";

import { useNavigation } from "../../../hooks/useNavigation";
export const GroupContainer = ({ user, group, members, deleteMember, showModalAddMember, handleInputChange, handleDropdownChange }) => {
    const [consultants, setConsultants] = useState([]);
    const [clients, setClients] = useState([]);
    const [missions, setMissions] = useState([]);
    const [idClientActive, setIdClientActive] = useState();
    const { pathname, redirect } = useNavigation()

    useEffect(() => {
        const { client_id } = group;
        mission_id && setIdClientActive(client_id);
    }, [group, mission_id])

    const handleImageOnChange = (image) => {
        const groupImage = image.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
        }
        reader.readAsDataURL(groupImage);
    }

    useEffect(() => {
        getAllConsultants(user.office_id).then((response) => {
            const all_consultants = response.consultants;
            const array = [];
            all_consultants.map(c => {
                const consultant = { label: `${c.user.first_name} ${c.user.last_name}`, value: c.user_id }
                array.push(consultant)
            })
            setConsultants(array)
        })

        getAllClients(user.id).then((response) => {
            const all_clients = response.clients.map(client => {
                return {
                    value: client.id, 
                    label: client.name
                }
            })
            const noClient = {label: "Aucun", value: null} 

            all_clients.push(noClient)

            setClients(all_clients)
        })
    }, [user.id, user.office_id])

    useEffect(() => {
        if(idClientActive) {
            getMissionsByClient(idClientActive).then((response) => {
                if(response.missions){

                    const all_missions = response?.missions.map(mission => {
                        return {
                            value: mission.id, 
                            label: mission.name
                        }
                    })
                    setMissions(all_missions)
                }
                else {
                    setMissions({value: null, label: "Aucune mission"})
                }
            })
        }
        else setMissions([])
    }, [idClientActive])



    const handleDropdownClientChange = (dropdown) => {
        const { value } = dropdown;
        handleDropdownChange(dropdown);
        setIdClientActive(value.value)
        
    }

    const handleViewTalent = (id) => {
        redirect(`/consultant/beneficiaries/${id}/profile`)
    }

    const renderAvatarGroup = (image) => {
        if (image) return <img src={image} alt="avatar-group" onClick={(image) => handleImageOnChange(image)} />

        return (
            <label>
                <Icon icon={'people'} />
                {/* <input type="file" onChange={(image) => handleImageOnChange(image)} /> */}
            </label>
        )
    }

    const { name, image, id, client_id, mission_id } = group;
    const defaultConsultant = consultants.filter(consultant => consultant.value === user.id);
    let defaultClient = null;
    let defaultMission = null;

    if(client_id) {
        defaultClient = clients.filter(client => client.value === group.client_id);
    }

    if(mission_id) {
        defaultMission = missions.filter(mission => mission.value === group.mission_id);
    }
    return (
        <div className="group-content">
            <div className="group-presentation">
                <div className="group-presentation-card">
                    <h2>Présentation du groupe</h2>
                    <div className="presentation-card-container">
                        {renderAvatarGroup(image)}
                        <InputField title={"Nom du groupe"} name={"name"} value={name ? name : ""} required onChange={handleInputChange} />
                    </div>
                    <BigCombo title={"Chef(fe) de projet"} id={"user_id"} options={defaultConsultant} value={defaultConsultant[0]} onChange={handleDropdownChange} />
                    <div className="presentation-card-container">
                        <BigCombo title={"Clients"} id={"client_id"} options={clients ? clients : []} value={defaultClient ? defaultClient[0] : null} onChange={handleDropdownClientChange} />
                    </div>
                    <div className="presentation-card-container">
                        <BigCombo title={"Missions"} id={"mission_id"} options={missions ? missions : []} value={defaultMission ? defaultMission[0] : null} onChange={handleDropdownChange} />
                    </div>
                </div>
            </div>
            {id &&
                <div className="group-members">
                    <div className="group-members-presentation">
                        {members.length > 0 ? <h2>Membres du groupe ({members.length})</h2> : <h2>Aucun membre ajouté</h2>}
                        <CircleButton icon={"plus"} onClick={showModalAddMember} />
                    </div>
                    <div className="all-members">
                        {members && members.map((member, index) => (
                            <MemberGroupCard member={member} deleteMember={deleteMember} key={index} onClick={() => handleViewTalent(member.id)}/>
                        ))}
                    </div>
                </div>
            }
        </div>
    )
}
import React from 'react'
import classNames from 'classnames'
import Icon from '../Icon/Icon'
import './CircleButton.scss';

const CircleButton = ({icon, color, backgroundColor, isActivated, id, disabled, onClick, ...rest}) => {
  return (
      <button
        className={classNames('circle-button', { disabled })}
        data-background-color={backgroundColor}
        onClick={isActivated ? onClick : undefined}
        {...rest}
      >
        <Icon icon={icon} color={color} />
      </button>
    )
}

CircleButton.defaultProps = {
  color: Icon.color.White,
  backgroundColor: Icon.color.Accent,
  isActivated: true,
  id: '',
  disabled: false,
  onClick: () => {},
}

export default CircleButton;
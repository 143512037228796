import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Step from '../../atoms/Step/Step'
import './DigitLineStep.scss';

class DigitLineStep extends Component {
  static propTypes = {
    steps: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
  }

  isComplete = (step) => {
    return step > this.props.current
  }

  renderSteps = () => {
    const { steps } = this.props
    const arrSteps = []
    for (let i = 0; i < steps; i++) {
      const key = (i + 1).toString()

      arrSteps.push(
        <div key={key} className="step-wrapper">
          <Step outline={this.isComplete(i + 1)} label={key} />
          {i + 1 !== steps && <div className="separator" />}
        </div>,
      )
    }
    return arrSteps
  }

  render() {
    return <div className="digit-line-step">{this.renderSteps()}</div>
  }
}

export default DigitLineStep

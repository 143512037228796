import { axiosInstance } from "./baseApi";

/* 
data : {
    user_id,
    room_name
} 
*/
export const generateTwilioToken = async (data) => {
    const response = await axiosInstance.post('visio/generate-token', data);
    return response.data;
}

export const createVisioRoom = async (room_name) => {
    const response = await axiosInstance.post('visio/create-room', {uniqueName: room_name});
    return response.data;
}

export const getUserCalendar = async (user_id) => {
    const response = await axiosInstance.get(`calendars/user/${user_id}`);
    return response.data;
}
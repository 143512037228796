import React, { Component } from 'react'
import PropTypes from 'prop-types'
import QuizStep from '../QuizStep/QuizStep'
import './QuizQuestion.scss'
class QuizQuestion extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    stepLabel: PropTypes.string,
    stepOutline: PropTypes.bool,
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    stepLabel: null,
  }

  render() {
    const { label, stepLabel, stepOutline } = this.props

    return (
      <div className="quiz-question">
        <QuizStep stepLabel={stepLabel} stepOutline={stepOutline} label={label} />
        <div className="content">{this.props.children}</div>
      </div>
    )
  }
}

export default QuizQuestion

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import uniqid from 'uniqid'

import {
  actions as ModuleTemplateActions,
  selectors as ModuleTemplateSelectors,
} from '../../../redux/ModuleTemplateRedux'
import { selectors as ResourceTemplateSelectors } from '../../../redux/ResourceTemplateRedux'
 

const mapStateToProps = (state) => ({
  template: ModuleTemplateSelectors.template(state),
  resources: ResourceTemplateSelectors.templates(state),
})

const mapDispatchToProps = (dispatch) => ({
  updateTemplate: (template) => dispatch(ModuleTemplateActions.updateTemplate(template)),
})

class ModuleTextEditDragDropContext extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  handleDragEnd = (result) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    return source.droppableId === destination.droppableId
      ? this.handleMove(result)
      : this.handleAdd(result)
  }

  handleMove({ source, destination }) {
    const { template } = this.props
    const resources = [...template.data.resources]
    const { index: from } = source
    const { index: to } = destination

    if (from === to) {
      return
    }

    resources.splice(to, 0, ...resources.splice(from, 1))
    this.setResources(resources)
  }

  handleAdd(result) {
    const { template, resources } = this.props
    const { destination, draggableId } = result
    const { index } = destination
    const { id } = JSON.parse(draggableId)
    const data = template.data || { resources: [] }
    const clone = (data.resources || []).slice(0)
    const resource = isNaN(id)
      ? { type: id, title: '' }
      : resources.find((resource) => id === resource.id.toString())

    clone.splice(index, 0, {
      ...resource,
      id: undefined,
      _id: uniqid(),
    })

    this.setResources(clone)
  }

  setResources(resources) {
    const { template, updateTemplate } = this.props
    const { data } = template

    updateTemplate({
      data: { ...data, resources },
    })
  }

  render() {
    return <DragDropContext onDragEnd={this.handleDragEnd}>{this.props.children}</DragDropContext>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleTextEditDragDropContext)
import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_MY_TALENTS_EVOLUTION,
    headers: {
    //   'Authorization': 'Bearer 457f4be3-878c-494f-b109-0a428bfd65d2',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });


export const getTemplatesCourses = async (user_id) => {
    const response = await axiosInstance.get(`/template_owners/${user_id}/courses/all`);
    return response.data;
}

export const getTemplatesOfficeCourses = async (office_id) => {
  const response = await axiosInstance.get(`/template_owners/office/${office_id}/courses/all`);
  return response.data;
}

export const getTemplatesMyTalentsCourses = async () => {
  const response = await axiosInstance.get(`/template_owners/mytalents/courses/all`);
  return response.data;
}

export const getAllPublishModules = async(user_id) => {
  const response = await axiosInstance.get(`/template_owners/${user_id}/modules/all`);
  return response.data;
}

export const getAllPublishOfficeModules = async(user_id) => {
  const response = await axiosInstance.get(`/template_owners/office/${user_id}/modules/all`);
  return response.data;
} 

export const getAllPublishMyTalentsModules = async() => {
  const response = await axiosInstance.get(`/template_owners/mytalents/modules/all`);
  return response.data;
}

export const getAllPublishModulesContainer = async(user_id) => {
  const response = await axiosInstance.get(`/template_owners/${user_id}/modules_containers/all`);
  return response.data;
}

export const getAllPublishOfficeModulesContainer = async(user_id) => {
  const response = await axiosInstance.get(`/template_owners/${user_id}/modules_containers/all`);
  return response.data;
}

export const getAllPublishMyTalentsModulesContainer = async() => {
  const response = await axiosInstance.get(`/template_owners/mytalents/modules_containers/all`);
  return response.data;
}

export const getRessourcesType = async () => {
  const response = await axiosInstance.get('/modules/types/all');
  return response.data;
}

export const getFormsType = async () => {
const response = await axiosInstance.get('/modules/forms/types');
return response.data;
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DigitLineStep from '../../molecules/DigitLineStep/DigitLineStep'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import InputField from '../../molecules/InputField/InputField'
import ComboField from '../../molecules/ComboField/ComboField'
import DateField from '../../molecules/DateField/DateField'
import './WelcomePopin.scss'
const inputTypes = {
  phone: 'phone',
  situation: 'situation',
  birthday: 'birthday',
  address: 'address',
  education: 'education',
  about: 'about',
}

export default class WelcomePopin extends Component {
  static propTypes = {
    steps: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
    mainTitle: PropTypes.string.isRequired,
    name: PropTypes.string,
    content: PropTypes.string.isRequired,
    inputType: PropTypes.oneOf(Object.values(inputTypes)),
    prevId: PropTypes.string,
    prev: PropTypes.string,
    nextId: PropTypes.string,
    next: PropTypes.string,
    inputContent: PropTypes.shape({
      inputTitle: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      inputName: PropTypes.string.isRequired,
      inputValue: PropTypes.string.isRequired,
    }),
    comboContent: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
          }),
        ),
        value: PropTypes.string,
      }),
    ),
    aboutContent: PropTypes.arrayOf(
      PropTypes.shape({
        inputContent: PropTypes.shape({
          inputTitle: PropTypes.string.isRequired,
          placeholder: PropTypes.string.isRequired,
          inputName: PropTypes.string.isRequired,
          inputValue: PropTypes.string.isRequired,
        }),
        comboContent: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            options: PropTypes.arrayOf(
              PropTypes.shape({
                value: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
              }),
            ),
            value: PropTypes.string,
          }),
        ),
      }),
    ),
    onChange: PropTypes.func,
    onPrev: PropTypes.func,
    onNext: PropTypes.func,
    datePicker: PropTypes.shape({
      title: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.string,
      locale: PropTypes.string,
      dayShortLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
      monthLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    addressInputs: PropTypes.arrayOf(
      PropTypes.shape({
        inputTitle: PropTypes.string.isRequired,
        placeholder: PropTypes.string.isRequired,
        inputName: PropTypes.string.isRequired,
      }),
    ),
    disabledNextButton: PropTypes.bool,
  }

  static defaultProps = {
    disabledNextStep: false,
  }

  handlePrev = () => {
    const { onPrev } = this.props
    if (onPrev) {
      onPrev()
    }
  }

  handleNext = () => {
    const { onNext } = this.props
    if (onNext) {
      onNext()
    }
  }

  handleChange = (value) => {
    const { onChange } = this.props

    if (onChange) {
      onChange(value)
    }
  }

  renderInputType = () => {
    switch (this.props.inputType) {
      case WelcomePopin.inputTypes.phone:
        return this.renderInput()
      case WelcomePopin.inputTypes.situation:
        return this.renderCombo()
      case WelcomePopin.inputTypes.birthday:
        return this.renderBirthday()
      case WelcomePopin.inputTypes.address:
        return this.renderAddress()
      case WelcomePopin.inputTypes.education:
        return this.renderCombo()
      case WelcomePopin.inputTypes.about:
        return this.renderAbout()
      default:
    }
  }

  renderInput = () => {
    const { inputTitle, placeholder, inputName, inputValue } = this.props.inputContent
    return (
      <InputField
        title={inputTitle}
        placeholder={placeholder}
        name={inputName}
        onChange={this.handleChange}
        value={inputValue}
      />
    )
  }

  renderBirthday = () => {
    const {
      title,
      placeholder,
      name,
      value,
      dayShortLabels,
      monthLabels,
      locale,
    } = this.props.datePicker
    return (
      <DateField
        title={title}
        placeholder={placeholder}
        name={name}
        value={value}
        calendarLabels={{ dayShortLabels, monthLabels }}
        onChange={this.handleChange}
        locale={locale}
      />
    )
  }

  renderAddress = () => (
    <div className="address">
      {this.props.addressInputs.map((input, i) => (
        <InputField
          key={i}
          title={input.inputTitle}
          placeholder={input.placeholder}
          name={input.inputName}
          onChange={this.handleChange}
          value={input.inputValue}
        />
      ))}
    </div>
  )

  renderCombo = () => {
    return this.props.comboContent.map((input, i) => (
      <ComboField
        key={i}
        value={input.value}
        title={input.title}
        placeholder={input.placeholder}
        name={input.id}
        options={input.options}
        onChange={this.handleChange}
      />
    ))
  }

  renderAbout = () => {
    return this.props.aboutContent.map((inputDesc, key) => {
      if (inputDesc.inputContent) {
        return (
          <InputField
            key={key}
            title={inputDesc.inputContent.inputTitle}
            placeholder={inputDesc.inputContent.placeholder}
            name={inputDesc.inputContent.inputName}
            onChange={this.handleChange}
            value={inputDesc.inputContent.inputValue}
          />
        )
      }
      if (inputDesc.comboContent) {
        return inputDesc.comboContent.map((input, i) => {
          return (
            <ComboField
              key={key}
              value={input.value}
              title={input.title}
              placeholder={input.placeholder}
              name={input.id}
              options={input.options}
              onChange={this.handleChange}
            />
          )
        })
      }
      return null
    })
  }

  render() {
    const {
      mainTitle,
      steps,
      current,
      name,
      content,
      prevId,
      nextId,
      prev,
      next,
      disabledNextButton,
    } = this.props

    return (
      <div className="welcome-popin">
        <DigitLineStep steps={steps} current={current} />
        <p className="title">
          {mainTitle}
          {name}
        </p>
        <p className="content">{content}</p>
        {this.renderInputType()}
        <div className="button-wrapper">
          {prev ? (
            <PrimaryButton id={prevId} outline label={prev} onClick={this.handlePrev} />
          ) : null}
          {next ? (
            <PrimaryButton
              id={nextId}
              label={next}
              onClick={this.handleNext}
              disabled={disabledNextButton}
            />
          ) : null}
        </div>
      </div>
    )
  }
}

WelcomePopin.inputTypes = inputTypes

import React, { Component } from 'react'
import { connect } from 'react-redux'

import Page from '../../containers/global/Page/Page'

import {
  actions as BeneficiaryActions,
  selectors as BeneficiarySelectors,
} from '../../redux/BeneficiaryRedux'
import { actions as BeneficiaryGroupActions } from '../../redux/BeneficiaryGroupRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import ConsultantBeneficiaryMaestrosContainer from '../../containers/consultant/ConsultantBeneficiaryMaestrosContainer/ConsultantBeneficiaryMaestrosContainer'
import "./ConsultantBeneficiaryMaestrosPage.scss";

const mapStateToProps = (state, props) => ({
  id: props.match.params.user_id,
  pathname: NavigationSelectors.pathname(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
  maestros: BeneficiarySelectors.maestros(state),
})

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  getBeneficiary: (id) => dispatch(BeneficiaryActions.getBeneficiary(id)),
  getCurrentUserGroups: () => dispatch(BeneficiaryGroupActions.getCurrentUserGroups()),

  resetGroups: () => dispatch(BeneficiaryGroupActions.resetGroups()),
})


class ConsultantBeneficiaryMaestrosPage extends Component {
  componentDidMount() {
    if (!/\/(maestros)$/.test(this.props.pathname)) {
      this.props.redirect(`${this.props.pathname}/courses`)
    }

    this.props.getBeneficiary(this.props.id)
  }

  render() {
    const { maestros, beneficiary } = this.props

    if (beneficiary === null || !maestros) {
      return null
    }

    return <ConsultantBeneficiaryMaestrosContainer maestros={maestros} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(ConsultantBeneficiaryMaestrosPage))
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Components
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
// import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle'
// import Icon from '../../atoms/Icon/Icon'
import './SubscribeCard.scss'
// propTypes
const propTypes = {
  labelTitle: PropTypes.string.isRequired,
  labelCount: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  labelButton: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
}

class SubscribeCard extends Component {
  onClick = (e, id) => {
    this.props.onButtonClick(e, id)
  }

  render() {
    const { labelTitle, labelCount, count, labelButton } = this.props
    return (
      <div className="subscribe-card">
        <h2>{labelTitle}</h2>
        <div className="subscribe-card-content">
          <div className='subscribe-total'>
            <p className="label">{labelCount} : </p>
            <p className="count">{count}</p>
          </div>
          {labelButton && (
            <PrimaryButton label={labelButton} id="contact-mytalent" onClick={this.onClick} outline />
          )}
        </div>
      </div>
    )
  }
}

SubscribeCard.protoTypes = propTypes

export default SubscribeCard
/* eslint-disable no-unused-vars */
import React from 'react';
// import { CircularProgressbar } from 'react-circular-progressbar'
import InfoField from '../../molecules/InfoField/InfoField';
// import IconButton from '../../atoms/IconButton/IconButton'
import AvatarCircle from '../../atoms/AvatarCircle/AvatarCircle';
// import GradientSVG from './GradientSVG'
// import Icon from '../../atoms/Icon/Icon'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton';
import { actions as VideoCallActions } from '../../../redux/VideocallRedux';
import { connect } from 'react-redux';
import { selectors as ConsultantSelectors } from '../../../redux/ConsultantRedux';
import { ParcoursProgressBar } from '../../molecules/ParcoursProgressBar/ParcoursProgressBar';

import './CandidatCard.scss';

const mapDispatchToProps = (dispatch) => ({
  createInvite: (payload) => dispatch(VideoCallActions.createInvite(payload)),
});

const mapStateToProps = (state) => ({
  consultants: ConsultantSelectors.consultants(state),
});

const CandidatCard = ({
  id,
  accompaniment_type,
  programFollowed,
  percentage,
  reason,
  sharedConsultants,
  financer_state,
  color,
  isDeletable,
  isManager,
  onDeleteClick,
  onMessagesClick,
  onClick,
  onAttributeClick,
  attributeLabelButton,
  attributeReadonly,
  locale,
  primaryConsultant,
  consultants,
  avatar,
  name,
  email,
  phone,
  primaryConsultantName,
  primaryConsultantDeleted,
  noPrimaryConsultantLabel,
  primaryConsultantLabel,
  status,
}) => {

  const statusLabel = {
    created: 'created',
    wait_financer: 'wait_financer',
    in_validation: 'in_validation',
    in_progress: 'in_progress',
    follow_up: 'follow_up',
    archived: 'archived',
  };

  const renderInProgress = () => {
    const {
      lengthLabel,
      typeOfAccompanimentLabel,
      programFollowedLabel,
      nextAppointmentLabel,
      groupLabel,
    } = locale;
    return (
      <div className="content-card in-progress">
        {/* <InfoField lightLabel={lengthLabel} boldLabel={length} /> */}
        <InfoField lightLabel={typeOfAccompanimentLabel} boldLabel={accompaniment_type} />
        <div className="course-progress">
          <InfoField lightLabel={programFollowedLabel} boldLabel={programFollowed} />
          <div className="circular-prog-wrap">
            <ParcoursProgressBar pourcent={percentage !== undefined ? percentage : 0} />
          </div>
        </div>
        {/* <InfoField lightLabel={nextAppointmentLabel} boldLabel={nextAppointment ? moment(nextAppointment).format('DD/MM/YYYY HH:mm') : '-'}/>
        <InfoField lightLabel={groupLabel} boldLabel={group ? group : '-'}/> */}
      </div>
    );
  };

  const renderArchive = () => {
    const { reasonLabel } = locale;
    return (
      <div className="content-card">
        <InfoField lightLabel={reasonLabel} boldLabel={reason} />
      </div>
    );
  };

  const renderSharedConsultants = () => {
    if (!sharedConsultants) return;

    return sharedConsultants.map((consultant, i) => (
      <div key={i} className="avatar-wrapper" style={{ zIndex: i }}>
        <AvatarCircle photoUrl={consultant.avatar} />
      </div>
    ));
  };

  const renderWaiting = () => {
    return (
      <div className="content-card">
        <InfoField color={color} lightLabel={locale.financeStateLabel} boldLabel={financer_state} />
      </div>
    );
  };

  const renderStatusRelatedInformation = () => {
    switch (status.status) {
      case statusLabel.wait_financer:
      case statusLabel.in_validation:
        return renderWaiting();
      case statusLabel.in_progress:
      case statusLabel.follow_up:
        return renderInProgress();
      case statusLabel.archived:
        return renderArchive();
      default:
    }
  };
  const handleMailClick = () => {
    onMessagesClick({ id: id });
  };

  const handleBeneficiaryClick = () => {
    onClick(id);
  };

  const handleDeleteClick = () => {
    onDeleteClick({ id: id });
  };

  const hasEnoughConsultants = !primaryConsultant
    ? consultants.length > 0
    : consultants.filter((consultant) => primaryConsultant?.id !== consultant.id).length > 0;

  return (
    <div className="candidat-card">
      <div className="header">
        <p className="status">{status.label}</p>
        <div className="tools">
          <div className="consultants">{renderSharedConsultants()}</div>
          {isManager && attributeReadonly && hasEnoughConsultants && (
            <PrimaryButton label={attributeLabelButton} id={id} onClick={onAttributeClick} />
          )}
        </div>
      </div>

      <div className="candidat-infos" onClick={handleBeneficiaryClick}>
        <AvatarCircle photoUrl={avatar} />
        <div className="candidat-infos-name">
          <p className="name">{name}</p>
          <p className="contact">{email}</p>
          <p className="contact">{phone}</p>
        </div>
      </div>
      {isDeletable && (
        <div className="action-buttons">
          <p onClick={handleDeleteClick}>Supprimer</p>
        </div>
      )}

      {renderStatusRelatedInformation()}
      {isManager && primaryConsultantName && (
        <div className="candidat-infos">
          {primaryConsultantDeleted && (
            <div className="candidat-orphan">{noPrimaryConsultantLabel}</div>
          )}
          <InfoField lightLabel={primaryConsultantLabel} boldLabel={primaryConsultantName} />
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidatCard);

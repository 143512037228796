import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { actions as NavigationActions } from '../../redux/NavigationRedux'
import PortalForgotPasswordPopin from '../../components/organisms/PortalForgotPasswordPopin/PortalForgotPasswordPopin'
import { actions as UserActions } from '../../redux/UserRedux'
import { selectors as WSSelectors } from '../../redux/WSRedux'
import { usePortalCustomization } from '../../hooks/usePortalCustomization'
import { useParams } from 'react-router'
import './PortalForgotPasswordPage.scss';

const PortalForgotPasswordPage = () => {
  const dispatch = useDispatch()
  const [done, setDone] = useState(false)
  const pending = useSelector((state) => WSSelectors.pending(state, 'auth.forgotPassword'))
  const { office } = useParams()
  const { slug } = usePortalCustomization({ slug: office })

  const onClose = () => {
    dispatch(NavigationActions.push(`/${slug}/auth/login`))
  }

  const onSubmit = ({ email }) => {
    dispatch(UserActions.forgotPassword(email))

    setDone(true)
  }

  return (
    <div className="portal-forgot-password-page">
      <PortalForgotPasswordPopin
        onClose={onClose}
        onSubmit={onSubmit}
        done={done}
        disabled={pending}
      />
    </div>
  )
}

export default PortalForgotPasswordPage

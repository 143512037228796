import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ComboField from '../ComboField/ComboField'
import QuizStep from '../QuizStep/QuizStep'
import './QuizCombo.scss'
class QuizCombo extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    stepLabel: PropTypes.string,
    stepOutline: PropTypes.bool,
    comboFieldProps: PropTypes.shape({}).isRequired,
  }

  static defaultProps = {
    stepOutline: true,
    stepLabel: null,
  }

  render() {
    const { stepLabel, stepOutline, label, comboFieldProps } = this.props
    return (
      <div className="quiz-combo">
        <QuizStep stepLabel={stepLabel} stepOutline={stepOutline} label={label} />
        <ComboField {...comboFieldProps} />
        <p className="quizz-combo-printable">{comboFieldProps.value}</p>
      </div>
    )
  }
}

export default QuizCombo

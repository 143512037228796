/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Icon from "../../atoms/Icon/Icon";
import { updateModule } from "../../../api/CourseApi";
import { selectors as UserSelectors } from '../../../redux/UserRedux';
import { useSelector } from 'react-redux'
import IframeChecker from "../IframeChecker/IframeChecker";




// import DocViewer from "react-doc-viewer";
import './ParcoursDocument.scss';



export const ParcoursDocument = ({ document }) => {
    const u = useSelector(UserSelectors.user);

    const [link, setLink] = useState();
    const [video, setVideo] = useState();
    const [file, setFile] = useState();
   const [isDownloadLink, setIsDownloadLink] = useState(false)

   function normalizeURL(url) {
    if (!/^https?:\/\//i.test(url)) {
      return 'http://' + url;
    }
    return url;
  }
    useEffect(() => {
        if (u.role === 'beneficiary')
            document.done = 3;
        updateModule(document);

        switch (document.type) {
            case 'link':
                setLink(document);
                setVideo(null);
                setFile(null);
                break;
            case 'video':
                setVideo(document);
                setLink(null);
                setFile(null);
                break;
            case 'file':
                setFile(document);
                setLink(null);
                setVideo(null);
                break;
            default:
                setLink(null);
                setVideo(null);
                setFile(null);
                break;
        }

    }, [document])


    const renderLink = () => {
        if (u.role === 'beneficiary')
            document.done = 3;
        updateModule(document);
        return (
            <div className={`ressource-document ${link.type}`}>
                <div className="ressource-document-header">
                    <h1>Lien intéressant</h1>
                </div>
                {document.description && <p>{document.description}</p>}
                {/* <span className="reading-time">Temps de lecture : 10 minutes</span> */}
                <div className="ressource-document-container link">
                    <Icon icon="document-link" />
                    <a href={normalizeURL(link.url)} target={"_blank"}> {link.title}
                    </a>
                </div>
                <IframeChecker url={link.url} />
                {/* <iframe height="500" src={link.url} title={link.title}> </iframe> */}
            </div>
        )
    }
    function convertToEmbedUrl(url) {
        if (u.role === 'beneficiary')
            document.done = 3;
        updateModule(document);
        if (url.includes("youtube.com/watch?v=") ) {
            var videoCode = url.split("watch?v=")[1].split("&")[0];
            var embedUrl = "https://www.youtube.com/embed/" + videoCode;

            if (url.includes("&")) {
                var otherParams = url.split("&").slice(1).join("&");
                embedUrl += "&" + otherParams;
            }
            return embedUrl;
        } else if (url.includes("youtu.be")) {
            var videoCode = url.split("youtu.be/")[1];
            var embedUrl = "https://www.youtube.com/embed/" + videoCode;
            return embedUrl
        } else {
            return url;
        }
    }

    const renderVideo = () => {
        if (u.role === 'beneficiary')
            document.done = 3;
        updateModule(document);
        return (
            <div className={`ressource-document ${video.type}`}>
                <div className="ressource-document-header">
                    <h1>{video.title}</h1>
                </div>
                {document.description && <p>{document.description}</p>}

                <div className="ressource-document-container video">
                <p className="description">
                        {video.description}
                    </p>
                    <iframe className="video" src={convertToEmbedUrl(video.url)} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    
                </div>
            </div>
        )
    }

    const handleDownload = async () => {
        if (u.role === 'beneficiary')
            document.done = 3;
        updateModule(document);
        setIsDownloadLink(true)
    }

    const renderFile = () => {
        if (u.role === 'beneficiary')
            document.done = 3;
        updateModule(document);
        const docs = [
            {uri: file.url}
        ]
        return (
            <div className={`ressource-document ${file.type}`}>

                <div className="ressource-document-header">
                    <h1>{file.title}</h1>
                </div>
                {document.description && <p>{document.description}</p>}
                <div className="ressource-document-to-download" onClick={handleDownload}>
                    <p>Document à télécharger :</p>
                    <a href={file.url} download target="_blank">
                    {file.title}
                    </a>

                </div>
                <IframeChecker url={file.url} />
                {/* <DocViewer documents={docs} /> */}
            </div>
        )
    }

    return (
        <>
            {file && renderFile()}
            {video && renderVideo()}
            {link && renderLink()}
        </>
    )
}
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import './ParcoursTimelineSteps.scss';

export const ParcoursTimelineSteps = ({ questions, sendIndexActif, questionActive, id }) => {
    const [allQuestions, setAllQuestions] = useState()
    const [question, setQuestion] = useState('');
    const buttonsRef = useRef();


    const getTitleQuestion = (index, id) => {
        setQuestion(allQuestions[index].title);
        let buttons = Array.from(buttonsRef.current.children)

        buttons.forEach(element => {
            element.classList.remove('hover')
            if (element.id === id) {
                element.classList.add('hover')
            }
        });
    }

    const clearQuestion = () => {
        setQuestion('');
        let buttons = Array.from(buttonsRef.current.children);
        buttons.forEach(element => {
            element.classList.remove('hover')
        });
    }

    const activateButton = (id) => {
        let buttons = Array.from(buttonsRef.current.children);
        buttons.forEach(element => {
            if (parseInt(element.id) === id) {
                element.classList.add('active')
            }
           
        });
    }

    const disabledButton = (id) => {
        let buttons = Array.from(buttonsRef.current.children);
        buttons.forEach(element => {
            if (parseInt(element.id) === id) {
                element.classList.remove('active')
            }
        });
    }

    const getActiveQuestion = (index, id) => {
        allQuestions.forEach(element => {
            if(element.answer?.length === 0) {
                return null
            }
            if(element.id === id) {
                sendIndexActif(index);
                activateButton(id);
                setQuestion('');
            }
        });
    }

    const renderTitleTooltip = (title) => {
        const index = title.indexOf('?');
        return <>{index >= 0 ? <span>{title.slice(0, index + 1)}</span> : <span>{title}</span>}</>
    }

    
    useEffect(() => {
        if(allQuestions && questionActive) {
            allQuestions.forEach(element => {
                if(element.answer) {

                    if(element.answer.length > 0 && questionActive.id !== element.id) {
                        activateButton(element.id)
                    }
                    else {
                        disabledButton(element.id)
                    } 
                }
            });
        }
    }, [allQuestions, questionActive])


    useEffect(() => {
       setAllQuestions(questions)
    }, [questions])


    useEffect(() => {
        setAllQuestions([])
    }, [id])

    return (
        <div className='parcours-timeline-steps'>
            <div className='timeline' ref={buttonsRef}>
                {allQuestions?.map((question, index) => (
                    <button
                        id={question.id}
                        key={index}
                        onMouseEnter={() => getTitleQuestion(index, question.id)}
                        onMouseLeave={clearQuestion}
                        onClick={() => getActiveQuestion(index, question.id)}
                        className={(questionActive && questionActive.id === question.id) ? 'current-question-active' : ''}
                    >
                        <div className='tooltip'>
                            {renderTitleTooltip(question.title)}
                        </div>
                        {questionActive && questionActive.id === question.id &&
                            <div className='dot'></div>
                        }
                    </button>
                ))}
            </div>
        </div>
    )
}
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { getConsultantInfos } from '../../../api/ConsultantApi';
import {
  updateStatusGuestAppointment,
} from '../../../api/AppointmentsApi';

import AvatarCircle from '../../atoms/AvatarCircle/AvatarCircle';
import CheckedIcon from './AgendaTypeIllu/icon/check.svg';
import CircleButton from '../../atoms/CircleButton/CircleButton';
import { ReportAppointmentPopin } from '../../../containers/popins/ReportAppointmentPopin/ReportAppointmentPopin';

import CloseIcon from './AgendaTypeIllu/icon/close.svg';
import CollectiveIllustration from './AgendaTypeIllu/collective.svg';
import IndividualIllustration from './AgendaTypeIllu/individual.svg';
import IndividualVisioIllustration from './AgendaTypeIllu/individual-visio.svg';

import moment from 'moment';
import 'moment/locale/fr';
import './AgendaRow.scss';
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton';
import { createVisioRoom } from '../../../api/TwilioApi';
import EditAppointmentPopin from '../../../containers/popins/EditAppointmentPopin/EditAppointmentPopin';
import { NewTag } from '../../atoms/NewTag/NewTag';

export const AgendaRow = ({
  user,
  type,
  appointment,
  isBeneficiary,
  setActionsConfirmationAppointment,
  isSelected,
  selectable,
  onSelect,
  setAppointmentSelected,
  setDeclineAppointment,
  setToaster,
  setReportAppointment,
}) => {
  const [isPassedDateAppointment, setIsPassedDateAppointement] = useState(false);
  const [isOpenModalReportAppointment, setIsOpenModalReportAppointment] = useState(false);
  const [isConsultantAppointmentOwner, setIsConsultantAppointmentOwner] = useState();
  const [color, setColor] = useState('');
  const [consultant, setConsultant] = useState({ id: '', first_name: '', last_name: '' });
  const today = moment().format('YYYY-MM-DD');
  const [userStatus, setUserStatus] = useState();
  const [showEditAppointmentModal, setShowEditAppointmentModal] = useState(false);

  const handleSelectAppointment = () => {
    if (selectable) {
      onSelect(appointment.id);
      setAppointmentSelected(appointment);
      const e = document.getElementsByClassName('calendar-page-right-side')[0];
      if (!e.classList.contains('agenda-fixed-view')) {
        e.classList.add('agenda-fixed-view');
    }
    }
  };

  const handleGenerateAndJoinVisioRoom = (e) => {
    e.stopPropagation();
    if (appointment.videocall_type === 'twilio') {
      let new_tab = window.open('', '_blank');
      createVisioRoom(appointment.id).then((res) => {
        const join_url = process.env.REACT_APP_VISIO_FRONT_URL + appointment.id;
        new_tab.location.href = join_url;
      });
    } else {
      window.open(appointment.videocall_url, '_blank');
    }
  };

  const handleActionsConfirmationOnClick = (status) => {
    if (status === 'ok') {
      setActionsConfirmationAppointment({ appointment: appointment, status: status });
      setUserStatus('ok');
    } else if (status === 'ko') {
      setDeclineAppointment({ appointment: appointment, status: status });
    }
  };

  const handleReportAppointment = (comment) => {
    const updateStatusUser = { status: 'rescheduled', comment: comment };
    updateStatusGuestAppointment(appointment.id, user.id, updateStatusUser)
      .then((response) => {
        setReportAppointment(response.appointment);
        setToaster({
          title: 'Demande de report du rendez - vous',
          message: 'La demande du report de rendez - vous a été réalisé avec succès.',
          type: 'success',
        });
      })
      .catch(() => {
        setToaster({
          title: 'Demande de report du rendez - vous',
          message: 'Une erreur est survenue lors de la demande du report de rendez - vous.',
          type: 'error',
        });
      });
    setIsOpenModalReportAppointment(false);
  };

  const findUserInAppointment = () => {
    const foundUserInIndividuals = appointment.users_to.individuals.find(
      (participant) => participant.user_id === user.id,
    );
    if (foundUserInIndividuals) {
      return foundUserInIndividuals.status;
    }

    for (const group of appointment.users_to.groups) {
      const foundUserInGroup = group.group_users.find(
        (participant) => participant.user_id === user.id,
      );
      if (foundUserInGroup) {
        return foundUserInGroup.status;
      }
    }
    return null;
  };

  useEffect(() => {
    getConsultantInfos(appointment.user_from).then((response) => {
      const consultant = response.consultant.user;
      setConsultant({
        id: consultant.id,
        first_name: consultant.first_name,
        last_name: consultant.last_name,
      });
    });
  }, []);

  useEffect(() => {
    if (appointment && appointment.rdv_at) {
      const dateAppointment = moment(appointment.rdv_at, 'YYYY-MM-DD').format('YYYY-MM-DD');
      setIsPassedDateAppointement(moment(dateAppointment).isBefore(today));
    }
  }, [appointment]);

  useEffect(() => {
    if (isPassedDateAppointment) {
      setColor('grey');
    } else {
      if (type === 'Individual') {
        setColor('orange');
      } else {
        setColor('green');
      }
    }
  }, [isPassedDateAppointment, type]);

  useEffect(() => {
    const isOwner = appointment.user_from === user.id;
    setIsConsultantAppointmentOwner(isOwner);
  }, [consultant, user]);

  useEffect(() => {
    setUserStatus(findUserInAppointment);
  }, [appointment, user]);

  const renderIllustration = () => {
    const { is_videocall } = appointment;
    return (
      <div className="agenda-row-illustration">
        {type === 'Individual' ? (
          is_videocall === 1 ? (
            <IndividualVisioIllustration />
          ) : (
            <IndividualIllustration />
          )
        ) : (
          <CollectiveIllustration />
        )}
      </div>
    );
  };

  const renderInfos = () => {
    const { title, rdv_at, rdv_to } = appointment;
    const start = moment(rdv_at).format('HH:mm');
    const end = moment(rdv_to).format('HH:mm');

    return (
      <div className="agenda-row-title">
        <h1>{title}</h1>
        <div className="agenda-row-infos">
          <p style={{ color: `var(--${color})` }}>
            {start} - {end}{' '}
          </p>
          {type === 'Collective' && (
            <span>
              | Animé par {consultant.first_name} {consultant.last_name}
            </span>
          )}
        </div>
      </div>
    );
  };

  const renderParticipantsGroup = () => {
    const { nb_seats } = appointment;
    const maxVisibleProfiles = 4;
    const totalIndividuals = appointment?.users_to.individuals?.length || 0;
    const totalGroupUsers =
      appointment?.users_to.groups?.reduce(
        (sum, group) => sum + (group?.group_users?.length || 0),
        0,
      ) || 0;
    const totalParticipants = totalIndividuals + totalGroupUsers;
    const remainingParticipants = Math.max(0, totalParticipants - maxVisibleProfiles);

    const allParticipants = appointment?.users_to.groups
      ?.flatMap((group) => group?.group_users || [])
      .concat(appointment?.users_to.individuals || [])
      .slice(0, maxVisibleProfiles);

    return (
      <div className="agenda-row-participants">
        <h6>
          {totalParticipants}/{nb_seats}
        </h6>
        <div className="agenda-row-talents">
          {allParticipants?.map((user, index) =>
            user.image_url ? (
              <img
                key={index}
                className="talent-profile-picture"
                alt="talent-profile"
                src={`${process.env.REACT_APP_ASSETS_API_STATIC_URL}${user.image_url.replace(
                  process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL,
                  '',
                )}`}
              />
            ) : (
              <AvatarCircle photoUrl={null} />
            ),
          )}

          {remainingParticipants > 0 && (
            <div className="talent-more-participants">
              <span>+{remainingParticipants}</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderParticipantIndividual = () => {
    const user = appointment.users_to.individuals[0];
    let consultantImg;
    if (user) {
      if (isConsultantAppointmentOwner === 'Consultant') {
        getConsultantInfos(consultant.id).then((response) => {
          consultantImg = response.consultant.user.image_url;
        })
      }
      return (
        <div className="agenda-row-participants">
          {isBeneficiary ? (
            <h6>Consultant</h6>
          ) : (
            <h6>{isConsultantAppointmentOwner ? 'Participant' : 'Consultant'}</h6>
          )}

          <div className="agenda-row-talent">
            <AvatarCircle photoUrl={isConsultantAppointmentOwner ?user.image_url : consultantImg} />
            <p>
              {isConsultantAppointmentOwner
                ? `${user.first_name} ${user.last_name}`
                : `${consultant.first_name} ${consultant.last_name}`}
            </p>
          </div>
        </div>
      );
    }
  };

  const renderActions = () => {
    const { is_videocall, is_tallysheet, rdv_at, status } = appointment;
    const isTallysheet = is_tallysheet === 1;
    const date = moment(rdv_at).format('YYYY/MM/DD');
    const isToday = moment(date).isSame(today, 'day');

    return (
      <div className="agenda-row-actions">
        {(isConsultantAppointmentOwner || userStatus === 'ok') && is_videocall === 1 && (
          <>
            {isTallysheet && (
              <div className="appointment-tooltip">
                <CircleButton icon="calendar" disabled={!isToday} />
                {isToday && <span className="appointment-tooltiptext">Envoyer la feuille d'émargement</span>}
              </div>
            )}
            <div className="appointment-tooltip" onClick={(e) => handleGenerateAndJoinVisioRoom(e)}>
              <CircleButton icon="visio" />
              <span className="appointment-tooltiptext">Rejoindre la visio</span>
            </div>
            {(status === 'rescheduled' && (
              <NewTag
              title={'Demande de report'}
              color={'orange'}
            />
            ))}
          </>
        )}
        {!isConsultantAppointmentOwner && userStatus === 'pending' && (
          <div className="actions-confirmation">
            <div className='actions-confirmation-buttons'>
              <button
                className="cancel"
                id="cancel"
                onClick={() => handleActionsConfirmationOnClick('ko')}
              >
                <CloseIcon />
              </button>
              <button
                className="confirmation"
                id="confirmation"
                onClick={() => handleActionsConfirmationOnClick('ok')}
              >
                <CheckedIcon />
              </button>
            </div>
            {(type === 'Individual' && (
              <PrimaryButton
                id={'report'}
                label={'Demande de report'}
                outline
                onClick={() => setIsOpenModalReportAppointment(true)}
              />
            ))}
            
          </div>
        )}
      </div>
    );
  };


  const { rdv_at, id } = appointment;
  return (
    <div
      className={`agenda-row  `}
      id={id}
      onClick={handleSelectAppointment}
      style={
        isSelected
          ? { backgroundColor: `var(--${color}-light)`, border: `1px solid var(--${color})` }
          : {}
      }
    >
      <div className="agenda-row-border" style={{ backgroundColor: `var(--${color})` }}></div>
      {renderIllustration()}
      {renderInfos()}
      <div className="agenda-row-right">
        {type === 'Individual' ? renderParticipantIndividual() : renderParticipantsGroup()}
        {renderActions()}
      </div>
      {/* {isPassedDateAppointment && <div className="is-passed-appointment"></div>} */}
      {isOpenModalReportAppointment && (
        <ReportAppointmentPopin
          open={isOpenModalReportAppointment}
          onClose={() => setIsOpenModalReportAppointment(false)}
          handleReportAppointment={handleReportAppointment}
        />
      )}
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { Tag } from '../../atoms/Tag/Tag';
import './ParcoursAllStep.scss';

export const ParcoursAllSteps = ({ steps, stepActive, step }) => {
  let allSteps = document.querySelectorAll('.step-card');
  const [defaultBackgroundColor] = useState([
    'main-color',
    'main-color-dark',
    'main-color-light',
    'main-color-lighter',
    'blue-dark',
  ]);
  // const stepActiveRef = useRef(null);

  const handleStepClick = (step, event, state) => {
    event.preventDefault();
    if (state !== 0) {
    stepActive(step);
    allSteps.forEach((element) => {
      if (element.id === step.id.toString()) {
        element.classList.add('step-active');
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        element.classList.remove('step-active');
        window.scrollTo(0, 0);
      }
    });
  }
  }; 

  useEffect(() => {
    if (step && step.id) {
      stepActive(step);
      let allSteps = document.querySelectorAll('.step-card');
      allSteps.forEach((element) => {
        if (element.id === step.id.toString()) {
          element.classList.add('step-active');
          element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      });
    }
  }, [steps, step, stepActive]);

  if (steps) {
    return (
      <div className="parcours-all-steps">
        <h3>Liste des étapes</h3>
        <div className="all-steps">
          {steps?.map((step, index) => {
            const colorIndex = index % defaultBackgroundColor.length;
            const checkContainerLocked = () => {
              const indexStep = index;
              const stepsTMP = [ ...steps];
              const sliceStepsArray = stepsTMP.slice(0, indexStep);
              // setAllModules(sliceModuleContainerArray.map((module) => {
              //   return module.modules
              // }).flat())
              const responseContainer = sliceStepsArray.some(
                (obj) =>
                  (obj.locked === 1 && (obj.done !== 3)
                    ? 0
                    : obj.done === 0
                    ? 1
                    : obj.done) === 0 && (obj.done !== 3),
              )
              if (responseContainer === true && step.locked === 1) {
                return 0;
              } else {
                return step.done === 0 ? 1 : step.done;
              }
              // setStateModuleContainer(responseContainer === true ? 0 : moduleContainer.done);
            }
            return (
              <div
                className={step.done === 3 ? 'step-card done' : 'step-card'}
                id={step.id}
                key={index}
                onClick={(event) => {
                  handleStepClick(step, event, checkContainerLocked());
                }}
              >
                <div
                  className="step-illustration"
                  style={
                    step.image
                      ? { backgroundImage: `url("${step.image}")` }
                      : { backgroundColor: `var(--${defaultBackgroundColor[colorIndex]})` }
                  }
                ></div>
                <div className="step-title">
                  <Tag state={checkContainerLocked()} isBackgroundColor={true} />
                  <h2>{step.title}</h2>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return <></>;
};

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../atoms/Icon/Icon'
import IconButton from '../../atoms/IconButton/IconButton'
import InputField from '../InputField/InputField'
import './FormMultipleChoiceConfigurationRow.scss'
class FormMultipleChoiceConfigurationRow extends Component {
  static propTypes = {
    alphaKey: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,

    onChange: PropTypes.func,
    onAdd: PropTypes.func,
    onRemove: PropTypes.func,
    onDrag: PropTypes.func,
  }

  static defaultProps = {
    onChange: () => null,
    onAdd: () => null,
    onRemove: () => null,
    onDrag: () => null,
  }

  handleAdd = () => {
    this.props.onAdd({ id: this.props.id })
  }

  handleRemove = () => {
    this.props.onRemove({ id: this.props.id })
  }

  handleDrag = () => {
    this.props.onDrag({ id: this.props.id })
  }

  handleChange = ({ value }) => {
    this.props.onChange({ id: this.props.id, value })
  }

  render() {
    const { alphaKey, id, value } = this.props

    return (
      <div className="form-multiple-choice-configuration-row">
        <div className="step-alpha-key">{(alphaKey + 1).toString()}</div>
        <div className="choice-field">
          <InputField name={id} value={value} onChange={this.handleChange} />
        </div>
        <div className="buttons">
          <IconButton
            icon={Icon.icon.RoundPlus}
            id={id}
            onClick={this.handleAdd}
            color={Icon.color.Green1}
          />
          <IconButton
            icon={Icon.icon.RoundMinus}
            id={id}
            onClick={this.handleRemove}
            color={Icon.color.Red1}
          />
          <IconButton
            icon={Icon.icon.Drag}
            id={id}
            onClick={this.handleDrag}
            color={Icon.color.Accent}
          />
        </div>
      </div>
    )
  }
}

export default FormMultipleChoiceConfigurationRow

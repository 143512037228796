import React, { Component } from 'react'
import FieldContainer from '../FieldContainer/FieldContainer'
import NotesArea from './NotesArea'

class NotesAreaContainer extends Component {
  render() {
    return <NotesArea {...this.props} />
  }
}

export default FieldContainer({ type: 'text' })(NotesAreaContainer);

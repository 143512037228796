/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
// import VideoPopin from '../../components/organisms/VideoPopin/VideoPopin'
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside'
import EditResourcePopin from '../../containers/course/EditResourcePopin/EditResourcePopin'
import Page from '../../containers/global/Page/Page'
import TopSearch from '../../containers/global/TopSearch/TopSearch'
import { useDispatch, useSelector } from 'react-redux'
import ResourceList from '../../containers/module/ResourceList/ResourceList'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import { actions as NavigationActions, selectors as NavigationSelectors } from '../../redux/NavigationRedux'
import './ConsultantResourcesPage.scss';
import { selectors as UserSelectors } from "../../redux/UserRedux"
import { getAllPublishModules, getAllPublishMyTalentsModules, getAllPublishOfficeModules } from '../../api/TemplateCourseApi'

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
})

const ConsultantResourcesPage = ({ user }) => {
  const dispatch = useDispatch()
  const [scope, setScope] = useState('consultant')
  const [query, setQuery] = useState();
  const [resource, setResource] = useState({});
  const [resources, setResources] = useState([]);
  const [videoPopin, setVideoPopin] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const currentTab = useSelector((state) => NavigationSelectors.query(state, 'tab') || 'consultant')
  const pathname = useSelector(NavigationSelectors.pathname)
  const [showAddResource, setShowAddResource] = useState(false);
  const redirect = useCallback(
    (destination) => {
      dispatch(NavigationActions.push(destination))
    },
    [dispatch],
  )

  // call api
  const getConsultantResources = () => {
    const { id } = user;
    getAllPublishModules(id).then((response) => {
      setResources(response.modules)
    })
  }

  const getOfficeResources = () => {
    const { id } = user;
    getAllPublishOfficeModules(id).then((response) => {
      setResources(response.modules)
    })
  }

  const getMyTalentsResources = () => {
    getAllPublishMyTalentsModules().then((response) => {
      setResources(response.modules)
    })
  }

  // use effect
  useEffect(() => {
    getTemplates();
  }, [])

  useEffect(() => {
    getTemplates()

    if (scope !== currentTab) {
      handleTabChange(currentTab)
    }
  }, [scope, currentTab])
  

  // methods
  const getTemplates = useCallback(
    () => {
      if(scope === "mytalents") {
        getMyTalentsResources();
      }
      else if(scope === "office") {
        getOfficeResources();
      }
      else if(scope === "consultant") {
        getConsultantResources();
      }
    }, [getConsultantResources, getOfficeResources, scope]
  )

  const handleSearch = (query) => {
    setQuery(query)
  }

  const handleAddClick = () => {
    setShowAddResource(true)
    setResource({type: 'document'}); 
  }

  const handleEditClick = (resource) => {
    setResource(resource)
  }

  const handleVideoClicked = (url, title) => {
    setVideoPopin(url);
    setVideoTitle(title);
  }

  const handleEditSubmit = (resource) => {
    handleEditPopinClose()
  }

  const handleEditPopinClose = () => {
    setShowAddResource(false);
    setResource(null)
  }

  const handleCloseVideoPopin = () => {
       setVideoPopin('');
       setVideoTitle('')
  }

  const handleTabChange = useCallback(
    (scope) => {
      setScope(scope)
      redirect(`${pathname}?tab=${scope}`)
    },
    [pathname, redirect],
  )

  // render components
  const renderSearchBar = () => {
    return <TopSearch onSearch={handleSearch} />
  }

  const renderTabs = () => {
    const tabs = [
      { id: 'consultant', label: "Mes ressources"},
      { id: 'office', label: "Nos ressources" },
      { id: 'mytalents', label: "MyTalents" },
    ]

    return (
      <LineMenu value={scope} links={tabs} onChange={handleTabChange} components={renderSearchBar()} />
    )
  }

  // const renderVideo = () => {
  //   const { videoPopin, videoTitle } = state

  //   return (
  //     <VideoPopin
  //       title={videoTitle}
  //       videoId={videoPopin}
  //       open={!!videoPopin}
  //       onClose={handleCloseVideoPopin}
  //     />
  //   )
  // }

  const renderVideo = () => {}

  const renderContent = () => {
    return (
      <>
        <div className="add-button-wrapper">
          {scope === 'consultant' && (
            <CircleButton icon="plus" onClick={handleAddClick} />
          )}
        </div>

        <ResourceList
          resources={resources}
          editable={scope === 'consultant'}
          onEditClick={handleEditClick}
          onVideoClicked={handleVideoClicked}
        />

        <EditResourcePopin
          open={showAddResource}
          resource={resource}
          canPublish
          onSubmit={handleEditSubmit}
          onClose={handleEditPopinClose}
        />
        {renderVideo()}
      </>
    )
  }

  const aside = <ConsultantPageAside />
  const content = <TabbedContentLayout header={renderTabs()} content={renderContent()} /> 

  return (
    <div className="consultant-resources-page">
      <LeftAsideLayout aside={aside} content={content} />
    </div>
  )
}

export default connect(mapStateToProps, null)(Page(ConsultantResourcesPage))
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import _pick from 'lodash/pick'
import { asyncValidate } from '../../../helpers/form/FormValidator/FormValidator'

import Icon from '../../atoms/Icon/Icon'
// import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle'
import CircleButton from '../../atoms/CircleButton/CircleButton'
import InputField from '../../molecules/InputField/InputFieldContainer'
import ComboField from '../../molecules/ComboField/ComboFieldContainer'
import LoadingSpinner from '../../atoms/LoadingSpinner/LoadingSpinner'
import './CabinetCard.scss'

const mapStateToProps = (state, props) => ({
  initialValues: _pick(props, ['name', 'email', 'region', 'phone', 'emails']),
})


class CabinetCard extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    onFileClick: PropTypes.func,

    logoUrl: PropTypes.string,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string,
    email: PropTypes.string.isRequired,
    emails: PropTypes.string.isRequired,
    region: PropTypes.string,
    uploading: PropTypes.bool,

    locale: PropTypes.shape({
      title: PropTypes.string.isRequired,
      nameTitle: PropTypes.string.isRequired,
      namePlaceholder: PropTypes.string.isRequired,
      emailTitle: PropTypes.string.isRequired,
      emailPlaceholder: PropTypes.string.isRequired,
      emailsTitle: PropTypes.string.isRequired,
      emailsPlaceholder: PropTypes.string.isRequired,
      phoneTitle: PropTypes.string.isRequired,
      phonePlaceholder: PropTypes.string.isRequired,
      regionTitle: PropTypes.string.isRequired,
      regionPlaceholder: PropTypes.string.isRequired,
      regions: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
  }

  static defaultProps = {
    logoUrl: '/static/icons/building-icon.svg',
    onChange: ({ id, value }) => undefined,
    onFileClick: () => undefined,
    uploading: false,
  }

  handleChange = ({ id, value }) => {
    this.props.onChange({ id, value })
  }

  handleFileClick = (event) => {
    this.props.onFileClick();
    event.preventDefault();
  }

  render() {
    const { logoUrl, locale, uploading } = this.props
    const { nameTitle, namePlaceholder } = locale
    const { emailTitle, emailPlaceholder } = locale
    const { emailsTitle, emailsPlaceholder } = locale
    const { phoneTitle, phonePlaceholder } = locale
    const { regionTitle, regionPlaceholder, regions } = locale
    const regionOptions = regions.map((region) => ({
      label: region,
      value: region,
    }))
    
    return (
      <form className="cabinet-card" noValidate>
        {/* <IconCardTitle label={locale.title} icon={Icon.icon.House} /> */}

        <div className="card">
          <header>
            <img src={logoUrl} alt="logo-url" onClick={this.handleFileClick} />
            {!uploading && (
              <CircleButton id="logo" icon={Icon.icon.Image} onClick={this.handleFileClick} />
            )}
            {uploading && <LoadingSpinner />}
            <div className='card-name-cabinet'>
              <InputField name="name" placeholder={namePlaceholder} required onChange={this.handleChange}/>
            </div>
          </header>

          <section className="form">

            <InputField
              name="email"
              placeholder={emailPlaceholder}
              title={emailTitle}
              required
              type="email"
              onChange={this.handleChange}
            />

            <InputField
              name="phone"
              placeholder={phonePlaceholder}
              title={phoneTitle}
              required
              type="text"
              onChange={this.handleChange}
            />

            <ComboField
              clearable
              name="region"
              title={regionTitle}
              placeholder={regionPlaceholder}
              options={regionOptions}
              onChange={this.handleChange}
            />

            <InputField
              name="emails"
              placeholder={emailsPlaceholder}
              title={emailsTitle}
              onChange={this.handleChange}
            />
          </section>
        </div>
      </form>
    )
  }
}

CabinetCard = reduxForm(
  {
    form: 'office',
    asyncChangeFields: ['name', 'email', 'emails'],
    asyncValidate: (values) =>
      asyncValidate(values, {
        name: { rule: 'required' },
        email: { rule: 'required|email' },
      }),
  })(CabinetCard)

export default connect(mapStateToProps)(CabinetCard)
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import './ImpersonateUserRow.scss'
export default class ImpersonateUserRow extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    roleLabel: PropTypes.string,
    labelButton: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string,
  }

  static defaultProps = {
    id: null,
    roleLabel: '',
    labelButton: '',
    onClick: ({ id }) => {},
  }

  handleClick = ({ id }) => {
    this.props.onClick({ id })
  }

  render() {
    const { name, roleLabel, labelButton, id } = this.props

    return (
      <div className="impersonate-user-row">
        <div className="name">{name}</div>
        <div className="separator" />
        <div className="role">{roleLabel}</div>

        <div className="action">
          <PrimaryButton label={labelButton} id={id} onClick={this.handleClick} />
        </div>
      </div>
    )
  }
}

import React from 'react';
import './IconRessource.scss';
// All ressources icons
import CalendarIcon from './IlluIcon/calendar.svg'
import EmptyIcon from './IlluIcon/empty.svg'
import FeedbackIcon from './IlluIcon/feedback.svg'
import FilesIcon from './IlluIcon/files.svg'
import GroupUserIcon from './IlluIcon/group-users.svg'
import HeadphonesIcon from './IlluIcon/headphones.svg'
import KanbanIcon from './IlluIcon/kanban.svg'
import LinkIcon from './IlluIcon/link.svg'
import LockedIcon from './IlluIcon/locked.svg'
import PdfUploadIcon from './IlluIcon/pdf-upload.svg'
import QuestionIcon from './IlluIcon/question.svg'
import ReadingIcon from './IlluIcon/reading.svg'
import SchoolIcon from './IlluIcon/school-material.svg'
import StatsIcon from './IlluIcon/stats.svg'
import TestIcon from './IlluIcon/test.svg'
import TextIcon from './IlluIcon/text.svg'
import VerifyIcon from './IlluIcon/verify.svg'
import WhiteBoardIcon from './IlluIcon/whiteboard.svg'
import PdfIcon from './IlluIcon/pdf.svg';
import DocIcon from './IlluIcon/doc.svg';
import ExcelIcon from './IlluIcon/xls.svg';

// Icon
const icon = {
  Calendar: 'appointmentEvo',
  Empty: 'empty',
  Feedback: 'feedback',
  Files: 'files',
  GroupUser: 'group-user',
  Headphones: 'video',
  Kanban: 'kanban',
  Link: 'link',
  Locked: 'locked',
  PdfUpload: 'document_to_return',
  Question: 'form',
  Reading: 'document',
  File: 'file',
  School: 'school',
  Stats: 'stats',
  Test: 'test',
  Text: 'synthesis',
  Verify: 'verify',
  WhiteBoard: 'whiteboard',
  Pdf: 'pdf',
  Doc: 'doc',
  Excel: 'xls'
}
// Component
const IconRessource = ({ icon }) => (
  <div className={`ressource-icon ${icon}`}>
    {icon === IconRessource.icon.Calendar && <CalendarIcon />}
    {icon === IconRessource.icon.Empty && <EmptyIcon />}
    {icon === IconRessource.icon.Feedback && <FeedbackIcon />}
    {icon === IconRessource.icon.Files && <FilesIcon />}
    {icon === IconRessource.icon.GroupUser && <GroupUserIcon />}
    {icon === IconRessource.icon.Headphones && <HeadphonesIcon />}
    {icon === IconRessource.icon.Kanban && <KanbanIcon />}
    {icon === IconRessource.icon.Link && <LinkIcon />}
    {icon === IconRessource.icon.Locked && <LockedIcon />}
    {icon === IconRessource.icon.PdfUpload && <PdfUploadIcon />}
    {icon === IconRessource.icon.Question && <QuestionIcon />}
    {icon === IconRessource.icon.Reading && <ReadingIcon />}
    {icon === IconRessource.icon.File && <ReadingIcon />}

    {icon === IconRessource.icon.School && <SchoolIcon />}
    {icon === IconRessource.icon.Stats && <StatsIcon />}
    {icon === IconRessource.icon.Test && <TestIcon />}
    {icon === IconRessource.icon.Text && <TextIcon />}
    {icon === IconRessource.icon.Verify && <VerifyIcon />}
    {icon === IconRessource.icon.WhiteBoard && <WhiteBoardIcon />}
    {icon === IconRessource.icon.Pdf && <PdfIcon />}
    {icon === IconRessource.icon.Doc && <DocIcon />}
    {icon === IconRessource.icon.Excel && <ExcelIcon />}
  </div>
)

IconRessource.icon = icon;

export default IconRessource

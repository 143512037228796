export const toasterStates = {
    warning: {
      icon: "warning", 
      color: "orange",
    },
    error: {
      icon: "stop", 
      color: "red",
    },
    success: {
      icon: "check", 
      color: "green",
    },
  };
import { axiosInstance } from "./baseApi";

export const getUserProfile = async(user_id) => {
    const response = await axiosInstance.get(`users/${user_id}/profile`);
    return response.data;
}

export const updateUserProfile = async (user_id, data) => {
    const response = await axiosInstance.put(`users/${user_id}/profile/update`, data);
    return response.data;
}
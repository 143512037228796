/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux';
import { selectors as ConsultantSelectors } from '../../redux/ConsultantRedux';
import { selectors as UserSelectors } from '../../redux/UserRedux';
import { selectors as OfficeSelectors } from '../../redux/OfficeRedux';
import { selectors as BeneficiarySelectors } from '../../redux/BeneficiaryRedux';

import { connect } from 'react-redux';

import {
  getCourseById,
  createStep,
  createModuleContainer,
  updateCourse,
  updateStep,
  updateModuleContainer,
  deleteStep,
  deleteModuleContainer,
  createModule,
  updateModule,
  createSubmodule,
  duplicateModuleContainer,
  duplicateModule,
  getModule,
} from '../../api/CourseApi';
import {
  getAllPublishModules,
  getAllPublishMyTalentsModules,
  getAllPublishOfficeModules,
  getRessourcesType,
} from '../../api/TemplateCourseApi';

import AsideModuleSelector from '../../containers/course/AsideModuleSelector/AsideModuleSelector';
import CircleButton from '../../components/atoms/CircleButton/CircleButton';
import DescriptionTextArea from '../../components/molecules/DescriptionTextArea/DescriptionTextArea';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import { EditImageStepPopin } from '../../containers/course/EditImageStepPopin/EditImageStepPopin';
import ExistingModuleContainerPopin from '../../containers/popins/ExistingModuleContainerPopin/ExistingModuleContainerPopin';
import Icon from '../../components/atoms/Icon/Icon';
import Page from '../../containers/global/Page/Page';
import InputField from '../../components/molecules/InputField/InputField';

import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton';
import RemoveStepModulePopin from '../../containers/course/RemoveStepModulePopin/RemoveStepModulePopin';
import ModuleResourceRow from '../../components/molecules/ModuleResourceRow/ModuleResourceRow';
import RightAsideLayout from '../../layouts/RightAsideLayout/RightAsideLayout';
import { RemoveStepPopin } from '../../containers/course/RemoveStepPopin/RemoveStepPopin';

import { useToaster } from '../../hooks/useToaster';
import { useParams } from 'react-router';
import { Toaster } from '../../components/atoms/Toaster/Toaster';
import { EditCoverImagePopin } from '../../containers/course/EditCoverImagePopin/EditCoverImagePopin';
import { useLocation, useHistory } from 'react-router-dom';

import './CourseEditPage.scss';

// import history from 'history';

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  consultant: ConsultantSelectors.consultant(state),
  office: OfficeSelectors.office(state),
  pathname: NavigationSelectors.pathname(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
});

const mapDispatchToProps = (dispatch) => ({
  back: (prefix = '') => dispatch(NavigationActions.back(prefix, true)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
});

const CourseEditPage = (props) => {
  const history = useHistory();
  const params = useParams();
  const [idCounterStep, setIdCounterStep] = useState();
  const [course, setCourse] = useState();
  const [titleCourse, setTitleCourse] = useState('');
  const [descriptionCourse, setDescriptionCourse] = useState('');
  const [steps, setSteps] = useState([]);
  const [stepId, setStepId] = useState();
  const [stepStates, setStepStates] = useState(Array(steps.length).fill(true));
  const [isImageStepOpen, setIsImageStepOpen] = useState(false);
  const [imagesStep, setImagesStep] = useState([]);
  const [isRemoveStep, setIsRemoveStep] = useState(false);
  const [modulesContainer, setModulesContainer] = useState([{ id: null, data: [] }]);
  const [isRemoveModuleContainer, setIsRemoveModuleContainer] = useState(false);
  const [currentModuleContainer, setCurrentModuleContainer] = useState();
  const [modules, setModules] = useState({});
  const [isOpenModuleButtonActions, setIsOpenModuleButtonActions] = useState({});
  const [modulesType, setModulesType] = useState([]);
  const [modulesExisting, setModulesExisting] = useState([]);
  const tabs = [
    { id: 'new', label: 'Nouveau' },
    { id: 'existing', label: 'Existant' },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [lockedModuleContainer, setLockedModuleContainer] = useState([]);
  const [showPopinExistingModulesContainer, setShowPopinExistingModulesContainer] = useState(false);
  const { toasters, addToaster, removeToaster } = useToaster();
  const [showEditCoverImagePopin, setShowEditCoverImagePopin] = useState(false);

  const [isAttributeCourse, setIsAttributeCourse] = useState(false);
  const [beneficiaryId, setBeneficiaryId] = useState(null);
  const [isOneTitleIsNull, setisOneTitleIsNull] = useState(false);

  const modulesRef = useRef([]);
  const location = useLocation();

  // const history = useHistory();

  // image cropper
  const [image, setImage] = useState('');
  // const [cropData, setCropData] = useState("#");
  // const cropperRef = createRef();


  useEffect(() => {
    getCourseById(params.course_id).then((response) => {
      setCourse(response.course);
      // setImage(response.course.cover_url ? response.course.cover_url : "/static/images/course-default-background.jpg")
      // setImage("/static/images/cat.jpg")
      setTitleCourse(response.course?.title);
      setSteps(
        response.course?.steps.map((step) => ({
          ...step,
          published: step.published || false,
        })),
      );
      setIdCounterStep(response.course?.steps.length);
      setStepStates(Array(response.course?.steps.length).fill(true));
      if (response.course.accompaniment_id !== null) {
        setIsAttributeCourse(true);
      } else setIsAttributeCourse(false);
      const modulesContainer = response.course.steps.reduce((moduleContainer, step) => {
        moduleContainer[step.id] = step.modules_containers || [];
        return moduleContainer;
      }, {});

      setModulesContainer(modulesContainer);

      const modules = Object.entries(modulesContainer).reduce(
        (module, [step_id, moduleContainers]) => {
          moduleContainers.forEach((moduleContainer) => {
            module[moduleContainer.id] = moduleContainer.modules || [];
          });
          return module;
        },
        {},
      );
      setModules(modules);

      // checked locked module container
      response.course.steps.forEach((element) => {
        const moduleC = element.modules_containers;
        moduleC.forEach((element) => {
          if (element.locked === 1) {
            setLockedModuleContainer((prevState) => [...prevState, element.id]);
          }
        });
      });
    });

    getRessourcesType().then((response) => {
      setModulesType(response);
    });

    getAllPublishedModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllImagesStep();
  }, [steps]);

  useEffect(() => {
    Object.keys(modules).forEach((key) => {
      if (modules[key]) {
        modules[key].forEach((module) => {
          updateModule(module);
        });
      }
    });
  }, [modules]);

  useEffect(() => {
    if (course) {
      setBeneficiaryId(course.beneficiary_user_id);
    }
  }, [course]);

  // Méthode concernant le parcours
  const handleContentChange = (name, event) => {
    const updatedData = { ...course };
    // eslint-disable-next-line default-case
    switch (name) {
      case 'title':
        setTitleCourse(event.target.value);
        updatedData.title = event.target.value;
        break;
      case 'description':
        setDescriptionCourse(event.value);
        updatedData.description = event.value;
    }
    updateCourse(updatedData);
    setCourse(updatedData);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const { type, source, destination, draggableId } = result;

    if (type === 'STEP') {
      const newSteps = [...steps];
      const [removed] = newSteps.splice(source.index, 1);
      newSteps.splice(destination.index, 0, removed);

      const reorderedSteps = newSteps.map((step, index) => ({ ...step, order: index + 1 }));
      setIsLoading(true);

      reorderedSteps.forEach(async (step) => {
        try {
          await updateStep(step);
        } catch (error) {
          return error;
        }
      });
      addToaster('Etape ajoutée', 'Une nouvelle étape a été ajoutée avec succès.', 'success');
      setIsLoading(false);
      setSteps(reorderedSteps);
    }

    if (type === 'MODULE') {
      const newModulesContainers = { ...modulesContainer };
      const sourceValue = newModulesContainers[source.droppableId];
      const destinationValue = newModulesContainers[destination.droppableId];
      const [removed] = sourceValue.splice(source.index, 1);

      if (source.droppableId === destination.droppableId) {
        destinationValue.splice(destination.index, 0, removed);
        const reorderedModulesContainer = destinationValue.map((moduleContainer, index) => ({
          ...moduleContainer,
          order: index + 1,
        }));
        setIsLoading(true);
        reorderedModulesContainer.forEach(async (mContainer) => {
          try {
            await updateModuleContainer(mContainer);
            addToaster(
              'Module ajouté',
              `Le module ${mContainer.title} a été ajouté avec succès.`,
              'success',
            );
          } catch (error) {
            return error;
          }
        });

        setIsLoading(false);
        setModulesContainer({
          ...newModulesContainers,
          [destination.droppableId]: reorderedModulesContainer,
        });
      } else {
        removed.step_id = destination.droppableId;
        destinationValue.splice(destination.index, 0, removed);
        const reorderedSourceContainer = sourceValue.map((moduleContainer, index) => ({
          ...moduleContainer,
          order: index + 1,
          step_id: source.droppableId,
        }));
        const reorderedDestinationContainer = destinationValue.map((moduleContainer, index) => ({
          ...moduleContainer,
          order: index + 1,
          step_id: destination.droppableId,
        }));

        // reorderedSourceContainer.forEach(updateModuleContainer);
        setIsLoading(true);
        reorderedSourceContainer.forEach(async (mContainer) => {
          try {
            await updateModuleContainer(mContainer);

            addToaster(
              'Module ajouté',
              `Le module ${mContainer.title} a été ajouté avec succès.`,
              'success',
            );
          } catch (error) {
            return error;
          }
        });
        setIsLoading(false);

        // reorderedDestinationContainer.forEach(updateModuleContainer);
        setIsLoading(true);
        reorderedDestinationContainer.forEach(async (mContainer) => {
          try {
            await updateModuleContainer(mContainer);
          } catch (error) {
            return error;
          }
        });
        setIsLoading(false);
        setModulesContainer({
          ...newModulesContainers,
          [source.droppableId]: reorderedSourceContainer,
          [destination.droppableId]: reorderedDestinationContainer,
        });
      }
    }

    if (type === 'RESSOURCE') {
      const newModules = { ...modules };
      const currentModuleContainer = Object.values(modulesContainer)
        .flat()
        .find((obj) => obj.id === destination.droppableId);
      const moduleContainer_id = destination.droppableId;

      // Ressource prise dans les ressources existantes
      if (
        source.droppableId === 'user-existing-ressources' ||
        source.droppableId === 'office-existing-ressources' ||
        source.droppableId === 'mytalents-existing-ressources'
      ) {
        let module_id;
        if (source.droppableId === 'office-existing-ressources') {
          module_id = draggableId.split('office-ressource-')[1].split(' ')[0].trim();
        } else if (source.droppableId === 'user-existing-ressources') {
          module_id = draggableId.split('user-ressource-')[1].split(' ')[0].trim();
        } else if (source.droppableId === 'mytalents-existing-ressources') {
          module_id = draggableId.split('mytalents-ressource-')[1].split(' ')[0].trim();
        }

        setIsLoading(true);

        getModule(module_id).then((responseModule) => {
          const new_module = responseModule.module;
          new_module.modules_container_id = moduleContainer_id;
          new_module.consultant_id = props.user.profile_id;
          new_module.order = destination.index + 1;
          duplicateModule(new_module)
            .then(async (response) => {
              await setModules((prevModules) => {
                let modulesCopy = { ...prevModules };
                response.module.order = destination.index + 1;
                if (modulesCopy.hasOwnProperty(moduleContainer_id)) {
                  // Insérer le nouveau module
                  modulesCopy[moduleContainer_id].splice(destination.index, 0, {
                    ...response.module,
                    order: destination.index + 1,
                    key: destination.index,
                  });

                  // Mettre à jour les propriétés order et key de tous les modules
                  modulesCopy[moduleContainer_id] = modulesCopy[moduleContainer_id].map(
                    (module, index) => {
                      return { ...module, order: index + 1, key: index };
                    },
                  );

                  // Trier les modules
                  modulesCopy[moduleContainer_id].sort((a, b) => {
                    if (a.order !== b.order) {
                      return a.order - b.order;
                    } else {
                      return a.key - b.key;
                    }
                  });
                } else {
                  modulesCopy[moduleContainer_id] = [
                    { ...response.module, order: destination.index + 1 },
                  ];
                }
                return modulesCopy;
              });
            })
            .finally(() => {
              setIsLoading(false);
              addToaster(
                'Ressource ajoutée',
                `La ressource ${new_module.title} a été ajoutée avec succès.`,
                'success',
              );
            });
        });
      }
      // Si nouvelle ressource ou ressource vide
      else {
        const ressourceFound = Object.values(modules)
          .flat()
          .find((obj) => obj.id === draggableId);

        // La ressource existe déjà dans un module container
        if (ressourceFound) {
          // Déplacement d'une ressource dans un même module container
          if (source.droppableId === destination.droppableId) {
            const [removed] = newModules[destination.droppableId].splice(source.index, 1);
            newModules[destination.droppableId].splice(destination.index, 0, removed);

            const reorderedModules = newModules[destination.droppableId].map(
              (moduleContainer, index) => ({
                ...moduleContainer,
                order: index + 1,
                step_id: currentModuleContainer.step_id,
              }),
            );
            setIsLoading(true);
            reorderedModules.forEach(async (module) => {
              try {
                await updateModule(module);
              } catch (error) {
                return error;
              }
            });

            setIsLoading(false);
            setModules({ ...newModules, [destination.droppableId]: reorderedModules });
          }

          // Déplacement d'une ressource dans un autre module container
          else {
            const [removed] = newModules[source.droppableId].splice(source.index, 1);
            removed.modules_container_id = destination.droppableId;
            newModules[destination.droppableId].splice(destination.index, 0, removed);

            const reorderedModules = newModules[destination.droppableId].map(
              (moduleContainer, index) => ({
                ...moduleContainer,
                order: index + 1,
                step_id: currentModuleContainer.step_id,
              }),
            );
            setIsLoading(true);

            reorderedModules.forEach(async (module) => {
              try {
                await updateModule(module);
              } catch (error) {
                return error;
              }
            });

            setIsLoading(false);
            setModules({ ...newModules, [destination.droppableId]: reorderedModules });
          }
        } else if (destination.droppableId === source.droppableId) {
          return null;
        }

        // La ressource n'existe pas encore dans un module container : création d'un module + d'un submodule
        else {
          const type = draggableId.split('ressource-')[1].split(' ')[0].trim();
          let subtype = '';

          const subTypeMapping = { Vidéo: 'video', Lien: 'link', Document: 'file' };

          if (type === 'document') {
            const subtypeName = draggableId.split('ressource-document')[1].trim();
            subtype = subTypeMapping[subtypeName] || null;
          }

          const newModule = {
            modules_container_id: moduleContainer_id,
            order: destination.index,
            type: type,
          };
          let newSubmodule;
          setIsLoading(true);

          createModule(newModule)
            .then((response) => {
              const { id, type, modules_container_id } = response.module;
              if (type === 'synthesis') {
                newSubmodule = {
                  module_id: id,
                  type: type,
                  title: '',
                  description: '',
                  consultant_notes: '',
                  consultant_private_notes: '',
                  beneficiary_notes: '',
                  order: destination.index,
                  done: 1,
                };
              } else if (type === 'feedback') {
                newSubmodule = {
                  module_id: id,
                  type: type,
                  title: '',
                  description: '',
                  consultant_notes: '',
                  beneficiary_notes: '',
                  order: destination.index,
                  done: 1,
                };
              } else if (type === 'document_to_return') {
                newSubmodule = {
                  module_id: id,
                  type: type,
                  title: '',
                  description: '',
                  url: '',
                  url_return: null,
                  order: destination.index,
                  done: 1,
                };
              } else if (type === 'appointmentEvo') {
                newSubmodule = {
                  module_id: id,
                  type: type,
                  title: '',
                  description: '',
                  url: '',
                  url_return: null,
                  order: destination.index,
                  done: 1,
                  appointment_id: null,
                  rdv_at: null,
                  rdv_to: null,
                };
              } else if (type === 'centraltest') {
                newSubmodule = { module_id: id, type: type };
              } else {
                newSubmodule = {
                  module_id: id,
                  type: subtype,
                  title: '',
                  description: '',
                  url: '',
                  order: destination.index,
                  done: 1,
                };
              }

              if (type !== 'form') {
                setIsLoading(true);
                createSubmodule(newSubmodule)
                  .then((response) => {
                    const { modules_container_id } = response.module;
                    setModules((prevModules) => {
                      const modulesCopy = { ...prevModules };

                      if (modulesCopy.hasOwnProperty(modules_container_id)) {
                        modulesCopy[modules_container_id].splice(
                          destination.index,
                          0,
                          response.module,
                        );
                      } else {
                        modulesCopy[modules_container_id] = [response.module];
                      }

                      return modulesCopy;
                    });

                    const reorderedModules = modules[destination.droppableId].map(
                      (moduleContainer, index) => ({
                        ...moduleContainer,
                        order: index + 1,
                        step_id: currentModuleContainer.step_id,
                      }),
                    );

                    setIsLoading(true);

                    reorderedModules.forEach(async (module) => {
                      try {
                        await updateModule(module);
                      } catch (error) {
                        return error;
                      }
                    });
                  })
                  .finally(() => {
                    addToaster(
                      'Nouvelle ressource ajoutée',
                      'La nouvelle ressource a été ajoutée avec succès.',
                      'success',
                    );
                    setIsLoading(false);
                  });
              } else if (type === 'appointmentEvo') {
                setModules((prevModules) => {
                  const modulesCopy = { ...prevModules };

                  if (modulesCopy.hasOwnProperty(modules_container_id)) {
                    modulesCopy[modules_container_id].splice(destination.index, 0, response.module);
                  } else {
                    modulesCopy[modules_container_id] = [response.module];
                  }

                  return modulesCopy;
                });
              } else {
                setModules((prevModules) => {
                  const modulesCopy = { ...prevModules };

                  if (modulesCopy.hasOwnProperty(modules_container_id)) {
                    modulesCopy[modules_container_id].splice(destination.index, 0, response.module);
                  } else {
                    modulesCopy[modules_container_id] = [response.module];
                  }

                  return modulesCopy;
                });
              }
            })
            .finally(() => {
              addToaster(
                'Nouvelle ressource ajoutée',
                'La nouvelle ressource a été ajoutée avec succès.',
                'success',
              );
              setIsLoading(false);
            });
        }
      }
    }
  };

  // Méthodes concernant les étapes : ajouter, supprimer, modifier
  const handleAddStep = () => {
    setIdCounterStep(idCounterStep + 1);
    const step = { course_id: course?.id, order: idCounterStep + 1 };
    setIsLoading(true);
    createStep(step)
      .then((response) => {
        response.step.title = null;
        setSteps([...steps, response.step]);
        const newModuleContainer = { step_id: response.step.id, order: 1 };
        setIsLoading(true);
        createModuleContainer(newModuleContainer)
          .then((response) => {
            const newModulesContainer = {
              ...modulesContainer,
              [response.modules_container.step_id]: [
                ...(modulesContainer[response.modules_container.step_id] || []),
                response.modules_container,
              ],
            };
            setModulesContainer(newModulesContainer);

            const modules = Object.entries(newModulesContainer).reduce(
              (module, [step_id, moduleContainers]) => {
                moduleContainers.forEach((moduleContainer) => {
                  module[moduleContainer.id] = moduleContainer.modules || [];
                });
                return module;
              },
              {},
            );

            setModules(modules);
          })
          .finally(() => {
            addToaster('Étape ajoutée', 'Une nouvelle étape a été ajoutée avec succès.', 'success');
            setIsLoading(false);
          });
      })
      .finally(() => {
        addToaster('Erreur', 'Une erreur est survenue lors de la création de l’étape.', 'success');
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setStepStates(Array(steps.length).fill(true));
  }, [steps]);

  const handleDeleteStep = (id) => {
    const updatedSteps = steps.filter((step) => step.id !== id);
    const stepToDelete = steps.find((step) => step.id === id);
    const hasNoModules = stepToDelete.modules_containers.every(
      (container) => container.modules.length === 0,
    );
    if (hasNoModules) {
      const updatedModulesContainer = { ...modulesContainer };
      delete updatedModulesContainer[id];
      setModulesContainer(updatedModulesContainer);
      setSteps(updatedSteps);
      setIsLoading(true);
      deleteStep(id).finally(() => {
        addToaster('Étape supprimée', 'L’étape a été supprimée avec succès.', 'success');
        setIsLoading(false);
      });
    } else {
      setStepId(id);
      setIsRemoveStep(true);
    }
  };

  const confirmDeleteStep = (id, name) => {
    const updatedSteps = steps.filter((step) => step.id !== id);
    const updatedModulesContainer = { ...modulesContainer };
    delete updatedModulesContainer[id];
    setModulesContainer(updatedModulesContainer);
    setSteps(updatedSteps);
    setIsLoading(true);
    deleteStep(id).finally(() => {
      addToaster('Étape supprimée', `L’étape ${name} a été supprimée avec succès.`, 'success');
      setIsLoading(false);
    });
    setStepId(null);
  };

  const handleClosePopinDeleteStep = () => {
    setStepId(null);
    setIsRemoveStep(false);
  };

  const handleOnChangeStepTitle = (id, event) => {
    if (event.target.value) {
      setisOneTitleIsNull(false);
    } else {
      setisOneTitleIsNull(true);
    }
    const updateNewStep = steps.find((step) => step.id === id);
    updateNewStep.title = event.target.value;
    const updateSteps = steps.map((step) => {
      if (step.id === id) return { ...step, title: event.target.value };
      return step;
    });
    updateStep(updateNewStep);
    setSteps(updateSteps);
    // }
  };

  const handleDurationStepOnChange = (time) => {
    const { id, value } = time;
    const updateNewStep = steps.find((step) => step.id === parseInt(id));
    if (updateNewStep) {
      // Vérifiez que updateNewStep n'est pas undefined
      updateNewStep.duration = value;
      const updateSteps = steps.map((step) => {
        if (step.id === id) return { ...step, duration: value };
        return step;
      });
      updateStep(updateNewStep);
      setSteps(updateSteps);
    } else {
      console.error('Step not found for id:', id);
    }
  };

  const handleDescriptionStepOnChange = (id, event) => {
    const updateNewStep = steps.find((step) => step.id === id);
    updateNewStep.description = event.value;
    const updateSteps = steps.map((step) => {
      if (step.id === id) return { ...step, description: event.value };
      return step;
    });
    updateStep(updateNewStep);
    setSteps(updateSteps);
  };

  const handleToggleStep = (index) => {
    const newStepStates = [...stepStates];
    newStepStates[index] = !newStepStates[index];
    setStepStates(newStepStates);
  };

  const handleOnChangeImage = (id, event) => {
    const image = event.target.files[0];
    const currentStep = steps.find((element) => element.id === id);
    setStepId(id);
    const reader = new FileReader();
    const existingImageIndex = imagesStep?.findIndex((element) => element.id === id);

    reader.onload = (e) => {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const maxWidth = 800;
        const maxHeight = 600;

        let width = img.width;
        let height = img.height;

        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }

        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        const base64 = canvas.toDataURL('image/jpeg');
        const newStep = { ...currentStep, upload_name: image.name, upload: base64 };
        setIsLoading(true);
        updateStep(newStep)
          .then((response) => {
            const updatedImages = [...imagesStep];
            updatedImages[existingImageIndex] = { id: id, image: response.step.image };
            setImagesStep(updatedImages);
          })
          .finally(() => {
            addToaster(
              "Image de l'étape",
              "L'image de l'étape à été téléchargé avec succés",
              'success',
            );
            setIsLoading(false);
          });
      };
      img.src = e.target.result;
      //ANCIEN CODE - A REMETTRE SI COMPRESSEUR IMG NE FONCTIONNE PAS EN PROD
      // const newStep = { ...currentStep, upload_name: image.name, upload: reader.result }
      // setIsLoading(true)
      // updateStep(newStep).then((response) => {
      //   const updatedImages = [...imagesStep];
      //   updatedImages[existingImageIndex] = { id: id, image: response.step.image }
      //   setImagesStep(updatedImages)
      // }).finally(() => {
      //   addToaster('titre', 'message', "success")
      //   setIsLoading(false);
      // })
    };
    reader.readAsDataURL(image);
  };

  const handleDeleteImage = () => {
    const updatedStepsImage = imagesStep.filter((element) => element.id !== stepId);
    const currentStep = steps.find((element) => element.id === stepId);
    const newStep = { ...currentStep, image: null };
    setImagesStep(updatedStepsImage);
    setIsImageStepOpen(false);
    setIsLoading(true);
    updateStep(newStep).finally(() => {
      addToaster('Image supprimée', 'Votre image a été supprimée avec succès.', 'success');
      setIsLoading(false);
    });
  };

  const handleImageStepOnClick = (id) => {
    setIsImageStepOpen(true);
    setStepId(id);
  };

  const getImageStepOpen = (isOpen) => {
    setIsImageStepOpen(isOpen);
  };

  const getAllImagesStep = () => {
    const allImages = steps.map((element) => {
      const correspondingImage = imagesStep.find((item) => item.id === element.id);
      if (correspondingImage) {
        return { id: element.id, image: correspondingImage.image };
      } else {
        return { id: element.id, image: element.image };
      }
    });

    setImagesStep(allImages);
  };

  useEffect(() => {
    if (course) {
      setDescriptionCourse(course.description);
    }
  }, [course]);

  // Méthodes concernant les modules container
  const handleAddModuleContainer = (stepId) => {
    const newModuleContainer = { step_id: stepId, order: modulesContainer[stepId].length + 1 };
    setIsLoading(true);
    createModuleContainer(newModuleContainer)
      .then((response) => {
        const newModulesContainer = {
          ...modulesContainer,
          [response.modules_container.step_id]: [
            ...(modulesContainer[response.modules_container.step_id] || []),
            response.modules_container,
          ],
        };
        setModulesContainer(newModulesContainer);

        const stepIndex = steps.findIndex((step) => step.id === stepId);

        if (stepIndex === -1) {
          console.error('Étape introuvable');
          return;
        }

        const updatedSteps = [...steps];
        updatedSteps[stepIndex] = {
          ...updatedSteps[stepIndex],
          modules_containers: [
            ...(updatedSteps[stepIndex].modules_containers || []),
            response.modules_container,
          ],
        };

        const modules = Object.entries(newModulesContainer).reduce(
          (module, [step_id, moduleContainers]) => {
            moduleContainers.forEach((moduleContainer) => {
              module[moduleContainer.id] = moduleContainer.modules || [];
            });
            return module;
          },
          {},
        );

        setModules(modules);
      })
      .finally(() => {
        addToaster(
          'Nouveau module ajouté',
          'Le nouveau module a été ajouté avec succès.',
          'success',
        );
        setIsLoading(false);
      });
  };

  const handleAddModuleContainerExisting = (stepId) => {
    setShowPopinExistingModulesContainer(true);
    setStepId(stepId);
  };

  const handleClosePopin = () => {
    setShowPopinExistingModulesContainer(false);
  };

  const getModuleContainerChosen = (newModuleContainer, stepId) => {
    const newModulesContainer = {
      ...modulesContainer,
      [stepId]: [...(modulesContainer[stepId] || []), newModuleContainer],
    };
    const modules = Object.entries(newModulesContainer).reduce(
      (module, [step_id, moduleContainers]) => {
        moduleContainers.forEach((moduleContainer) => {
          if (moduleContainer) {
            module[moduleContainer.id] = moduleContainer.modules || [];
          }
        });
        return module;
      },
      {},
    );

    setModules(modules);
    setModulesContainer(newModulesContainer);
    addToaster(
      'Module ajouté',
      `Le module ${newModuleContainer.title} a été ajouté avec succès.`,
      'success',
    );
    handleClosePopin();
  };

  const removeDeleteModuleContainer = (mContainer) => {
    const updatedModuleContainer = modulesContainer[mContainer.step_id].filter(
      (moduleContainer) => moduleContainer.id !== mContainer.id,
    );
    const newModulesContainer = {
      ...modulesContainer,
      [mContainer.step_id]: updatedModuleContainer,
    };

    setModulesContainer(newModulesContainer);
    setIsLoading(true);
    deleteModuleContainer(mContainer.id)
      .then(setIsRemoveModuleContainer(false))
      .finally(() => {
        addToaster('Module supprimé', 'Le module a été supprimé avec succès.', 'success');
        setIsLoading(false);
      });
  };

  const handleDuplicateModuleContainer = (moduleContainer) => {
    setIsLoading(true);

    const consultant_id = props.user.profile_id;
    const moduleContainerDuplicate = { ...moduleContainer, consultant_id: consultant_id };

    duplicateModuleContainer(moduleContainerDuplicate)
      .then((response) => {
        const newModuleContainer = response.modules_container;
        const stepId = response.modules_container.step_id;
        const getIndex = modulesContainer[stepId].indexOf(moduleContainer);

        const newModulesContainer = {
          ...modulesContainer,
          [stepId]: [...(modulesContainer[stepId] || []), newModuleContainer],
        };

        const modules = Object.entries(newModulesContainer).reduce(
          (module, [step_id, moduleContainers]) => {
            moduleContainers.forEach((moduleContainer) => {
              module[moduleContainer.id] = moduleContainer.modules || [];
            });
            return module;
          },
          {},
        );

        setModules(modules);
        setModulesContainer(newModulesContainer);
      })
      .finally(() => {
        addToaster('Module dupliqué', 'Le module a été dupliqué avec succès.', 'success');
        setIsLoading(false);
      });
  };

  const handlePublishModuleContainer = (moduleContainer) => {
    const updatedModuleContainer = {
      ...moduleContainer,
      template_published: !moduleContainer.template_published ? 1 : 0,
    };

    const newModulesContainer = modulesContainer[moduleContainer.step_id].map((element) => {
      if (element.id === moduleContainer.id) {
        return updatedModuleContainer;
      }
      return element;
    });

    setModulesContainer({
      ...modulesContainer,
      [moduleContainer.step_id]: newModulesContainer,
    });

    updateModuleContainer(updatedModuleContainer).finally(() => {
      const message = updatedModuleContainer.template_published
        ? 'Le module a été publié avec succès.'
        : 'Le module a été dépublié avec succès.';
      addToaster('Publication Module', message, 'success');
    });
  };

  const handleToggleLockedModuleContainer = (moduleContainer) => {
    if (moduleContainer.locked === 0) {
      const modLock = module.locked ? 0 : 1;
      const done =
        modLock === 1 && module.order !== 1 && (module.done === 1 || module.done === 0)
          ? 0
          : module.done === 0
          ? 1
          : module.done;
      const moduleContainerToUpdate = { ...moduleContainer, locked: 1, done };
      updateModuleContainer(moduleContainerToUpdate);
      setLockedModuleContainer([...lockedModuleContainer, moduleContainer.id]);

      const mcToUpdate = modulesContainer[moduleContainer.step_id].map((element) => {
        if (element.id === moduleContainer.id) {
          return moduleContainerToUpdate;
        }
        return element;
      });

      const newModulesContainer = {
        ...modulesContainer,
        [moduleContainer.step_id]: mcToUpdate,
      };

      setModulesContainer(newModulesContainer);
    } else {
      const moduleContainerToUpdate = { ...moduleContainer, locked: 0 };
      updateModuleContainer(moduleContainerToUpdate);
      const newLockedModulesContainer = lockedModuleContainer.filter(
        (element) => element !== moduleContainer.id,
      );
      setLockedModuleContainer(newLockedModulesContainer);

      const mcToUpdate = modulesContainer[moduleContainer.step_id].map((element) => {
        if (element.id === moduleContainer.id) {
          return moduleContainerToUpdate;
        }
        return element;
      });

      const newModulesContainer = {
        ...modulesContainer,
        [moduleContainer.step_id]: mcToUpdate,
      };

      setModulesContainer(newModulesContainer);
    }
  };

  const handleDeleteModuleContainer = (moduleContainer) => {
    const isEmptyModuleContainer = moduleContainer.modules.length === 0;

    if (isEmptyModuleContainer) {
      const updatedModulesContainer = modulesContainer[moduleContainer.step_id].filter(
        (element) => element.id !== moduleContainer.id,
      );
      const newModulesContainer = {
        ...modulesContainer,
        [moduleContainer.step_id]: updatedModulesContainer,
      };
      setModulesContainer(newModulesContainer);
      setIsLoading(true);
      deleteModuleContainer(moduleContainer.id).finally(() => {
        addToaster('Module supprimé', 'Votre module a été correctement supprimé', 'success');
        setIsLoading(false);
      });
    } else {
      setCurrentModuleContainer(moduleContainer);
      setIsRemoveModuleContainer(true);
    }
  };

  const handleOnCloseRemoveModuleContainer = () => {
    setIsRemoveModuleContainer(false);
  };

  const handleOnCloseEditCoverImagePopin = () => {
    setShowEditCoverImagePopin(false);
  };

  const handlerOnChangeModuleTitle = (id, stepId, event) => {
    const moduleContainerToUpdate = modulesContainer[stepId].find(
      (moduleContainer) => moduleContainer.id === id,
    );

    moduleContainerToUpdate.title = event.target.value;

    const updatedModulesContainer = modulesContainer[stepId].map((moduleContainer) => {
      if (moduleContainer.id === id) {
        return moduleContainerToUpdate;
      }
      return moduleContainer;
    });

    const newModulesContainer = {
      ...modulesContainer,
      [stepId]: updatedModulesContainer,
    };

    setModulesContainer(newModulesContainer);
    updateModuleContainer(moduleContainerToUpdate);
  };

  const handleDescriptionModuleContainerOnChange = (id, stepId, event) => {
    const moduleContainerToUpdate = modulesContainer[stepId].find(
      (moduleContainer) => moduleContainer.id === id,
    );

    moduleContainerToUpdate.description = event.value;

    const updatedModulesContainer = modulesContainer[stepId].map((moduleContainer) => {
      if (moduleContainer.id === id) {
        return moduleContainerToUpdate;
      }
      return moduleContainer;
    });

    const newModulesContainer = {
      ...modulesContainer,
      [stepId]: updatedModulesContainer,
    };

    setModulesContainer(newModulesContainer);
    updateModuleContainer(moduleContainerToUpdate);
  };

  const openModuleButtonActions = (moduleId) => {
    setIsOpenModuleButtonActions((prev) => {
      return {
        ...prev,
        [moduleId]: true,
      };
    });
  };

  const closeModuleButtonsActions = (moduleId) => {
    setIsOpenModuleButtonActions((prev) => {
      return {
        ...prev,
        [moduleId]: false,
      };
    });
  };

  // Méthodes concernant les modules
  const getRessourceDeleted = (moduleId) => {
    let moduleDeleted = null;
    let tableauTrouve = null;

    Object.entries(modules).forEach(([key, array]) => {
      const index = array.findIndex((item) => item.id === moduleId);
      if (index !== -1) {
        moduleDeleted = array[index];
        tableauTrouve = array;
        tableauTrouve.splice(index, 1);
        return;
      }
    });
    const newModules = { ...modules };
    setModules(newModules);

    Object.values(modulesContainer).forEach((mcontainer) => {
      mcontainer.forEach((module, index) => {
        if (module.id === moduleId) {
          mcontainer.splice(index, 1);
          return;
        }
      });
    });

    const newModulesContainer = { ...modulesContainer };
    setModulesContainer(newModulesContainer);
  };

  const handleUpdateModules = async (updatedModules) => {
    // await updateModules(updatedModules);
  };

  const getRessourceDuplicate = async (duplicateModule) => {
    // setModules(modules)
    const modulesCopy = { ...modules };
    modulesCopy[duplicateModule.modules_container_id].splice(1, 0, duplicateModule);
    const sortedData = [...modulesCopy[duplicateModule.modules_container_id]].sort((a, b) => {
      if ('order' in a && 'order' in b) {
        return a.order - b.order;
      }
      return 0;
    });

    let previousOrder = 0;
    for (let i = 0; i < sortedData.length; i++) {
      const item = sortedData[i];
      if ('order' in item) {
        if (item.order === previousOrder) {
          item.order = previousOrder + 1;
        }
        previousOrder = item.order;
      }
    }

    modulesCopy[duplicateModule.modules_container_id] = sortedData;

    // Gestion asynchrone avec gestion des erreurs
    for (const module of sortedData) {
      try {
        await updateModule(module);
      } catch (error) {
        console.error('Failed to update module:', error);
        // Gérer l'erreur comme nécessaire
      }
    }
    setModules(modulesCopy);
    // return modulesCopy;
  };

  const getUpdatedResource = (updatedResource) => {
    let foundObject = null;
    let moduleKey = null;

    for (const key in modules) {
      const array = modules[key];
      const foundItem = array.find((item) => item.id === updatedResource.id);
      if (foundItem) {
        foundObject = foundItem;
        moduleKey = key;
        break;
      }
    }

    if (foundObject) {
      const index = modules[moduleKey].indexOf(foundObject);
      if (index !== -1) {
        modules[moduleKey][index] = updatedResource;

        const updatedModules = { ...modules };
        updatedModules[moduleKey] = [...modules[moduleKey]];
        updatedModules[moduleKey][index] = updatedResource;

        setModules(updatedModules);
      }
    } else {
      return null;
    }
  };

  const getRessourcesPublished = (resourcePublished) => {
    const updatedModules = { ...modulesExisting };

    if (!updatedModules.user) {
      updatedModules.user = [];
    }

    updatedModules.user.push(resourcePublished);

    setModulesExisting(updatedModules);
  };

  const getAllPublishedModules = () => {
    getAllPublishModules(props.user.id)
      .then((response) => {
        const userModules = response.modules;
        return userModules;
      })
      .catch((error) => {
        console.error('Error fetching user modules:', error);
        return [];
      })
      .then((userModules) => {
        return getAllPublishMyTalentsModules()
          .then((response) => {
            const myTalentsModules = response.modules;
            return myTalentsModules;
          })
          .catch((error) => {
            console.error('Error fetching my talents modules:', error);
            return [];
          })
          .then((myTalentsModules) => {
            return getAllPublishOfficeModules(props.user.id)
              .then((response) => {
                const officeModules = response.modules;
                return officeModules;
              })
              .catch((error) => {
                console.error('Error fetching office modules:', error);
                return [];
              })
              .then((officeModules) => {
                setModulesExisting({
                  user: userModules,
                  mytalents: myTalentsModules,
                  office: officeModules,
                });
              });
          });
      });
  };

  // Autres
  const handleConfirmPopinClick = () => {
    if (course.accompaniment_id === null) {
      props.redirect(`/consultant/templates/courses`);
    } else {
      props.redirect(`/consultant/beneficiaries/${beneficiaryId}/courses`);
    }
  };

  const handleTogglePublish = () => {
    const isPublish = course.template_published === 0 ? 1 : 0;
    const updatedCourse = { ...course, template_published: isPublish };
    updateCourse(updatedCourse)
      .then((response) => {
        setCourse(response.course);
      })
      .finally(() => {
        isPublish
          ? addToaster('Parcours publié', 'Votre parcours a été publié avec succès.', 'success')
          : addToaster(
              'Parcours dépublié',
              'Votre parcours a été dépublié avec succés.',
              'success',
            );
      });
  };

  const handleSave = () => {};

  const handleShowEditCoverImagePopin = () => {
    setShowEditCoverImagePopin(true);
  };

  const handleCoverUrlChange = (event) => {
    const image = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const maxWidth = 800;
        const maxHeight = 600;

        let width = img.width;
        let height = img.height;

        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }

        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        const base64 = canvas.toDataURL('image/jpeg');
        const updatedCourse = { ...course, upload_name: image.name, upload: base64 };
        setIsLoading(true);
        updateCourse(updatedCourse)
          .then((response) => {
            setCourse(response.course);
            setImage(response.course.cover_url);
          })
          .finally(() => {
            addToaster(
              'Couverture du parcours ajoutée',
              'L’image de couverture du parcours a été ajoutée avec succès.',
              'success',
            );
            setIsLoading(false);
          });
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(image);
  };

  const handleGetCroppedFile = (img) => {
    const updatedCourse = {
      ...course,
      upload_name: `croped_cover_img_${new Date().getTime()}`,
      upload: img,
    };
    updateCourse(updatedCourse).then((response) => {
      setCourse(response.course);
    });
    setShowEditCoverImagePopin(false);
  };

  const getToaster = (t) => {
    addToaster(t.title, t.message, t.type);
  };

  useEffect(() => {
    if (course) {
      setImage(
        course.cover_url ? course.cover_url : '/static/images/course-default-background.jpg',
      );
    }
  }, [course]);

  const renderHeader = () => {
    const published = course && course.template_published;
    return (
      <header className="course-edit-page-header">
        <div className="actions">
          <div className="left-components" style={{ width: '100%' }}>
            <CircleButton icon={Icon.icon.Back} onClick={handleConfirmPopinClick} />
            <input
              type="text"
              value={!titleCourse ? '' : titleCourse}
              name="title"
              placeholder={'Insérer le titre du parcours'}
              onChange={(event) => handleContentChange('title', event)}
              autoComplete="off"
              style={{ width: '100%' }}
              className="title"
            />
          </div>

          <div className="right-components">
            {course && !course.accompaniment_id && (
              <PrimaryButton
                label={published ? 'Dépublier' : 'Publier'}
                id="publish"
                icon={Icon.icon.Publish}
                onClick={handleTogglePublish}
              />
            )}
            {/* <PrimaryButton label={"Exporter"} id="save" onClick={handleSave} /> */}
          </div>
        </div>
        <div className="cover-image" style={{ backgroundImage: `url("${image}")` }}>
          <div className="cover-image-actions">
            <label className="cover-image-updload">
              <Icon icon={Icon.icon.Image} />
              <input type="file" onChange={handleCoverUrlChange} />
            </label>
            <div className="cover-image-resize">
              <button onClick={handleShowEditCoverImagePopin}>
                <Icon icon="crop" color={'accent'} />
              </button>
            </div>
          </div>
        </div>
      </header>
    );
  };

  const renderTemplateInformations = () => {
    const modulesCount = Object.values(modulesContainer).reduce(
      (total, arr) => total + arr.length,
      0,
    );
    return (
      <>
        <div className="informations">
          <div className="entry">
            <div className="count">{steps?.length}</div>
            <div className="label">{steps?.length > 1 ? 'étapes' : 'étape'} </div>
          </div>
          <div className="entry">
            <div className="count">{modulesCount ? modulesCount : 0}</div>
            <div className="label">{modulesCount > 1 ? 'modules' : 'module'}</div>
          </div>
        </div>

        <DescriptionTextArea
          maxLength={1500}
          name="description"
          placeholder={'Description du parcours'}
          onChange={(event) => handleContentChange('description', event)}
          value={!descriptionCourse ? '' : descriptionCourse}
        />
      </>
    );
  };

  const renderNewItem = (title, onClick, id) => {
    return (
      <div className="new-item" onClick={id ? () => onClick(id) : onClick}>
        <p>{title}</p>
        <CircleButton icon={Icon.icon.Plus} />
      </div>
    );
  };
  const renderNewModule = (id) => {
    return (
      <Droppable droppableId={id} type="RESSOURCE">
        {(provided) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            className="new-ressource"
          >
            <h3>Glisser les ressources ici</h3>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  const renderModules = (id) => {
    const actions = [
      { id: 'edit', name: 'Editer', icon: Icon.icon.Edit2 },
      { id: 'lock', name: 'Verrouiller', icon: Icon.icon.Lock },
      { id: 'publish', name: 'Publier', icon: Icon.icon.Publish },
      { id: 'duplicate', name: 'Dupliquer', icon: Icon.icon.Duplicate },
      { id: 'remove', name: 'Supprimer', icon: Icon.icon.Trash2 },
    ];
    return modules[id]?.map((element, index) => (
      <div className="course-ressources" key={index}>
        <Draggable key={element.id} draggableId={element.id} index={index}>
          {(provided) => (
            <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
              <ModuleResourceRow
                moduleProps={element}
                actions={actions}
                getRessourceDeleted={getRessourceDeleted}
                getNewModuleDuplicate={getRessourceDuplicate}
                getRessourcesPublished={getRessourcesPublished}
                getRessourceUpdated={getUpdatedResource}
                beneficiaryId={beneficiaryId}
                isAttruteCourse={isAttributeCourse}
                setToaster={getToaster}
              />
            </div>
          )}
        </Draggable>
      </div>
    ));
  };

  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     location.state = null
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [history]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (location && location.state) {
        const moduleIdToScroll = location.state;
        if (moduleIdToScroll && modulesRef.current[moduleIdToScroll]) {
          modulesRef.current[moduleIdToScroll].scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [location]);

  useEffect(() => {}, [location]);

  const renderModuleContainer = (moduleContainer, index) => {
    return (
      <>
        <div
          className="course-module"
          id={moduleContainer?.id}
          ref={(element) => (modulesRef.current[moduleContainer?.id] = element)}
        >
          <div className="course-module-title">
            <Icon icon="drag" color="accent" />
            {lockedModuleContainer.includes(moduleContainer.id) && (
              <Icon icon="lock" color="grey2" />
            )}
            <p>{index ? `Module ${index + 1}` : 'Module 1'}</p>
            <input
              type="text"
              name="title"
              placeholder={'Insérer le titre du module'}
              value={!moduleContainer?.title ? '' : moduleContainer.title}
              onChange={(event) =>
                handlerOnChangeModuleTitle(moduleContainer?.id, moduleContainer?.step_id, event)
              }
              autoComplete="off"
              className="module-title"
            />
          </div>
          <div className="course-module-actions">
            {moduleContainer.template_published ? (
              <Icon icon="publish" color={Icon.color.Accent} />
            ) : (
              ''
            )}
            {!isOpenModuleButtonActions[moduleContainer.id] ? (
              <button
                className="button-toggle-actions"
                onClick={() => openModuleButtonActions(moduleContainer.id)}
              >
                <Icon icon={'more-horizontal'} color={Icon.color.Grey2} />
              </button>
            ) : (
              <div className="action-container">
                {lockedModuleContainer.includes(moduleContainer.id) ? (
                  <div
                    className="slide-in"
                    onClick={() => handleToggleLockedModuleContainer(moduleContainer)}
                  >
                    <Icon icon="lock" color="grey1" />
                  </div>
                ) : (
                  <div
                    className="slide-in"
                    onClick={() => handleToggleLockedModuleContainer(moduleContainer)}
                  >
                    <Icon icon="unlocked" color="grey1" />
                  </div>
                )}
                <div
                  className="slide-in"
                  onClick={() => handlePublishModuleContainer(moduleContainer)}
                >
                  <Icon icon="publish" color="grey1" />
                </div>
                <div
                  className="slide-in"
                  onClick={() => handleDuplicateModuleContainer(moduleContainer)}
                >
                  <Icon icon="duplicate" color="grey1" />
                </div>
                <div
                  className="slide-in"
                  onClick={() => handleDeleteModuleContainer(moduleContainer)}
                >
                  <Icon icon="trash" color="grey1" />
                </div>
                <div
                  className="slide-in"
                  onClick={() => closeModuleButtonsActions(moduleContainer.id)}
                >
                  <Icon icon={'close2'} color={Icon.color.Grey1} />
                </div>
              </div>
            )}
          </div>
        </div>
        <DescriptionTextArea
          maxLength={1000}
          name="description"
          placeholder={'Ajouter une description à votre module'}
          onChange={(event) =>
            handleDescriptionModuleContainerOnChange(
              moduleContainer?.id,
              moduleContainer?.step_id,
              event,
            )
          }
          value={!moduleContainer.description ? '' : moduleContainer.description}
        />
      </>
    );
  };

  const handleLockStep = (id, value) => {
    const updateNewStep = steps.find((step) => step.id === id);
    updateNewStep.locked = value;
    if (value === 1 && updateNewStep.done !== 2 && updateNewStep.done !== 3) {
      updateNewStep.done = 0;
    }
    const updateSteps = steps.map((step) => {
      if (step.id === id) return { ...updateNewStep };
      return step;
    });
    updateStep(updateNewStep);
    setSteps(updateSteps);
  };

  /*   const handlePublishStep = (id) => {
    const updatedSteps = steps.map((step) => {
      if (step.id === id) {
        return { ...step, published: !step.published };
      }
      return step;
    });
  
    setSteps(updatedSteps);
  
    const stepToUpdate = updatedSteps.find((step) => step.id === id);
    updateStep(stepToUpdate).finally(() => {
      const message = stepToUpdate.published ? 'L’étape a été publiée avec succès.' : 'L’étape a été dépubliée avec succès.';
      addToaster('Publication étape', message, 'success');
    });
  }; */

  const renderStep = (step, index) => {
    return (
      <div className={`course-step-container step-${step.id}`}>
        <div className={`course-step step-${step.id}`}>
          <div className="course-step-title">
            <Icon icon="drag" color="accent" />
            {imagesStep?.find((element) => element.id === step.id)?.image ? (
              <img
                src={imagesStep.find((element) => element.id === step.id).image}
                alt="step-illustration"
                onClick={() => handleImageStepOnClick(step.id)}
              />
            ) : (
              <label>
                <Icon icon={'image'} />
                <input type="file" onChange={(event) => handleOnChangeImage(step.id, event)} />
              </label>
            )}
            <p>Etape {index + 1}</p>
            <input
              type="text"
              name="title"
              value={!step.title ? '' : step.title}
              onChange={(event) => handleOnChangeStepTitle(step.id, event)}
              placeholder={"Insérer le titre de l'étape"}
              autoComplete="off"
              className="title"
            />
          </div>
          <div className="course-step-actions">
            <div
              style={{ width: '16px' }}
              onClick={() => handleLockStep(step.id, step.locked === 0 ? 1 : 0)}
            >
              {' '}
              {/* TODO: A relier au back */}
              {step.locked === 1 ? (
                <Icon icon="locked" color="grey1" />
              ) : (
                <Icon icon="unlocked" color="grey1" />
              )}
            </div>
            {/*   <div onClick={() => handlePublishStep(step.id)} className={step.published ? 'published' : ''}>
              <Icon icon="publish" color={step.published ? "" : "grey1"} />
            </div> */}
            <div onClick={() => handleDeleteStep(step.id)}>
              <Icon icon="trash" color="grey1" />
            </div>
            {stepStates[index] ? (
              <Icon icon="chevron-up" onClick={() => handleToggleStep(index)} />
            ) : (
              <Icon icon="chevron-down" onClick={() => handleToggleStep(index)} />
            )}
            {stepId === step.id && isRemoveStep && (
              <RemoveStepPopin
                open={isRemoveStep}
                onClose={handleClosePopinDeleteStep}
                handleRemove={() => confirmDeleteStep(step.id, step.title)}
              />
            )}
          </div>
        </div>
        <div className="course-step-duration">
          <p>Durée de l'étape</p>
          <InputField
            name={step.id?.toString()}
            placeholder={''}
            value={step.duration ? step.duration : '00:00'}
            type={'time'}
            onChange={handleDurationStepOnChange}
          />
        </div>
        <div className="course-step-description">
          <DescriptionTextArea
            maxLength={1000}
            name="description"
            placeholder={'Ajouter une description à votre étape'}
            onChange={(event) => handleDescriptionStepOnChange(step.id, event)}
            value={!step.description ? '' : step.description}
          />
        </div>
      </div>
    );
  };

  const renderContainer = (step, index) => {
    const sortedModulesContainer = modulesContainer[step.id]?.sort((a, b) => a.order - b.order);
    return (
      <div className="course-container" id={step.id}>
        {renderStep(step, index)}

        <div
          className="course-container-childrens"
          style={{ display: stepStates[index] ? 'block' : 'none' }}
        >
          <Droppable droppableId={step.id} type="MODULE">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sortedModulesContainer?.map((moduleContainer, index) => (
                  <Draggable
                    key={moduleContainer.id}
                    draggableId={moduleContainer.id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className="course-module-container"
                      >
                        {renderModuleContainer(moduleContainer, index)}
                        {modules[moduleContainer.id] && modules[moduleContainer.id]?.length > 0 ? (
                          <Droppable droppableId={moduleContainer.id} type="RESSOURCE">
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={`course-container-droppable ${
                                  snapshot.isDraggingOver ? 'course-container-droppable-over' : ''
                                }`}
                              >
                                {renderModules(moduleContainer.id)}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        ) : (
                          renderNewModule(moduleContainer.id)
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <hr></hr>
          <div className="add-module">
            {renderNewItem('Nouveau module', handleAddModuleContainer, step.id)}
            {renderNewItem('Ajouter un module existant', handleAddModuleContainerExisting, step.id)}
          </div>
        </div>
        {showPopinExistingModulesContainer && stepId === step.id && (
          <ExistingModuleContainerPopin
            handleClosePopin={handleClosePopin}
            isOpen={showPopinExistingModulesContainer}
            stepId={stepId}
            setModuleContainerChosen={(newModuleContainer) =>
              getModuleContainerChosen(newModuleContainer, step.id)
            }
          />
        )}
      </div>
    );
  };

  const handleToggleAllSteps = (steps) => {
    steps.forEach((element, key) => {
      const e = document.getElementById('course-template-toggle-step-all');
      if (!stepStates[0]) {
        e.innerHTML = 'Fermer toutes les étapes';
      } else {
        e.innerHTML = 'Ouvrir toutes les étapes';
      }
      setStepStates(Array(steps.length).fill(!stepStates[0]));
    });
  };

  const renderTemplateSteps = () => {
    const sortedSteps = steps.sort((a, b) => a.order - b.order);
    return (
      <div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
          <div style={{ display: 'flex', alignItems: 'end', gap: '0.5rem' }}>
            <p
              id="course-template-toggle-step-all"
              className="course-template-toggle-step-all"
              onClick={() => handleToggleAllSteps(steps)}
            >
              Fermer toutes les étapes
            </p>
            {stepStates[0] ? (
              <Icon icon="chevron-up" onClick={() => handleToggleStep(steps)} />
            ) : (
              <Icon icon="chevron-down" onClick={() => handleToggleStep(steps)} />
            )}
          </div>
        </div>

        <Droppable droppableId="steps" type="STEP">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {sortedSteps.map((step, index) => (
                <Draggable key={step.id} draggableId={`step-${step.id}`} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {renderContainer(step, index)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {renderNewItem('Nouvelle étape', handleAddStep)}
      </div>
    );
  };

  const renderTemplate = () => {
    return (
      <section>
        {renderTemplateInformations()}
        {renderTemplateSteps()}
      </section>
    );
  };


  const renderContent = () => {
    return (
      <>
        {renderHeader()}
        {renderTemplate()}
      </>
    );
  };


  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div className="course-edit-page">
        <RightAsideLayout
          aside={
            <AsideModuleSelector
              ressources={modulesType}
              existingRessources={modulesExisting}
              tabs={tabs}
            />
          }
          content={renderContent()}
        />
        {isImageStepOpen && (
          <EditImageStepPopin
            image={imagesStep}
            id={stepId}
            isOpenModal={isImageStepOpen}
            setIsOpenModal={getImageStepOpen}
            editImage={(event) => handleOnChangeImage(stepId, event)}
            deleteImage={handleDeleteImage}
          />
        )}
        {isRemoveModuleContainer && (
          <RemoveStepModulePopin
            open={isRemoveModuleContainer}
            onClose={handleOnCloseRemoveModuleContainer}
            handleRemove={() => removeDeleteModuleContainer(currentModuleContainer)}
            isPublished={currentModuleContainer.template_published}
          />
        )}
        {showEditCoverImagePopin && (
          <EditCoverImagePopin
            isOpen={showEditCoverImagePopin}
            onClose={handleOnCloseEditCoverImagePopin}
            cover_image={image}
            getCroppedFile={handleGetCroppedFile}
          />
        )}
        <div className="notification-container">
          {toasters.map((toaster) => (
            <Toaster
              key={toaster.id}
              title={toaster.title}
              message={toaster.message}
              type={toaster.type}
              onClose={() => removeToaster(toaster.id)}
            />
          ))}
        </div>
      </div>
    </DragDropContext>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Page(CourseEditPage));

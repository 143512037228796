/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Icon from "../../atoms/Icon/Icon";
import moment from 'moment';
import 'moment/locale/fr';
import './AgendaCalendar.scss'

export const AgendaCalendar = ({ appointments, viewSpecificAppointment }) => {
  const [calendar, setCalendar] = useState([]);
  const [today, setToday] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [currentDateFormated, setCurrentDateFormated] = useState();
  const [year, setYear] = useState();
  const [allAppointments, setAllAppointments] = useState([]);

  const days = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];

  const getDate = (month) => {
    const startDate = moment([year, month]).clone().startOf("month").startOf("week");
    const endDate = moment([year, month]).clone().endOf("month");
    const day = startDate.clone().subtract(1, "day");
    let currentCalendar = [];

    while (day.isBefore(endDate, "day")) {
      const week = Array(7).fill(0).map(() => {
        const date = day.add(1, "day").clone();
        return {
          value: date.format("DD"),
          date: date.format("L")
        };
      });

      currentCalendar.push(week);
    }

    setCalendar(currentCalendar)
  }

  const viewAppointment = (date) => {
    viewSpecificAppointment(date)
  }

  useEffect(() => {
    if (currentDate) {
      setCurrentDateFormated(currentDate.format('MMMM YYYY'))
      const indexMonth = moment(currentDate).month()
      getDate(indexMonth)
    }
  }, [currentDate])

  useEffect(() => {
    setCurrentDate(moment());
    setToday(moment().format('L'));
    setYear(new Date().getFullYear());
  }, [])

  useEffect(() => {
    const updatedAppointments = appointments.map((apt) => {
      if (apt && apt.rdv_at) {
        const date = moment(apt.rdv_at).format('L');
        const id = apt.id;
        return { date: date, id: id };
      }
      return null;
    }).filter((apt) => apt !== null);

    setAllAppointments(updatedAppointments);
  }, [appointments])

  const isExtraDays = (week, date) => {
    if (week === 0 && date > 10) {
      return true;
    } else if (week === 5 && date < 10) {
      return true;
    } else if (week === 4 && date < 10) {
      return true;
    } else {
      return false;
    }
  };

  const renderCalendarCurrentMonth = () => {

    if (calendar && calendar.length > 0) {
      return calendar.map((week, index) => (
        <tr className="calender-row" key={index}>
          {week.map((day) => {
            const hasAppointment = allAppointments.some((appointment) => appointment.date === day.date);
            const dayValueClass = hasAppointment ? "day-value has-appointment" : "day-value";

            return (
              <td className="calender-col" key={day.date}>
                <span className={day.date === today ? `${dayValueClass} active` : dayValueClass} id={day.date} onClick={hasAppointment ? () => viewAppointment(day.date) : null}>
                  {isExtraDays(index, day.value) ? <span className="isDates-grey">{day.value}</span> : day.value}
                </span>
              </td>
            );
          })}
        </tr>
      ));
    }
  }

  return (
    <div className="agenda-calendar">
      <div className="agenda-calendar-header">
        <div onClick={() => setCurrentDate(moment(currentDate).subtract(1, 'months'))}><Icon icon={"back"} color={"accent"} /></div>
        <h2 className="month-name">
          {typeof currentDateFormated === 'string'
            ? currentDateFormated.charAt(0).toUpperCase() + currentDateFormated.slice(1)
            : ''}
        </h2>

        <div onClick={() => setCurrentDate(moment(currentDate).add(1, 'months'))}><Icon icon={"next"} color={"accent"} /></div>
      </div>
      <table celled="true" padded="true" className="agenda-calendar-date">
        <tbody>
          <tr className="day-name">
            {days.map((day, index) =>
              <th key={index}>{day}</th>
            )}
          </tr>

          {renderCalendarCurrentMonth()}
        </tbody>
      </table>
    </div>
  )
}
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import 'moment/locale/fr';
import "./AppointmentRessource.scss";
import { getAppointmentById } from "../../../../../api/AppointmentsApi";
import { getConsultantInfos } from "../../../../../api/ConsultantApi";
import { useSelector } from "react-redux";
import { selectors as UserSelectors } from '../../../../../redux/UserRedux';

export const AppointmentRessource = ({ressource, isExport = false}) => {
    const user = useSelector(UserSelectors.user);
    const [appointment, setAppointment] = useState()
    const [consultant, setConsultant] = useState({first_name: "", last_name: ""})
    useEffect(() => {
        if(ressource && ressource.appointment_id) {

            getAppointmentById(ressource.appointment_id).then((response) => {
                setAppointment(response.appointment)
                getConsultantInfos(response.appointment.user_from).then((response) => {
                    setConsultant({first_name: response.consultant.user.first_name, last_name: response.consultant.user.last_name})
                })
            })
        }
        else {
            setAppointment(null)
        } 
    }, [ressource])
    const renderStateAppoitment = () => {
        if((ressource.rdv_to === null) || (ressource.rdv_at === null) || (!appointment)) {
            return (
                <p className={classNames('waiting-appointment')}>En attente d'un horaire </p> 
            )
        }

        else if(appointment.rdv_to && appointment.rdv_at) {
            const date = moment(appointment.rdv_at).format('dddd DD MMMM YYYY');
            const rdv_at = moment(appointment.rdv_at).format('HH:mm');
            const rdv_to = moment(appointment.rdv_to).format('HH:mm');

            return (
                <p>Le {date} de {rdv_at} - {rdv_to} </p>
            )
        } 
    }

    const renderExportNote = () => {
        if(appointment && appointment.public_notes) {
            return (
                <>
                    <p><b>Description: </b>{appointment.public_notes}</p>
                    {user.role !== 'beneficiary' && <p><b>Note Privée: </b>{appointment.private_notes}</p>}
                    
                </>

            )
        }
    }

    return (
        <div className="appointment-ressource">
            {ressource?.title &&
            <h2>{ressource.title}</h2>
            }
            
            {ressource  && renderStateAppoitment()}
            {(ressource && isExport) && renderExportNote()}

        </div>
    )
}
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import ConsultantBeneficiary from '../../containers/consultant/ConsultantBeneficiary/ConsultantBeneficiary'
import CourseList from '../../containers/course/CourseList/CourseList'
import Page from '../../containers/global/Page/Page'
import AttributeCoursePopin from '../../containers/popins/AttributeCoursePopin/AttributeCoursePopin'
import { actions as BeneficiaryActions, selectors as BeneficiarySelectors } from '../../redux/BeneficiaryRedux'
import { actions as NavigationActions, selectors as NavigationSelectors } from '../../redux/NavigationRedux'
import { selectors as UserSelectors } from '../../redux/UserRedux'
import { getCourses, deleteCourseById } from '../../api/CourseApi';
import LoadingSpinner from "../../components/atoms/LoadingSpinner/LoadingSpinner";
import { useToaster } from '../../hooks/useToaster'
import CourseRemove from '../../containers/course/CourseRemove/CourseRemove'



import "./ConsultantBeneficiaryCoursesPage.scss"

const mapStateToProps = (state, props) => ({
  id: props.match.params.user_id,
  pathname: NavigationSelectors.pathname(state),
  user: UserSelectors.user(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
})

const mapDispatchToProps = (dispatch) => ({
  getBeneficiary: (id) => dispatch(BeneficiaryActions.getBeneficiary(id)),
  back: () => dispatch(NavigationActions.back()),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  // resetCourses: () => dispatch(CourseActions.resetCourses()),
})

const ConsultantBeneficiaryCoursesPage = (props) => {
  const [attribOpen, setAttribOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeletingCourse, setIsDeletingCourse] = useState(false);
  const { toasters, addToaster, removeToaster } = useToaster();
  const [deleteCourseId, setDeleteCourseId] = useState(null);
  



  const handleAttribPopin = () => {
    setAttribOpen(open => !open);
  }

  const handleCardClick = ({ id }) => {
    props.redirect(`/beneficiary/courses/${id}`)
  }

  const handleOnCloseRemoveCourse = () => {
    setIsDeletingCourse(false);
  }

  useEffect(() => {
    if (!/\/(profile|courses)$/.test(props.pathname)) {
      props.redirect(`${props.pathname}/courses`)
    }
    props.getBeneficiary(props.id);
    setIsLoading(true);
    getCourses(props.id).then((response) => {
      setCourses(response.courses)
    }).finally(() => {
      setIsLoading(false);
    })
  }, [])


  const getNewCourse = (newCourse) => {
    if(courses) {
      setCourses([...courses, newCourse])

    } 
    else {
      setCourses([newCourse])
    }
  };

  const getCourseDeleted = (deleteCourse_id) => {
    setDeleteCourseId(deleteCourse_id)
    setIsDeletingCourse(true);
  };

  const handleRemove = async () => {
    try {

      await deleteCourseById(deleteCourseId);
      const updatedCourses = courses.filter(course => course.id !== deleteCourseId);
      setCourses(updatedCourses);
      setIsDeletingCourse(false);
      addToaster("Suppresion du parcours", "Le parcours a été supprimé avec succès", "success");
      setDeleteCourseId(null)
    } catch (error) {
      addToaster("Suppresion du parcours", "Une erreur est survenue lors de la suppression du cours", "error");
      setDeleteCourseId(null)

    }
  }

  const renderAttribPopin = () => {
    if (!attribOpen) {
      return null
    }

    return <AttributeCoursePopin open onClose={handleAttribPopin} newCourse={getNewCourse}/>
  }

  const renderContent = () => {
    const { user, beneficiary, isLoading } = props
    const { consultants = [] } = beneficiary

    const me = consultants.find((consultant) => consultant.id === user.id)
    const isPrimaryConsultant = me ? me.is_primary : false
    return (
      <>
        { (
          <div className="components">
            <CircleButton icon="plus" onClick={handleAttribPopin} />
          </div>
        )}

        {isLoading ? <LoadingSpinner/> : <CourseList courses={courses} onCardClick={handleCardClick} deleteCourse={getCourseDeleted}/>}
        
        {renderAttribPopin()}
      </>
    )
  }

  return (
    <>
    {props.beneficiary && 
    <ConsultantBeneficiary>
      <div className="consultant-beneficiary-courses-page">
        {isDeletingCourse && <CourseRemove open={isDeletingCourse} onClose={handleOnCloseRemoveCourse} handleRemove={handleRemove} />}

        {renderContent()}
        </div>
    </ConsultantBeneficiary>
    }
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(ConsultantBeneficiaryCoursesPage))
import Icon from '../Icon/Icon';
import React from 'react';
import './BackgroundIcon.scss'

export const BackgroundIcon = ({ icon, color }) => {
    // const colorBg = `var(--${color}-lighter)`;
    let colorBg;
    let colorIcon;
    switch(color) {
        case "main-color":
            colorBg = `var(--${color}-lighter)`;
            colorIcon = 'accent'
            break
        case "green":
            colorBg = `var(--${color}-light)`;
            colorIcon = color
            break
        default:
            colorBg = `var(--main-color-lighter)`;
            colorIcon = 'accent'
            break;
    }
    return (
        <div className='background-icon' style={{backgroundColor: colorBg}}>
            <Icon icon={icon} color={colorIcon}/>
        </div>
    )
}
import React, { useState } from 'react'
import { Modal } from '../../../components/atoms/Modal/Modal';
import InputField from "../../../components/molecules/InputField/InputField";

export const AddModulePopin = ({onClose, open}) => {
  const [title, setTitle] = useState('');
  // methods
  const handleSaveModule = () => {}

  const handleTitleOnChange = (title) => {
    const { value } = title;
    setTitle(value)
  }
 
  // render components
  const renderContent = () => {
    return (
      <div className='app-module-popin-content'>
        <InputField 
          title={"Titre du module"} 
          placeholder={"Ajouter un titre..."} 
          name={"title"}
          value={title}
          required
          onChange={handleTitleOnChange}
          />
      </div>
    )
  }

  return (
    <Modal
    onClosePopin={onClose}
    openPopin={open}
    title={"Ajouter un nouveau module"}
    content={renderContent()}
    cancelButton={"Annuler"}
    actionButton={"Créer"}
    handleActionButton={handleSaveModule}
    disabledActionButton={false}
    />
  )
}
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '../../../components/atoms/Icon/Icon'
import LogoutButton from '../../../components/atoms/LogoutButton/LogoutButton'
import SideMenu from '../../../components/organisms/SideMenu/SideMenu'

import { inFollowUp, inProgress } from '../../../helpers/beneficiaryStatus'
import UserLeftColumnLayout from '../../../layouts/UserLeftColumnLayout/UserLeftColumnLayout'
import { actions as MessageActions } from '../../../redux/MessageRedux'
import { actions as OfficeActions, selectors as OfficeSelectors } from '../../../redux/OfficeRedux'
import { actions as UserActions, selectors as UserSelectors } from '../../../redux/UserRedux'
import { actions as BeneficiaryActions, selectors as BeneficiarySelectors, } from '../../../redux/BeneficiaryRedux'
import { useNavigation } from '../../../hooks/useNavigation'
// import { selectors as portalSelectors } from '../../../redux/PortalRedux';

import { useTakeEvery } from "../../../hooks/useSaga";

import './BeneficiaryPageAside.scss'

const BeneficiaryPageAside = () => {
  const dispatch = useDispatch()
  const { pathname, redirect } = useNavigation()
  // const [tutorial, setTutorial] = useState(false)
  const office = useSelector(OfficeSelectors.office)
  const user = useSelector(UserSelectors.user)
  // const unreadMessages = useSelector(MessageSelectors.unreadMessages)
  // const loadingCvDesignrLink = useSelector(BeneficiarySelectors.loadingCvDesignrLink)
  // const isPortalActive = useSelector(portalSelectors.isActive)

  const handleMenuChange = ({ id }) => {
    redirect(id)
  }

  useTakeEvery(BeneficiaryActions.setCvDesignrExternalLink.getType(), useCallback((action) => {
    action.payload.cvDesignrExternalLink.length && window.open(action.payload.cvDesignrExternalLink)
  }, []))

  // const handleOpenToCvDesignr = (type) => {
  //   if (loadingCvDesignrLink) {
  //     return false
  //   }

  //   dispatch(BeneficiaryActions.getCvDesignrLink(type))

  //   return false
  // }


  const getLinks = () => {
    const isInProgress = inProgress(user)
    const isInFollowUp = inFollowUp(user)

    let links = []

    if (isInProgress || isInFollowUp) {
      links.push({
        id: '/beneficiary/courses',
        label: "Parcours",
        icon: Icon.icon.Paperboard,
      })
    }

    if (isInProgress) {
      links.push({
        id: '/beneficiary/calendar',
        label: "Agenda",
        icon: Icon.icon.Calendar,
      })
    }

    links.push({
      id: '/beneficiary/job',
      label: "Emploi",
      icon: Icon.icon.Export,
    })

    links = [
      ...links,
      /*
      {
        id: '/beneficiary/messages',
        label: "Message",
        icon: Icon.icon.Inbox,
        count: unreadMessages,
      },
      {
        id: '/beneficiary/profile',
        label: "Profile",
        icon: Icon.icon.Settings,
      },
      */
    ]

    // if (isInProgress || isInFollowUp) {
    //   links.push({
    //     id: 'cvdesignr_link_documents',
    //     label: "Mon CV",
    //     icon: Icon.icon.Export,
    //     onClick: () => handleOpenToCvDesignr('cv'),
    //   })
    // }

    return links
  }

  const logout = () => {
    dispatch(UserActions.logout())
  }

  useEffect(() => {
    dispatch(OfficeActions.getCurrentUserOffice())
    dispatch(MessageActions.getMessages({ type: 'inbox', state: null, q: '' }))
  }, [dispatch])

  const renderHeader = () => {
    if (!office || !user) {
      return null
    }

    return (
      <>
        <div className="logos">
          {/* {!isPortalActive && (
            <>
              <img
                className="mytalents-logo"
                alt="Logo"
                src="/static/logos/logo-my-talents-dark.svg"
                width={60}
                height={"auto"}
              />
              <div className="separator" />
            </>
          )} */}
          <img className="office-logo" src={`${process.env.REACT_APP_ASSETS_API_STATIC_URL}${(office.image_url).replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '')}`} alt={office.name}/>
        </div>
      </>
    )
  }

  const renderMenu = () => {
    const links = getLinks()

    return <SideMenu links={links} pathname={pathname} onClick={handleMenuChange} /> 
  }

  const renderFooter = () => {
    // return <LogoutButton label={"Déconnexion"} onClick={logout} />
    return <div className="footer">
    <div className="tutorials">
      <Icon icon="help" color="grey2"/>
      <a href="https://www.youtube.com/playlist?list=PL7A0hnS_VPcdN292o0BYXsMrloSiHMS82" target="_blank">
        Tutoriels
      </a>
    </div>
    <LogoutButton label={"Déconnexion"} onClick={logout} />
  </div>

    
  }

  if (!user) {
    return null
  }

  return (
    <div className="beneficiary-page-aside">
      <UserLeftColumnLayout header={renderHeader()} menu={renderMenu()} footer={renderFooter()} />
    </div>
  )
}

export default BeneficiaryPageAside
const ModuleTypes = {
    document: "Document",
    document_to_return: "Document à retourner",
    feedback: "Feedback",
    synthesis: "Synthèse"
};

const DocumentSubTypes = {
    file: "Document",
    video: "Vidéo",
    link: "Lien"
};

export { ModuleTypes, DocumentSubTypes };
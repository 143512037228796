import React from "react";
import { Modal } from "../../../components/atoms/Modal/Modal";

import moment from 'moment';
import 'moment/locale/fr';

export const DeclineAppointmentPopin = ({ onClose, open, appointment, handleDeclineAppointment }) => {

    const renderContent = () => {
        return (
            <div className="decline-appointment-popin-content">
                <p>Êtes-vous sûr(e) de vouloir refuser le rendez-vous du {moment(appointment.rdv_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')} à {moment(appointment.rdv_at, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')} ?</p>
                <p>Votre consultant sera informé de votre indisponibilité à assister au rendez - vous.</p>
            </div>
        )
    }

    if (appointment) {
        return (
            <Modal
                onClosePopin={onClose}
                openPopin={open}
                title={"Refuser le rendez - vous"}
                content={renderContent()}
                cancelButton={"Retour"}
                actionButton={"Refuser le rendez - vous"}
                handleActionButton={handleDeclineAppointment}
            />
        )
    }
}
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _debounce from 'lodash/debounce'
// import SearchField from '../../../components/molecules/SearchField/SearchField'

export default class TopSearch extends PureComponent {
  static propTypes = {
    onSearch: PropTypes.func.isRequired,
    value: PropTypes.string,
    debounceDuration: PropTypes.number,
  }

  static defaultProps = {
    value: null,
    debounceDuration: 300,
  }

  state = {
    value: '',
  }

  search = _debounce(({ value }) => {
    this.props.onSearch(value)
  }, this.props.debounceDuration)

  handleChange = ({ value }) => {
    this.setState({ value })
    this.search({ value })
  }

  render() {
    let { value } = this.props

    if (value === null) {
      value = this.state.value
    }

    /*
    return (
      <div className="top-search">
        <SearchField onChange={this.handleChange} showLine={false} value={value} />
      </div>
    )
    */

    return (
      <></>
    )
  }
}

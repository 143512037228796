import { call, put, select } from 'redux-saga/effects'
import { actions as UserActions, selectors as UserSelectors } from '../redux/UserRedux'
import { services } from '../services'
import { actions as NotificationActions } from '../redux/NotificationRedux'
import bugsnagClient from '../helpers/bugsnagClient'
import Cookies from 'js-cookie'
import config from '../config/AppConfig'

function saveTokenToBrowser(token) {
  Cookies.set(config.COOKIE_NAME, JSON.stringify(token), {
    domain: config.COOKIE_HOSTNAME,
    expires: 1, // In a day
  })

  localStorage.setItem('mytalents-evolution.token.preprod', JSON.stringify(token))
}

export function* setToken() {
  const token = yield select(UserSelectors.token)

  token
    ? saveTokenToBrowser(token)
    : Cookies.remove(config.COOKIE_NAME, {
        domain: config.COOKIE_HOSTNAME,
      })

  yield put(UserActions.autoRefreshToken())
}

export function* getToken() {
  const cookie = Cookies.get(config.COOKIE_NAME)

  const token = cookie ? JSON.parse(cookie) : null

  yield put(UserActions.setToken(token))
}

export function* getCurrentUser() {
  const token = yield select(UserSelectors.token)

  if (token) {
    yield call(services.user.getMe, null, function* ({ ok, data }) {
      ok ? yield setCurrentUser(data) : yield put(NotificationActions.status({ ok, data }))
    })
  } else {
    yield setCurrentUser(null)
  }
}

export function* setCurrentUser(user) {
  const sessionUser = user ? { id: user.id, role: user.role } : null

  bugsnagClient.setUser(sessionUser)

  yield put(UserActions.setUser(user))
}

export function* exportAll() {
  yield call(services.user.exportAll, null, function* ({ ok, data }) {
    if (!ok) {
      return yield put(NotificationActions.status({ ok, data }))
    }

    const anchor = document.createElement('a')
    const url = window.URL.createObjectURL(new Blob([data], { type: 'text/csv' }))

    anchor.href = url
    anchor.download = 'MyTalents - Users.csv'
    anchor.click()
    window.URL.revokeObjectURL(url)
  })
}

import React from 'react';
import { useSelector } from 'react-redux';
import { selectors as UserSelectors } from '../../../redux/UserRedux';
import Icon from '../../../components/atoms/Icon/Icon';
import { NewTag } from '../../../components/atoms/NewTag/NewTag';

import { useNavigation } from '../../../hooks/useNavigation';
import moment from 'moment';
import 'moment/locale/fr';

import './MissionsList.scss';

export const MissionsList = ({
  missions,
  isClickable,
  showClient,
  showTalents,
  isDeletable,
  handleDeleteMission,
}) => {
  const user = useSelector(UserSelectors.user);
  const { pathname, redirect } = useNavigation();
  return (
    <div className="missions-list-wrapper">
      <table className="missions-list">
        <thead>
          <tr>
            <th>Nom de la mission</th>
            {showClient && <th>Client</th>}
            <th>Responsable de mission</th>
            <th>Consultants</th>
            <th>Date de création</th>
            <th>Reporting</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {missions
            .filter(
              (
                item,
                index,
                self, //TODO: Check if BUG Mission List
              ) => index === self.findIndex((t) => t.id === item.id),
            )
            .map((mission, index) => {
              const handleClick = isClickable
                ? () => redirect(`${pathname}/${mission.id}`)
                : undefined;

              const isDownloadable = mission.talents && mission.talents.length > 0;

              return (
                <tr key={index} className={isClickable ? 'row-clickable' : ''}>
                  <td className="mission-name" onClick={handleClick}>
                    {mission.name}
                  </td>
                  {showClient && <td onClick={handleClick}>{mission.client_name}</td>}
                  <td onClick={handleClick}>
                    {mission.responsable &&
                      mission.responsable.map((r, index) => (
                        <p key={index}>
                          {r.first_name} {r.last_name}
                        </p>
                      ))}
                  </td>
                  <td onClick={handleClick} className="mission-consultant">
                    {mission.consultants &&
                      mission.consultants.map((consultant, index) => (
                        <NewTag
                          title={`${consultant.first_name} ${consultant.last_name}`}
                          key={index}
                          color={'orange'}
                        />
                      ))}
                  </td>
                  <td onClick={handleClick}>{moment(mission.created_at).format('DD/MM/YYYY')}</td>

                  {isDownloadable ? (
                    <td className="">
                      <div className="actions-reporting dowloadable">
                        <Icon icon="export" color={'grey3'} />
                        <a
                          download
                          href={`${process.env.REACT_APP_API_MY_TALENTS_EVOLUTION}/missions/${mission.id}/download/excel`}
                        >
                          Télécharger
                        </a>
                      </div>
                    </td>
                  ) : (
                    <td >
                      <div className="actions-reporting no-downloadable">
                        <Icon icon="export" color={'grey2'} />
                        <p>Télécharger</p>
                      </div>
                    </td>
                  )}
                  <td>
                    {isDeletable &&
                      mission.responsable &&
                      mission.responsable[0].id === user.id && (
                        <div className="actions">
                          <div
                            className="div-icon"
                            onClick={() => redirect(`${pathname}/${mission.id}`)}
                          >
                            <Icon icon="pencil" color={'grey3'} />
                          </div>
                          <div className="div-icon" onClick={() => handleDeleteMission(mission)}>
                            <Icon icon="trash2" color={'grey3'} />
                          </div>
                        </div>
                      )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { ParcoursProgressBar } from '../../../components/molecules/ParcoursProgressBar/ParcoursProgressBar';
import { ParcoursInfos } from '../../../components/molecules/ParcoursInfos/ParcoursInfos';
import { ParcoursAllSteps } from '../../../components/molecules/ParcoursAllSteps/ParcoursAllStep';
import { IconBack } from '../../../components/atoms/IconBack/IconBack';
import './ParcoursLeftContainer.scss';
import IconChevron from '../../../components/atoms/IconChevron/IconChevron';
import { round } from 'lodash';
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton';
import { getUser } from '../../../api/userAPI';
import { selectors as OfficeSelectors } from '../../../redux/OfficeRedux'
import { useSelector } from 'react-redux'



export const ParcoursLeftContainer = ({
  parcours,
  stepActive,
  getActiveStep,
  user,
  isExport = false,
  exportFunction = () => {},
  // resetCourse,
}) => {
  const office = useSelector(OfficeSelectors.office)
  const [pourcentDone, setPourcentDone] = useState();
  const [isOpen, setIsOpen] = useState(true);
  const [course, setCourse] = useState();
  const [talent, setTalent] = useState();
  // const [updatedStep, setUpdatedStep] = useState();
  const urlBack =
    user.role === 'beneficiary'
      ? '/beneficiary/courses'
      : course && course.user_id
      ? `/consultant/beneficiaries/${course.user_id}/courses`
      : '/consultant/templates/courses';

  const getStepActive = (step) => {
    stepActive(step);
    // setUpdatedStep(step)
  };

  const toggleOpen = () => {
    setIsOpen((data) => !data);
  };

  useEffect(() => {
    parcours && setCourse(parcours);
  }, [parcours]);

  useEffect(() => {
    if (course && course.steps) {
      const talentTmp = getUser(course.user_id).then((res) => {setTalent(res.user);});
      const modulesCount = course.steps.reduce((total, step) => {
        const modulesContainer = step.modules_containers;
        const modulesCount = modulesContainer.reduce((totalModules, moduleContainer) => {
          const modules = moduleContainer.modules;
          return totalModules + modules.length;
        }, 0);
        return total + modulesCount;
      }, 0);

      const modulesDone = course.steps.reduce((total, step) => {
        const modulesContainer = step.modules_containers;
        const modulesDone = modulesContainer.reduce((totalModules, moduleContainer) => {
          const modules = moduleContainer.modules;
          const modulesDone = modules.filter((module) => module.done === 3).length;
          return totalModules + modulesDone;
        }, 0);
        return total + modulesDone;
      }, 0);

      const modulesDonePercentage = round((modulesDone / modulesCount) * 100);

      setPourcentDone(modulesDonePercentage);
    }
  }, [course]);
  return (
    <>
      {course && (
        <div
          className="beneficiary-parcours-left-container"
          style={{ width: isExport ? '100%' : '', padding: isExport ? '36px' : '0' }}
        >
          <div className="header" style={{}}>
            {!isExport && (
              <IconBack
                isText={true}
                iconColor={'grey1'}
                data={{ pathname: `${urlBack}`, state: null }}
              />
            )}

            {!isExport && (
              <div style={{ width: 'fit-container' }}>
                <PrimaryButton label={'Exporter'} onClick={exportFunction} id="export-button"  />
              </div>
            )}

            {isExport && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img className='office-logo' style={{ width: '20%' }} src={`${process.env.REACT_APP_ASSETS_API_STATIC_URL}${(office?.image_url).replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '')}`} alt={office.name} /></div>}

            {isExport && <h2>{talent.first_name} {talent.last_name}</h2>}

            <h1 className="parcours-title-desktop">{course.title}</h1>
            <div className="parcours-title-mobile">
              <h1>{course.title}</h1>
              <IconChevron sendIsOpen={toggleOpen} />
            </div>
            {isOpen && (
              <>
                {/* <PrimaryButton
                  label={'Reinitialiser'}
                  onClick={resetCourse}
                  id="export-button"
                /> */}
                <div className="parcours-informations">
                  <ParcoursInfos
                    icon={'clipboard'}
                    label={'Etapes'}
                    count={course.steps?.length}
                    colorBackground={'white'}
                  />
                  <ParcoursInfos
                    icon={'grid'}
                    label={'Modules'}
                    count={course.steps
                      ?.map((step) => step.modules_containers.length)
                      .reduce((acc, curr) => acc + curr, 0)}
                    colorBackground={'white'}
                  />
                  {/* <ParcoursInfos icon={"clock"} label={"Durée"} count={"12h"} colorBackground={'white'} /> */}
                </div>

                <ParcoursProgressBar pourcent={pourcentDone} />
                {course.description && <p className="parcours-description">{course.description}</p>}
              </>
            )}
          </div>

          <div className="steps" style={{}}>
            <ParcoursAllSteps
              steps={course.steps}
              stepActive={getStepActive}
              step={getActiveStep}
            />
          </div>
        </div>
      )}
    </>
  );
};

import React from 'react'
import Icon from '../../atoms/Icon/Icon'
import './ComboFilterField.scss';

const ComboFilterField = ({ title, value, id, options, onChange }) => {
  const handleChange = (e) => {
    onChange({
      value: e.target.value,
      id: e.target.id
    })
  }

  return (
    <div className="combo-filter-field">
      <div className="title">{title}</div>
      <div className="select-container">
        <select value={value} onChange={handleChange} id={id}>
          {
            options.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))
          }
        </select>
        <Icon icon={Icon.icon.ChevronDown} color={Icon.color.Accent} />
      </div>
    </div>
  )
}

export default ComboFilterField;
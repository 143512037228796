import React from "react";
import './ParcoursProgressBar.scss';

export const ParcoursProgressBar = ({ pourcent }) => {
    return (
        <>
        {pourcent  ? <div className='pourcentage-done-course'>
            <span>Avancement : <p> {pourcent} %</p></span>
            <div className='progression-bar'>
                <div className='progression-done-bar' style={{ width: `${pourcent}%` }}></div>
            </div>
        </div> : <></> }
        
        </>
    )
}
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

 
import { actions as CourseTemplateActions } from '../../../redux/CourseTemplateRedux'
import { actions as WSActions, selectors as WSSelectors } from '../../../redux/WSRedux'
import { selectors as NavigationSelectors } from '../../../redux/NavigationRedux'

import { Popin } from '../../global/Popin/Popin'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'

const mapStateToProps = (state) => ({
  id: Number(NavigationSelectors.pathname(state).replace(/.+\/(\d+)\/remove$/, '$1')),
  pathname: NavigationSelectors.pathname(state),
  removing: WSSelectors.pending(state, 'courseTemplate.remove'),
  removed: WSSelectors.resolved(state, 'courseTemplate.remove'),
})

const mapDispatchToProps = (dispatch) => ({
  remove: (id) => dispatch(CourseTemplateActions.removeTemplate(id)),
  resetRemoveAction: () => dispatch(WSActions.reset('courseTemplate.remove')),
})

class CourseTemplateRemove extends PureComponent {
  static propTypes = {
    id: PropTypes.number,
    onClose: PropTypes.func.isRequired,
  }

  handleRemove = () => {
    this.props.remove(this.props.id)
  }

  componentDidUpdate() {
    if (this.props.removed) {
      this.props.onClose()
      this.props.resetRemoveAction()
    }
  }

  render() {
    const { onClose, removing } = this.props

    return (
      <Popin onClose={onClose} open>
        <AlertPopin
          disabled={removing}
          label={"Supprimer un modèle"}
          text={"Voulez-vous vraiment supprimer ce modèle ?"}
          labelCancelButton={"Annuler"}
          labelConfirmButton={"Supprimer"}
          onCancelButtonClick={onClose}
          onConfirmButtonClick={this.handleRemove}
        />
      </Popin>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseTemplateRemove)
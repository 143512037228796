import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment/moment'
import Page from '../../containers/global/Page/Page'
import Icon from '../../components/atoms/Icon/Icon'
import ActionPopinHeader from '../../components/molecules/ActionPopinHeader/ActionPopinHeader'
import PopinLayout from '../../layouts/PopinLayout/PopinLayout'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import { actions as ModuleActions } from '../../redux/ModuleRedux'
import QuizCombo from '../../components/molecules/QuizCombo/QuizCombo'
import QuizGroup from '../../components/molecules/QuizGroup/QuizGroup'
import QuizQcm from '../../components/molecules/QuizQcm/QuizQcm'
import QuizStars from '../../components/molecules/QuizStars/QuizStars'
import QuizTextArea from '../../components/molecules/QuizTextArea/QuizTextArea'
import QuizYesNo from '../../components/molecules/QuizYesNo/QuizYesNo'
import QuizOpinionScale from '../../components/molecules/QuizOpinionScale/QuizOpinionScale'
import QuizDate from '../../components/molecules/QuizDate/QuizDate'
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton'
import { get as getModule } from '../../services/ModuleService'
import { selectors as UserSelectors } from '../../redux/UserRedux'

import { history } from '../../navigation/History'
import './BeneficiaryFormModulePage.scss'

const mapStateToProps = (state, props) => ({
  formId: Number(props?.match?.params?.form_id || props.form_id) || null,
  pathname: NavigationSelectors.pathname(state),
  token: UserSelectors.token(state),
})

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  getModule: (module_id) => dispatch(ModuleActions.getModule(module_id)),
  setModule: (module) => dispatch(ModuleActions.setModule(module)),
  saveModuleFormAnswers: (module_id, answers) =>
    dispatch(ModuleActions.saveModuleFormAnswers({ module_id, answers })),
})
class BeneficiaryFormModulePage extends Component {
  static propTypes = {
    form_id: PropTypes.any,
  }

  handleBack = () => {
    const { pathname, redirect, setModule } = this.props
    setModule(null)
    redirect(pathname.replace(/\/forms\/\w+$/, ''))
  }

  renderHeader = () => {
    const { form } = this.state
    const { title } = form

    return (
      <ActionPopinHeader
        iconButton={Icon.icon.Back}
        boldTitle={`${title}`}
        onIconClick={this.handleBack}
      />
    )
  }

  handleAnswer = (answer) => {
    this.setState({
      results: {
        ...this.state.results,
        [answer.id]: answer.value,
      },
      answersChanged: true,
    })
  }

  renderQuestion = (key, question, prefix_id = '') => {
    const { form } = this.state

    switch (question.type) {
      case 'text': {
        return (
          <QuizTextArea
            key={key}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            name={`${prefix_id}${question.id}`}
            value={
              this.state.results[`${prefix_id}${question.id}`]
                ? this.state.results[`${prefix_id}${question.id}`]
                : ''
            }
            onChange={this.handleAnswer}
            placeholder={"Rentrez votre réponse ici..."}
            maxLength={
              question.options.max_length != null && question.options.max_length
                ? parseInt(question.options.max_length, 10)
                : 0
            }
            disabled={form !== null && form.done}
          />
        )
      }
      case 'select': {
        const comboFieldProps = {
          name: `${prefix_id}${question.id}`,
          options: question.options.choices.map((opt) => ({ value: opt, label: opt })),
          onChange: this.handleAnswer,
          value: this.state.results[`${prefix_id}${question.id}`]
            ? this.state.results[`${prefix_id}${question.id}`]
            : question.options.required != null && question.options.required
            ? question.options.choices[0]
            : '',
          placeholder: "Sélectionnez une réponse",
          clearable: question.options.required == null || !question.options.required,
          readOnly: form !== null && form.done,
        }

        return (
          <QuizCombo
            key={key}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            comboFieldProps={comboFieldProps}
          />
        )
      }
      case 'multiple_choice': {
        const prefix_question = `${prefix_id}${question.id}`

        const choices = question.options.choices.map((choice) => ({
          label: choice,
          isSelected:
            this.state.results[prefix_question] && this.state.results[prefix_question][choice]
              ? this.state.results[prefix_question][choice]
              : false,
        }))

        if (question.options.allow_other != null && question.options.allow_other) {
          const isSelected =
            this.state.results &&
            this.state.results[prefix_question] &&
            this.state.results[prefix_question]["Question à choix multiples"]
          choices.push({
            label:"Question à choix multiples",
            isSelected: isSelected
              ? this.state.results[prefix_question]["Question à choix multiples"]
              : false,
          })
        }

        const handleMultipleChoiceChange = (value) => {
          const newState = choices.reduce(
            (res, choice) => ({
              ...res,
              [choice.label]: value.id === choice.label ? value.value : choice.isSelected,
            }),
            {},
          )

          this.handleAnswer({ id: `${prefix_id}${question.id}`, value: newState })
        }

        return (
          <QuizQcm
            key={key}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            options={choices}
            onChange={handleMultipleChoiceChange}
            disabled={form !== null && form.done}
          />
        )
      }
      case 'group': {
        return (
          <QuizGroup
            key={key}
            label={question.title}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
          >
            {question.questions
              .sort((q1, q2) => q1.order - q2.order)
              .map((itemQuestion, key) =>
                this.renderQuestion(key + 1, itemQuestion, `${question.id}_`),
              )}
          </QuizGroup>
        )
      }
      case 'yes_no': {
        return (
          <QuizYesNo
            key={key}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            id={`${prefix_id}${question.id}`}
            value={
              this.state.results[`${prefix_id}${question.id}`] !== undefined
                ? this.state.results[`${prefix_id}${question.id}`]
                : null
            }
            yesLabel={"Oui"}
            noLabel={"Non"}
            onChange={this.handleAnswer}
            disabled={form !== null && form.done}
          />
        )
      }
      case 'rating': {
        return (
          <QuizStars
            key={key}
            id={`${prefix_id}${question.id}`}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            starsCount={5}
            selectedStars={
              this.state.results[`${prefix_id}${question.id}`]
                ? this.state.results[`${prefix_id}${question.id}`]
                : 0
            }
            onChange={this.handleAnswer}
            disabled={form !== null && form.done}
          />
        )
      }
      case 'opinion_scale': {
        return (
          <QuizOpinionScale
            key={key}
            id={`${prefix_id}${question.id}`}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            scale={question.options.scale ? question.options.scale : 10}
            value={
              this.state.results[`${prefix_id}${question.id}`]
                ? this.state.results[`${prefix_id}${question.id}`]
                : -1
            }
            onChange={this.handleAnswer}
            disabled={form !== null && form.done}
          />
        )
      }
      case 'date': {
        return (
          <QuizDate
            key={key}
            id={`${prefix_id}${question.id}`}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            monthLabels={["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]}
            dayTitle={"Jour"}
            monthTitle={"Mois"}
            yearTitle={"Année"}
            value={
              this.state.results[`${prefix_id}${question.id}`]
                ? this.state.results[`${prefix_id}${question.id}`]
                : ''
            }
            onChange={this.handleAnswer}
            disabled={form !== null && form.done}
          />
        )
      }
      default: {
        return null
      }
    }
  }

  handleSaveAnswer = () => {
    const { form, results } = this.state

    const { data } = form
    const { questions } = data

    const newQuestions = questions.map((question) => {
      if (question.type === 'group') {
        const newItemsQuestions = question.questions.map((itemQ) => {
          let answer = results[`${question.id}_${itemQ.id}`]
            ? results[`${question.id}_${itemQ.id}`]
            : ''
          if (itemQ.type === 'multiple_choice') {
            answer = Object.keys(answer).filter((choice) => answer[choice] === true)
          }
          if (itemQ.type === 'yes_no') {
            answer =
              results[`${question.id}_${itemQ.id}`] !== undefined
                ? results[`${question.id}_${itemQ.id}`]
                : null
          }
          if (answer !== '') {
            const questionType = itemQ.type
            if (questionType === 'rating' || questionType === 'opinion_scale') {
              const answerNumber = parseInt(answer, 10)
              answer = isNaN(answerNumber) ? '' : answerNumber
            }
            if (questionType === 'date') {
              answer = /^\d+\//.test(answer)
                ? moment(answer, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : moment(answer).format('YYYY-MM-DD')
            }
          }

          return {
            id: itemQ.id,
            type: itemQ.type,
            answer: answer === '' ? null : answer,
          }
        })

        return {
          id: question.id,
          type: question.type,
          questions: newItemsQuestions,
        }
      }

      let answer = results[`${question.id}`] ? results[`${question.id}`] : ''
      if (question.type === 'multiple_choice') {
        answer = Object.keys(answer).filter((choice) => answer[choice] === true)
      }
      if (question.type === 'yes_no') {
        answer = results[`${question.id}`] !== undefined ? results[`${question.id}`] : null
      }
      if (answer !== '') {
        const questionType = question.type
        if (questionType === 'rating' || questionType === 'opinion_scale') {
          const answerNumber = parseInt(answer, 10)
          answer = isNaN(answerNumber) ? '' : answerNumber
        }
        if (questionType === 'date') {
          answer = /^\d+\//.test(answer)
            ? moment(answer, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : moment(answer).format('YYYY-MM-DD')
        }
      }

      return {
        id: question.id,
        type: question.type,
        answer: answer === '' ? null : answer,
      }
    })

    this.setState({ answersChanged: false }, () => {
      this.props.saveModuleFormAnswers(form.id, newQuestions)
    })
  }

  renderContent = () => {
    const { form } = this.state
    const { data } = form
    const { questions } = data

    return (
      <div className="questions">
        {questions
          .sort((q1, q2) => q1.order - q2.order)
          .map((question, key) => this.renderQuestion(key + 1, question))}
        <div>{"*Ces champs sont obligatoires"}</div>
        {form !== null && !form.done && (
          <div className="actions">
            <PrimaryButton
              label="Valider"
              id="submit"
              onClick={this.handleSaveAnswer}
            />
          </div>
        )}
      </div>
    )
  }

  static getDerivedStateFromProps(props, state) {
    const { formId: id } = props
    const { form } = state

    if (id) {
      if (form && (state.results === null || state.form === null || state.form.id !== id)) {
        const results = {}

        const buildResults = (question, prefix_id = '') => {
          if (question.type === 'group') {
            question.questions.map((itemQuestion) => buildResults(itemQuestion, `${question.id}_`))
          } else if (question.answer != null) {
            results[`${prefix_id}${question.id}`] = question.answer
            if (question.type === 'date' && results[`${prefix_id}${question.id}`] === '') {
              results[`${prefix_id}${question.id}`] = moment().format('DD/MM/YYYY')
            }
            if (question.type === 'multiple_choice') {
              const allowedAnswers = [...question.options.choices]
              if (question.options && question.options.allow_other) {
                allowedAnswers.push("Question à choix multiples")
              }

              const newState = allowedAnswers.reduce(
                (res, choice) => ({
                  ...res,
                  [choice]: question.answer.includes(choice),
                }),
                {},
              )

              results[`${prefix_id}${question.id}`] = newState
            }
          }

          if (
            (!results[`${prefix_id}${question.id}`] ||
              results[`${prefix_id}${question.id}`] === '') &&
            question.options.required != null &&
            question.options.required &&
            question.type === 'select'
          ) {
            results[`${prefix_id}${question.id}`] = question.options.choices[0]
          }
        }

        form.data.questions.map((question) => buildResults(question))

        return {
          form: form || null,
          results,
        }
      }

      return null
    }

    return {
      form: {},
    }
  }

  constructor(props) {
    super(props)

    this.state = {
      form: null,
      results: null,
      unblock: null,
      answersChanged: false,
    }
  }

  async componentDidMount() {
    const unblock = history.block(() => {
      if (this.state.answersChanged) {
        return 'true'
      }
    })

    this.setState({
      unblock,
    })

    const { formId, token } = this.props

    const module = await getModule({ token: token.token, module_id: formId })

    this.setState({
      ...this.state,
      form: module.data,
    })
  }

  componentWillUnmount() {
    if (this.state.unblock !== null) {
      this.state.unblock()
    }
  }

  render() {
    const { formId } = this.props
    const { form } = this.state

    if (!form || form.id !== formId) {
      return null
    }

    const header = this.renderHeader()
    const contentLayout = this.renderContent()

    return (
      <div className="beneficiary-form-module-page">
        <PopinLayout header={header} content={contentLayout} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(BeneficiaryFormModulePage))
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext } from 'react-beautiful-dnd'
import { connect } from 'react-redux'

import {
  actions as ModuleTemplateActions,
  selectors as ModuleTemplateSelectors,
} from '../../../redux/ModuleTemplateRedux'
 

const mapStateToProps = (state) => ({
  question: ModuleTemplateSelectors.question(state),
  template: ModuleTemplateSelectors.template(state),
  selectedQuestionGroup: ModuleTemplateSelectors.selectedQuestionGroup(state),
})

const mapDispatchToProps = (dispatch) => ({
  updateTemplate: (template) => dispatch(ModuleTemplateActions.updateTemplate(template)),
  setQuestion: (question) => dispatch(ModuleTemplateActions.setQuestion(question)),
  setGroupIsDragging: (groupIsDragging) =>
    dispatch(ModuleTemplateActions.setGroupIsDragging(groupIsDragging)),
  setSelectedQuestionGroup: (selectedQuestionGroup) =>
    dispatch(ModuleTemplateActions.setSelectedQuestionGroup(selectedQuestionGroup)),
  setDragInfo: (dragInfo) => dispatch(ModuleTemplateActions.setDragInfo(dragInfo)),
})

class ModuleFormEditDragDropContext extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  moveMultipleChoiceListItem = ({ source, destination }) => {
    const { question, setQuestion } = this.props

    const { index: from } = source
    const { index: to } = destination

    const newChoices = [...question.options.choices]

    newChoices.splice(to, 0, ...newChoices.splice(from, 1))

    const newQuestion = {
      ...question,
      options: {
        ...question.options,
        choices: newChoices,
      },
    }

    setQuestion(newQuestion)
  }

  getFlattenQuestions = (questions) => {
    const flattenQuestions = []
    questions.forEach((question) => {
      flattenQuestions.push({
        inGroupKey: false,
        question,
      })

      if (question.type === 'group') {
        question.questions.forEach((subQuestion) => {
          flattenQuestions.push({
            inGroupKey: true,
            question: subQuestion,
          })
        })
      }
    })

    return flattenQuestions
  }

  rebuildQuestions = ({ flattenQuestions, movedQuestion, from, to }) => {
    let moveDone = false

    const newQuestions = []
    let currentGroup = null
    flattenQuestions.forEach((flatQuestion, flatQuestionKey) => {
      if (flatQuestionKey === to) {
        if (
          currentGroup !== null &&
          movedQuestion.question.type !== 'group' &&
          (flatQuestion.inGroupKey || this.props.selectedQuestionGroup)
        ) {
          newQuestions[currentGroup].questions.push(movedQuestion.question)
        } else {
          newQuestions.push(movedQuestion.question)
        }
        moveDone = true
      }

      if (flatQuestion.question.type === 'group') {
        newQuestions.push({
          ...flatQuestion.question,
          questions: [],
        })
        currentGroup = newQuestions.length - 1
      } else if (flatQuestion.inGroupKey) {
        if (currentGroup !== null) {
          newQuestions[currentGroup].questions.push(flatQuestion.question)
        }
      } else {
        newQuestions.push(flatQuestion.question)
        if (currentGroup !== null) {
          currentGroup = null
        }
      }
    })

    if (!moveDone) {
      if (
        currentGroup !== null &&
        movedQuestion.question.type !== 'group' &&
        this.props.selectedQuestionGroup
      ) {
        newQuestions[currentGroup].questions.push(movedQuestion.question)
      } else {
        newQuestions.push(movedQuestion.question)
      }
    }

    return newQuestions
  }

  moveQuestionListItem = ({ source, destination }) => {
    const { template, updateTemplate } = this.props

    const { data } = template
    const { questions } = data

    const { index: from } = source
    const { index: to } = destination

    const flattenQuestions = this.getFlattenQuestions(questions)

    const movedQuestion = flattenQuestions.splice(from, 1)[0]

    const newQuestions = this.rebuildQuestions({ flattenQuestions, movedQuestion, from, to })

    const newTemplate = {
      ...template,
      data: {
        ...data,
        questions: newQuestions,
      },
    }

    updateTemplate(newTemplate)
  }

  addNewQuestion = ({ type, destination }) => {
    let newQuestion = {
      type,
      title: '',
      options: {
        required: false,
      },
    }

    switch (type) {
      case 'text': {
        newQuestion = {
          ...newQuestion,
          options: {
            ...newQuestion.options,
            max_length: 0,
          },
        }
        break
      }
      case 'select': {
        newQuestion = {
          ...newQuestion,
          options: {
            ...newQuestion.options,
            choices: [],
          },
        }
        break
      }
      case 'multiple_choice': {
        newQuestion = {
          ...newQuestion,
          options: {
            ...newQuestion.options,
            choices: [''],
            allow_other: false,
          },
        }
        break
      }
      case 'group': {
        newQuestion = {
          ...newQuestion,
          questions: [],
        }
        break
      }
      case 'yes_no': {
        newQuestion = {
          ...newQuestion,
        }
        break
      }
      case 'rating': {
        newQuestion = {
          ...newQuestion,
        }
        break
      }
      case 'opinion_scale': {
        newQuestion = {
          ...newQuestion,
          options: {
            ...newQuestion.options,
            scale: 10,
          },
        }
        break
      }
      case 'date': {
        newQuestion = {
          ...newQuestion,
        }
        break
      }
      default:
        throw new Error(`Incompatible question type: "${type}"`)
    }

    const { template, updateTemplate } = this.props

    const { data } = template
    const { questions } = data

    const { index: to } = destination
    const from = Number.POSITIVE_INFINITY

    const flattenQuestions = this.getFlattenQuestions(questions)

    const newQuestions = this.rebuildQuestions({
      flattenQuestions,
      movedQuestion: { inGroupKey: false, question: newQuestion },
      from,
      to,
    })

    const newTemplate = {
      ...template,
      data: {
        ...template.data,
        questions: newQuestions,
      },
    }

    updateTemplate(newTemplate)
  }

  handleDragStart = (result) => {
    const { template, setGroupIsDragging, setDragInfo } = this.props
    const { type, source } = result

    if (template && type === 'question-list') {
      setDragInfo(result)
      const { droppableId } = source

      const droppableIdObject = JSON.parse(droppableId)

      if (droppableIdObject.id !== 'question-types-list') {
        const { data } = template
        const { questions } = data

        const { index: from } = source

        const flattenQuestions = this.getFlattenQuestions(questions)

        if (flattenQuestions[from].question.type === 'group') {
          setGroupIsDragging(true)
        }
      }
    }
  }

  handleDragEnd = (result) => {
    const {
      template,
      question,
      setGroupIsDragging,
      setSelectedQuestionGroup,
      setDragInfo,
    } = this.props
    const { destination, type, source, draggableId } = result

    setGroupIsDragging(false)

    if (!destination) {
      return
    }

    if (question && type === 'multiple-choice-list') {
      this.moveMultipleChoiceListItem(result)
    }

    if (template && type === 'question-list') {
      setDragInfo(null)
      const { droppableId } = source

      const droppableIdObject = JSON.parse(droppableId)

      if (droppableIdObject.id === 'question-types-list') {
        const draggableIdObject = JSON.parse(draggableId)

        this.addNewQuestion({ destination, type: draggableIdObject.type })
      } else {
        this.moveQuestionListItem(result)
      }

      setSelectedQuestionGroup(null)
    }

    return null
  }

  handleDragUpdate = (result) => {
    const { template, setDragInfo } = this.props
    const { type } = result

    if (template && type === 'question-list') {
      setDragInfo(result)
    }
  }

  render() {
    return (
      <DragDropContext
        onDragStart={this.handleDragStart}
        onDragUpdate={this.handleDragUpdate}
        onDragEnd={this.handleDragEnd}
      >
        {this.props.children}
      </DragDropContext>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleFormEditDragDropContext)
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ActionPopin from '../ActionPopin/ActionPopin'
// import IconCombo from './IconCombo'
import DropStepZone from '../../molecules/DropStepZone/DropStepZone'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import Icon from '../../atoms/Icon/Icon'
import IconButton from '../../atoms/IconButton/IconButton'
import InputField from '../../molecules/InputField/InputField'
import LoadingSpinner from '../../atoms/LoadingSpinner/LoadingSpinner'
import './DropFilePopin.scss';

export default class DropFilePopin extends Component {
  state = {
    selectedOption: 'link' 
  }

  static propTypes = {
    accept: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,

    title: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    noTitle: PropTypes.bool,

    onChange: PropTypes.func.isRequired,
    onFileChange: PropTypes.func,
    onTogglePublish: PropTypes.func,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    onPreview: PropTypes.func,
    disabled: PropTypes.bool,

    locale: PropTypes.shape({
      dropZoneLabel: PropTypes.string.isRequired,
      uploadButtonLabel: PropTypes.string.isRequired,
      or: PropTypes.string.isRequired,
      linkLabel: PropTypes.string.isRequired,
      linkPlaceholder: PropTypes.string.isRequired,
      selectLabel: PropTypes.string.isRequired,
      videoLabel: PropTypes.string.isRequired,
      videoPlaceholder: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      titleLabel: PropTypes.string.isRequired,
      titlePlaceholder: PropTypes.string.isRequired,
      cta: PropTypes.string.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    accept: '*',
    type: '',
    title: '',
    url: '',
    noTitle: false,
    disabled: false,
    onFileChange: ({ file }) => undefined,
    onClick: () => undefined,
    onSubmit: () => undefined,
    onClose: () => undefined,
    onChange: ({ type, url }) => undefined,
    onPreview: null,
  }

  handleSubmit = () => {
    this.props.onSubmit()
  }

  handleBackClick = () => {
    this.props.onClose()
  }

  handleChange = ({ id, value }) => {
    this.props.onChange({ id, value })
  }

  handleTypeChange = ({ value }) => {
    this.handleChange({ id: 'type', value: value.value })
  }

  handleTogglePublish = () => {
    this.props.onTogglePublish()
  }

  handleRemoveFile = () => {
    this.props.onFileChange({ file: null })
    this.handleChange({ id: 'url', value: null })
  }

  handleOnPreview = () => {
    const { onPreview, url } = this.props
    if (onPreview !== null) {
      onPreview({ value: url })
    }
  }


  handleOptionChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  }

  renderResourceUploadType = () => {

    return this.renderDragNDrop()
  }

  validURL(str) {
    try {
      new URL(str);

      return true
    } catch (_) {
      return false;
    }
  }

  isDisabled() {
    const { disabled, type, title, url } = this.props

    return disabled || !(type && title && url) || !this.validURL(url)
  }

  renderDragNDrop = () => {
    const { onFileChange, onClick, disabled } = this.props
    const { dropZoneLabel, uploadButtonLabel, or } = this.props.locale

    if (disabled) {
      return <LoadingSpinner />
    }
    return (
      <div className="drag-n-drop">
        <DropStepZone onDrop={onFileChange} onClick={onClick} label={dropZoneLabel} />

        <p className="or">{or}</p>

        <PrimaryButton
          id="upload"
          label={uploadButtonLabel}
          icon="upload"
          disabled={disabled}
          onClick={onClick}
        />
      </div>
    )
  }

  renderDocument = () => (
    <div className="file">
      <Icon icon="document" color="grey3" />

      <div className="name">{this.props.title}</div>
      <IconButton icon="close" color="grey2" onClick={this.handleRemoveFile} />

      <IconButton icon="preview2" color="accent" onClick={this.handleOnPreview} />

    </div>
  )

  renderLink = () => {
    const { type, locale } = this.props
    const isVideo = type === 'video'
    const label = locale[isVideo ? 'videoLabel' : 'linkLabel']
    const placeholder = locale[isVideo ? 'videoPlaceholder' : 'linkPlaceholder']

    return (
      <>
        <InputField
          name="url"
          title={label}
          required
          value={this.props.url || ''}
          placeholder={placeholder}
          onChange={this.handleChange}
          type="url"
        />

        {this.props.url && (
          <IconButton icon="preview2" color="accent" onClick={this.handleOnPreview} />
        )}
      </>
    )
  }

  render() {
    const { locale, type, title, onTogglePublish, noTitle } = this.props
    // const { selectLabel } = locale
    const components = []
    const disabled = this.isDisabled()


    if (onTogglePublish) {
      components.push(
        <PrimaryButton
          key="publish"
          label={locale.publish}
          id="publish"
          icon={Icon.icon.Publish}
          disabled={disabled}
          onClick={this.handleTogglePublish}
        />,
      )
    }

    return (
      <div className="drop-file-popin">
        <ActionPopin
          title={locale.title}
          labelButton={locale.cta}
          components={components}
          disabled={disabled}
          onBack={this.handleBackClick}
          onSubmit={this.handleSubmit}
        >
          {!noTitle && (
            <InputField
              type="text"
              name="title"
              required
              onChange={this.handleChange}
              value={title}
              title={locale.titleLabel}
              placeholder={locale.titlePlaceholder}
              autoFocus
            />
          )}
          {/* <IconCombo
            id="type"
            title={selectLabel}
            options={comboOptions}
            onChange={this.handleTypeChange}
            value={type}
            placeholderClassName={type}
          /> */}

          {
            type === "file" ?
            <>
            <div className='input-field-choices'>
              <label>
                <input type="radio" name="option" value="link" checked={this.state.selectedOption === 'link'} onChange={this.handleOptionChange} />
                Ajouter un lien
              </label>
              <label>
                <input type="radio" name="option" value="document" checked={this.state.selectedOption === 'document'} onChange={this.handleOptionChange} />
                Déposer un document
              </label>
            </div>
         
           {this.state.selectedOption === "document" && this.renderResourceUploadType()} 
           {this.state.selectedOption === "link" && this.renderLink()}
            </>
            : this.renderLink()
          }

          <div className='drop-file-popin-actions'>
            <PrimaryButton label={"Annuler"} id="cancel" />
            <PrimaryButton label={"Enregister"} id="save" onClick={this.handleSubmit}/>
          </div>
        </ActionPopin>
      </div>
    )
  }
}

const AccompanimentCoSolutionLocale = {
    sublabel: "Solution identifiée",
    newJobLabel: "Nouvel intitulé du poste",
    newRemunerationLabel: "Nouvelle rémunération souhaitée",
    differentialRemunerationLabel: "Indémnités différentielle de rémunération",
    newActivitySectorLabel: "Nouveau secteur d'activité"
}

const optionsSolutions = [
    { value: 'other', label: 'Autre' },
    { value: 'cdi-trial-validated', label: 'CDI période essai validée' },
    { value: 'cdi-trial-in-progress', label: 'CDI essai en cours' },
    { value: 'cdd-more-than-6-months', label: 'CDD de plus de 6 mois' },
    { value: 'cdd-less-than-6-months', label: 'CDD de moins de 6 mois' },
    { value: 'business-startup', label: "Création d'entreprise" },
    { value: 'business-acquisition', label: "Reprise d'entreprise" },
    { value: 'administrative-competition', label: "Concours administratif" },
    { value: 'internal-redeployment', label: "Reclassement interne" },
    { value: 'effective-retirement', label: 'Retraite effective' },
    { value: 'early-retirement', label: 'Préretraite' },
    { value: 'cca', label: 'CCA' },
    { value: 'long-term-training', label: 'Formation longue' },
    { value: 'abandonment', label: 'Abandon' },
    { value: 'personal-project', label: 'Projet personnel' },
    { value: 'unidentified-yet', label: 'Non identifiée encore' },
]

const optionsDifferentialRemuneration = [
    { value: ' ', label: 'Aucun' },
    { value: 'yes', label: 'Oui' },
    { value: 'no', label: 'Non' },
]

const activityOptions = [
    { value: "other", label: "Autre" },
    { value: "agriculture_forestry_fishing", label: "Agriculture, sylviculture, pêche" },
    { value: "industry", label: "Industrie" },
    { value: "building_civil_agricultural_engineering", label: "Bâtiment, génie civil et agricole" },
    { value: "trade_commercial", label: "Commerce et services marchands" },
    { value: "insurance_financial", label: "Assurances et services financiers" },
    { value: "non_market", label: "Services non marchands" },
]

export {
    AccompanimentCoSolutionLocale,
    optionsSolutions,
    optionsDifferentialRemuneration,
    activityOptions
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { selectors as LocaleSelectors } from '../../redux/LocaleRedux'
import { selectors as UserSelectors } from '../../redux/UserRedux'
import { actions as BeneficiaryActions } from '../../redux/BeneficiaryRedux'

import Logo from '../../static/assets/logo-my-talents.svg'
import WelcomePopin from '../../components/organisms/WelcomePopin/WelcomePopin'

import 'moment/locale/fr'
import "./BeneficiaryInValidationPage.scss"
const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  locale: LocaleSelectors.locale(state),
})

const mapDispatchToProps = (dispatch) => ({
  saveCurrentBeneficiary: (beneficiary) =>
    dispatch(BeneficiaryActions.saveCurrentBeneficiary(beneficiary)),
})

class BeneficiaryInValidationPage extends Component {


  handleChange = (value) => {
    const newValues = {
      ...this.state.values,
      [value.id]: value.value,
    }

    this.setState({
      values: newValues,
    })
  }

  handleSubmit = () => {
    const { user, saveCurrentBeneficiary } = this.props

    const newUser = {
      ...user,
      birth_date: moment(this.state.values.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'),

      address: this.state.values.address,
      zip_code: this.state.values.zip_code,
      city: this.state.values.city,

      cursus_formation: this.state.values.education,

      cursus_professional_situation: this.state.values.situation,
      cursus_socio_professional_category: this.state.values.socio_professional_category,
      diploma_name: this.state.values.diploma_name,
      cursus_function: this.state.values.cursus_function,
      cursus_company_activity_sector: this.state.values.activity,
    }

    saveCurrentBeneficiary(newUser)
  }

  getEducationOptions = () => {
    const beneficiaryFormations = [
      {key: "I", value: "Bac + 5 et + : Master 2 / Ecole d'ingénieur et +"},
      {key: "II", value: "Bac + 3/4 : Licence / Master 1"},
      {key: "III", value: "Bac + 2 : BTS, DUT..."},
      {key: "IV", value: "Bac général, technologique ou professionnel, BP, BT ou équivalent"},
      {key: "V", value: "Sortie après l'année de terminale de CAP ou sortie du second cycle général et technologique avant l'année de terminale (seconde ou première)"},
      {key: "Vbis", value: "Sortie en cours de cycle de l'enseignement secondaire (de la 6ème à la 3ème)"},
      {key: "VI", value: "Abandon en cours de CAP ou BEP avant l'année de terminale"},
    ]

    return beneficiaryFormations.map((item) => ({
      value: item.key,
      label: item.value,
    }))
  }

  getSituationOptions = () => {
    const beneficiaryProfessionalSituations = [
      {key: "cdd", value: "Salarié(e) CDD"},
      {key: "cdi", value: "Salarié(e) CDI"},
      {key: "jobseeker", value: "Demandeur d'emploi"},
      {key: "student", value: "Etudiant(e)"},
      {key: "other", value: "Autre"},
  ]
  return beneficiaryProfessionalSituations.map((item) => ({
    value: item.key,
    label: item.value
  }))
  }

  getActivityOptions = () => {
    const beneficiaryCompanyActivitySectors = [
      {key: "other", value: "Autre"},
      {key: "agriculture_forestry_fishing", value: "Agriculture, sylviculture, pêche"},
      {key: "industry", value: "Industrie"},
      {key: "building_civil_agricultural_engineering", value: "Bâtiment, génie civil et agricole"},
      {key: "trade_commercial", value: "Commerce et services marchands"},
      {key: "insurance_financial", value: "Assurances et services financiers"},
      {key: "non_market", value: "Services non marchands"},

  ]
  return beneficiaryCompanyActivitySectors.map((item) => ({
    value: item.key,
    label: item.value
  }))
  }

  getSocioProfessionalCategoriesOptions = () => {
    const beneficiarySocioProfessionalCategories = [
      {key: "unskilled_worker", value: "Ouvrier(e) non qualifié(e)"},
      {key: "qualified_worker", value: "Ouvrier(e) qualifié(e)"},
      {key: "employee", value: "Employé(e)"},
      {key: "agent_control", value: "Agent de maîtrise"},
      {key: "technician", value: "Technicien"},
      {key: "engineer", value: "Ingénieur"},
      {key: "frame", value: "Cadre"},
      {key: "assimilated_frame", value: "Assimilé cadre"},
      {key: "executive_frame", value: "Cadre dirigeant"},
      {key: "other", value: "Autre"},

  ]

    return beneficiarySocioProfessionalCategories.map((item) => ({
      value: item.key,
      label: item.value,
    }))
  }

  buildSteps = () => {
    const { locale } = this.props

    let disabledNextButtonStep1 = false

    const displayFormat = 'DD/MM/YYYY'

    if (this.state.values.birthdate === '' || this.state.values.birthdate == null) {
      disabledNextButtonStep1 = true
    }
    const date = moment(this.state.values.birthdate, displayFormat)

    if (
      this.state.values.birthdate !== null &&
      (this.state.values.birthdate.length !== displayFormat.length ||
        date.format() === 'Invalid Date')
    ) {
      disabledNextButtonStep1 = true
    }

    const disabledNextButtonStep2 =
      this.state.values.address === '' ||
      this.state.values.zip_code === '' ||
      this.state.values.city === ''

    const educationOptions = this.getEducationOptions()

    const disabledNextButtonStep3 = false

    const educationComboContent = [
      {
        title: "Niveau de formation *",
        id: 'education',
        placeholder: "Veuillez choisir votre niveau de formation",
        options: educationOptions,
        value: this.state.values.education,
      },
    ]

    let disabledNextButtonStep4 = false

    const situationOptions = this.getSituationOptions()
    const activityOptions = this.getActivityOptions()
    const socioProfressionalCategories = this.getSocioProfessionalCategoriesOptions()

    const aboutContent = [
      {
        comboContent: [
          {
            title: "Votre situation professionnelle *",
            id: 'situation',
            placeholder: "Veuillez choisir la situation professionnelle",
            options: situationOptions,
            value: this.state.values.situation,
          },
        ],
      },
    ]

    if (this.state.values.situation === 'cdd' || this.state.values.situation === 'cdi') {
      aboutContent.push({
        comboContent: [
          {
            title: "Catégorie socioprofessionnelle *",
            id: 'socio_professional_category',
            placeholder: "Veuillez choisir votre catégorie socioprofessionnelle",
            options: socioProfressionalCategories,
            value: this.state.values.socio_professional_category,
          },
        ],
      })

      aboutContent.push({
        inputContent: {
          inputTitle: "Titre du diplôme *",
          placeholder: '',
          inputName: 'diploma_name',
          inputValue: this.state.values.diploma_name,
        },
      })

      aboutContent.push({
        inputContent: {
          inputTitle: "Fonctions *",
          placeholder: '',
          inputName: 'cursus_function',
          inputValue: this.state.values.cursus_function,
        },
      })

      aboutContent.push({
        comboContent: [
          {
            title: "Secteur d'activité",
            id: 'activity',
            placeholder: "Veuillez choisir le secteur d'activité",
            options: activityOptions,
            value: this.state.values.activity,
          },
        ],
      })

      if (
        this.state.values.socio_professional_category === null ||
        this.state.values.diploma_name === '' ||
        this.state.values.cursus_function === '' ||
        this.state.values.activity === null
      ) {
        disabledNextButtonStep4 = true
      }
    }

    return [
      {
        steps: 4,
        current: 1,
        mainTitle: "Félicitations",
        content: "Plus que quelques informations personnelles à compléter avant le début de votre accompagnement.",
        inputType: WelcomePopin.inputTypes.birthday,
        datePicker: {
          title: "Votre date de naissance *",
          placeholder: "JJ/MM/AAAA",
          name: 'birthdate',
          value: this.state.values.birthdate,
          dayShortLabels: ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
          monthLabels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
          locale,
        },
        nextId: 'next-step',
        next: "Suivant",
        onNext: () => {
          this.setState({
            step: 1,
          })
        },
        disabledNextButton: disabledNextButtonStep1,
      },
      {
        steps: 4,
        current: 2,
        mainTitle: "Félicitations",
        content: "Plus que quelques informations personnelles à compléter avant le début de votre accompagnement.",
        inputType: WelcomePopin.inputTypes.address,
        addressInputs: [
          {
            inputTitle: "Votre addresse *",
            placeholder: '',
            inputName: 'address',
            inputValue: this.state.values.address,
          },
          {
            inputTitle: "Votre code postal *",
            placeholder: '',
            inputName: 'zip_code',
            inputValue: this.state.values.zip_code,
          },
          {
            inputTitle: "Votre ville *",
            placeholder: '',
            inputName: 'city',
            inputValue: this.state.values.city,
          },
        ],
        prevId: 'prev-step',
        nextId: 'next-step',
        prev: "Précédent",
        next: "Suivant",
        onPrev: () => {
          this.setState({
            step: 0,
          })
        },
        onNext: () => {
          this.setState({
            step: 2,
          })
        },
        disabledNextButton: disabledNextButtonStep2,
      },
      {
        steps: 4,
        current: 3,
        mainTitle: "Félicitations",
        content: "Plus que quelques informations personnelles à compléter avant le début de votre accompagnement.",
        inputType: WelcomePopin.inputTypes.education,
        comboContent: educationComboContent,
        prevId: 'prev-step',
        nextId: 'next-step',
        prev: "Précédent",
        next: "Suivant",
        onPrev: () => {
          this.setState({
            step: 1,
          })
        },
        onNext: () => {
          this.setState({
            step: 3,
          })
        },
        disabledNextButton: disabledNextButtonStep3,
      },
      {
        steps: 4,
        current: 4,
        mainTitle: "Félicitations",
        content: "Plus que quelques informations personnelles à compléter avant le début de votre accompagnement.",
        inputType: WelcomePopin.inputTypes.about,
        aboutContent,
        prevId: 'prev-step',
        nextId: 'next-step',
        prev: "Précédent",
        next: "Valider",
        onPrev: () => {
          this.setState({
            step: 2,
          })
        },
        onNext: this.handleSubmit,
        disabledNextButton: disabledNextButtonStep4,
      },
    ]
  }

  constructor(props) {
    super(props)

    this.state = {
      step: 0,
      user_id: null,
      values: {
        birthdate: null,
        address: '',
        zip_code: '',
        city: '',
        education: this.getEducationOptions()[0].value,
        situation: this.getSituationOptions()[0].value,
        socio_professional_category: null,
        diploma_name: '',
        cursus_function: '',
        activity: null,
      },
    }
  }

  static getDerivedStateFromProps({ user }, state) {
    if (user && state.user_id === null) {
      return {
        user_id: user.id,
        values: {
          birthdate: user.birth_date
            ? moment(user.birth_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : null,
          address: user.address || '',
          zip_code: user.zip_code || '',
          city: user.city || '',
          education: user.cursus_formation || this.getEducationOptions()[0].value,
          situation: user.cursus_professional_situation || this.getSituationOptions()[0].value,
          socio_professional_category: user.cursus_socio_professional_category || null,
          diploma_name: user.diploma_name || '',
          cursus_function: user.cursus_function || '',
          activity: user.cursus_company_activity_sector || null,
        },
      }
    }

    return null
  }

  render() {
    const { user } = this.props

    if (!user) {
      return null
    }

    const steps = this.buildSteps()

    return (
      <div className="beneficiary-invalidation-page">
        <Logo className="logo" />

        <WelcomePopin {...steps[this.state.step]} onChange={this.handleChange} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryInValidationPage)
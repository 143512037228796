/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react'
import { PropTypes } from 'prop-types'

import { useClickOutside } from '../../../hooks/useClickOutside'
import Icon from '../../atoms/Icon/Icon'
import { NewTag } from '../../atoms/NewTag/NewTag'

import './DropdownTag.scss'

export const DropdownTag = ({
  id,
  label,
  defaultValue,
  placeholder,
  dropdownArray,
  multipleChoice,
  sendSelectChoices,
  disabled,
  isActionOnDeleteTag,
  handleOnDeleteTag,
  user,
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownRef = useRef(null)
  const [tags, setTags] = useState([])
  const [query, setQuery] = useState('')
  const [dropdown, setDropdown] = useState([])
  useClickOutside(dropdownRef, () => setShowDropdown(false))

  const handleAddTagOnClick = (tag, type) => {
    tag.type = type
    const existingTag = tags.some((element) => element?.user_id === tag.user_id)
    multipleChoice ? !existingTag && setTags([...tags, tag]) : setTags([tag])
  }


  const handleDeleteTagOnClick = (tag) => {
    const isArray = Array.isArray(tag)
    if (user) {
      if (!isArray) {
        const newTags = tags.filter(
          (element) => element.user_id !== tag.user_id || element.user_id === user.id,

        )
        setTags(newTags)
      } else {
        const newTags = tags.filter(
          (element) => !tag.some((t) => t.user_id === element.user_id && t.user_id !== user.id),

        )
        setTags(newTags)
      }
    }
    else {
      if (!isArray) {
        const newTags = tags.filter(
          (element) => element.user_id !== tag.user_id
        )
        setTags(newTags)
      } else {
        const newTags = tags.filter(
          (element) => !tag.some((t) => t.user_id === element.user_id),

        )
        setTags(newTags)
      }
    }


    isActionOnDeleteTag && handleOnDeleteTag(tag)
  }

  const handleSelectGroup = (event, users) => {
    const { target } = event;
    users.forEach((group) => (group.type = 'groups'));
  
    const userIdsInTags = tags.map(tag => tag.user_id);
    const usersToAdd = users.filter(user => !userIdsInTags.includes(user.user_id));
  
    target.checked
      ? setTags((prevTags) => [...prevTags, ...usersToAdd])
      : handleDeleteTagOnClick(users);
  };


  const handleSearch = (event) => {
    const { target } = event
    const searchQuery = target.value.toUpperCase()

    if (searchQuery.length > 0) {
      const filterIndividuals = dropdownArray.individuals.filter(
        (element) =>
          element.first_name.toUpperCase().includes(searchQuery) ||
          element.last_name.toUpperCase().includes(searchQuery),
      )

      const filterGroups = dropdownArray.groups.filter((group) =>
        group.name.toUpperCase().includes(searchQuery),
      )

      const filterGroupUsers = dropdownArray.groups.reduce((acc, group) => {
        const groupIndividuals = group.group_users.filter(
          (individual) =>
            individual.first_name.toUpperCase().includes(searchQuery) ||
            individual.last_name.toUpperCase().includes(searchQuery),
        )

        if (groupIndividuals.length > 0) {
          acc.push({
            ...group,
            group_users: groupIndividuals,
          })
        }
        return acc
      }, [])

      const array = {
        individuals: filterIndividuals,
        groups: filterGroups.concat(filterGroupUsers),
      }
      setDropdown(array)
    } else {
      setDropdown(dropdownArray)
    }
    setQuery(target.value)
  }

  useEffect(() => {
    sendSelectChoices({ id: id, values: tags })
  }, [tags, id])

  useEffect(() => {
    setTags(defaultValue ? defaultValue : [])
  }, [])

  useEffect(() => {
    setDropdown(dropdownArray)
  }, [dropdownArray])

  const renderDropdownList = () => {
    return (
      <ul className="dropdown-list" ref={dropdownRef}>
        <input
          className="dropdown-list-searchbar"
          type="text"
          placeholder="Rechercher par noms, par groupes..."
          value={query}
          onChange={handleSearch}
        />
        <p className="dropdown-list-title">{label}</p>
        {/* User in individuals */}
        {dropdown?.individuals?.map((element, index) => (
          <li key={index} onClick={() => handleAddTagOnClick(element, 'individuals')}>
            {element.first_name} {element.last_name}
          </li>
        ))}
        {/* User in groups */}
        {dropdown?.groups?.map((group, index) => (
          <div key={index}>
            <div className="dropdown-list-group">
              <p className="dropdown-list-title">{group.name}</p>
              {multipleChoice && (
                <div className="dropdown-list-select-all">
                  <label>Sélectionner tout le groupe</label>
                  <input
                    type="checkbox"
                    id={group.name}
                    onChange={(event) => handleSelectGroup(event, group.group_users)}
                  />
                </div>
              )}
            </div>
            {group.group_users.map((user, index) => (
              <li key={index} onClick={() => handleAddTagOnClick(user, 'groups')}>
                {user.first_name} {user.last_name}
              </li>
            ))}
          </div>
        ))}
      </ul>
    )
  }

  const renderTags = () => {
    return (
      <div className="dropdown-selected-tags">
        {tags && tags.length > 0 ? (
          tags.map((tag, index) => (
            <NewTag
              title={`${tag?.first_name} ${tag?.last_name}`}
              color={disabled ? 'grey' : ''}
              icon={'close'}
              iconColor={'grey1'}
              key={index}
              onClickIcon={() => handleDeleteTagOnClick(tag)}
            />
          ))
        ) : (
          <p className="dropdown-placeholder">{placeholder}</p>
        )}
      </div>
    )
  }

  return (
    <div className="container-dropdown-tag">
      <label>{label} *</label>
      <div className="dropdown" onClick={() => !disabled && setShowDropdown(true)}>
        {renderTags()}
        <div className="dropdown-icon">
          <Icon icon="select" />
        </div>
      </div>
      {showDropdown && dropdown && renderDropdownList()}
    </div>
  )
}

DropdownTag.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.array,
  placeholder: PropTypes.string,
  multipleChoice: PropTypes.bool,
  sendSelectChoices: PropTypes.func,
}

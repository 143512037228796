import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

 
import { actions as BeneficiaryGroupActions } from '../../../redux/BeneficiaryGroupRedux'
import { actions as WSActions, selectors as WSSelectors } from '../../../redux/WSRedux'
import { selectors as NavigationSelectors } from '../../../redux/NavigationRedux'

import { Popin } from '../../global/Popin/Popin'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'

const mapStateToProps = (state) => ({
  id: Number(NavigationSelectors.pathname(state).replace(/.+\/(\d+)\/remove$/, '$1')),
  pathname: NavigationSelectors.pathname(state),
  removing: WSSelectors.pending(state, 'beneficiaryGroup.remove'),
  removed: WSSelectors.resolved(state, 'beneficiaryGroup.remove'),
})

const mapDispatchToProps = (dispatch) => ({
  remove: (id) => dispatch(BeneficiaryGroupActions.removeGroup(id)),
  resetRemoveAction: () => dispatch(WSActions.reset('beneficiaryGroup.remove')),
})

class ManagerBeneficiaryGroupsRemove extends PureComponent {
  static propTypes = {
    id: PropTypes.number,
    onClose: PropTypes.func.isRequired,
  }

  handleSubmit = () => {
    this.props.remove(this.props.id)
  }

  componentDidUpdate() {
    if (this.props.removed) {
      this.props.onClose()
      this.props.resetRemoveAction()
    }
  }

  render() {
    const { onClose, removing } = this.props

    return (
      <Popin onClose={onClose} open>
        <AlertPopin
          disabled={removing}
          label={"Supprimer un groupe"}
          text={'Voulez-vous vraiment supprimer ce groupe ? Tous les talents associés à ce groupe vont passer en statut "non groupé"'}
          labelCancelButton={"Annuler"}
          labelConfirmButton={"Supprimer"}
          onCancelButtonClick={onClose}
          onConfirmButtonClick={this.handleSubmit}
        />
      </Popin>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerBeneficiaryGroupsRemove)
import ApiService from './ApiService'
import axios from "axios";
const api = ApiService

export const get = ({ token, id }) => {
  return api.get(`/courses/${id}`, null, { headers: { Authorization: `Bearer ${token}` } })
}

export const exportCourse = ({ token, id }) => {
  return api.get(`/courses/${id}/export`, null, { headers: { Authorization: `Bearer ${token}` } })
}

export const getAll = ({ token }) => {
  return axios.get('http://localhost:8888/courses/all/user/6924');
  // return api.get('/courses/me', null, { headers: { Authorization: `Bearer ${token}` } })
}

export const getAllForBeneficiary = ({ token, id }) => {
  return api.get(`/courses/beneficiary/${id}`, null, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const remove = ({ token, id }) => {
  return api.delete(`/courses/${id}`, null, { headers: { Authorization: `Bearer ${token}` } })
}

export const exportSynthesis = ({ token, id }) => {
  return api.get(`/courses/${id}/synthesis`, null, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

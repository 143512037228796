import React, { useEffect, useState } from 'react';
import QuizStep from '../QuizStep/QuizStep';
import QuizQcmRow from '../QuizQcmRow/QuizQcmRow';

export const MultipleQuizQcm = ({ label, stepLabel, optionsProps, image, onChange }) => {
    const [options, setOptions] = useState([])

      useEffect(() => {
        setOptions(optionsProps)
      }, [optionsProps])

      const handleOnClick = (choice) => {
        const updatedOptions = options.map((option) => {
          if (option.label === choice.id) {
            return { ...option, isSelected: choice.value };
          }
          return option;
        });
    
        setOptions(updatedOptions);
        onChange(updatedOptions)
      };


    return (
        <div>
            <QuizStep label={label} stepLabel={stepLabel} />
            {image && <img src={image} alt='question_illu' className='question-illustration'/>}
            {(options && Array.isArray(options)) && options.map((option, i) => {
                return (
                    <QuizQcmRow
                        key={`${i}_${option.label}`}
                        stepLabel={(i + 1).toString()}
                        id={option.label}
                        label={option.label}
                        isSelected={option.isSelected}
                        onClick={handleOnClick}
                        stepOutline={false}
                    />
                )
            })}
        </div>
    )
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import ActionPopinHeader from '../../molecules/ActionPopinHeader/ActionPopinHeader'
import Icon from '../../atoms/Icon/Icon'
import './AlertPopin.scss';

export default class AlertPopin extends Component {
  static propTypes = {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    text: PropTypes.string.isRequired,
    labelCancelButton: PropTypes.string,
    labelConfirmButton: PropTypes.string,
    onCancelButtonClick: PropTypes.func,
    onConfirmButtonClick: PropTypes.func,
  }

  static defaultProps = {
    disabled: false,
  }

  onCancelButtonClick = (e, id) => {
    this.props.onCancelButtonClick({ id })
  }

  onConfirmButtonClick = (e, id) => {
    this.props.onConfirmButtonClick({ id })
  }

  render() {
    const { label, text, labelCancelButton, labelConfirmButton, disabled } = this.props

    return (
      <div className="alert-popin">
        <ActionPopinHeader boldTitle={label} icon={Icon.icon.Warning} />

        <div className="content">
          <p className="text">{text}</p>
          <div className="buttons">
            <PrimaryButton
              label={labelCancelButton}
              id="cancel-button"
              onClick={this.onCancelButtonClick}
              outline
              disabled={disabled}
            />

            <PrimaryButton
              label={labelConfirmButton}
              id="confirm-button"
              onClick={this.onConfirmButtonClick}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    )
  }
}

import React from "react";
import Icon from "../Icon/Icon";
import './IconBackground.scss';
export const IconBackground = ({ icon, color, background }) => {
    let colorBackground;

    if (background === "white") {
        colorBackground = 'var(--pure-white)'
    }
    else if(background === "blue") {
        colorBackground = 'var(--main-color-lighter)'
    }

    return (
        <div className="icon-background" style={{ backgroundColor: colorBackground }}>
            <Icon icon={icon} color={color} />
        </div>
    )
}
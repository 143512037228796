import React, { useEffect, useState } from "react";
import './EditImageStepPopin.scss';
import { Popin } from "../../global/Popin/Popin";
import Icon from "../../../components/atoms/Icon/Icon";

export const EditImageStepPopin = ({image, id, editImage, deleteImage, isOpenModal, setIsOpenModal}) => {
    const [isOpen, setIsOpen] = useState(isOpenModal);
    const [stepImage, setStepImage] = useState();


    useEffect(() => {
        if(image) {
            setStepImage(image.find(element => element.id === id).image)
        } 
    }, [image, id])

    const onClose = () => {
        setIsOpen(false)
        setIsOpenModal(false)
    }

    return (
        <Popin onClose={onClose} open={isOpen} focusTrapped={false}>
            <div className="image-step-popin">
                <div className="image-step-popin-edit">
                <h2>Aperçu de l'image</h2>
                <div className="actions">

                <label className="action edit-image">
                    <Icon icon="edit"/>
                    <p>Modifier</p>
                    <input type="file" onChange={editImage}/>
                </label>

                <label className="action delete-image" onClick={deleteImage}>
                <Icon icon="trash2"/>
                    Supprimer
                </label>
                </div>
                </div>
                <img src={stepImage} alt={"illustration-step"}/>
            </div>
        </Popin>
    )
}

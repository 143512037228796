import React from "react";
import PropTypes from 'prop-types';
import IconCardTitle from "../../molecules/IconCardTitle/IconCardTitle";
import ComboField from "../../molecules/ComboField/ComboField";
import InputField from "../../molecules/InputField/InputField";
import DateField from "../../molecules/DateField/DateField";

import './FormationCard.scss';

export const FormationCard = ({ title, locale, readOnly, onChange, formationType, nameFormation, opinionConsultant, financingFormation, startDate, endDate, costFormation, durationFormation }) => {

    const handleChange = (newValue) => {
        if (!readOnly) {
            onChange(newValue)
        }
    }

    const { formationTypeLabel, nameFormationLabel, opinionConsultantLabel, financingLabel, startDateLabel, endDateLabel, costLabel, durationLabel, monthLabels, dayShortLabels } = locale
    return (
        <div className="accomp-collective-formation-card">
            <IconCardTitle icon="clipboard" color="accent" label={title} />
            <div className="accomp-collective-formation-card-content">
                <ComboField
                    title={formationTypeLabel}
                    name={formationType.id}
                    key={formationType.id}
                    value={formationType.value}
                    options={formationType.options}
                    onChange={handleChange}
                    readOnly={readOnly}
                />
                <InputField
                    title={nameFormationLabel}
                    name={nameFormation.id}
                    value={nameFormation.value}
                    onChange={handleChange}
                    type="string"
                    readonly={readOnly}
                />
                <ComboField
                    title={opinionConsultantLabel}
                    name={opinionConsultant.id}
                    key={opinionConsultant.id}
                    value={opinionConsultant.value}
                    options={opinionConsultant.options}
                    onChange={handleChange}
                    readOnly={readOnly}
                />

                <ComboField
                    title={financingLabel}
                    name={financingFormation.id}
                    key={financingFormation.id}
                    value={financingFormation.value}
                    options={financingFormation.options}
                    onChange={handleChange}
                    readOnly={readOnly}
                />

                <DateField
                    title={startDateLabel}
                    name={startDate.id}
                    value={startDate.value}
                    calendarLabels={{ dayShortLabels, monthLabels }}
                    onChange={handleChange}
                    locale={locale}
                    readOnly={readOnly}
                />
                <DateField
                    title={endDateLabel}
                    name={endDate.id}
                    value={endDate.value}
                    calendarLabels={{ dayShortLabels, monthLabels }}
                    onChange={handleChange}
                    locale={locale}
                    readOnly={readOnly}
                />

                <div className="accomp-collective-formation-card-content-flex ">

                    <InputField
                        title={durationLabel}
                        name={durationFormation.id}
                        value={durationFormation.value}
                        onChange={handleChange}
                        type="string"
                        readonly={readOnly}
                    />
                    <span>H</span>
                </div>


                <div className="accomp-collective-formation-card-content-flex ">
                    <InputField
                        title={costLabel}
                        name={costFormation.id}
                        value={costFormation.value}
                        onChange={handleChange}
                        type="string"
                        readonly={readOnly}
                    />
                    <span>€</span>
                </div>
            </div>
        </div>
    )
}

FormationCard.propTypes = {
    title: PropTypes.string,
    formationType: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.object),
    }),
    nameFormation: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
    }),
    opinionConsultant: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.object),
    }),
    financingFormation: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.object),
    }),
    startDate: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
    }),
    endDate: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
    }),
    costFormation: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
    }),
    durationFormation: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
    })
}

export default FormationCard;
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isValid } from 'redux-form'
import Page from '../../containers/global/Page/Page'
import './BeneficiaryProfileConsultantPage.scss';
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import { actions as BeneficiaryActions } from '../../redux/BeneficiaryRedux'
import { selectors as UserSelectors } from '../../redux/UserRedux'

import BeneficiaryPageAside from '../../containers/beneficiary/BeneficiaryPageAside/BeneficiaryPageAside'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import BeneficiaryProfile from '../../containers/beneficiary/BeneficiaryProfile/BeneficiaryProfile'
import BeneficiaryConsultant from '../../containers/beneficiary/BeneficiaryConsultant/BeneficiaryConsultant'
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton'
import BeneficiaryAccompaniment from '../../containers/beneficiary/BeneficiaryAccompaniment/BeneficiaryAccompaniment';
// import BeneficiaryPageAside from '../../containers/beneficiary/BeneficiaryPageAside/BeneficiaryPageAside';
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside';
import ManagerPageAside from '../../containers/manager/ManagerPageAside/ManagerPageAside';

const mapStateToProps = (state) => ({
  valid: isValid('beneficiary-edit')(state),
  user: UserSelectors.user(state),
  pathname: NavigationSelectors.pathname(state),
})

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  saveCurrentBeneficiary: (beneficiary) =>
    dispatch(BeneficiaryActions.saveCurrentBeneficiary(beneficiary)),
})

 
class BeneficiaryProfileConsultantPage extends Component {

  handleTabChange = (tabId) => {
    // const { pathname, redirect } = this.props

    this.setState({
      currentTab: tabId,
    })

  }

  handleBeneficiaryChange = (value) => {
    this.setState({
      beneficiary: value.value,
    })
  }

  buildNewUser = () => {
    const { user } = this.props

    let newBeneficiary = { ...user }

    newBeneficiary = {
      ...newBeneficiary,

      last_name: this.state.beneficiary.last_name,
      first_name: this.state.beneficiary.first_name,
      image_url: this.state.beneficiary.image_url,
      gender: this.state.beneficiary.gender,
      birth_date: this.state.beneficiary.birth_date,

      phone_mobile: this.state.beneficiary.phone_mobile,
      address: this.state.beneficiary.address,
      zip_code: this.state.beneficiary.zip_code,
      city: this.state.beneficiary.city,
      phone_home: this.state.beneficiary.phone_home,

      cursus_formation: this.state.beneficiary.cursus_formation,
      cursus_professional_situation: this.state.beneficiary.cursus_professional_situation,
      cursus_socio_professional_category: this.state.beneficiary.cursus_socio_professional_category,
      diploma_name: this.state.beneficiary.diploma_name,
      cursus_function: this.state.beneficiary.cursus_function,
      cursus_company_activity_sector: this.state.beneficiary.cursus_company_activity_sector,
      cursus_non_company_activity_sector: this.state.beneficiary.cursus_non_company_activity_sector,
      cursus_company_size: this.state.beneficiary.cursus_company_size,
      cursus_handicap_status: this.state.beneficiary.cursus_handicap_status,
      cursus_handicap_file: this.state.beneficiary.cursus_handicap_file,
      cursus_cv_file: this.state.beneficiary.cursus_cv_file,

      financer_support_agreement_file: this.state.beneficiary.financer_support_agreement_file,
      comments: this.state.beneficiary.comments,
    }

    if (newBeneficiary.birth_date === null || newBeneficiary.birth_date === '') {
      delete newBeneficiary.birth_date
    }

    if (newBeneficiary.cursus_company_size != null && newBeneficiary.cursus_company_size === '') {
      newBeneficiary = {
        ...newBeneficiary,
        cursus_company_size: null,
      }
    }

    if (
      newBeneficiary.cursus_non_company_activity_sector != null &&
      newBeneficiary.cursus_non_company_activity_sector === ''
    ) {
      newBeneficiary = {
        ...newBeneficiary,
        cursus_non_company_activity_sector: null,
      }
    }

    return newBeneficiary
  }

  handleSaveBeneficiary = () => {
    if (this.state.beneficiary !== null) {
      const { saveCurrentBeneficiary } = this.props

      const newUser = this.buildNewUser()

      this.setState({
        resetBeneficiary: true,
      })
      saveCurrentBeneficiary(newUser)
    }
  }

  newUserIsDifferent = () => {
    const { user } = this.props
    const newUser = this.buildNewUser()

    return [
      'last_name',
      'first_name',
      'image_url',
      'gender',
      'birth_date',
      'phone_mobile',
      'address',
      'zip_code',
      'city',
      'phone_home',
      'cursus_formation',
      'cursus_professional_situation',
      'cursus_socio_professional_category',
      'diploma_name',
      'cursus_function',
      'cursus_company_activity_sector',
      'cursus_non_company_activity_sector',
      'cursus_company_size',
      'financer_support_agreement_file',
      'cursus_handicap_status',
      'cursus_handicap_file',
      'cursus_cv_file',
      'comments',
    ].reduce((res, prop) => res || newUser[prop] !== user[prop], false)
  }

  renderSaveButton = () => {
    const { valid } = this.props

    let disabledBtn = true

    if (this.state.beneficiary !== null) {
      disabledBtn = !this.newUserIsDifferent() || !valid
    }

    return (
      <PrimaryButton
        label={"Sauvegarder"}
        id="beneficiary-save"
        onClick={this.handleSaveBeneficiary}
        disabled={disabledBtn}
      />
    )
  }

  renderTabs = () => {
    const { pathname } = this.props
    const links = [
      { id: 'my_info', label: "Mes informations" },
      { id: 'my_accompaniment', label: "Mon accompagnement" },
      { id: 'my_consultant', label: "Mon consultant" },
    ]

    return (
      <LineMenu
        value={this.state.currentTab}
        links={links}
        onChange={this.handleTabChange}
        components={pathname.endsWith('/profile/consultant') ? null : this.renderSaveButton()}
      />
    )
  }

  renderContent = () => {
    const { currentTab } = this.state
    return (
      <>
      {currentTab === "my_info" && <BeneficiaryProfile onChange={this.handleBeneficiaryChange} />}
      {currentTab === "my_accompaniment" && <BeneficiaryAccompaniment />}
      {/* {currentTab === "my_accompaniment" && <ConsultantBeneficiaryAccompanimentPage />} */}
      {currentTab === "my_consultant" && <BeneficiaryConsultant />}
      </>
    )
    // if(this.state.currentTab === "my_info") {

    // }
    // const { pathname } = this.props

    // return pathname.endsWith('/profile/consultant') ? (
    //   <BeneficiaryConsultant />
    // ) : (
    //   <BeneficiaryProfile onChange={this.handleBeneficiaryChange} />
    // )
  }

  static getDerivedStateFromProps({ user }, state) {
    if (user && state.resetBeneficiary) {
      return {
        beneficiary: null,
        resetBeneficiary: false,
      }
    }

    return null
  }

  constructor(props) {
    super(props)

    this.state = {
      beneficiary: null,
      resetBeneficiary: false,
      currentTab: 'my_info'
    }
  }

  render() {
    const { user } = this.props

    if (!user) {
      return null
    }

    // const aside = <BeneficiaryPageAside />
    let aside = null;
    switch (user.role) {
      case 'consultant':
        aside = <ConsultantPageAside />;
        break;
      case 'beneficiary':
        aside = <BeneficiaryPageAside />;
        break;
      case 'manager':
        aside = <ManagerPageAside />;
        break;
      default:
        return null;
    }

    const content = (
      <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
    )

    return (
      <div className="beneficiary-profile-consultant-page">
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(BeneficiaryProfileConsultantPage))
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Popin } from '../../global/Popin/Popin'
import DropFilePopin from '../../../components/organisms/DropFilePopin/DropFilePopin'
import FileUpload from '../../global/FileUpload/FileUpload'
import { actions as NavigationActions } from '../../../redux/NavigationRedux'
import './EditResourcePopin.scss';

const mapDispatchToProps = (dispatch) => ({
  openUrl: (url) => dispatch(NavigationActions.open(url)),
})

class EditResourcePopin extends Component {
  static propTypes = {
    types: PropTypes.arrayOf(PropTypes.string),
    resource: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      type: PropTypes.string,
      template_published: PropTypes.bool,
    }),
    open: PropTypes.bool,
    asDocument: PropTypes.bool,
    canPublish: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    openUrl: PropTypes.func,
  }

  static defaultProps = {
    open: false,
    asDocument: false,
    canPublish: false,
    types: ['document', 'to_return_document', 'video', 'link'],
    resource: { type: 'document', title: '' },
  }

  static getDerivedStateFromProps({ resource, open }, state) {
    return open && state.resource ? null : { resource }
  }

  state = {
    resource: null,
  }

  handleSubmit = () => {
    this.props.onSubmit(this.state.resource)
  }

  handlePreview = ({ value }) => {
    this.props.openUrl(value)
  }

  handleChange = ({ id, value }) => {
    this.setState(({ resource }) => ({
      resource: { ...resource, [id]: value },
    }))
  }

  handleFileChange = ({ file }) => {
    const { title } = this.props.resource
    const { url } = file || {}

    const newData = [...this.props.resource.data];
    newData[0] = {...newData[0], url: url };

    this.setState(({ resource }) => ({
      resource: { ...resource, url, title: '', data:  newData},
    }))
  }

  handleTogglePublish = () => {
    this.setState(
      ({ resource }) => ({
        resource: { ...resource, template_published: !resource.template_published },
      }),
      this.handleSubmit,
    )
  }

  renderContent() {
    const { onClose, canPublish, asDocument } = this.props
    const { resource } = this.state
    const resourceTypes = [{value: "document", label: "Document"}, {value: "video", label: "Vidéo"}, {value: "link", label: "URL"}];
    const options = resourceTypes
    .map(type => ({
      value: type.value, label: type.label
    }))
 
    // const options = Object.keys(resourceTypes)
    //   .map((value) => ({ value, label: resourceTypes[value] }))
    //   .filter(({ value }) => types.includes(value))

    const labelButton = resource.id
      ? "Editer"
      : asDocument
      ? "Valider"
      : "Ajouter"

    const title = resource.id
      ? "Editer une ressource"
      : asDocument
      ? "Votre ressource"
      : "Ajouter une ressource"

    const resourceTitle = asDocument ? "Votre ressource" : resource.title

    const locale = {
      dropZoneLabel: "Glissez et déposez votre document ici",
      uploadButtonLabel: "Upload de votre ordinateur",
      or: "ou",
      titleLabel: "Titre",
      titlePlaceholder: "Titre de la ressource",
      linkLabel: "Lien",
      linkPlaceholder: "https://",
      selectLabel: "Type de ressource",
      videoLabel: "ID Youtube",
      videoPlaceholder: "https://www.youtube.com/watch?v=a1OTIE1K90K",
      publish: resource.template_published ? 'Dé-publier' : 'Publier',
      title,
      cta: labelButton,
    }

    return (
      <FileUpload id="url" onChange={this.handleFileChange}>
        {({ onClick, onFileChange, uploading }) => (
          <DropFilePopin
            options={options}
            type={resource.type === "document" ? resource.data[0].type : resource.type}
            url={resource.url}
            title={resourceTitle}
            published={resource.template_published}
            onClose={onClose}
            onClick={onClick}
            disabled={uploading}
            noTitle={asDocument}
            onFileChange={onFileChange}
            onTogglePublish={canPublish ? this.handleTogglePublish : null}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPreview={this.handlePreview}
            locale={locale}
          />
        )}
      </FileUpload>
    )
  }

  render() {
    const { onClose, open } = this.props

    return (
      <Popin onClose={onClose} open={open}>
        <div className="resource-edit-popin">{open && this.renderContent()}</div>
      </Popin>
    )
  }
}


export default connect(null, mapDispatchToProps)(EditResourcePopin)
// Libs
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Icons
import Icon from '../Icon/Icon'
import './IconModuleType.scss'
// moduleType
const moduleType = {
  rdv: Icon.icon.Rdv,
  form: Icon.icon.Form,
  file: Icon.icon.File,
  free: Icon.icon.Free,
  freeActive: Icon.icon.FreeActive,
  exercice: Icon.icon.Exercice,
  report: Icon.icon.Report,
  document: Icon.icon.Document,
  video: Icon.icon.Video,
  document_to_return: Icon.icon.FileReturn,
  link: Icon.icon.Document,
  centraltest: Icon.icon.CentralTest,
  feedback: Icon.icon.Feedback
}

// Proptypes
const propTypes = {
  moduleType: PropTypes.oneOf(Object.values(moduleType)).isRequired,
  color: PropTypes.oneOf(Object.values(Icon.color)),
}

const defaultProps = {
  color: null,
}

class IconModuleType extends Component {
  icon() {
    switch (this.props.moduleType) {
      case moduleType.rdv:
        return <Icon icon={moduleType.rdv} color={this.props.color} />
      case 'form':
        return <Icon icon={Icon.icon.Form} color={this.props.color} />
      case moduleType.free:
        return <Icon icon={moduleType.free} color={this.props.color} />
      case moduleType.freeActive:
        return <Icon icon={moduleType.freeActive} color={this.props.color} />
      case moduleType.exercice:
        return <Icon icon={moduleType.exercice} color={this.props.color} />
      case moduleType.report:
        return <Icon icon={moduleType.report} color={this.props.color} />
      case 'link':
        return <Icon icon={Icon.icon.File} color={this.props.color} />
      case 'file':
        return <Icon icon={Icon.icon.Document} color={this.props.color} />
      case 'video':
        return <Icon icon={moduleType.video} color={this.props.color} />
      case 'document_to_return':
        return <Icon icon={moduleType.document_to_return} color={this.props.color} />
      case moduleType.link:
        return <Icon icon={Icon.icon.File} color={this.props.color} />
      case moduleType.feedback:
        return <Icon icon={moduleType.feedback} color={this.props.color} />
      case moduleType.centraltest:
        return <Icon icon={moduleType.centraltest} color={this.props.color} />
      default:
        break
    }
  }

  render() {

    const { moduleType } = this.props
    return <div className={`icon-module-type-${moduleType}`}>{this.icon()}</div>
  }
}

IconModuleType.propTypes = propTypes
IconModuleType.moduleType = moduleType
IconModuleType.defaultProps = defaultProps

export default IconModuleType

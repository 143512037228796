import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './ParcoursAttribRow.scss'
const propTypes = {
  id: PropTypes.number.isRequired,
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  stepNumber: PropTypes.number.isRequired,
  moduleNumber: PropTypes.number.isRequired,
  locale: PropTypes.shape({
    step: PropTypes.shape({
      step: PropTypes.string.isRequired,
      steps: PropTypes.string.isRequired,
    }),
    module: PropTypes.shape({
      module: PropTypes.string.isRequired,
      modules: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

const defaultProps = {
  isSelected: false,
}

class ParcoursAttribRow extends Component {
  onClick = (e) => {
    this.props.onClick(e, this.props.id)
  }

  render() {
    const { isSelected, label, stepNumber, moduleNumber, locale } = this.props
    const { step, module } = locale

    return (
      <div className={`parcours-attrib-row ${isSelected ? 'selected' : ''}`} onClick={this.onClick}>
        <div className="label">{label}</div>
        <div className="separator" />
        <div className="step-container">
          <p className="step-number">{stepNumber}</p>
          <p className="step-label">{stepNumber > 1 ? step.steps : step.step}</p>
        </div>
        <div className="module-container">
          <p className="module-number">{moduleNumber}</p>
          <p className="module-label">{moduleNumber > 1 ? module.modules : module.module}</p>
        </div>
      </div>
    )
  }
}

ParcoursAttribRow.propTypes = propTypes
ParcoursAttribRow.defaultProps = defaultProps

export default ParcoursAttribRow

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  actions as BeneficiaryGroupActions,
  selectors as BeneficiaryGroupSelectors,
} from '../../../redux/BeneficiaryGroupRedux'
import { actions as WSActions, selectors as WSSelectors } from '../../../redux/WSRedux'
import { selectors as NavigationSelectors } from '../../../redux/NavigationRedux'

import { Popin } from '../../global/Popin/Popin'
import ActionPopin from '../../../components/organisms/ActionPopin/ActionPopin'
import InputField from '../../../components/molecules/InputField/InputField'
import PrimaryButton from "../../../components/atoms/PrimaryButton/PrimaryButton";

const mapStateToProps = (state) => ({
  id: NavigationSelectors.lastParam(state),
  pathname: NavigationSelectors.pathname(state),
  group: BeneficiaryGroupSelectors.group(state),
  saving: WSSelectors.pending(state, 'beneficiaryGroup.save'),
  saved: WSSelectors.resolved(state, 'beneficiaryGroup.save'),
})

const mapDispatchToProps = (dispatch) => ({
  getGroup: (id) => dispatch(BeneficiaryGroupActions.getGroup(id)),
  saveGroup: (data) => dispatch(BeneficiaryGroupActions.saveGroup(data)),
  resetSaveAction: () => dispatch(WSActions.reset('beneficiaryGroup.save')),
})

 
class ManagerBeneficiaryGroupsEdit extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps({ group, id }, state) {
    if (state.group) {
      return null
    }

    return {
      group: /\d+/.test(id) ? group : {},
    }
  }

  state = {
    group: null,
  }

  handleChange = ({ id, value }) => {
    this.setState(({ group }) => ({
      group: { ...group, [id]: value },
    }))
  }

  handleSubmit = () => {
    const { group } = this.state

    this.props.saveGroup(group)
  }

  componentDidMount() {
    const { id, getGroup } = this.props

    if (/\d+/.test(id)) {
      getGroup(id)
    }
  }

  componentDidUpdate() {
    if (this.props.saved) {
      this.props.onClose()
      this.props.resetSaveAction()
    }
  }

  render() {
    if (!this.state.group) {
      return null
    }

    const { onClose, saving } = this.props
    const { group } = this.state
    const { name = '' } = group

    return (
      <Popin onClose={onClose} open>
        <ActionPopin
          onBack={onClose}
          disabled={saving}
          onSubmit={this.handleSubmit}
          labelButton={"Valider"}
          title={group.id ? "Editer un groupe" : "Ajouter un groupe"}
        >
          <InputField
            name="name"
            disabled={saving}
            placeholder={"Nom du groupe"}
            title={"Nom du groupe"}
            onChange={this.handleChange}
            value={name}
          />
        </ActionPopin>

        <div>
            <PrimaryButton label={"Valider"} onClick={this.handleSubmit}/>
        </div>
      </Popin>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerBeneficiaryGroupsEdit)
import React from 'react'
import PropTypes from 'prop-types'
import "./SectionTitle.scss"
// PropTypes
const propTypes = {
  label: PropTypes.string.isRequired,
}

// Component
const SectionTitle = ({ label }) => (
  <div className="section-title">
    <div className="line-left" />
    <p className="label">{label}</p>
    <div className="line-right" />
  </div>
)

SectionTitle.propTypes = propTypes

export default SectionTitle

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import TextAreaField from '../TextAreaField/TextAreaField'
import IconCardTitle from '../IconCardTitle/IconCardTitle'
import Icon from '../../atoms/Icon/Icon'
import "./NotesArea.scss"
export default class NotesArea extends Component {
  static propTypes = {
    isPrivate: PropTypes.bool,
    title: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }

  static defaultProps = {
    isPrivate: false,
    onChange: ({ id, value }) => undefined,
  }

  handleChange = ({ id, value }) => {
    this.props.onChange({ id, value })
  }

  render() {
    const { isPrivate, title, name, value, placeholder } = this.props
    const { onBlur, onFocus } = this.props
    const className = classnames('notes-area', { private: isPrivate })

    return (
      <div className={className}>
        <div className="header">
          <IconCardTitle label={title} icon={Icon.icon.Pencil} />
          {isPrivate && <Icon icon={Icon.icon.Private} />}
        </div>

        <TextAreaField
          name={name}
          onChange={this.handleChange}
          value={value}
          placeholder={placeholder}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </div>
    )
  }
}

import React from 'react'
import { connect } from 'react-redux'

import { actions as ConsultantActions } from '../../../redux/ConsultantRedux'
import { actions as WSActions, selectors as WSSelectors } from '../../../redux/WSRedux'

import { Modal } from "../../../components/atoms/Modal/Modal"
 
const mapStateToProps = (state) => ({
  activating: WSSelectors.pending(state, 'consultant.activate'),
  activated: WSSelectors.resolved(state, 'consultant.activate'),
  inError: WSSelectors.error(state, 'consultant.activate'),
})

const mapDispatchToProps = (dispatch) => ({
  activate: (id) => dispatch(ConsultantActions.activateConsultant(id)),
  resetActivateAction: () => dispatch(WSActions.reset('consultant.activate')),
})

const ManagerConsultantActivate = ({id, onClose, activate}) => {
  const handleSubmit = () => {
    activate(id);
    onClose();
  }

  return (
    <Modal  
      onClosePopin={onClose}
      openPopin={true}
      title={"Activation compte consultant"}
      content={"Voulez-vous activer ce compte consultant ?"}
      cancelButton={"Annuler"}
      actionButton={"Activer"}
      handleActionButton={handleSubmit}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerConsultantActivate)
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Page from '../../containers/global/Page/Page'
import querystring from 'querystring'

class OAuthCallbackPage extends Component {
  static propTypes = {
    code: PropTypes.string,
  }

  static defaultProps = {
    code: null,
  }

  componentDidMount() {
    const queryString = window.location.search?.replace(/^\?/, '')
    const params = querystring.parse(queryString)
    if (window?.opener) {
      window.opener.postMessage({ type: 'code', code: params?.code }, '*')
      window.close()
    } else {
    }
  }

  render() {
    return <div />
  }
}

export default Page(OAuthCallbackPage)
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconProgState from '../../atoms/IconProgState/IconProgState'
import IconModuleType from '../../atoms/IconModuleType/IconModuleType'
import CircleButton from '../../atoms/CircleButton/CircleButton'
import CheckBoxField from '../CheckBoxField/CheckBoxField'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import IconButton from '../../atoms/IconButton/IconButton'
import Icon from '../../atoms/Icon/Icon'
import './ParcoursStepSynthese.scss'
export default class ParcoursStepSynthese extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isActivated: PropTypes.bool,
    iconProgState: PropTypes.string,
    type: PropTypes.string,
    moduleIndex: PropTypes.number,
    stepIndex: PropTypes.number,
    label: PropTypes.string.isRequired,
    text: PropTypes.string,
    onClick: PropTypes.func,
    onClickLock: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickDelete: PropTypes.func,
    onClickCompleted: PropTypes.func,
    isEditable: PropTypes.bool,
    isLocked: PropTypes.bool,
    isConsultant: PropTypes.bool,
    locale: PropTypes.shape({
      completed: PropTypes.string.isRequired,
      lockTooltip: PropTypes.string,
    }).isRequired,
    isComplete: PropTypes.bool,
  }

  static defaultProps = {
    type: '',
    moduleIndex: null,
    stepIndex: null,
    text: '',
    isConsultant: false,
    isActivated: true,
    isEditable: false,
    iconProgState: IconProgState.icons.locked,
    onClick: (id) => {},
    onClickLock: () => {},
    onClickEdit: () => {},
    onClickDelete: () => {},
    onClickCompleted: () => {},
    totalQuestion: 0,
    multipleChoice: 0,
    questionToFill: 0,
    isLocked: false,
    isComplete: false,
  }

  checkForSeperator = () => {
    const { isEditable, text, children } = this.props

    if (!isEditable) {
      return !!(text || children)
    }
  }

  handleClickCompleted = () => {
    const { id, iconProgState } = this.props

    this.props.onClickCompleted({ id, iconProgState })
  }

  handleLockClick = () => {
    const { stepIndex, moduleIndex, isLocked } = this.props
    this.props.onClickLock({ stepIndex, moduleIndex, locked: !isLocked })
  }

  handleEditClick = () => {
    const { type, moduleIndex } = this.props
    this.props.onClickEdit({ type, moduleIndex })
  }

  handleDeleteClick = () => {
    const { type, moduleIndex } = this.props
    this.props.onClickDelete({ type, moduleIndex })
  }

  renderEditButton = () => {
    const { isConsultant, iconProgState, isComplete } = this.props

    if (isConsultant) {
      return <CircleButton icon="edit" onClick={this.handleEditClick} />
    }

    return (
      iconProgState !== 'locked' &&
      !isComplete && <CircleButton icon="edit" onClick={this.handleEditClick} />
    )
  }

  renderDeleteButton = () => {
    const { isConsultant } = this.props

    if (!isConsultant) {
      return null
    }

    return (
      <IconButton
        icon={Icon.icon.Trash2}
        color={Icon.color.Grey2}
        onClick={this.handleDeleteClick}
      />
    )
  }

  renderDoneInput() {
    const { isEditable, isConsultant, isComplete, locale, iconProgState } = this.props

    if (isEditable || isConsultant || iconProgState === 'locked' || iconProgState === 'finish') {
      return null
    }

    return (
      <div className="action-completed">
        <PrimaryButton label={locale.completed} id="complete" onClick={this.handleClickCompleted} />
        <CheckBoxField
          name="check"
          label={locale.completed}
          value={isComplete}
          onChange={this.handleClickCompleted}
        />
      </div>
    )
  }

  render() {
    const {
      isActivated,
      iconProgState,
      label,
      text,
      isEditable,
      children,
      isLocked,
      isConsultant,
      moduleIndex,
      locale,
    } = this.props

    return (
      <div
        className={`parcours-module-container synthesis ${isEditable ? 'editable' : 'no-editable'}`}
      >
        <div className={`header ${isActivated ? 'active' : 'disable'}`}>
          {!isEditable && <IconProgState icon={iconProgState} />}
          <IconModuleType moduleType={IconModuleType.moduleType.report} />
          <div className="label">{label}</div>

          <div className="actions">
            {moduleIndex > 0 && isConsultant && (
              <div className="action-item">
                <IconButton
                  icon={Icon.icon.Lock}
                  color={isLocked ? IconButton.color.Accent : IconButton.color.Grey2}
                  onClick={this.handleLockClick}
                  id="lock"
                  tooltip={locale.lockTooltip || ''}
                />
              </div>
            )}
            <div className="action-item">{this.renderDeleteButton()}</div>
            <div className="action-item">{this.renderDoneInput()}</div>
            <div className="action-item">{this.renderEditButton()}</div>
          </div>
        </div>

        {this.checkForSeperator() && <div className="parcours-step-separator" />}

        {text && (
          <div className="parcours-step-body">
            <p className="text">{text}</p>
          </div>
        )}
        {children && (
          <div className="children">
            {children.map((node, index) => {
              return (
                <div className={`children-item synthesis`} key={index}>
                  {node}
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

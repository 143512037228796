import React from "react";
import Icon from "../../atoms/Icon/Icon";

import './QuizButtonsGroup.scss';

export const QuizButtonsGroup = ({ onClickPreviousQuestion, onClickNextQuestion, disabledNextQuestion, disabledPreviousQuestion }) => {
    return (
        <div className="quiz-buttons-group">
            <button onClick={onClickPreviousQuestion} disabled={disabledPreviousQuestion} className={disabledPreviousQuestion ? "disabled" : "enabled"}>
                <Icon icon="back" color={"white"} />
            </button>
            <button onClick={onClickNextQuestion} disabled={disabledNextQuestion} className={disabledNextQuestion ? "disabled" : "enabled"}>
                <Icon icon="back" color={"white"} />
            </button>

        </div>
    )
}
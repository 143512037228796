import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle'
import Icon from '../../atoms/Icon/Icon'
import InputField from '../../molecules/InputField/InputFieldContainer'
import ComboField from '../../molecules/ComboField/ComboField'
import ComboFieldContainer from '../../molecules/ComboField/ComboFieldContainer'
import FileUploadField from '../../molecules/FileUploadField/FileUploadField'
import './ExperienceCard.scss'
class ExperienceCard extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    educationComboContent: PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.object),
      value: PropTypes.string.isRequired,
      clearable: PropTypes.bool,
    }),
    situationComboContent: PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.object),
      value: PropTypes.string.isRequired,
      clearable: PropTypes.bool,
    }),
    socioProfessionalCategoryComboContent: PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.object),
      value: PropTypes.string.isRequired,
      clearable: PropTypes.bool,
      required: PropTypes.bool,
    }),
    functionInputContent: PropTypes.shape({
      inputTitle: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      inputName: PropTypes.string.isRequired,
      inputValue: PropTypes.string.isRequired,
    }),
    diplomaInputContent: PropTypes.shape({
      inputTitle: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      inputName: PropTypes.string.isRequired,
      inputValue: PropTypes.string.isRequired,
    }),
    activityComboContent: PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.object),
      value: PropTypes.string.isRequired,
      clearable: PropTypes.bool,
      required: PropTypes.bool,
    }),
    companySizeComboContent: PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.object),
      value: PropTypes.string.isRequired,
      clearable: PropTypes.bool,
    }),
    nonCompanyActivitySectorComboContent: PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.object),
      value: PropTypes.string.isRequired,
      clearable: PropTypes.bool,
    }),
    resumeField: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
    readOnly: PropTypes.bool,
  }

  static defaultProps = {
    educationComboContent: null,
    situationComboContent: null,
    socioProfessionalCategoryComboContent: null,
    functionInputContent: null,
    activityComboContent: null,
    companySizeComboContent: null,
    nonCompanyActivitySectorComboContent: null,
    resumeField: null,
    readOnly: false,
  }

  handleChange = (newValue) => {
    if (!this.props.readOnly) {
      this.props.onChange(newValue)
    }
  }

  handleDeleteFile = ({ id }) => {
    const newValues = [{ id, value: '' }]

    this.handleChange(newValues)
  }

  handleFileChange = ({ id, file }) => {
    const newValues = [{ id, value: file.url }]
    this.handleChange(newValues)
  }

  render() {
    const { resumeField, readOnly } = this.props

    const comboFields = [
      'educationComboContent',
      'diplomaInputContent',
      'situationComboContent',
      'socioProfessionalCategoryComboContent',
      'functionInputContent',
      'activityComboContent',
      'companySizeComboContent',
      'nonCompanyActivitySectorComboContent',
    ]

    return (
      <div className="experience-card">
        <IconCardTitle label={this.props.title} icon={Icon.icon.Suitcase} />
        <div className="experience-card-content">
          {comboFields.map((comboFieldPropName) => {
            const comboFieldProps = this.props[comboFieldPropName]

            if (comboFieldProps !== null) {
              if ( comboFieldPropName === 'diplomaInputContent' || comboFieldPropName === 'functionInputContent' ) {
                return (
                  <InputField
                    key={comboFieldPropName}
                    title={comboFieldProps.inputTitle}
                    placeholder={comboFieldProps.placeholder}
                    name={comboFieldProps.inputName}
                    onChange={this.handleChange}
                    value={comboFieldProps.inputValue}
                    readonly={readOnly}
                  />
                )
              }

              const clearable = comboFieldProps.clearable ? comboFieldProps.clearable : false

              if (clearable) {
                return (
                  <ComboField
                    key={comboFieldPropName}
                    value={comboFieldProps.value}
                    title={comboFieldProps.title}
                    placeholder={comboFieldProps.placeholder}
                    name={comboFieldProps.id}
                    options={comboFieldProps.options}
                    onChange={this.handleChange}
                    clearable={clearable}
                    readOnly={readOnly}
                  />
                )
              }
              return (
                <ComboFieldContainer
                  key={comboFieldPropName}
                  value={comboFieldProps.value}
                  title={comboFieldProps.title}
                  placeholder={comboFieldProps.placeholder}
                  name={comboFieldProps.id}
                  options={comboFieldProps.options}
                  onChange={this.handleChange}
                  clearable={clearable}
                  readOnly={readOnly}
                  required={comboFieldProps.required || false}
                />
              )
            }

            return null
          })}
          {resumeField !== null && (
            <FileUploadField
              id={resumeField.id}
              value={resumeField.value}
              label={resumeField.label}
              handleDeleteFile={this.handleDeleteFile}
              handleFileChange={this.handleFileChange}
              readOnly={this.readOnly}
            />
          )}
        </div>
      </div>
    )
  }
}

export default ExperienceCard

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import QuizQcmRow from '../QuizQcmRow/QuizQcmRow'
import QuizQuestion from '../QuizQuestion/QuizQuestion'
import './QuizQcm.scss'
class QuizQcm extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    stepLabel: PropTypes.string,
    stepOutline: PropTypes.bool,
    image: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        isSelected: PropTypes.bool.isRequired,
      }),
    ).isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    onChange: () => undefined,
    stepLabel: null,
    disabled: false,
  }

  handleClick = (value) => {
    this.props.onChange(value)
  }
  
  selectOptions = (options) => {
    const { disabled } = this.props
    return options.map((option, i) => {
      return (
        <QuizQcmRow
          key={`${i}_${option.label}`}
          stepLabel={(i + 1).toString()}
          stepOutline={false}
          id={option.label}
          label={option.label}
          isSelected={option.isSelected}
          onClick={this.handleClick}
          disabled={disabled}
        />
      )
    })
  }

  render() {
    const { label, stepLabel, stepOutline, options, image } = this.props
    return (
      <QuizQuestion label={label} stepLabel={stepLabel} stepOutline={stepOutline}>
        {image && <img src={image} alt='question_illu' className='question-illustration'/>}
        {this.selectOptions(options)}
      </QuizQuestion>
    )
  }
}

export default QuizQcm

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import IconChevron from "../../../components/atoms/IconChevron/IconChevron";
import ParcoursListRessource from "../../../components/molecules/ParcoursListRessource/ParcoursListRessource";
import { Tag } from "../../../components/atoms/Tag/Tag";
import './ParcoursListModule.scss'
import { updateModuleContainer } from "../../../api/CourseApi"; 
import Icon from "../../../components/atoms/Icon/Icon";

export const ParcoursListModule = ({
  moduleContainer,
  openModuleContainer,
  id_step,
  isExport = false,
  course,
}) => {
  const [isOpen, setIsOpen] = useState(openModuleContainer);
  const [stateModuleContainer, setStateModuleContainer] = useState();
  const [allModules, setAllModules] = useState();


  const toggleOpen = () => {
    setIsOpen((data) => !data);
  };

  const checkContainerLocked = () => {
    const step = course.steps.find((step) => step.id === id_step);
    const modules_containers = step.modules_containers;
    const indexModuleContainer = modules_containers.findIndex((obj) => obj.id === moduleContainer.id);
    const sliceModuleContainerArray = modules_containers.slice(0, indexModuleContainer);
    setAllModules(sliceModuleContainerArray.map((module) => {
      return module.modules
    }).flat())
    const responseContainer = sliceModuleContainerArray.some(
      (obj) =>
        (obj.locked === 1 && (obj.done !== 3)
          ? 0
          : obj.done === 0
          ? 1
          : obj.done) === 0 && (obj.done !== 3),
    )
    setStateModuleContainer(responseContainer === true ? 0 : moduleContainer.done);
  }

  useEffect(() => {
    // moduleContainer.modules = moduleContainer.modules.filter((module) => module.type !== 'text');
    // const moduleInProgress = moduleContainer.modules.some(
    //   (element) => element.done === 3 || element.done === 2,
    // );
    // const moduleIsAllDone = moduleContainer.modules.every((element) => element.done === 3);
    // if (!moduleInProgress && !moduleIsAllDone) {
    //   setStateModuleContainer(1);
    //   moduleContainer.done = 1;
    //   updateModuleContainer(moduleContainer);
    // } else if (moduleIsAllDone) {
    //   setStateModuleContainer(3);
    //   moduleContainer.done = 3;
    //   updateModuleContainer(moduleContainer);
    // } else if (moduleInProgress) {
    //   setStateModuleContainer(2);
    //   moduleContainer.done = 2;
    //   updateModuleContainer(moduleContainer);
    // }
    
    if (
      ( (moduleContainer.done === 1 || moduleContainer.done === 0) ? 0 : moduleContainer.done === 0 ? 1 : moduleContainer.done) === 0
    ) {
      checkContainerLocked();
    } else {
      setStateModuleContainer(moduleContainer.done); 
    }
    

  }, [moduleContainer]);

  // useEffect(() => {
  //   setStateModuleContainer(moduleContainer.done); 
  // }, [moduleContainer]);

  return (
    <>
      <div className="parcours-list-modules" id={module.id}>
        <div
          className="parcours-list-modules-header"
          style={isOpen ? { borderBottom: '1px solid var(--grey-medium)' } : {}}
        >
          <div className="d-flex">
            <Tag state={stateModuleContainer} />
            {moduleContainer.locked === 1 && stateModuleContainer ? <Icon icon={'lock'} color={Icon.color.Grey1} /> : ''}
          </div>
          <div className="title">
            <h2>{moduleContainer.title}</h2>
            <IconChevron sendIsOpen={toggleOpen} />
          </div>
        </div>
        {isOpen && (
          <div className="parcours-list-module print-container">
            <p className="description">{moduleContainer.description}</p>
            <div className="print-container" style={{ display: 'flex', flexDirection: 'column' }}>
              {moduleContainer.modules.map((module, index) => (
                <>
                  <div className="page-break" />
                  <ParcoursListRessource
                    key={index}
                    module={module}
                    ressource={module.data}
                    id_step={id_step}
                    id_moduleContainer={moduleContainer.id}
                    isExport={isExport}
                    course={course}
                    stateModuleContainer={stateModuleContainer}
                    allModules={allModules}
                  />
                  {/* <div className="page-break" /> */}
                </>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="page-break-always" />
    </>
  );
};

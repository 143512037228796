import React, { Component } from 'react'
import FieldContainer from '../FieldContainer/FieldContainer'
import DateField from './DateField'

class DateFieldContainer extends Component {
  render() {
    return <DateField {...this.props} />
  }
}
export default FieldContainer({ type: 'text' })(DateFieldContainer);

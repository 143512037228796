// React & Libs
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../atoms/Icon/Icon'
import InputField from '../../molecules/InputField/InputFieldContainer'
import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle'
import './CoordCard.scss'
// Proptypes
const propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  zip_code: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  city: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  phone_mobile: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  phone_home: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  email: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    readonly: PropTypes.bool,
  }),
  onChange: PropTypes.func,
  locale: PropTypes.shape({
    address: PropTypes.string,
    addressPlaceHolder: PropTypes.string,
    zip_code: PropTypes.string,
    postCodePlaceHolder: PropTypes.string,
    city: PropTypes.string,
    cityPlaceHolder: PropTypes.string,
    phone_mobile: PropTypes.string,
    mobilePlaceHolder: PropTypes.string,
    phone_home: PropTypes.string,
    phonePlaceHolder: PropTypes.string,
    email: PropTypes.string,
    emailPlaceHolder: PropTypes.string,
  }).isRequired,
  readOnly: PropTypes.bool,
  isNew: PropTypes.bool
}

const defaultProps = {
  address: null,
  zip_code: null,
  city: null,
  phone_mobile: null,
  phone_home: null,
  email: null,
  onChange: ({ id, value }) => { },
  readOnly: false,
}

// Component
class CoordCard extends Component {
  handleChange = ({ id, value }) => {
    if (!this.props.readOnly) {
      this.props.onChange({ id, value })
    }
  }

  render() {
    const { title, address, zip_code, city, phone_mobile, phone_home, email, locale, readOnly, isNew } = this.props

    return (
      <div className="coord-card">
        <IconCardTitle label={title} icon={Icon.icon.House} />
        <div className="form">
          <div className="fields">
            {email !== null && (
              <InputField
                title={locale.email}
                name={email.id}
                placeholder={locale.emailPlaceHolder}
                value={email.value}
                readonly={readOnly || (email.readonly != null ? email.readonly : true)}
                onChange={this.handleChange}
                required={true}
              />
            )}

            {!isNew && address !== null && (
              <InputField
                title={locale.address}
                name={address.id}
                placeholder={locale.addressPlaceHolder}
                value={address.value}
                onChange={this.handleChange}
                readonly={readOnly}
                required={false}
              />
            )}

            <div className='fields-flex'>

            {!isNew && zip_code !== null && (
              <InputField
                title={locale.zipCode}
                name={zip_code.id}
                placeholder={locale.postCodePlaceHolder}
                // placeholder='placeholder'
                value={zip_code.value}
                onChange={this.handleChange}
                readonly={readOnly}
                required={false}
              />
            )}
            {!isNew && city !== null && (
              <InputField
                title={locale.city}
                name={city.id}
                placeholder={locale.cityPlaceHolder}
                value={city.value}
                onChange={this.handleChange}
                readonly={readOnly}
                required={false}
              />
            )}
            </div>

            <div className='fields-flex'>
            {!isNew && phone_mobile !== null && (
                <InputField
                  title={locale.mobile}
                  name={phone_mobile.id}
                  placeholder={locale.mobilePlaceHolder}
                  value={phone_mobile.value}
                  onChange={this.handleChange}
                  readonly={readOnly}
                  required={true}
                />
            )}
            {!isNew && phone_home !== null && (
              <InputField
                title={locale.phone}
                name={phone_home.id}
                placeholder={locale.phonePlaceHolder}
                value={phone_home.value}
                onChange={this.handleChange}
                readonly={readOnly}
              />
            )}
            </div>


          </div>
        </div>
      </div>
    )
  }
}

CoordCard.propTypes = propTypes
CoordCard.defaultProps = defaultProps

export default CoordCard

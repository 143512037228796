/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import Page from '../../containers/global/Page/Page';
import BeneficiaryPageAside from '../../containers/beneficiary/BeneficiaryPageAside/BeneficiaryPageAside';
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside';
import ManagerPageAside from '../../containers/manager/ManagerPageAside/ManagerPageAside';

import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout';
import { ParcoursLeftContainer } from '../../containers/beneficiary/ParcoursLeftContainer/ParcoursLeftContainer';
import { ParcoursRightContainer } from '../../containers/beneficiary/ParcoursRightContainer/ParcoursRightContainer';
import { selectors as NavigationSelectors } from '../../redux/NavigationRedux';
import { selectors as UserSelectors } from '../../redux/UserRedux';
import { getCourseById, updateCourse, updateStep, updateModuleContainer, updateModule } from '../../api/CourseApi';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';
import Icon from '../../components/atoms/Icon/Icon';
import './BeneficiaryCoursePage.scss';
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton';
import { getUser } from '../../api/userAPI';
// import { forEach } from 'lodash';

const mapStateToProps = (state, props) => ({
  module_id: props.match.params.module_id || NavigationSelectors.query(state, 'module_id'),
  user: UserSelectors.user(state),
});

const BeneficiaryCoursePage = (props) => {
  const componentRef = useRef(null);
  const [step, setStep] = useState({});
  const [isExport, setIsExport] = useState(false);
  const [talent, setTalent] = useState({});
  const [course, setCourse] = useState({});
  // const [questions, setQuestions] = useState([]);
  const location = useLocation();

  // get course
  const fetchCourses = async (id) => {
    const data = await getCourseById(id);
    setCourse(data.course);
    return data;
  };

///////////////////////////////////
const markAllAsDone = (elements) => {
  elements.forEach(element => element.done = 3);
};

const areAllElementsDone = (elements) => {
  return elements.every(element => element.done === 3);
};

const updateModuleFormQuestions = (moduleData) => {
  moduleData.forEach((dataElement) => {
    if (dataElement.module_form_questions) {
      if (areAllElementsDone(dataElement.module_form_questions)) {
        markAllAsDone(dataElement.module_form_questions);
      }
    } 
  });
};

const updateModuleData = (module) => {
  updateModuleFormQuestions(module.data);
  if (areAllElementsDone(module.data)) {
    markAllAsDone(module.data);
    module.done = 3;
  }
};

const updateModuleContainers = (moduleContainer) => {
  moduleContainer.modules.forEach((module) => {
    updateModuleData(module);
  });
  if (areAllElementsDone(moduleContainer.modules)) {
    markAllAsDone(moduleContainer.modules);
    moduleContainer.done = 3;
  } else if (moduleContainer.modules.some(module => module.done === 3)) {
    moduleContainer.done = 2;
  } else if (moduleContainer.modules.every(module => module.done !== 3 && module.done !== 2)) {
    moduleContainer.done = 1;
  }
};

const processStep = (step) => {
  step.modules_containers.forEach((moduleContainer) => {
    updateModuleContainers(moduleContainer);
  });
  if (areAllElementsDone(step.modules_containers)) {
    markAllAsDone(step.modules_containers);
    step.done = 3;
  } else if (step.modules_containers.some(module => module.done === 3)) {
    step.done = 2;
  } else if (step.modules_containers.every(module => module.done !== 3 && module.done !== 2)) {
    step.done = 1;
  }
};

const checkCourseStatus = () => {
  if (!course || !course.steps || course.steps.length === 0) return;

  let courseTmp = course
  course.steps.forEach((step, i) => {
    processStep(step);
    // Mettre à jour l'étape entière avec le backend
    // updateStep(step);
    courseTmp.steps[i] = step;
  });
  updateCourse(courseTmp);
};
//////////////////////////////////








  useEffect(() => {
    checkCourseStatus();
  }, [course]);

  useEffect(() => {
    fetchCourses(parseInt(props.match.params.course_id));
  }, [props.match.params.course_id]);

  useEffect(() => {
    fetchCourses(parseInt(props.match.params.course_id));
    
  }, []);

  const fetchData = async () => {
    await fetchCourses(parseInt(props.match.params.course_id));
    if (course?.steps) {
      const firstStepNotDone = course.steps.find((element) => element.done !== 3);
      if (firstStepNotDone) {
        setStep(firstStepNotDone);
        handleStepActive(firstStepNotDone);
      } else {
        const firstStep = course.steps[0];
        firstStep && setStep(firstStep);
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchCourses(parseInt(props.match.params.course_id));
        const coursetmp = response.course;
        getUser(coursetmp.user_id).then((res) => {setTalent(res.user);});
        setCourse(coursetmp);

        fetchData();
      } catch (error) {
        console.error('Une erreur est survenue lors de la récupération des courses :', error);
      }
    })();
  }, []);

  // const resetCourse = () => { //TODO: Reinitialiser le cours
  //   // course.steps.forEach(element => {
  //   //     element.done = 1;
  //   // });

  // }

  useEffect(() => {
    if (location.state && course && course.steps) {
      const stepId = location.state.step.id;
      const m = course.steps.find((step) => step.id === stepId).modules_containers;
      const modulesContainersInProgress = m.some((element) => element.done === 2);
      const modulesContainersAllDone = m.every((element) => element.done === 3);

      if (modulesContainersInProgress) {
        const newStep = { ...location.state.step, done: 2 };
        updateStep(newStep);
        handleStepActive(newStep);
      } else if (modulesContainersAllDone) {
        const newStep = { ...location.state.step, done: 3 };
        updateStep(newStep);
        handleStepActive(newStep);
      } else {
        const newStep = { ...location.state.step, done: 1 };
        updateStep(newStep);
        handleStepActive(newStep);
      }
    } else {
      if (course?.steps) {
        const firstStepNotDone = course.steps.find((element) => element.done !== 3);
        if (firstStepNotDone) {
          setStep(firstStepNotDone);
          handleStepActive(firstStepNotDone);
        } else {
          const firstStep = course.steps[0];
          firstStep && setStep(firstStep);
        }
      }
    }
  }, [location, course]);

  useEffect(() => {
    if (location && location.state?.quiz) {
      if (location.state.quiz) {
        const quizModule = location.state.quiz;
        if (step.modules_containers) {
          for (let moduleContainer of step.modules_containers) {
            for (let module of moduleContainer.modules) {
              if (module.id === quizModule.id) {
                Object.assign(module, quizModule);
              }
            }
          }
        }
      }
    }
  }, [step, location]);

  const recupererDonneeParId = (Recherche) => {
    if (!course || course === undefined || Object.keys(course).length === 0) {
      return Recherche;
    }
    const donneeTrouvee = course['steps'].find((donnee) => donnee.id === Recherche.id);
    return donneeTrouvee || Recherche;
  };
  const handleStepActive = (stepActive) => {
    setStep(recupererDonneeParId(stepActive));
  };

  /// export

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const exportCourse = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `Export_${(course.title ? course.title.replace(/ /g, '_') + '_' + talent?.first_name + talent?.last_name : 'Export')}_${new Date().toLocaleDateString()}`,
    // onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: () => {
      setIsExport(true);
    },
    onAfterPrint: () => {
      setIsExport(false);
    },
    copyStyles: true,
    removeAfterPrint: true,
  });

const [isEdit, setIsEdit] = useState(true);
  
const renderAlertBanner = () => (
      <div 
        className="course-edit-alert"
        style={{ display: isExport ? 'none' : 'flex'}}
      >
      <div className="info">
        <strong>Attention : mode édition activée</strong>
        <span className="tooltip-text">
          <Icon icon="help" color="red" /> 
          <span className="tooltip-content">
            Dans le mode édition, lorsque vous parcourez une ressource ou complétez une information, le module ou la ressource se valide automatiquement.
          </span>
        </span>
      </div>
    </div>
  );

  // Export

  const contentCourse = () => {
  
    if (course !== undefined && course.steps) {
      return (
        <div ref={componentRef} className="beneficiary-course-page-content print-container" >
          {/* {!isExport && <PrimaryButton
            label="test"
            style={{ height: '15px' }}
            onClick={() => {
              setIsExport(true); // Définir isExport à true avant l'exportation
              setTimeout(() => {
                exportCourse(); // Déclencher l'exportation après un léger délai
              }, 100);
            }}
          />} */}

{isEdit && props.user.role === 'consultant' && renderAlertBanner()}
          
          <ParcoursLeftContainer
            parcours={course}
            stepActive={handleStepActive}
            getActiveStep={step}
            user={props.user}
            isExport={isExport}
            exportFunction={() => {
              setIsExport(true); // Définir isExport à true avant l'exportation
              setTimeout(() => {
                exportCourse(); // Déclencher l'exportation après un léger délai
              }, 100);
            }}
            // resetCourse={resetCourse}
          />
          <ParcoursRightContainer step={step} isExport={isExport} course={course.steps.length ? course : []} />
        </div>
      );
    }
  };

  let aside;
  switch (props.user.role) {
    case 'consultant':
      aside = <ConsultantPageAside />;
      break;
    case 'beneficiary':
      aside = <BeneficiaryPageAside />;
      break;
    case 'manager':
      aside = <ManagerPageAside />;
      break;
    default:
      return null;
  }

  const content = contentCourse();

  return (
    <div className="beneficiary-course-page">
      <LeftAsideLayout aside={aside} content={content} />
    </div>
  );
};

export default connect(mapStateToProps, null)(Page(BeneficiaryCoursePage));

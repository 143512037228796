import React from 'react'
import { connect } from 'react-redux'
// import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux'
import { actions as UserActions } from '../../../redux/UserRedux'

import UserLeftColumnLayout from '../../../layouts/UserLeftColumnLayout/UserLeftColumnLayout'
import SideMenu from '../../../components/organisms/SideMenu/SideMenu'
import LogoutButton from '../../../components/atoms/LogoutButton/LogoutButton'
import Icon from '../../../components/atoms/Icon/Icon'

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(UserActions.logout()),
  redirect: (path) => dispatch(NavigationActions.replace(path)),
})


const AdminPageAside = (pathname) => {
  const dispatch = useDispatch()
  const handleMenuChange = ({ id }) => {
    const link = getLinks().find((link) => link.id === id)
    if (!link || !link.options) {
      pathname.redirect(id)
    }
  }

  const logout = () => dispatch(UserActions.logout({force: true}))

  const getLinks = () => [
    {
      id: "/admin/offices",
      label: "Clients",
      icon: Icon.icon.Building,
    },
    {
      id: "/admin/templates",
      label: "Modèles",
      icon: Icon.icon.Paperboard,
      options: [
        { id: "/admin/templates/courses", label: "Parcours" },
        // { id: "/admin/templates/modules", label: "Modules" },
        // { id: "/admin/templates/resources", label: "Ressources" },
      ],
    },
    {
      id: "/admin/stats",
      label: "Statistiques",
      icon: Icon.icon.Piechart,
    },
  ]

  const renderHeader = () => {
    return(
      <div>
        <img
          className="mytalents-logo"
          alt="Logo"
          src="/static/logos/logo-my-talents-dark.svg"
          width={60}
          height={"auto"}
        />
      </div> 
    )

  }
  const renderMenu = () => {
    return (
      <SideMenu
        links={getLinks()}
        pathname={pathname.pathname}
        onClick={handleMenuChange}
        onClickOption={handleMenuChange}
      />
    )
  }


  const renderFooter = () => {
    return <LogoutButton label={"Déconnexion"} onClick={logout} />
  }
  return (
    <div className="beneficiary-page-aside" >
      <UserLeftColumnLayout
        header={renderHeader()}
        menu={renderMenu()}
        footer={renderFooter()}
      />
    </div >
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPageAside);

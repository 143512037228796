import { getCountries, getAPE } from "../../../api/utils"

const typeDropdown = [
    {label: "Client", value: "client"}
]

const liabilityTvaDropdown = [
    {label: "Taxable", value: "taxable"},
    {label: "Partiellement taxable", value: "partially_taxable"},
    {label: "Exonéré", value: "exempt"}
]

async function formatCountries() {
    try {
        const countries = await getCountries();
        const countriesDropdown = countries.map((country) => {
            return {
                label: country.nom_fr,
                value: country.nom_fr.toLowerCase()
            };
        });
        return countriesDropdown;
    } catch (error) {
        console.error("Une erreur s'est produite :", error);
        return [];
    }
}

async function formatAPE() {
    try {
        const ape = await getAPE();
        const apesDropdown = ape.map((ape_) => {
            return {
                label: `${ape_.id} - ${ape_.label}`,
                value: `${ape_.id} - ${ape_.label}`
            };
        });
        return apesDropdown;
    } catch (error) {
        console.error("Une erreur s'est produite :", error);
        return [];
    }
}

export { typeDropdown, liabilityTvaDropdown, formatCountries, formatAPE };
import React, { useEffect, useState, useLayoutEffect } from 'react';
import { ParcoursListModule } from '../../module/ParcoursListModule/ParcoursListModule';
import { ParcoursInfos } from '../../../components/molecules/ParcoursInfos/ParcoursInfos';
import './ParcoursRightContainer.scss';
import { Tag } from '../../../components/atoms/Tag/Tag';
import { element } from 'prop-types';
// import { updateStep } from "../../../api/CourseApi";

export const ParcoursRightContainer = ({ step, isExport = false, course = null }) => {
  const [stateModuleActive, setStateModuleActive] = useState(0);
  const [modulesContainer, setModulesContainer] = useState();
  const [stateStep, setStateStep] = useState();
  const [countActiveModule, setCountActiveModule] = useState(0);

  useEffect(() => {
    step && setModulesContainer(step.modules_containers);
  }, [step]);

  useEffect(() => {
    step && setStateStep(step.done);
  }, [step]);

  useLayoutEffect(() => {
    const elements = document.querySelectorAll('.activeModule');
  
    if (elements.length > 0) {
      elements[0].scrollIntoView({ behavior: 'smooth', block: 'nearest', // Aligne l'élément au début du conteneur visible
        inline: 'nearest' });
    }
  }, [course]);

  return (
    <>
      {/* {course.lenght && course.steps && !isExport} */}
      {course !== undefined && course.steps && course.steps.length > 0 && isExport ? (
        course.steps.map((stepObj, index) => (
          <>
            {/* <div className="page-break-always" /> */}

            <div key={index} className="beneficiary-parcours-right-container">
              <div className="background-white"></div>
              <div className="beneficiary-parcours-step-header">
                <Tag state={stepObj.done} isBackgroundColor={true} />
                <h1>{stepObj?.title}</h1>
                <p className="description">{stepObj?.description}</p>
                {stepObj?.duration && (
                  <div className="beneficiary-parcours-step-infos">
                    <div className="parcours-group-infos">
                      <ParcoursInfos
                        icon={'clock'}
                        label={'Durée'}
                        count={stepObj?.duration}
                        colorBackground={'blue'}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className={`${!isExport ? "beneficiary-parcours-all-modules" : "beneficiary-parcours-all-modules-Export"}`}>
                {stepObj.modules_containers &&
                  stepObj.modules_containers.map((moduleContainer, i) => {
                    return (
                      <div key={i}>
                        {/* <div className="page-break" /> */}

                        <ParcoursListModule
                          moduleContainer={moduleContainer}
                          key={i}
                          openModuleContainer={true}
                          id_step={stepObj.id}
                          isExport={isExport}
                          course={course}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            {/* <div className="page-break" /> */}
          </>
        ))
      ) : (
        <div className="beneficiary-parcours-right-container">
          <div className="background-white"></div>
          <div className="beneficiary-parcours-step-header">
            <Tag state={stateStep} isBackgroundColor={true} />
            <h1>{step?.title}</h1>
            <p className="description">{step?.description}</p>
            {step?.duration && (
              <div className="beneficiary-parcours-step-infos">
                <div className="parcours-group-infos">
                  <ParcoursInfos
                    icon={'clock'}
                    label={'Durée'}
                    count={step?.duration}
                    colorBackground={'blue'}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={`${!isExport ? "beneficiary-parcours-all-modules" : "beneficiary-parcours-all-modules-Export"}`}>
            {modulesContainer &&
              modulesContainer.map((moduleContainer, index) => (
                <div className={`${moduleContainer.done !== 3 ? 'activeModule' : ''}`} key={index}>
                  <ParcoursListModule
                    moduleContainer={moduleContainer}
                    key={index}
                    openModuleContainer={true}
                    id_step={step.id}
                    isExport={isExport}
                    course={course}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

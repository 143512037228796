import { useState } from "react";

export const useToaster = () => {
    const [toasters, setToasters ] = useState([]);

    const addToaster = (title, message, type) => {
        const newToaster = {id: Date.now(), title, message, type };
        setToasters([...toasters, newToaster]);
    }

    const removeToaster = (id) => {
        const updatedToasters = toasters.filter(toaster => toaster.id !== id);
        setToasters(updatedToasters);
    }

    return { toasters, addToaster, removeToaster } 
}

import React, { useState } from "react";

import Icon from "../../../components/atoms/Icon/Icon";
import SearchField from "../../../components/molecules/SearchField/SearchField";
import { MissionsList } from "../../missions/MissionsList/MissionsList";

import './ClientMissionsContainer.scss';

export const ClientMissionsContainer = ({ client, missionsprops }) => {
    const [missions, setMissions] = useState(missionsprops);
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchTalentOnChange = (search) => {
        setSearchQuery(search.value);
        const lowerCaseQuery = search.value.toLowerCase();
        const filteredMissions = missionsprops.filter(mission => mission.name.toLowerCase().includes(lowerCaseQuery));
        setMissions(filteredMissions);
    }

    const nb_missions = missionsprops ? missionsprops.length : 0;
    return (
        <div className="client-missions-container">
            <div className="client-missions-header">
                <h2>Les missions ({nb_missions})</h2>
                <div className="client-missions-filters">
                    <Icon icon={"sliders"} color={'accent'} />
                    <SearchField placeholder={"Rechercher par nom d'une mission ou responsable"} value={searchQuery} onChange={handleSearchTalentOnChange} />
                </div>
            </div>
            <div className="all-missions-client">
                {missionsprops && missionsprops.length > 0 
                    ? <MissionsList missions={missions} isClickable={false} showClient={false} showTalents={false}/>
                    : <p>Aucune mission...</p>
                }
                
            </div>
        </div>
    )
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _keyBy from 'lodash/keyBy'
import classnames from 'classnames'

 

import { selectors as StatsSelectors } from '../../../redux/StatsRedux'
import StatsBarChart from '../../../components/organisms/StatsBarChart/StatsBarChart'

const mapStateToProps = (state) => ({
  managerOfficeStats: StatsSelectors.managerOfficeStats(state),
})

class ManagerChartStatistics extends Component {
  static propTypes = {

    managerOfficeStats: PropTypes.objectOf(PropTypes.any),
  }

  static defaultProps = {
    managerOfficeStats: null,
  }

  renderBeneficiaryCountByGenderChart = () => {
    const { managerOfficeStats } = this.props

    let data = []
    if (managerOfficeStats.beneficiaryCountByGender.length > 0) {
      data = managerOfficeStats.beneficiaryCountByGender.map((d) => (
      {
        xValue: d.label === "male" ? "Homme" : "Femme",
        yValue: d.count,
      }))
    }

    return (
      <StatsBarChart
        className={classnames('statsChart', 'by-gender')}
        title={"Répartition par sexe"}
        displayYPercents
        data={data}
        ratio={{
          width: 280,
          height: 275,
        }}
      />
    )
  }

  renderBeneficiaryCountByFormationChart = () => {
    const { managerOfficeStats } = this.props

    let data = []
    if (managerOfficeStats.beneficiaryCountByFormation.length > 0) {
      const beneficiaryFormations = ["I", "II", "III", "IV", "V", "VBis", "VI"]
      const dataValues = _keyBy(managerOfficeStats.beneficiaryCountByFormation, 'label')
      const defaultData = {
        yValue: 0,
      }
      data = Object.keys(beneficiaryFormations).map((beneficiaryFormationKey) => {
        let values = defaultData
        if (dataValues[beneficiaryFormationKey]) {
          values = {
            ...values,
            yValue: dataValues[beneficiaryFormationKey].count,
          }
        }

        return {
          ...values,
          xValue: beneficiaryFormations[beneficiaryFormationKey],
        }
      })
    }

    return (
      <StatsBarChart
        className={classnames('statsChart', 'by-formation')}
        title={"Niveau de formation"}
        data={data}
        ratio={{
          width: 440,
          height: 275,
        }}
      />
    )
  }

  renderBeneficiaryCountByFinancerChart = () => {
    const { managerOfficeStats } = this.props

    let data = []
    if (managerOfficeStats.beneficiaryCountByFinancer.length > 0) {
      const beneficiaryTypes = ["Particulier", "OPACIF", "OPCA", "France travail", "Entreprise", "Etat", "Collectivité territoriale", "Etablissement public", "CPF", "Autre"]

      const dataValues = _keyBy(managerOfficeStats.beneficiaryCountByFinancer, 'label')
      const defaultData = {
        yValue: 0,
      }
      data = Object.keys(beneficiaryTypes).map((beneficiaryTypeKey) => {
        let values = defaultData
        if (dataValues[beneficiaryTypeKey]) {
          values = {
            ...values,
            yValue: dataValues[beneficiaryTypeKey].count,
          }
        }

        return {
          ...values,
          xValue: beneficiaryTypes[beneficiaryTypeKey],
        }
      })
    }

    return (
      <StatsBarChart
        className={classnames('statsChart', 'by-financer')}
        title={"Origine du financement"}
        data={data}
        ratio={{
          width: 745,
          height: 275,
        }}
      />
    )
  }

  render() {
    return (
      <div className="charts-wrapper">
        {this.renderBeneficiaryCountByGenderChart()}
        {this.renderBeneficiaryCountByFormationChart()}
        {this.renderBeneficiaryCountByFinancerChart()}
      </div>
    )
  }
}

export default connect(mapStateToProps, null)(ManagerChartStatistics)
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectors as UserSelectors } from '../../../redux/UserRedux';
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../../redux/NavigationRedux';
import { selectors as BeneficiarySelectors } from '../../../redux/BeneficiaryRedux';

import CourseRemove from '../CourseRemove/CourseRemove';
import ParcoursCard from '../../../components/organisms/ParcoursCard/ParcoursCard';
import './CourseList.scss';
import { round } from 'lodash';

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  user: UserSelectors.user(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
});

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
});

class CourseList extends Component {
  static propTypes = {
    onCardClick: PropTypes.func,
    deleteCourse: PropTypes.func,
  };

  static defaultProps = {
    onCardClick: ({ id }) => undefined,
  };

  handleCardClick = ({ id }) => {
    this.props.onCardClick({ id });
  };

  handleRemoveClick = ({ id }) => {
    this.props.deleteCourse(id);
  };

  handleClosePopin = () => {
    this.props.redirect(this.props.pathname.replace(/\/courses\/.+$/, '/courses'));
  };

  handleEditClick = ({ id }) => {
    this.props.redirect(`/consultant/templates/courses/${id}`);
  };

  renderRemovePopin() {
    if (!/\/\d+\/remove/.test(this.props.pathname)) {
      return null;
    }

    return <CourseRemove onClose={this.handleClosePopin} />;
  }

  renderCards() {
    const { beneficiary, user, courses } = this.props;
    const { consultants = [] } = beneficiary;

    const currentConsultant = consultants.find((consultant) => {
      return consultant.profile_id === user.profile_id && consultant.is_primary
    })

    const cards = courses?.map(({ id, title, cover_url, steps, percentage_done, nb_steps, nb_modules, nb_modules_done }) => {
      const modulesDonePercentage = round(nb_modules_done / nb_modules * 100);
      return (
        <ParcoursCard
          course={{
            id,
            coverUrl: cover_url,
            isEditable: true,
            title,
            canDelete: true,
            canPreview: true,
            stepsCount: nb_steps,
            modulesCount: nb_modules,
            isRemovable: !!currentConsultant,
            percentage_done: modulesDonePercentage,
          }}
          key={id}
          onCardClick={this.handleCardClick}
          onRemoveClick={this.handleRemoveClick}
          onEditClick={this.handleEditClick}
        />
      )
    })

    return (
      cards ? <div className="courses">{cards}</div> : <div className="courses">Aucun parcours suivi pour le moment</div>
    )
  }

  render() {
    return (
      <div className="course-list">
        {this.renderCards()}
        {this.renderRemovePopin()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseList);

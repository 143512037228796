import React from "react";
import { Modal } from "../../../components/atoms/Modal/Modal";

export const DontLeavePopin = ({ open, onClose, handleActionButton}) => {


    const renderContent = () => {
        return (
            <p>Voulez-vous quitter sans sauvegarder ? Vos données seront perdues</p>
         )
    }  

    return (
        <Modal
        onClosePopin={onClose}
        openPopin={open}
        title={"Quitter sans sauvegarder ?"}
        content={renderContent()}
        cancelButton={"Non"}
        actionButton={"Oui"}
        handleActionButton={handleActionButton}

        />
    )
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { actions as UserActions } from '../../redux/UserRedux'
import { actions as NavigationActions } from '../../redux/NavigationRedux'
import { selectors as WSSelectors } from '../../redux/WSRedux'
 
import ForgotPasswordPopin from '../../components/organisms/ForgotPasswordForm/ForgotPasswordPopin'
import Logo from '../../static/assets/logo-my-talents-white.svg';
import './ForgotPasswordPage.scss';

const mapStateToProps = (state) => ({
  pending: WSSelectors.pending(state, 'auth.forgotPassword'),
})

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(NavigationActions.push('/')),
  forgotPassword: (email) => dispatch(UserActions.forgotPassword(email)),
})

class ForgotPasswordPage extends Component {
  static propTypes = {
    forgotPassword: PropTypes.func,
  }

  state = {
    done: false,
  }

  handleClose = () => {
    this.props.close()
  }

  handleSubmit = ({ email }) => {
    this.props.forgotPassword(email)
    this.setState({ done: true })
  }

  render() {
    const { pending } = this.props
    const { done } = this.state

    return (
      <div className="forgot-password-page" >
        <Logo className="logo" />

        <ForgotPasswordPopin
          onClose={this.handleClose}
          onSubmit={this.handleSubmit}
          done={done}
          disabled={pending}
          locale={{
            title: "Vous avez oublié votre mot de passe ?",
            infoDescription: "Pas de panique ! Saisissez votre adresse email ci dessous pour le réinitialiser",
            sentDescription: "Vous pouvez dès à présent modifier votre mot de passe via le lien de réinitialisation que nous vous avons fait parvenir par courriel",
            cta: "Demander mon nouveau mon de passe",
            emailLabel: "Votre email valide",
          }}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage)
const AccompanimentCoJobLocale = {
    companyLabel: "Entreprise",
    startDateLabel: "Date d'embauche",
    locationLabel: "Site géographique",
    seniorityLabel: "Ancienneté",
    remunerationLabel: "Rémunération  (K€/an)",
    monthLabels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    dayShortLabels: ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim'],
}



export {
    AccompanimentCoJobLocale
}
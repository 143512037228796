import React, { Component } from 'react'
import CheckBoxField from './CheckBoxField'
import FieldContainer from '../FieldContainer/FieldContainer'


class CheckBoxFieldContainerFieldContainer extends Component {
  render() {
    return <CheckBoxField {...this.props} value={!!this.props.value} />
  }
}

export default FieldContainer({ type: 'checkbox' })(CheckBoxFieldContainerFieldContainer);
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import { selectors as UserSelectors } from "../../../redux/UserRedux";

import { addGroup, addTalentGroup, deleteTalentGroup, updateGroup } from "../../../api/groupsApi";
import { addTalentMission } from "../../../api/MissionsApi";

import { AddGroupPopin } from "../AddGroupPopin/AddGroupPopin";
import { IconBack } from "../../../components/atoms/IconBack/IconBack";
import { GroupContainer } from "../GroupContainer/GroupContainer";
import PopinLayout from "../../../layouts/PopinLayout/PopinLayout";
import PrimaryButton from "../../../components/atoms/PrimaryButton/PrimaryButton";

import { useNavigation } from "../../../hooks/useNavigation";
import { useHistory } from 'react-router-dom';

import './AddGroupLayout.scss';

export const AddGroupLayout = () => {
    const user = useSelector(UserSelectors.user);
    const [newGroup, setNewGroup] = useState({});
    const [showAddModal, setShowAddModal] = useState(false);
    const [members, setMembers] = useState([]);
    const [disabledSaveButton, setDisabledSaveButton] = useState(true);
    const history = useHistory();

    const handleShareGroupOnClick = () => { }
    const { redirect } = useNavigation();

    useEffect(() => {
        setNewGroup({ user_id: user.id })
    }, [user.id])

    useEffect(() => {
        const { name, user_id, client_id, mission_id } = newGroup;
        name && user_id && setDisabledSaveButton(false);

        name && user_id && client_id && !mission_id && setDisabledSaveButton(true);
    }, [newGroup])

    const handleSaveGroupOnClick = () => {
        const { id } = newGroup;
        if (id) {
            handleUpdateGroup()
            // redirect('/consultant/beneficiaries/groups');
            history.push({ pathname: '/consultant/beneficiaries/groups', state: { newGroup} });
        }
        else {
            handleAddGroup();
        }
    }

    const handleDropdownChange = (dropdown) => {
        const { value, id } = dropdown
        setNewGroup({ ...newGroup, [id]: value.value })

        if (id === "client_id" && value === null) {
            setNewGroup({ ...newGroup, mission_id: null })
        }
    }

    const handleInputChange = (input) => {
        const { id, value } = input;
        id === "name" && setNewGroup({ ...newGroup, name: value });
    }

    // Add group 
    const handleAddGroup = () => {
        addGroup(newGroup).then((response) => {
            setNewGroup(response.group);
            setDisabledSaveButton(true);
        })
    }

    // Add talent mission
    const handleAddTalentMission = (mission_id, talent_id) => {
        addTalentMission(mission_id, talent_id);
    }

    // Update group
    const handleUpdateGroup = () => {
        updateGroup(newGroup.id, newGroup);        
        // .then(() => {
        //     addToaster("Groupe ajouté", `Le groupe ${newGroup.name} a été ajouté avec succès`, "success");
           

        // })
        // .catch(() => {
        //     addToaster("Erreur", `Une erreur est survenue lors de l'ajout du groupe ${newGroup.name}`, "error")
        // })
        // ;
    }

    // Delete talents
    const handleDeleteMember = (member) => {
        const filterMembers = members.filter(m => m.id !== member.id);
        setMembers(filterMembers)
        deleteTalentGroup(newGroup.id, member.id)
    }

    const getNewMembers = (newMembers) => {
        setMembers((prevMembers) => [...prevMembers, ...newMembers]);
        setShowAddModal(false);
        setNewGroup({ ...newGroup, group_users: newMembers })
        newMembers.forEach(talent => {
            const new_talent = { user_id: talent.user_id || talent.id }
            addTalentGroup(newGroup.id, new_talent);
            handleAddTalentMission(newGroup.mission_id, new_talent)
        });
    }

    const renderContent = () => {
        return (
            <GroupContainer
                user={user}
                group={newGroup}
                members={members}
                deleteMember={handleDeleteMember}
                showModalAddMember={() => setShowAddModal(true)}
                handleInputChange={handleInputChange}
                handleDropdownChange={handleDropdownChange} />
        )
    }

    const renderHeader = () => {
        const { name } = newGroup
        return (
            <div className="group-header">
                <div className="group-title">
                    <IconBack iconColor={"accent"} />
                    <input value={name ? name : ""} placeholder="Nouveau groupe" onChange={(name) => setNewGroup({ ...newGroup, name: name.target.value })} />
                </div>
                <div className="group-actions">
                    <PrimaryButton id={"share"} outline={true} label={"Partager"} onClick={handleShareGroupOnClick} />
                    <PrimaryButton id={"save"} label={"Sauvegarder"} onClick={handleSaveGroupOnClick} disabled={disabledSaveButton} />
                </div>
            </div>
        )
    }

    return (
        <>
            <PopinLayout header={renderHeader()} content={renderContent()} />
            {showAddModal && <AddGroupPopin open={showAddModal} onClose={() => setShowAddModal(false)} user={user} setMembers={getNewMembers} />}
        </>
    )
}
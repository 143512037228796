import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { selectors as UserSelectors } from '../../../redux/UserRedux'
import {
  needToFillFinancerInfo,
  waitingCreationValidation,
  waitingPersonnalInfoValidation,
} from '../../../helpers/beneficiaryStatus'
import InfoPage from '../../../components/molecules/InfoPage/InfoPage'
import BeneficiaryProfileForm from './BeneficiaryProfileForm'

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
})

class BeneficiaryProfile extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
  }

  static defaultProps = {
    onChange: null,
  }

  renderStatusMessage = () => {
    const { user } = this.props
    let message = null

    if (waitingCreationValidation(user)) {
      message = "Vous n'avez aucun engagement avec nous actuellement. Vous pouvez contacter votre consultant par message ou par téléphone."
    }

    if (needToFillFinancerInfo(user)) {
      message = "Vous devez remplir vos moyens de financement"
    }

    if (waitingPersonnalInfoValidation(user)) {
      message = "Vous devez compléter et valider vos informations personnelles"
    }

    if (message !== null) {
      return <InfoPage text={message} />
    }

    return null
  }

  handleOnChange = (value) => {
    const { onChange } = this.props
    if (onChange !== null) {
      onChange(value)
    }
  }

  render() {
    const { user } = this.props

    if (!user) {
      return null
    }

    return (
      <div className="beneficiary-profile">
        {this.renderStatusMessage()}
        <BeneficiaryProfileForm user={user} onProfileChange={this.handleOnChange} />
      </div>
    )
  }
}

export default connect(mapStateToProps, null)(BeneficiaryProfile)
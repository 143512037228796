/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import { selectors as UserSelectors } from "../../../redux/UserRedux";

import { formatCountries, formatAPE } from "./clientDropdown";
import BigCombo from "../../../components/molecules/BigCombo/BigCombo";
import DescriptionTextArea from "../../../components/molecules/DescriptionTextArea/DescriptionTextArea";
import InputField from "../../../components/molecules/InputField/InputField";

import './ClientContainer.scss';
import { getAllConsultants } from "../../../api/ConsultantApi";

export const ClientContainer = ({ clientProps, isClientCompleted, sendClient }) => {
    const user = useSelector(UserSelectors.user);
    const [client, setClient] = useState(clientProps);
    const [consultantsDropdown, setConsultantsDropdown] = useState([]);
    const [countries, setCountries] = useState([]);
    const [, setAPE] = useState([]);
    const [consultants, setConsultants] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [responsable, setResponsable] = useState({});
    const [isEditable, setIsEditable] = useState();

    const handleInputOnChange = (input) => {
        const { id, value } = input;
        setClient({ ...client, [id]: value });
    }

    const handleTextAreaOnChange = (textarea) => {
        const { id, value } = textarea;
        setClient({ ...client, [id]: value });
    }

    const handleDropdownOnChange = (dropdown) => {
        const { id, value } = dropdown;
        setClient({ ...client, [id]: value.value });
    }

    const getCurrentConsultant = () => {
        const current_consultant = consultants.filter(consultant => consultant.value === client.user_id);
        setResponsable(current_consultant[0]);
    }

    const getContries = () => {
        formatCountries().then((countriesDropdown) => {
            setCountries(countriesDropdown)
        });
    }

    const getAPE = () => {
        formatAPE().then((apeDropdown) => {
            setAPE(apeDropdown)
        });
    }

    const getConsultants = () => {
        getAllConsultants(user.office_id).then((response) => {
            const all_consultants = response.consultants.map((c) => {
                return {
                    value: c.user.id,
                    label: `${c.user.first_name} ${c.user.last_name}`
                };
            });
           setConsultants(all_consultants)
           setConsultantsDropdown(all_consultants)
        })
    }

    useEffect(() => {
        const { name } = client;

        if (name && client.user_id) {
            isClientCompleted(true)
        }
        else {
            isClientCompleted(false)

        }
        sendClient(client)

    }, [client])

    useEffect(() => {
        consultants && consultants.length > 0 && getCurrentConsultant();
    }, [consultants])

    useEffect(() => {
        !client.user_id && setClient({ ...client, user_id: user.id });
        getContries();
        getAPE();
        getConsultants();
    }, [])

    useEffect(() => {
        if(client && client.id) {
            if(user.id === client?.responsable[0]?.id) {
                setIsEditable(true)
            }
            else setIsEditable(false)

        } 
        else {
            setIsEditable(true)
        }
    }, [user, client])

    const renderClientGeneralInformations = () => {
        const { name, description } = client;

        let responsableClient = "";

        if (client.responsable) {
            responsableClient = consultantsDropdown?.filter(c => c.value === client?.responsable[0]?.id)[0];
        }
        else {
            responsableClient = consultantsDropdown?.filter(c => c.value === user.id)[0];
        }

        return (
            <div className="client-general-informations">
                <h2>Informations générales</h2>
                <InputField title={"Nom du client"} name={"name"} value={name ? name : ""} required onChange={handleInputOnChange} disabled={!isEditable}/>
                <BigCombo id={"user_id"} title={"Responsable du client *"} value={responsableClient ? responsableClient.label : ""} options={[]} onChange={handleDropdownOnChange} disabled={true}/>
                <div>
                    <label className="common-form-label">Description</label>
                    <DescriptionTextArea name={"description"} id={"description"} value={description ? description : ""} maxLength={1500} onChange={handleTextAreaOnChange} disabled={!isEditable}/>
                </div>
                <BigCombo title={"Pays"} id={"country"} options={countries} value={client.country ? (client.country.charAt(0).toUpperCase() + client.country.slice(1)) : ""} onChange={handleDropdownOnChange} disabled={!isEditable}/>
                <InputField title={"NAF / APE"} name={"naf_ape"} value={client.naf_ape ? client.naf_ape : ""} onChange={handleInputOnChange} disabled={!isEditable}/>
                {/* <BigCombo title={"NAF / APE"} id={"naf_ape"} options={ape} value={client.naf_ape ? client.naf_ape : ""} onChange={handleDropdownOnChange} disabled={!isEditable}/> */}
            </div>
        )
    }

    const renderClientInformations = () => {
        const { first_name, last_name, email, phone, address, city, zipcode, activity_area, website, number_employees, annual_sales } = client
        return (
            <div className="client-informations">
                <h2>Informations client</h2>
                <div className="informations-flex">
                    <InputField title={"Prénom"} name={"first_name"} value={first_name ? first_name : ""} onChange={handleInputOnChange} disabled={!isEditable}/>
                    <InputField title={"Nom"} name={"last_name"} value={last_name ? last_name : ""} onChange={handleInputOnChange} disabled={!isEditable}/>
                </div>
                <div className="informations-flex">
                    <InputField title={"Email"} name={"email"} value={email ? email : ""} onChange={handleInputOnChange} disabled={!isEditable}/>
                    <InputField title={"Téléphone"} name={"phone"} value={phone ? phone : ""} onChange={handleInputOnChange} disabled={!isEditable}/>
                </div>

                <InputField title={"Adresse"} name={"address"} value={address ? address : ""} onChange={handleInputOnChange} disabled={!isEditable}/>
                <div className="informations-flex">
                    <InputField title={"Ville"} name={"city"} value={city ? city : ""} onChange={handleInputOnChange} disabled={!isEditable}/>
                    <InputField title={"Code postal"} name={"zipcode"} value={zipcode ? zipcode : ""} onChange={handleInputOnChange} disabled={!isEditable}/>
                </div>
                <InputField title={"Secteur d'activité"} name={"activity_area"} value={activity_area ? activity_area : ""} onChange={handleInputOnChange} disabled={!isEditable}/>
                <InputField title={"Site web"} name={"website"} value={website ? website : ""} onChange={handleInputOnChange} disabled={!isEditable}/>
                <InputField title={"Nombre d'employés"} name={"number_employees"} value={number_employees ? number_employees : ""} onChange={handleInputOnChange} disabled={!isEditable}/>
                <InputField title={"Chiffre d'affaires annuel (en €)"} name={"annual_sales"} value={annual_sales ? annual_sales : ""} onChange={handleInputOnChange} disabled={!isEditable}/>
            </div>
        )
    }
    return (
        <div className="client-container">
            {renderClientGeneralInformations()}
            {renderClientInformations()}
        </div>
    )
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

 

import SectionTitle from '../../../components/molecules/SectionTitle/SectionTitle'
import StatsTable from '../../../components/organisms/StatsTable/StatsTable'

import { selectors as StatsSelectors } from '../../../redux/StatsRedux'

const mapStateToProps = (state) => ({
  managerOfficeStats: StatsSelectors.managerOfficeStats(state),
})

class ManagerOutOfAnnualStatistics extends Component {
  static propTypes = {

    managerOfficeStats: PropTypes.objectOf(PropTypes.any),
  }

  static defaultProps = {
    managerOfficeStats: null,
  }

  renderBeneficiaryCount = () => {
    const { managerOfficeStats } = this.props

    const columns = [
      {
        id: 'beneficiary_count',
        title: "Nombre de candidats suivis",
        selector: 'beneficiary_count',
      },
      {
        id: 'rate_created_wait',
        title: "Taux de transformation prospect/en attente (%)",
        selector: (item) => (item.rate_created_wait * 100).toFixed(2),
      },
      {
        id: 'rate_wait_in_progress',
        title: "Taux de transformation demande/en cours (%)",
        selector: (item) => (item.rate_wait_in_progress * 100).toFixed(2),
      },
    ]

    const defaultData = {
      beneficiary_count: 0,
      rate_created_wait: 0,
      rate_wait_in_progress: 0,
    }

    let data = []
    if (managerOfficeStats.beneficiaryCount.length > 0) {
      data = managerOfficeStats.beneficiaryCount.map((item, index) => ({
        ...defaultData,
        ...item,
        id: index,
      }))
    }

    return <StatsTable columns={columns} data={data} dataIdSelector="id" />
  }

  render() {
    return (
      <div className="statistics-section">
        <SectionTitle label={"Statistiques équipe"} />
        {this.renderBeneficiaryCount()}
      </div>
    )
  }
}

export default connect(mapStateToProps)(ManagerOutOfAnnualStatistics)
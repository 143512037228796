import React, { Component } from 'react'
import Logo from '../../static/assets/logo-my-talents.svg'

import "./ChangeBrowserPage.scss"
 
export default class ChangeBrowserPage extends Component {


  render() {
    return (
      <div className="change-browser-page">
        <Logo className="logo" />

        <div className="content">
          <div className="message">{"Vous utilisez un navigateur non pris en charge par la plateforme. Pour une meilleure expérience, rendez-vous sur MyTalents avec Chrome et Firefox."}</div>
        </div>
      </div>
    )
  }
}

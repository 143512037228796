/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { selectors as UserSelectors } from '../../../redux/UserRedux';

import { createAppointment, getContactsAppointments } from '../../../api/AppointmentsApi';

import { BackgroundIcon } from '../../../components/atoms/BackgroundIcon/BackgroundIcon';
import BigCombo from '../../../components/molecules/BigCombo/BigCombo';
import CheckBoxField from '../../../components/molecules/CheckBoxField/CheckBoxField';
import { DropdownTag } from '../../../components/molecules/DropdownTag/DropdownTag';
import InputField from '../../../components/molecules/InputField/InputField';
import { Modal } from '../../../components/atoms/Modal/Modal';
import { SorterButton } from '../../../components/atoms/SorterButton/SorterButton';
// import { getUser } from "../../../api/UserApi";

import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fr';
import './AddAppointmentPopin.scss';
import { checkCalendarSync, getUserCronofyProfile } from '../../../api/CronofyApi';

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
});

const AddAppointmentPopin = ({ open, onClose, user, setAppointment, isModule, talent, filter }) => {
  const [isOpen, setIsOpen] = useState(open);
  const [appointmentTypes, setAppointmentTypes] = useState([
    { value: 'individual', label: 'Rendez-vous individuel', isSelected: true },
    { value: 'collective', label: 'Atelier collectif', isSelected: false },
  ]);
  const [appointmentObligatory, setAppointmentObligatory] = useState([
    { id: 1, label: 'Oui', value: true },
    { id: 0, label: 'Non', value: false },
  ]);
  const [isIndividualAppointment, setIsIndividualAppointment] = useState(true);
  const [newAppointment, setNewAppointment] = useState({});
  const [appointmentTime, setAppointmentTime] = useState({
    date: null,
    rdv_at: null,
    rdv_to: null,
  });
  const [appointmentSignature, setAppointmentSignature] = useState(true);
  const [disabledButtonCreate, setDisabledButtonCreate] = useState(true);
  const [users, setUsers] = useState({});
  const [calendar, setCalendarId] = useState('');
  const [conferencingProfile, setConferencingProfile] = useState(null);
  const [showVisioLink, setShowVisioLink] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fuseauHoraire, setFuseauHoraire] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );

  // const [defaultTalent, setDefaultTalent] = useState();

  useEffect(() => {
    handleOnChangeSelect({ id: 'videocall_type', value: { value: 'none' } });
  }, []);

  useEffect(() => {
    if (filter) {
      if (filter.all || filter.indiv) {
        setAppointmentTypes([
          { value: 'individual', label: 'Rendez-vous individuel', isSelected: true },
          { value: 'collective', label: 'Atelier collectif', isSelected: false },
        ]);
      } else
        setAppointmentTypes([
          { value: 'individual', label: 'Rendez-vous individuel', isSelected: false },
          { value: 'collective', label: 'Atelier collectif', isSelected: true },
        ]);
    }
  }, [filter]);

  // methods
  const handleAddAppointment = () => {
    setLoading(true);
    let appointmentTmp = newAppointment;
    var tmp_at = moment.tz(newAppointment.rdv_at, 'YYYY-MM-DD HH:mm:ss', fuseauHoraire.id);
    var tmp_to = moment.tz(newAppointment.rdv_to, 'YYYY-MM-DD HH:mm:ss', fuseauHoraire.id);
    appointmentTmp.rdv_at = tmp_at.clone().tz('Europe/Paris').format('YYYY-MM-DD HH:mm:ss');
    appointmentTmp.rdv_to = tmp_to.clone().tz('Europe/Paris').format('YYYY-MM-DD HH:mm:ss');
    appointmentTmp.user_from_tallysheet_is_signed = 0;
    appointmentTmp.user_from_tallysheet_date_signed = null;
    appointmentTmp.tallysheet_last_date_sent = null;
    createAppointment(appointmentTmp).then((response) => {
      setAppointment(response.appointment);
      setIsOpen(false);
    });
  };

  const handleOnChangeInput = (input) => {
    const { id, value } = input;
    setNewAppointment((prevAppointment) => ({ ...prevAppointment, [id]: value }));
  };

  const handleParsingUrl = (input) => {
    const { value } = input;
    const newInputValue = value;

    const zoomRegex = /zoom.us/;
    const teamsRegex = /teams\.microsoft\.com/;
    const meetRegex = /meet\.google\.com/;
    const gotoRegex = /gotomeet\.me/;
    const indeedRegex = /indeed\.com\/video/;

    if (zoomRegex.test(newInputValue)) {
      setNewAppointment((prevAppointment) => ({
        ...prevAppointment,
        provider_description: 'zoom',
      }));
      setNewAppointment((prevAppointment) => ({ ...prevAppointment, join_url: value }));
    } else if (teamsRegex.test(newInputValue)) {
      setNewAppointment((prevAppointment) => ({
        ...prevAppointment,
        provider_description: 'ms_teams',
      }));
      setNewAppointment((prevAppointment) => ({ ...prevAppointment, join_url: value }));
    } else if (meetRegex.test(newInputValue)) {
      setNewAppointment((prevAppointment) => ({
        ...prevAppointment,
        provider_description: 'google_meet',
      }));
      setNewAppointment((prevAppointment) => ({ ...prevAppointment, join_url: value }));
    } else if (gotoRegex.test(newInputValue)) {
      setNewAppointment((prevAppointment) => ({
        ...prevAppointment,
        provider_description: 'go_to',
      }));
      setNewAppointment((prevAppointment) => ({ ...prevAppointment, join_url: value }));
    } else if (indeedRegex.test(newInputValue)) {
      setNewAppointment((prevAppointment) => ({
        ...prevAppointment,
        provider_description: 'indeed',
      }));
      setNewAppointment((prevAppointment) => ({ ...prevAppointment, join_url: value }));
    }
  };

  const handleOnChangeInputTime = (input) => {
    const { id, value } = input;
    if (id === 'date') {
      setAppointmentTime((prevAppointmentTime) => ({
        ...prevAppointmentTime,
        date: moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      }));
    } else if (id === 'rdv_at') {
      setAppointmentTime((prevAppointmentTime) => ({
        ...prevAppointmentTime,
        rdv_at: moment(value, 'HH:mm').format('HH:mm'),
      }));
    } else if (id === 'rdv_to') {
      setAppointmentTime((prevAppointmentTime) => ({ ...prevAppointmentTime, rdv_to: value }));
    }
  };

  const handleOnChangeSelect = (select) => {
    const { id, value } = select;
    setNewAppointment((prevAppointment) => ({ ...prevAppointment, [id]: value.value }));
    select.value.value === 'none'
      ? setNewAppointment((prevAppointment) => ({ ...prevAppointment, is_videocall: 0 }))
      : setNewAppointment((prevAppointment) => ({ ...prevAppointment, is_videocall: 1 }));
    select.value.value === 'explicit' ? setShowVisioLink(true) : setShowVisioLink(false);
  };

  const handleOnChangeObligatoryCheckbox = (checkbox) => {
    const updatedObligatory = appointmentObligatory.map((obligatory) => ({
      ...obligatory,
      value: obligatory.id === checkbox.id,
    }));
    setAppointmentObligatory(updatedObligatory);
    setNewAppointment((prevAppointment) => ({ ...prevAppointment, obligatory: checkbox.id }));
  };

  const handleOnToggleCheckbox = (checkbox) => {
    setAppointmentSignature((signature) => !signature);
    setNewAppointment((prevAppointment) => ({
      ...prevAppointment,
      obligatory: checkbox.value ? 1 : 0,
    }));
  };

  const handleOnChangeDescription = (description) => {
    const { target } = description;
    setNewAppointment((prevAppointment) => ({ ...prevAppointment, public_notes: target.value }));
  };

  const handleOnChangePrivateNote = (note) => {
    const { target } = note;
    setNewAppointment((prevAppointment) => ({ ...prevAppointment, private_notes: target.value }));
  };

  const getValueSorter = (filter) => {
    const { value } = filter;
    setNewAppointment((prevAppointment) => ({ ...prevAppointment, appointment_type: value }));
    value === 'individual'
      ? setNewAppointment((prevAppointment) => ({ ...prevAppointment, nb_seats: 1 }))
      : setNewAppointment((prevAppointment) => ({ ...prevAppointment, nb_seats: 10 }));
    value === 'individual' ? setIsIndividualAppointment(true) : setIsIndividualAppointment(false);
  };

  const handleGetDropdownChoices = (choices) => {
    const { id, values } = choices;
    const user_from = values[0]?.id
      ? values[0]?.id
      : values[0]?.user_id
      ? values[0]?.user_id
      : null;
    id === 'user_from' &&
      values &&
      setNewAppointment((prevAppointment) => ({ ...prevAppointment, user_from }));
    isIndividualAppointment &&
      id === 'users_to' &&
      values &&
      setNewAppointment((prevAppointment) => ({
        ...prevAppointment,
        [id]: { individuals: values, groups: [] },
      }));

    if (!isIndividualAppointment && id === 'users_to' && values) {
      const individuals = [];

      const usersGroups = JSON.parse(JSON.stringify(users.groups));
      usersGroups.map((group) => (group.group_users = []));

      values.map((value) => {
        if (value.type === 'individuals') {
          individuals.push(value);
        } else if (value.type === 'groups') {
          users.groups.forEach((group) => {
            group.group_users.forEach((u) => {
              if (u.user_id === value.user_id) {
                usersGroups.find((g) => g.id === group.id).group_users.push(u);
              }
            });
          });
        }
        return value;
      });

      setNewAppointment((prevAppointment) => ({
        ...prevAppointment,
        [id]: { individuals: individuals, groups: usersGroups },
      }));
    }

    if (id === 'fuseau_form' && values) {
      setFuseauHoraire(values[0]);
    }
  };

  const handleDefaultValueAppointment = () => {
    setNewAppointment((prevAppointment) => ({
      ...prevAppointment,
      title: null,
      obligatory: 1,
      is_tallysheet: 1,
      private_notes: '',
      address: null,
      phone: null,
      public_notes: null,
      videocall_type: 'none',
    }));
  };

  // use effect
  useEffect(() => {
    handleDefaultValueAppointment();
    getContactsAppointments(null, user.id).then((response) => {
      setUsers(response.users_to);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appointmentTime.rdv_at !== null || appointmentTime.rdv_to !== null) {
      const rdv_at = moment(
        `${appointmentTime.date} ${appointmentTime.rdv_at}`,
        'DD/MM/YYYY HH:mm',
      ).format('YYYY-MM-DD HH:mm:ss');
      const rdv_to = moment(
        `${appointmentTime.date} ${appointmentTime.rdv_to}`,
        'DD/MM/YYYY HH:mm',
      ).format('YYYY-MM-DD HH:mm:ss');
      const defaultRdv_to = moment(rdv_at).add(1, 'hour').format('YYYY-MM-DD HH:mm:ss');
      rdv_at &&
        setNewAppointment((prevAppointment) => ({
          ...prevAppointment,
          rdv_at: rdv_at,
          rdv_to: defaultRdv_to,
        }));
      appointmentTime.rdv_to &&
        rdv_to &&
        setNewAppointment((prevAppointment) => ({ ...prevAppointment, rdv_to: rdv_to }));
    } else {
      setNewAppointment((prevAppointment) => ({ ...prevAppointment, rdv_at: null, rdv_to: null }));
    }
  }, [appointmentTime, appointmentTime.date]);

  useEffect(() => {
    const {
      title,
      user_from,
      users_to,
      rdv_at,
      rdv_to,
      appointment_type,
      nb_seats,
      obligatory,
      videocall_type,
      is_videocall,
      is_videocall_type,
      is_tallysheet,
      private_notes,
    } = newAppointment;

    const anyValueNull = Object.values({
      title,
      user_from,
      users_to,
      rdv_at,
      rdv_to,
      appointment_type,
      nb_seats,
      obligatory,
      is_videocall,
      is_videocall_type,
      is_tallysheet,
      private_notes,
    }).some((value) => value === null);

    if (newAppointment.appointment_type === 'individual') {
      const individualsCount = users_to.individuals.length;
      setDisabledButtonCreate(anyValueNull || individualsCount === 0);
    } else if (newAppointment.appointment_type === 'collective') {
      const individualsCount = users_to.individuals.length;
      const groupsCount = users_to.groups.length;
      setDisabledButtonCreate(anyValueNull || (individualsCount === 0 && groupsCount === 0));
    } else {
      setDisabledButtonCreate(anyValueNull);
    }
  }, [newAppointment]);

  useEffect(() => {
    const defaultTitle = isIndividualAppointment ? 'Rendez - vous module' : 'Atelier collectif';

    setNewAppointment((prevAppointment) => ({ ...prevAppointment, title: defaultTitle }));
  }, [isIndividualAppointment]);

  useEffect(() => {
    checkCalendarSync(user.id).then((response) => {
      setCalendarId(response);

      const data = {
        user_id: user.id,
        calendar_id: response,
      };

      getUserCronofyProfile(data).then((response) => {
        const conferencingProfiles = response['cronofy.data']['conferencing_profiles'];

        if (conferencingProfiles.length > 1) {
          const maxIndex = conferencingProfiles.reduce((maxIndex, currentProfile, currentIndex) => {
            return currentIndex > maxIndex ? currentIndex : maxIndex;
          }, 0);

          const providerName = conferencingProfiles[maxIndex]['provider_name'];
          setConferencingProfile(providerName);
        } else if (conferencingProfiles.length === 1) {
          const providerName = conferencingProfiles['provider_name'];
          setConferencingProfile(providerName);
        }
      });
    });
  }, []);

  // render components
  const renderTitleSection = (sectionTitle, icon) => {
    return (
      <div className="appointment-popin-section-title">
        <BackgroundIcon icon={icon} />
        <p>{sectionTitle}</p>
      </div>
    );
  };

  const renderTypeAppointment = () => {
    return (
      <div className="appointment-popin-type">
        <div className="appointment-popin-content-sorter-buttons">
          {!isModule && (
            <>
              {appointmentTypes.map((appointmentType, index) => (
                <SorterButton
                  key={index}
                  label={appointmentType.label}
                  value={appointmentType.value}
                  sendValue={getValueSorter}
                  active={appointmentType.isSelected}
                  setActive={() => {
                    const updatedAppointmentsTypes = appointmentTypes.map((a, idx) => ({
                      ...a,
                      isSelected: idx === index,
                    }));
                    setAppointmentTypes(updatedAppointmentsTypes);
                  }}
                />
              ))}
            </>
          )}

          {!isModule &&
            appointmentTypes.map((appointmentType, index) => (
              <SorterButtonappointment-popin-talents
                key={index}
                label={appointmentType.label}
                value={appointmentType.value}
                sendValue={getValueSorter}
                active={appointmentType.isSelected}
                setActive={() => {
                  const updatedAppointmentsTypes = appointmentTypes.map((a, idx) => ({
                    ...a,
                    isSelected: idx === index,
                  }));
                  setAppointmentTypes(updatedAppointmentsTypes);
                }}
              />
            ))}

          {isModule && (
            <SorterButton
              label={'Rendez-vous individuel'}
              value={'individual'}
              sendValue={getValueSorter}
              active={true}
            />
          )}
        </div>

        <div className="appointment-popin-obligatory">
          <p>Le rendez - vous est obligatoire ?</p>
          {appointmentObligatory &&
            appointmentObligatory.map((obligatory, index) => (
              <CheckBoxField
                key={index}
                id={obligatory.id}
                name={obligatory.id}
                label={obligatory.label}
                value={obligatory.value}
                onChange={handleOnChangeObligatoryCheckbox}
                radio={true}
              />
            ))}
        </div>

        <InputField
          title={'Titre du rendez-vous'}
          placeholder={'Ajouter un titre...'}
          name={'title'}
          value={newAppointment.title ? newAppointment.title : ''}
          type={'text'}
          required
          onChange={handleOnChangeInput}
        />
      </div>
    );
  };

  const renderTimeAppointment = () => {
    const duration = moment.duration(
      moment(newAppointment.rdv_to).diff(moment(newAppointment.rdv_at)),
    );
    const formattedDuration = moment.utc(duration.as('milliseconds')).format('HH[h]mm[m]');
    const fuseauxHoraires = moment.tz.names();
    let formattedFuseauxHoraires = {};
    formattedFuseauxHoraires.groups = [];
    formattedFuseauxHoraires.individuals = fuseauxHoraires.map((fuseau) => {
      return {
        id: fuseau,
        first_name: fuseau,
        last_name: '',
      };
    });
    return (
      <div className="appointment-popin-time">
        {renderTitleSection('Date et horaires', 'calendar')}
        <div>
          <DropdownTag
            id={'fuseau_form'}
            label={'Fuseau horaire'}
            defaultValue={[
              {
                id: Intl.DateTimeFormat().resolvedOptions().timeZone,
                first_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
                last_name: '',
              },
            ]}
            placeholder={'Sélectionner un fuseau...'}
            dropdownArray={formattedFuseauxHoraires}
            multipleChoice={false}
            sendSelectChoices={handleGetDropdownChoices}
          />
        </div>
        <div className="appointment-popin-inputs-time">
          <InputField
            title={'Date'}
            placeholder={'Ajouter un date...'}
            name={'date'}
            value={newAppointment ? newAppointment.date : ''}
            type={'date'}
            required
            // min={moment().format('YYYY-MM-DD')}
            onChange={handleOnChangeInputTime}
          />
          <InputField
            title={'De'}
            placeholder={''}
            name={'rdv_at'}
            value={
              newAppointment.rdv_at
                ? moment(newAppointment.rdv_at, 'YYYY-MM-DD HH:mm').format('HH:mm')
                : '00:00'
            }
            type={'time'}
            required
            onChange={handleOnChangeInputTime}
            disabled={appointmentTime.date ? false : true}
          />
          <InputField
            title={'A'}
            placeholder={''}
            name={'rdv_to'}
            value={
              newAppointment.rdv_to
                ? moment(newAppointment.rdv_to, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
                : '00:00'
            }
            type={'time'}
            required
            onChange={handleOnChangeInputTime}
            disabled={appointmentTime.date ? false : true}
          />
        </div>
        {newAppointment.rdv_at && newAppointment.rdv_to && (
          <div>
            <p>
              Durée du rendez - vous : <span>{formattedDuration}</span>
            </p>
          </div>
        )}
      </div>
    );
  };

  const compareNames = (a, b) => {
    const nameA = a.first_name || a.name || '';
    const nameB = b.first_name || b.name || '';
  
    if (!nameA || !nameB) {
      return a.id - b.id;
    }
    const upperCaseNameA = nameA.toUpperCase();
    const upperCaseNameB = nameB.toUpperCase();
  
    if (upperCaseNameA < upperCaseNameB) {
      return -1;
    }
    if (upperCaseNameA > upperCaseNameB) {
      return 1;
    }
    return 0;
  };

  const renderConsultantAppointment = () => {
    const consultantsIndividuals = users?.individuals?.filter((user) => user.role === 'consultant');
    const consultantGroups = users?.groups?.filter((user) => user.role === 'consultant');
    const consultants = { individuals: consultantsIndividuals?.sort(compareNames), groups: consultantGroups?.sort(compareNames) };
    return (
      <div className="appointment-popin-consultant">
        {renderTitleSection('Consultant(s)', 'person')}
        <DropdownTag
          id={'user_from'}
          label={'Consultant(s)'}
          defaultValue={[{ id: user.id, first_name: user.first_name, last_name: user.last_name }]}
          placeholder={'Sélectionner un consultant...'}
          dropdownArray={consultants}
          multipleChoice={true}
          sendSelectChoices={handleGetDropdownChoices}
        />
      </div>
    );
  };

  const renderNumberOfParticpants = () => {
    return (
      <div className="appointment-popin-number-of-participants">
        {renderTitleSection('Nombre de participants', 'people')}
        <InputField
          name={'nb_seats'}
          value={newAppointment ? newAppointment.nb_seats : ''}
          type={'number'}
          onChange={handleOnChangeInput}
        />
      </div>
    );
  };

  const renderTalentsAppointment = () => {
    const title = isIndividualAppointment ? 'Talent' : 'Talents';
    const placeholder = isIndividualAppointment
      ? 'Sélectionner un talent...'
      : 'Sélectionner des talents, des groupes...';
    const talentIndividuals = users?.individuals?.filter((user) => user.role === 'beneficiary');

    const talentGroups =
      users?.groups?.reduce((acc, group) => {
        const groupBeneficiaryUsers = group.group_users.filter(
          (user) => user.role === 'beneficiary',
        );
        if (groupBeneficiaryUsers.length > 0) {
          acc.push({ ...group, group_users: groupBeneficiaryUsers });
        }

        return acc;
      }, []) || [];
    const talents = { individuals: talentIndividuals?.sort(compareNames), groups: talentGroups?.sort(compareNames) };

    return (
      <div className="appointment-popin-talents">
        {renderTitleSection(title, 'people')}
        {isModule ? (
          <DropdownTag
            id={'users_to'}
            label={title}
            placeholder={placeholder}
            dropdownArray={[]}
            defaultValue={[
              { user_id: talent.id, first_name: talent.first_name, last_name: talent.last_name },
            ]}
            disabled={true}
            multipleChoice={isIndividualAppointment ? false : true}
            sendSelectChoices={handleGetDropdownChoices}
          />
        ) : (
          <DropdownTag
            id={'users_to'}
            label={title}
            placeholder={placeholder}
            dropdownArray={talents}
            multipleChoice={isIndividualAppointment ? false : true}
            sendSelectChoices={handleGetDropdownChoices}
          />
        )}
      </div>
    );
  };

  const renderAppointmentProcedure = () => {
    //const options = [{ value: "zoom", label: "Zoom" }, { value: "google", label: "Google" }, { value: "mytalents", label: "MyTalents" }, { value: "teams", label: "Teams" }, { value: "none", label: "Aucun" }]
    let options = '';
    let warning = '';

    if (conferencingProfile === null || conferencingProfile === undefined) {
      options = [
        { value: 'twilio', label: 'Visio MyTalents', type: 'Twilio' },
        { value: 'explicit', label: 'Lien de visio personnalisé', type: 'Perso' },
        { value: 'none', label: 'Aucun', type: null },
      ];
      warning = '* Vous pouvez ajouter un outil de visio favori depuis l\'onglet "Profil"';
    } else {
      options = [
        { value: 'default', label: 'Outil de visio favori', type: conferencingProfile },
        { value: 'explicit', label: 'Lien de visio personnalisé', type: 'Perso' },
        { value: 'twilio', label: 'Visio MyTalents', type: 'Twilio' },
        { value: 'none', label: 'Aucun', type: null },
      ];
    }

    if (!isIndividualAppointment) {
      options = options.filter((option) => option.value !== 'twilio');
    }

    // const selectedOption = options.find(option => option.type === newAppointment.videocall_type);

    const selectedOption = options;

    return (
      <div className="appointment-popin-procedures">
        <div className="appointment-popin-procedures-1">
          {renderTitleSection('Lien de visio', 'visio')}
          <BigCombo
            id={'videocall_type'}
            title={'Outils visio'}
            value={'none'}
            options={selectedOption}
            onChange={handleOnChangeSelect}
          />
          <div>
            <p className="appointment-visio-warning">{warning}</p>
          </div>
          <InputField
            title={'Lien'}
            placeholder={'Insérer le lien...'}
            name={showVisioLink ? 'videocall_url' : 'videocall_url_hidden'}
            value={newAppointment ? newAppointment.visio : ''}
            type={'text'}
            onChange={(e) => {
              handleOnChangeInput(e);
              handleParsingUrl(e);
            }}
          />
        </div>
        <div className="appointment-popin-procedures-2">
          <div className="appointment-popin-procedures-address">
            {renderTitleSection('Adresse', 'pin')}
            <InputField
              title={''}
              placeholder={'Adresse...'}
              name={'address'}
              value={newAppointment.address ? newAppointment.address : ''}
              type={'text'}
              onChange={handleOnChangeInput}
            />
          </div>
          <div className="appointment-popin-procedures-phone">
            {renderTitleSection('Téléphone', 'phone')}
            <InputField
              title={''}
              placeholder={'Téléphone...'}
              name={'phone'}
              value={newAppointment.phone ? newAppointment.phone : ''}
              type={'text'}
              onChange={handleOnChangeInput}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderAppointmentSignature = () => {
    return (
      <div className="appointment-popin-signature d-none">
        {renderTitleSection('Emargement', 'document-read')}
        <CheckBoxField
          id={'signature'}
          name={'signature'}
          label={"Mettre en place une feuille d'émargement"}
          value={appointmentSignature}
          onChange={handleOnToggleCheckbox}
          radio={true}
        />
      </div>
    );
  };

  const renderAppointmentDescription = () => {
    return (
      <div className="appointment-popin-description">
        {renderTitleSection('Description', 'text')}
        <textarea onChange={handleOnChangeDescription} />
      </div>
    );
  };

  const renderAppointmentPrivateNote = () => {
    return (
      <div className="appointment-popin-description">
        {renderTitleSection('Note privée', 'text')}
        <textarea onChange={handleOnChangePrivateNote} />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="appointment-popin-content">
        {renderTypeAppointment()}
        {renderTimeAppointment()}
        {renderConsultantAppointment()}
        {!isIndividualAppointment && renderNumberOfParticpants()}
        {renderTalentsAppointment()}
        {renderAppointmentProcedure()}
        {renderAppointmentSignature()}
        {renderAppointmentDescription()}
        {renderAppointmentPrivateNote()}
      </div>
    );
  };

  return (
    <Modal
      onClosePopin={onClose}
      openPopin={isOpen}
      title={"Création d'un rendez - vous"}
      content={renderContent()}
      cancelButton={'Annuler'}
      actionButton={'Créer'}
      handleActionButton={handleAddAppointment}
      disabledActionButton={disabledButtonCreate}
      loading={loading}
    />
  );
};

export default connect(mapStateToProps, null)(AddAppointmentPopin);

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isValid, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import _pick from 'lodash/pick'
import Icon from '../../atoms/Icon/Icon'
import { asyncValidate } from '../../../helpers/form/FormValidator/FormValidator'
import AvatarField from '../../molecules/AvatarField/AvatarField'
import InputField from '../../molecules/InputField/InputFieldContainer'
import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle'
import './UserCard.scss'

const mapStateToProps = (state, props) => ({
  valid: isValid('user-card')(state),
  initialValues: _pick(props, ['email', 'firstName', 'name', 'phone', 'company', 'function']),
})

class UserCard extends Component {
  static propTypes = {
    title: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    company: PropTypes.string,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    photoUrl: PropTypes.string,
    onChange: PropTypes.func,
    onFileClick: PropTypes.func,
    hideAvatarAndHeader: PropTypes.bool,
    uploading: PropTypes.bool,
    locale: PropTypes.shape({
      name: PropTypes.string,
      namePlaceHolder: PropTypes.string,
      firstName: PropTypes.string,
      firstNamePlaceHolder: PropTypes.string,
      function: PropTypes.string,
      functionPlaceholder: PropTypes.string,
      company: PropTypes.string,
      phone: PropTypes.string,
      phonePlaceHolder: PropTypes.string,
      email: PropTypes.string,
      emailPlaceHolder: PropTypes.string,
    }).isRequired,
  }

  static defaultProps = {
    function: '',
    functionPlaceholder: '',
    company: '',
    hideAvatarAndHeader: false,
    onChange: (name, value) => { },
    onFileClick: () => undefined,
    uploading: false,
  }

  handleChange = ({ id, value }) => {
    this.props.onChange({ id, value })
  }

  handleFileClick = () => {
    this.props.onFileClick()
  }

  render() {
    const { title, locale, photoUrl, hideAvatarAndHeader, uploading } = this.props

    return (
      <div className="user-card">
        {!hideAvatarAndHeader && <IconCardTitle label={title} icon={Icon.icon.Person} />}
        <form onSubmit={this.handleSubmit} className="form" noValidate>
          {!hideAvatarAndHeader && (
            <div className="photo">
              <AvatarField
                photoUrl={photoUrl}
                onClick={this.handleFileClick}
                uploading={uploading}
              />
            </div>
          )}

          <div className="fields">
            {locale.firstName && locale.lastName &&
              <div className="two-column-row">
                <InputField
                  required
                  title={locale.firstName}
                  name="firstName"
                  placeholder={locale.firstNamePlaceHolder}
                  onChange={this.handleChange}
                />

                <span className="separator" />

                <InputField
                  required
                  title={locale.name}
                  name="name"
                  placeholder={locale.namePlaceHolder}
                  onChange={this.handleChange}
                />
              </div>
            }
            {locale.function && (
              <InputField
                required
                title={locale.function}
                name="function"
                placeholder={locale.functionPlaceholder}
                onChange={this.handleChange}
              />
            )}
            {locale.company && (
              <InputField
                title={locale.company}
                disabled
                name="company"
                onChange={this.handleChange}
              />
            )}
            <div className='fields-display-flex'>

                <InputField
                  required
                  title={locale.firstName}
                  name="firstName"
                  placeholder={locale.firstNamePlaceHolder}
                  onChange={this.handleChange}
                />
                <InputField
                  required
                  title={locale.name}
                  name="name"
                  placeholder={locale.namePlaceHolder}
                  onChange={this.handleChange}
                />
            </div>
            <InputField
              required
              title={locale.phone}
              name="phone"
              placeholder={locale.phonePlaceHolder}
              onChange={this.handleChange}
            />
            <InputField
              required
              title={locale.email}
              name="email"
              placeholder={locale.emailPlaceHolder}
              onChange={this.handleChange}
            />
          </div>
        </form>
      </div>
    )
  }
}

UserCard = reduxForm({
  form: 'user-card',
  asyncChangeFields: ['email', 'firstName', 'name', 'phone', 'function'],
  asyncValidate: (values) =>
    asyncValidate(values, {
      email: { rule: 'required|email' },
      phone: { rule: 'required' },
      firstName: { rule: 'required' },
      name: { rule: 'required' },
      function: { rule: 'required' },
    }),
})(UserCard)

export default connect(mapStateToProps)(UserCard)
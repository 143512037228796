import React, { Component } from 'react'
import ComboField from './ComboField'
import FieldContainer from '../FieldContainer/FieldContainer'

class ComboFieldContainerFieldContainer extends Component {
  render() {
    return <ComboField {...this.props} />
  }
}

export default FieldContainer()(ComboFieldContainerFieldContainer);
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { isDirty, isValid, reduxForm } from 'redux-form'
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton'
import CheckBoxField from '../../../components/molecules/CheckBoxField/CheckBoxFieldContainer'
import InputField from '../../../components/molecules/InputField/InputFieldContainer'
import { asyncValidate } from '../../../helpers/form/FormValidator/FormValidator'
import { I18n } from 'react-redux-i18n'
import Icon from '../../../components/atoms/Icon/Icon'

import './ResetPasswordForm.scss';

const mapStateToProps = (state) => ({
  valid: isValid('reset-password')(state),
  touched: isDirty('reset-password')(state),
})

const ResetPasswordForm = ({ disabled, isNew, onSubmit, handleSubmit, valid, touched, termsUrl, terms }) => {
  const [passwordType, setPasswordType] = useState('password')

  const handleSubmitForm = (values) => {
    handleSubmit(onSubmit)(values)
  }

  const handleTogglePassword = () => {
    setPasswordType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };

  return (
    <form onSubmit={handleSubmitForm} noValidate className="reset-password-form">
      <div className='reset-password-container'>

        <InputField
          name="password"
          title={"Votre mot de passe"}
          type={passwordType}
          disabled={disabled}
        />

        <span className='view-password-btn' onClick={handleTogglePassword}>
          <Icon icon='preview2' color={"grey1"} />
        </span>
      </div>

      {isNew && (
        <div className="field-container reset-password-terms">
          <CheckBoxField name="terms" />

          <a href={termsUrl} target="_blank">
            {terms}
          </a>
        </div>
      )}

      <PrimaryButton
        label={"Valider mon nouveau mot de passe"}
        id="submit"
        type="submit"
        disabled={disabled || !touched || !valid}
        onClick={handleSubmitForm}
      />
    </form>
  )

}
// class ResetPasswordForm extends Component {
//   static propTypes = {
//     disabled: PropTypes.bool,
//     isNew: PropTypes.bool,
//     onSubmit: PropTypes.func,
//   }

//   static defaultProps = {
//     disabled: true,
//     isNew: false,
//     onSubmit: () => undefined,
//   }

//   handleSubmit = (values) => {
//     this.props.handleSubmit(this.props.onSubmit)(values)
//   }

//   render() {
//     const { disabled, valid, touched, termsUrl, terms, isNew } = this.props

// return (
//   <form onSubmit={this.handleSubmit} noValidate className="reset-password-form">
//     <InputField
//       name="password"
//       title={"Votre mot de passe"}
//       type="password"
//       disabled={disabled}
//     />

//     {isNew && (
//       <div className="field-container reset-password-terms">
//         <CheckBoxField name="terms" />

//         <a href={termsUrl} target="_blank">
//           {terms}
//         </a>
//       </div>
//     )}

//     <PrimaryButton
//       label={"Valider mon nouveau mot de passe"}
//       id="submit"
//       type="submit"
//       disabled={disabled || !touched || !valid}
//       onClick={this.handleSubmit}
//     />
//   </form>
// )
//   }
// }


const ConnectedComponent = connect(mapStateToProps, null)(
  reduxForm(
    {
      form: 'reset-password',
      asyncChangeFields: ['password', 'terms'],
      validate: (values) => {
        const errors = {}

        if (!values.password) {
          errors.password = I18n.t('Ce champs est requis')
        } else if (
          !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\p{Z}\p{S}\p{P}]).{8,}$/u.test(values.password)
        ) {
          errors.password = I18n.t('Le mot de passe doit contenir au moins 8 caractères, incluant une majuscule, une minuscule, un chiffre et un caractère spécial')
        }

        return errors
      },
      asyncValidate: (values, dispatch, { t, isNew }) =>
        asyncValidate(values, {
          terms: { rule: isNew ? 'required|accepted' : null },
        }),
    })(ResetPasswordForm)
)


export default ConnectedComponent;


// ResetPasswordForm = reduxForm({
//   form: 'reset-password',
//   asyncChangeFields: ['password', 'terms'],
//   validate: (values ) => {
//     const errors = {}

//     if (!values.password) {
//       errors.password = I18n.t('Ce champs est requis')
//     } else if (
//       !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\p{Z}\p{S}\p{P}]).{8,}$/u.test(values.password)
//     ) {
//       errors.password = I18n.t("L'adresse email et le mot de passe ne correspondent pas")
//     }

//     return errors
//   },
//   asyncValidate: (values, dispatch, { t, isNew }) =>
//     asyncValidate(values, {
//       terms: { rule: isNew ? 'required|accepted' : null },
//     }),
// })(ResetPasswordForm)

// export default connect(mapStateToProps)(ResetPasswordForm)
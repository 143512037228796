import React, { Component } from 'react'
import { connect } from 'react-redux'

import { actions as MessageActions, selectors as MessageSelectors } from '../../redux/MessageRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import { selectors as UserSelectors } from '../../redux/UserRedux'
import PopinLayout from '../../layouts/PopinLayout/PopinLayout'
import ActionPopinHeader from '../../components/molecules/ActionPopinHeader/ActionPopinHeader'
import Icon from '../../components/atoms/Icon/Icon'
import Page from '../../containers/global/Page/Page'
import './MessagePage.scss';
const mapStateToProps = (state) => ({
  id: NavigationSelectors.lastParam(state),
  user: UserSelectors.user(state),
  message: MessageSelectors.message(state),
})

const mapDispatchToProps = (dispatch) => ({
  getMessageAndMarkAsRead: (id) => dispatch(MessageActions.getMessageAndMarkAsRead(id)),
  resetMessage: () => dispatch(MessageActions.setMessage(null)),
  back: () => dispatch(NavigationActions.back(null, true)),
})

class MessagePage extends Component {
  renderHeader() {
    const { message } = this.props
    const { subject } = message

    return (
      <ActionPopinHeader
        lightTitle={"Message"}
        boldTitle={subject}
        iconButton={Icon.icon.Back}
        onIconClick={this.props.back}
      />
    )
  }

  renderContent() {
    const { message, user } = this.props
    const { subject, content, user_tos, user_from } = message
    const isSender = user_from.id === user.id
    let displayName

    if (isSender) {
      displayName = user_tos.map(({ user }) => `${user.first_name} ${user.last_name}`).join(', ')
    } else {
      displayName = `${user_from.first_name} ${user_from.last_name}`
    }

    return (
      <div className="message">
        <div className="subject">
          <span>{"Sujet"}</span>
          <span>{subject}</span>
        </div>
        <div className="user">
          <span>{isSender ? 'Destinataire' : 'Expéditeur'}</span>
          <span>{displayName}</span>
        </div>
        <div className="content">{content}</div>
      </div>
    )
  }

  componentWillUnmount() {
    this.props.resetMessage()
  }

  componentDidMount() {
    this.props.getMessageAndMarkAsRead(this.props.id)
  }

  render() {
    if (!this.props.message) {
      return null
    }

    return (
      <div className="message-page">
        <PopinLayout header={this.renderHeader()} content={this.renderContent()} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(MessagePage))
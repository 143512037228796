import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_MY_TALENTS_EVOLUTION,
    headers: {
    //   'Authorization': 'Bearer 457f4be3-878c-494f-b109-0a428bfd65d2',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });


export const createCourse = async (data) => {
    const response = await axiosInstance.post('courses/add', data);
    return response.data;
}

export const getCourses = async(id) => {
    const response = await axiosInstance.get(`courses/all/user/${id}`);

    return response.data;
}

export const getCourseById = async(id) => {
    const response = await axiosInstance.get(`courses/${id}`);
    return response.data;
}

export const updateCourseById = async() => {
    const response = await axiosInstance.put('courses/update');
    return response.data;
}

export const deleteCourseById = async(id) => {
    const response = await axiosInstance.delete(`courses/${id}`);
    return response.data;
}

export const duplicateCourse = async(data) => {
    const response = await axiosInstance.post('/courses/duplicate', data);
    return response.data;
}

export const getModuleById = async(id) => {
    const response = await axiosInstance.get(`modules/${id}`);
    return response.data;
}

export const getModules = async(id) => {
    const response = await axiosInstance.get(`modules_containers/${id}`);
    return response.data;
}

export const updateCourse = async(data) => {
    const response = await axiosInstance.put('courses/update', data);
    return response.data;
}

export const assignCourse = async(data) => {
    const response = await axiosInstance.post('/courses/assign', data);
    return response.data;
}

export const getModuleContainer = async(module_container_id) => {
    const response = await axiosInstance.get(`modules_containers/${module_container_id}`);
    return response.data;
}

export const createModuleContainer = async(data) => {
    const response = await axiosInstance.post(`modules_containers/add`, data);
    return response.data;
}

export const updateModuleContainer = async(data) => {
    const response = await axiosInstance.put('modules_containers/update', data);
    return response.data;
}

export const duplicateModuleContainer = async(data) => {
    const response = await axiosInstance.post('modules_containers/duplicate', data);
    return response.data
}



export const deleteModuleContainer = async(id) => {
    const response = await axiosInstance.delete(`/modules_containers/${id}`);
    return response.data;
}

export const createStep = async (data) => {
    const response = await axiosInstance.post(`/steps/add`, data);
    return response.data;
}

export const updateStep = async(data) => {
    const response = await axiosInstance.put('steps/update', data);
    return response.data;
}

export const deleteStep = async(id) => {
    const response = await axiosInstance.delete(`/steps/${id}`);
    return response.data;
}

export const createModule = async(data) => {
    const response = await axiosInstance.post('/modules/add', data);
    return response.data;
}

export const getModule = async(module_id) => {
    const response = await axiosInstance.get(`/modules/${module_id}`);
    return response.data;
}


export const updateModule = async(data) => {
    const response = await axiosInstance.put('modules/update', data);
    return response.data;
}

export const duplicateModule = async(data) => {
    const response = await axiosInstance.post('modules/duplicate', data);
    return response.data;
}

export const deleteModule = async(id) => {
    const response = await axiosInstance.delete(`/modules/${id}`);
    return response.data;
}

export const createSubmodule = async(data) => {
    const response = await axiosInstance.post('/modules/submodules/add', data);
    return response.data;
}

export const deleteSubmodule = async(type, id) => {
    const response = await axiosInstance.delete(`/modules/submodules/${type}/${id}`);
    return response.data;
}


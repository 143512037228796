import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import uniqid from 'uniqid'

import { selectors as CourseTemplateSelectors } from '../../../redux/CourseTemplateRedux'
import { selectors as ModuleTemplateSelectors } from '../../../redux/ModuleTemplateRedux'
 

const mapStateToProps = (state) => ({
  templates: ModuleTemplateSelectors.templates(state),
  template: CourseTemplateSelectors.template(state),
})

class CourseEditDragDropContext extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onChangeSteps: PropTypes.func.isRequired,
    onChangeModules: PropTypes.func.isRequired,
  }

  static defaultProps = {
    onChange: ({ resources }) => resources,
  }

  handleDragEnd = (result) => {
    const { source, destination, type } = result

    if (!destination) {
      return
    }

    switch (type) {
      case 'step':
        return source.droppableId === destination.droppableId
          ? this.handleMoveStep(result)
          : this.handleAddStep(result)

      case 'module':
        return source.droppableId === destination.droppableId
          ? this.handleMoveModule(result)
          : this.handleAddModule(result)

      default:
    }
  }

  handleMoveStep({ source, destination }) {
    const { template, onChangeSteps } = this.props
    const steps = [...template.steps]
    const { index: from } = source
    const { index: to } = destination

    if (from === to) {
      return
    }

    steps.splice(to, 0, ...steps.splice(from, 1))
    onChangeSteps(steps)
  }

  handleAddStep(result) {
    const { template } = this.props
    const clone = template.steps.slice(0)
    const { index } = result.destination

    clone.splice(index, 0, {
      _id: uniqid(),
      title: '',
      modules: [],
    })

    this.props.onChangeSteps(clone)
  }

  handleAddModule(result) {
    const { template, templates, onChangeModules } = this.props
    const { source, destination, draggableId } = result
    const { index } = destination
    const { id } = JSON.parse(draggableId)
    const { stepIndex: dropIndex } = JSON.parse(destination.droppableId)
    const { stepIndex: dragIndex } = JSON.parse(source.droppableId)

    if (dragIndex !== undefined && dragIndex !== dropIndex) {
      return
    }

    const step = template.steps[dropIndex]
    const clone = step.modules.slice(0)
    const module = isNaN(id)
      ? this.createModuleByType(id)
      : templates.find((template) => id === template.id.toString())

    clone.splice(index, 0, {
      ...module,
      _id: uniqid(),
      id: undefined,
    })

    onChangeModules(dropIndex, clone)
  }

  handleMoveModule({ destination, source }) {
    const { template, onChangeModules } = this.props
    const { index: from } = source
    const { index: to, droppableId } = destination
    const { stepIndex } = JSON.parse(droppableId)
    const step = template.steps[stepIndex]

    if (!step) {
      return
    }

    const stepModules = step.modules || []
    const modules = [...stepModules]

    if (from === to) {
      return
    }

    modules.splice(to, 0, ...modules.splice(from, 1))
    onChangeModules(stepIndex, modules)
  }

  createModuleByType(type) {
    const data = {
      text: { resources: [] },
      form: { questions: [] },
    }
    let title = "";
    switch(type) {
      case 'form': 
      title = "Formulaire"
      break;

      case 'synthesis': 
      title = "Synthèse d'étape"
      break;

      case 'text': 
      title = "Bloc de ressources"
      break;

      case 'appointment': 
      title = "Rendez-vous"
      break;

      default:
      title = "Texte à définir";
    }

    return {
      type,
      title: title,
      data: data[type] || {},
    }
  }

  render() {
    return <DragDropContext onDragEnd={this.handleDragEnd}>{this.props.children}</DragDropContext>
  }
}

export default connect(mapStateToProps)(CourseEditDragDropContext)
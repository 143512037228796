import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconChevron from '../../atoms/IconChevron/IconChevron'
import IconModuleType from '../../atoms/IconModuleType/IconModuleType'
import IconButton from '../../atoms/IconButton/IconButton'
import ModuleRowContent from '../ModuleRowContent/ModuleRowContent'
import './ModuleRow.scss'

export default class ModuleRow extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    open: PropTypes.bool,
    moduleType: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    label: PropTypes.string.isRequired,
    resourcesCount: PropTypes.number,
    options: PropTypes.array,
    url: PropTypes.string,
    onEditClick: PropTypes.func,
    onRemoveClick: PropTypes.func.isRequired,
    onChevronClick: PropTypes.func,
    onVideoClicked: PropTypes.func,
  }

  static defaultProps = {
    editable: true,
    open: false,
    resourcesCount: 0,
    options: [],
    onEditClick: () => { },
    onChevronClick: () => { },
    onVideoClicked: () => { },
  }

  state = { isOpen: this.props.open}

  renderOptions = () => {
    const { options } = this.props

    return options.map(({ type, url, title }, i) => {
      if (type === 'link') type = 'file'
      if (type === 'document_to_return') type = 'file-return'
      return (
        <ModuleRowContent
          key={i}
          url={url}
          label={title ? title : "Sans titre"}
          icon={type}
          onClick={this.handleRowContentVideoClicked}
        />
      )
    })
  }

  handleEditClick = () => {
    this.props.onEditClick({ id: this.props.id })
  }

  handleRemoveClick = () => {
    this.props.onRemoveClick({ id: this.props.id })
  }

  handleChevrongClick = () => {
    this.props.onChevronClick({ id: this.props.id })
  }

  getIsOpen = (value) => {
    this.setState({ open: value });
  }

  handleVideoClicked = () => {
    const { url, label } = this.props

    this.props.onVideoClicked(url, label)
  }

  handleRowContentVideoClicked = (url, title) => {
    this.props.onVideoClicked(url, title)
  }

  handlePreviewClick = () => {
    this.props.onPreviewClick({ id: this.props.id })
  }

  render() {
    const { moduleType, label, resourcesCount, questionsCount, editable, url } = this.props
    const { open } = this.state

    return (
      <div className="module-row">
        <div className="module-row-header">
          <div className="left-wrapper">
            {!moduleType && 
            <>
            <IconChevron onClick={this.handleChevrongClick} open={!open} sendIsOpen={this.getIsOpen} />
            <IconModuleType moduleType={'free-active'} color={'accent'} />
            </>
            }
            <a
              className="label"
              target="_blank"
              href={moduleType !== 'video' ? url : null}
              onClick={moduleType === 'video' ? this.handleVideoClicked : null}
            >
              {label ? label : "Sans titre"}
            </a>
          </div>
          <div className="right-wrapper">
            {!moduleType ? <div className="resources-count">{resourcesCount}</div> : <div className="no-resources-count"></div>}
            {!editable && moduleType === 'quiz' && questionsCount > 0 && (
              <div className="actions">
                <IconButton icon="preview2" color="grey2" onClick={this.handlePreviewClick} />
              </div>
            )}
            {editable && (
              <div className="actions">
                {moduleType === 'quiz' && questionsCount > 0 && (
                  <IconButton icon="preview2" color="grey2" onClick={this.handlePreviewClick} />
                )}
                <IconButton icon="edit2" color="grey2" onClick={this.handleEditClick} />
                <IconButton icon="trash2" color="grey2" onClick={this.handleRemoveClick} />
              </div>
            )}
          </div>
        </div>
        <div className="options">{open ? this.renderOptions() : false}</div>
      </div>
    )
  }
}

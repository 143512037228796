import * as RouterActions from 'connected-react-router'
import { put, select } from 'redux-saga/effects'
import { actions as BeneficiaryActions } from '../redux/BeneficiaryRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../redux/NavigationRedux'
import { selectors as UserSelectors } from '../redux/UserRedux'

export function* back({ payload }) {
  const { prefix, usePrevious } = payload
  const lastPages = yield select(NavigationSelectors.lastPages)

  if (usePrevious && lastPages.length > 0) {
    const backRoute = lastPages[0]

    yield put(NavigationActions.push(backRoute, true))
  } else {
    const param = yield select(NavigationSelectors.lastParam)
    const pathname = yield select(NavigationSelectors.pathname)

    let newPathname = pathname.replace(`${prefix || ''}/${param}`, '')

    //Special case with manager seeing consultants beneficiaries
    if (/\/manager\/consultants\/\d+\/beneficiaries\/\d+\/courses/.test(pathname)) {
      newPathname = pathname.substring(0, pathname.indexOf('/beneficiaries'))
    }

    yield put(NavigationActions.push(newPathname, true))
  }
}

export function* replace({ payload }) {
  const { path } = payload
  yield put(RouterActions.replace(path))
}

export function* push({ payload }) {
  const { path } = payload

  window.scrollTo({ top: 0, behavior: 'smooth' })
  yield put(RouterActions.push(path))
}

export function* redirect({ payload }) {
  const { path } = payload

  yield put(RouterActions.replace(path))
}

export function open({ payload }) {
  const { url } = payload
  window.open(url)
}

export function* evaluate() {
  const user = yield select(UserSelectors.user)
  const token = yield select(UserSelectors.token)
  const pathname = yield select(NavigationSelectors.pathname)

  if ((!user || !token) && !pathname.endsWith('/auth/login')) {
    yield put(NavigationActions.replace('/auth/login'))
  }

  // const width = yield select(AppSelectors.windowWidth)
  const agent = navigator.userAgent.toLowerCase()
  const isIE = /Trident\/|MSIE/i.test(agent)

  if (isIE) {
    if (!pathname.startsWith('/change-browser')) {
      yield put(NavigationActions.push('/change-browser'))
    }
  } else if (!user || !token) {
    if (!pathname.startsWith('/oauth_callback') && !pathname.endsWith('/auth/login')) {
      yield put(NavigationActions.replace('/auth/login'))
    }
  } else if (user.role === 'beneficiary') {
    yield put(BeneficiaryActions.manageStatus())
  } else if (pathname.startsWith(`/${user.role}`) === false) {
    yield put(NavigationActions.push(`/${user.role}`))
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import AvatarCircle from '../../atoms/AvatarCircle/AvatarCircle'
// import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import './AvatarCard.scss'
class AvatarCard extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    job: PropTypes.string,
    phone: PropTypes.string,
    photoUrl: PropTypes.string,
    labelButton: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func.isRequired,
  }

  static defaultProps = {
    job: '',
    phone: '',
    photoUrl: '',
  }

  onClick = (e, id) => {
    this.props.onButtonClick(e, id)
  }

  render() {
    const { name, job, phone, photoUrl } = this.props
    return (
      <div className="avatar-card">
        <div className="info-container">
          <div className="avatar-container">
            <AvatarCircle photoUrl={photoUrl} />
          </div>
          <p className="name">{name}</p>
          <p className="job">{job}</p>
          <p className="phone">{phone}</p>
        </div>
        {/* <div className="button-container">
          <PrimaryButton label={labelButton} id="send-mail" onClick={this.onClick} />
        </div> */}
      </div>
    )
  }
}

export default AvatarCard

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
// import { connect } from 'react-redux'

 
// import { actions as CourseActions } from '../../../redux/CourseRedux'
// import { actions as WSActions, selectors as WSSelectors } from '../../../redux/WSRedux'
// import { selectors as NavigationSelectors } from '../../../redux/NavigationRedux'

import { Popin } from '../../global/Popin/Popin'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'

class CourseRemove extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    open: false,
  }


  render() {
    const { onClose, open, handleRemove } = this.props
    return (
      <Popin onClose={onClose} open={open}>
        <AlertPopin
          label={"Supprimer votre parcours"}
          text={"Êtes-vous sûr de vouloir supprimer ce parcours ?"}
          labelCancelButton={"Annuler"}
          labelConfirmButton={"Supprimer"}
          onCancelButtonClick={onClose}
          onConfirmButtonClick={handleRemove}
        />
      </Popin>
    )
  }
}

export default CourseRemove
import React, { useState } from 'react';

import { NewTag } from '../../atoms/NewTag/NewTag';
import Icon from '../../atoms/Icon/Icon';
import { DeleteMemberGroupPopin } from '../../../containers/groups/DeleteMemberGroupPopin/DeleteMemberGroupPopin';

import './MemberGroupCard.scss';

export const MemberGroupCard = ({ member, deleteMember, onClick }) => {
  const [showModalDeleteMember, setShowModalDeleteMember] = useState(false);

  const handleDeleteMember = () => {
    deleteMember(member);
    setShowModalDeleteMember(false);
  };
  const renderAvatar = (image) => {
    if (image) return <img className="avatar" src={image} alt="avatar-member" />;
    return (
      <div className="avatar">
        <Icon icon="person" color={'accent'} />
      </div>
    );
  };

  const { first_name, last_name, email, phone, image_url } = member;
  return (
    <div className="member-group-card" onClick={onClick}>
      <div className="member-group-card-header">
        <NewTag title={'En cours'} />
        <div onClick={() => setShowModalDeleteMember(true)}>
          <Icon icon={'trash2'} />
        </div>
      </div>
      <div className="member-group-card-content">
        <div className="member-informations">
          {renderAvatar(
            `${process.env.REACT_APP_ASSETS_API_STATIC_URL}${
              image_url
                ? image_url.replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '')
                : ''
            }`,
          )}
          <h1>
            {first_name} {last_name}
          </h1>
        </div>
        <p>{email}</p>
        <p>{phone}</p>
      </div>
      {/* {showModalDeleteMember && <DeleteMemberGroupPopin member={member} open={showModalDeleteMember} onClose={() => setShowModalDeleteMember(false)} onDeleteMemberGroup={() => {deleteMember(member); setShowModalDeleteMember(false)}}/>} */}
      {showModalDeleteMember && (
        <DeleteMemberGroupPopin
          member={member}
          open={showModalDeleteMember}
          onClose={() => setShowModalDeleteMember(false)}
          onDeleteMemberGroup={handleDeleteMember}
        />
      )}
    </div>
  );
};

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import './DateSelector.scss';

import classNames from 'classnames'
import DateUtil from './DateUtil'
import Icon from '../../atoms/Icon/Icon'
import ComboField from '../ComboField/ComboField'

class DateSelector extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    locale: PropTypes.shape({
      monthLabels: PropTypes.arrayOf(PropTypes.string),
      dayShortLabels: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    disablePreviousToday: PropTypes.bool,
    disablePreviousDate: PropTypes.string,
    disableAfterDate: PropTypes.string,

  }

  static defaultProps = {
    value: null,
    onChange: () => {},
    disablePreviousToday: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      displayedDate: new Date(),
      slideDirection: 'forward',
    }

    this.slideNext = this.slideNext.bind(this)
    this.slidePrev = this.slidePrev.bind(this)
    this.onSelectDate = this.onSelectDate.bind(this)
    this.getSelectedDate = this.getSelectedDate.bind(this)
  }

  componentDidMount() {
    const date = this.props.value || new Date().toString()
    this.changeDate(date)
  }

  changeDate(newDate) {
    const slideDirection = newDate > this.state.displayedDate ? 'forward' : 'backward'
    const displayedDate = DateUtil.getFirstDayOfMonth(DateUtil.normalize(new Date(newDate)))
    this.setState({ slideDirection, displayedDate })
  }

  onSelectDate(date) {
    this.props.onChange(date.toString())
  }

  getSelectedDate() {
    if (this.props.value == null) return null
    return DateUtil.normalize(new Date(this.props.value))
  }

  slideNext() {
    const month = new Date(this.state.displayedDate)
    month.setMonth(month.getMonth() + 1)
    this.changeDate(month)
  }

  slidePrev() {
    const month = new Date(this.state.displayedDate)
    month.setMonth(month.getMonth() - 1)
    this.changeDate(month)
  }

  renderDay(index, day, isInMonth, isSelected) {
    day = new Date(day)
    return (
      <div
        className={classNames({
          'date-selector-day': true,
          disabled: !isInMonth,
          enabled: isInMonth,
          selected: isSelected,
        })}
        key={index}
      >
        <div
          className="day-button"
          onClick={() => {
            this.onSelectDate(day)
          }}
        >
          <span>{day.getDate()}</span>
        </div>
      </div>
    )
  }

  renderDays(month) {
    const firstMonday = DateUtil.getFirstMondayBefore(month)
    const currentDay = new Date(firstMonday)
    const totalDays = 7 * 6
    const days = []
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="date-selector-day" key={`label${i}`}>
          <div className="dayLabel">
            <span>{this.props.locale && this.props.locale.dayShortLabels ? this.props.locale.dayShortLabels[i] : ""}</span>
          </div>
        </div>,
      )
    }

    const nowMoment = moment().subtract(1, 'd')

    for (let i = 0; i < totalDays; i++) {
      let isInMonth = currentDay.getMonth() === month.getMonth()

      
      if (this.props.disablePreviousToday) {
        const currentDayMoment = moment(currentDay)

        if (currentDayMoment.isBefore(nowMoment)) {
          isInMonth = false
        }
      }
      if (this.props.disablePreviousDate) {
        const currentDayMoment = moment(currentDay)

        if (currentDayMoment.isBefore(moment(this.props.disablePreviousDate, 'DD/MM/YYYY'))) {
          isInMonth = false
        }
      }
      if (this.props.disableAfterDate) {
        const currentDayMoment = moment(currentDay)

        if (currentDayMoment.isAfter(moment(this.props.disableAfterDate, 'DD/MM/YYYY'))) {
          isInMonth = false
        }
      }

      let isSelected = false
      if (this.getSelectedDate() && currentDay.toString() === this.getSelectedDate().toString())
        isSelected = true


      days.push(this.renderDay(i, currentDay, isInMonth, isSelected))
      currentDay.setDate(currentDay.getDate() + 1)
    }
    return days
  }

  renderMonth(month) {
    return (
      <div className="date-selector-month" key={month.toString()}>
        <div className="date-selector-days-container">{this.renderDays(month)}</div>
      </div>
    )
  }

  renderMonthLabel(month) {
    return (
      <div className="month-name">
        {this.renderMonthSelect(month)}
        {this.renderYearSelect(month)}
      </div>
    )
  }

  handleChangeDate({ id, value }) {
    const month = new Date(this.state.displayedDate)
    if (id === 'current-month') {
      month.setMonth(value)
    } else if (id === 'current-year') {
      month.setFullYear(value)
    }
    this.changeDate(month)
  }

  renderMonthSelect(month) {
    const options = []
    if (this.props.locale.monthLabels)
      this.props.locale.monthLabels.forEach((value, index) => {
        options.push({
          label: value,
          value: index,
        })
      })
    return (
      <ComboField
        options={options}
        name="current-month"
        value={month.getMonth()}
        onChange={this.handleChangeDate.bind(this)}
      />
    )
  }

  renderYearSelect(month) {
    const options = []
    const now = new Date()
    const range = (start, stop, step) =>
      Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)
    range(
      now.getFullYear() + 1,
      this.props.disablePreviousToday ? now.getFullYear() : 1950,
      -1,
    ).forEach((value) => {
      options.push({
        label: value.toString(),
        value,
      })
    })
    return (
      <ComboField
        options={options}
        name="current-year"
        value={month.getFullYear()}
        onChange={this.handleChangeDate.bind(this)}
      />
    )
  }

  render() {
    const nowMoment = moment()
    const displayedDateMoment = moment(new Date(this.state.displayedDate))

    const disablePrevDates =
      this.props.disablePreviousToday &&
      nowMoment.year() === displayedDateMoment.year() &&
      nowMoment.month() === displayedDateMoment.month()

    return (
      <div className="date-selector">
        <div className="date-selector-container">
          <div className="date-selector-header">
            <div className="btn-prev-month" onClick={disablePrevDates ? () => {} : this.slidePrev}>
              <Icon icon="back" color={disablePrevDates ? 'grey2' : 'accent'} />
            </div>
            {this.renderMonthLabel(this.state.displayedDate)}
            <div className="btn-next-month" onClick={this.slideNext}>
              <Icon icon="next" color="accent" />
            </div>
          </div>
          <div className="month-container">{this.renderMonth(this.state.displayedDate)}</div>
        </div>
      </div>
    )
  }
}

export default DateSelector

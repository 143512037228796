import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _keyBy from 'lodash/keyBy'

import StatsTable from '../../../components/organisms/StatsTable/StatsTable'
import { selectors as StatsSelectors } from '../../../redux/StatsRedux'

const mapStateToProps = (state) => ({
  managerOfficeStats: StatsSelectors.managerOfficeStats(state),
})


class ManagerBeneficiaryCountByActivityStatistics extends Component {
  static propTypes = {

    managerOfficeStats: PropTypes.objectOf(PropTypes.any),
  }

  static defaultProps = {
    managerOfficeStats: null,
  }

  render() {
    const { managerOfficeStats } = this.props

    const socioProfessionalCategories = ["Ouvrier(e) non qualifié(e)", "Ouvrier(e) qualifié(e)", "Employé(e)", "Agent de maîtrise", "Technicien", "Ingénieur", "Cadre", "Autre"]
    const socioProfessionalCategoriesColumns = Object.keys(socioProfessionalCategories).map(
      (socioProfessionalCategoryKey) => ({
        id: socioProfessionalCategoryKey,
        title: socioProfessionalCategories[socioProfessionalCategoryKey],
        selector: `professional_category_${socioProfessionalCategoryKey}`,
      }),
    )

    const columns = [
      {
        id: 'label',
        title: '',
        selector: 'label',
        highlight: true,
      },
      ...socioProfessionalCategoriesColumns,
      {
        id: 'total',
        title: "TOTAL",
        selector: 'total',
      },
    ]

    let data = []
    if (managerOfficeStats.beneficiaryCountByActivity.length > 0) {
      const dataValues = _keyBy(managerOfficeStats.beneficiaryCountByActivity, 'label')
      const socioProfessionalCategoriesDefaultData = Object.keys(
        socioProfessionalCategories,
      ).reduce(
        (res, socioProfessionalCategoryKey) => ({
          ...res,
          [`professional_category_${socioProfessionalCategoryKey}`]: 0,
        }),
        {},
      )
      const defaultData = {
        ...socioProfessionalCategoriesDefaultData,
        total: 0,
      }
      const companyActivitySectors = ["Agriculture, sylviculture, pêche", "Industrie", "Bâtiment, génie civil et agricole", "Commerce et services marchands", "Assurances et services financiers", "Services non marchands", "Autre"]
      data = Object.keys(companyActivitySectors).map((companyActivitySectorKey, index) => {
        let values = defaultData
        if (dataValues[companyActivitySectorKey]) {
          values = {
            ...values,
            ...dataValues[companyActivitySectorKey],
          }
        }

        return {
          ...values,
          id: index,
          label: companyActivitySectors[companyActivitySectorKey],
        }
      })
      if (dataValues.total) {
        const values = {
          ...defaultData,
          ...dataValues.total,
        }
        data.push({
          ...values,
          id: 'total',
          label: "TOTAL",
        })
      }
    }

    return (
      <StatsTable
        className="statsTable"
        title={"Origine des talents"}
        columns={columns}
        data={data}
        dataIdSelector="id"
      />
    )
  }
}

export default connect(mapStateToProps, null)(ManagerBeneficiaryCountByActivityStatistics)
import React from "react";
import { Modal } from "../../../components/atoms/Modal/Modal";
import './DeleteMemberGroupPopin.scss'
export const DeleteMemberGroupPopin = ({ member, open, onClose, onDeleteMemberGroup }) => {

    const renderContent = () => {
        const { first_name, last_name } = member
        return (
            <div className="delete-member-group-popin">
                <p>Voulez - vous retirer <span> {first_name} {last_name}</span> de ce groupe ? </p>
            </div>
        )
    }
    return (
        <Modal
            onClosePopin={onClose}
            openPopin={open}
            title={"Suppression du membre"}
            content={renderContent()}
            cancelButton={"Annuler"}
            actionButton={"Supprimer"}
            handleActionButton={onDeleteMemberGroup}
        />
    )
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import VideoPopin from '../../components/organisms/VideoPopin/VideoPopin'
import AdminPageAside from '../../containers/admin/AdminPageAside/AdminPageAside'
import Page from '../../containers/global/Page/Page'
import TopSearch from '../../containers/global/TopSearch/TopSearch'
 
import {AddModulePopin} from '../../containers/module/AddModulePopin/AddModulePopin'
import ModuleList from '../../containers/module/ModuleList/ModuleList'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import {
  actions as ModuleTemplateActions,
  selectors as ModuleTemplateSelectors,
} from '../../redux/ModuleTemplateRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  moduleTemplate: ModuleTemplateSelectors.templates(state),
})

const mapDispatchToProps = (dispatch) => ({
  getTemplates: (filter) => dispatch(ModuleTemplateActions.getTemplates(filter)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})

class AdminModuleTemplatesPage extends Component {


  state = {
    published: true,
    title: '',
    sort: '',
    openAddNew: false,
    videoPopin: '',
    videoTitle: '',
  }

  handleAddPopin = () => {
    this.setState(({ openAddNew }) => ({ openAddNew: !openAddNew }))
  }

  renderVideo = () => {
    const { videoPopin, videoTitle } = this.state

    return (
      <VideoPopin
        title={videoTitle}
        videoId={videoPopin}
        onClose={this.handleCloseVideoPopin}
        open={!!videoPopin}
      />
    )
  }

  handleVideoClicked = (url, title) => {
    this.setState({ videoPopin: url, videoTitle: title })
  }

  handleCloseVideoPopin = () => {
    this.setState({ videoPopin: '', videoTitle: '' })
  }

  renderContent = () => (
    <>
      <CircleButton icon="plus" onClick={this.handleAddPopin} />
      <ModuleList modules={this.props.moduleTemplate} onVideoClicked={this.handleVideoClicked} />
      <AddModulePopin onClose={this.handleAddPopin} open={this.state.openAddNew} />
      {this.renderVideo()}
    </>
  )

  handleTabChange = (id) => {
    const published = id === 'published'

    this.setState({ published }, this.getTemplates)
  }

  handleSearch = (value) => {
    this.setState({ title: value }, this.getTemplates)
  }

  renderSearchBar() {
    return <TopSearch onSearch={this.handleSearch} />
  }

  renderTabs() {
    const { published } = this.state
    const links = [
      { id: 'published', label: "Publiés" },
      { id: 'draft', label: "Non publiés" },
    ]

    return (
      <LineMenu
        value={published ? 'published' : 'draft'}
        links={links}
        onChange={this.handleTabChange}
        components={this.renderSearchBar()}
      />
    )
  }

  getTemplates = () => {
    const { title, published } = this.state

    this.props.getTemplates({
      template_published: published,
      title,
    })
  }

  componentDidMount() {
    this.getTemplates()
  }

  render() {
    const aside = <AdminPageAside />
    const content = (
      <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
    )

    return <LeftAsideLayout aside={aside} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(AdminModuleTemplatesPage))
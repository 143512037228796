import React, { Component } from 'react'
import { connect } from 'react-redux'
import { actions as NavigationActions } from '../../redux/NavigationRedux'
import { actions as BeneficiaryActions } from '../../redux/BeneficiaryRedux'

import Logo from '../../static/assets/logo-my-talents.svg'
import WelcomePopin from '../../components/organisms/WelcomePopin/WelcomePopin'
import "./BeneficiaryWaitingPage.scss"

const mapDispatchToProps = (dispatch) => ({
  disableWaitingPage: () => dispatch(BeneficiaryActions.disableWaitingPage()),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})

class BeneficiaryWaitingPage extends Component {

  buildSteps = () => {
    return [
      {
        steps: 1,
        current: 1,
        mainTitle: "Félicitations, vous êtes passé(e) à l’étape suivante",
        content: "Vous devez désormais compléter si nécessaire la section financement de votre dossier.",
        nextId: 'next-step',
        next: "Suivant",
        onNext: () => {
          this.props.disableWaitingPage()
          this.props.redirect('/beneficiary/profile')
        },
      },
    ]
  }

  render() {
    const steps = this.buildSteps()

    return (
      <div className="beneficiary-waiting-page">
        <Logo className="logo" />

        <WelcomePopin {...steps[0]} />
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(BeneficiaryWaitingPage)
import React from "react"

// Icons
import AppointmentIcon from "./resourceIcon/appointment.svg";
import CentralTestIcon from "./resourceIcon/centraltest.svg";
import DocumentToReturnIcon from "./resourceIcon/document_to_return.svg";
import FeedbackIcon from "./resourceIcon/feed.svg"
import FormIcon from "./resourceIcon/form.svg";
import LinkIcon from "./resourceIcon/link.svg";
import ReadingIcon from "./resourceIcon/reading.svg";
import SynthesisIcon from "./resourceIcon/synthesis.svg";
import VideoIcon from "./resourceIcon/video.svg";
import WhiteboardIcon from "./resourceIcon/whiteboard.svg";
import ChoiceIcon from "./resourceIcon/choix.svg";
import ConnectionIcon from "./resourceIcon/connection.svg";
import DragIcon from "./resourceIcon/drag.svg";
import DropdownIcon from "./resourceIcon/dropdown.svg";
import DateIcon from "./resourceIcon/rdv.svg";
import RuleIcon from "./resourceIcon/ruler.svg";
import StarIcon from "./resourceIcon/star-empty.svg";
import TextIcon from "./resourceIcon/text.svg";
import YesNoIcon from "./resourceIcon/yesno.svg";

const icon = {
    // ressource
    Appointment: "appointment",
    CentralTest: "centraltest",
    DocumentToReturn: "document_to_return",
    Feedback: "feedback",
    Form: "form",
    Link: "lien",
    Reading: "document",
    Synthesis: "synthesis",
    Video: "vidéo",
    Whiteboard: "exercice",
    // type question
    Choice: "multiple_choice",
    Connection: "connection",
    Drag: "drag",
    Dropdown: "select",
    Date: "date",
    Rule: "opinion_scale",
    Star: "rating",
    Text: "text",
    YesNo: "yes_no"
}

const IconRessources = ({ icon }) => (
    <div className={`ressource-icon ${icon}`}>
        {icon === IconRessources.icon.Appointment && <AppointmentIcon />}
        {icon === IconRessources.icon.CentralTest && <CentralTestIcon />}
        {icon === IconRessources.icon.DocumentToReturn && <DocumentToReturnIcon />}
        {icon === IconRessources.icon.Feedback && <FeedbackIcon />}
        {icon === IconRessources.icon.Form && <FormIcon />}
        {icon === IconRessources.icon.Reading && <ReadingIcon />}
        {icon === IconRessources.icon.Link && <LinkIcon />}
        {icon === IconRessources.icon.Synthesis && <SynthesisIcon />}
        {icon === IconRessources.icon.Video && <VideoIcon />}
        {icon === IconRessources.icon.Whiteboard && <WhiteboardIcon />}  
        {icon === IconRessources.icon.Choice && <ChoiceIcon/>}
        {icon === IconRessources.icon.Connection && <ConnectionIcon/>} 
        {icon === IconRessources.icon.Drag && <DragIcon/>}      
        {icon === IconRessources.icon.Dropdown && <DropdownIcon/>}      
        {icon === IconRessources.icon.Date && <DateIcon/>}      
        {icon === IconRessources.icon.Rule && <RuleIcon/>}      
        {icon === IconRessources.icon.Star && <StarIcon/>}      
        {icon === IconRessources.icon.Text && <TextIcon/>}      
        {icon === IconRessources.icon.YesNo && <YesNoIcon/>}      



    </div>
)

IconRessources.icon = icon

export default IconRessources
import React, { useState, useEffect } from "react";
import DropStepZone from "../../molecules/DropStepZone/DropStepZone";
import './DocumentsAccCard.scss'
import IconCardTitle from "../../molecules/IconCardTitle/IconCardTitle";
import CircleButton from "../../atoms/CircleButton/CircleButton";

import { DeleteDocumentAccompanimentPopin } from "../../../containers/popins/DeleteDocumentAccompanimentPopin/DeleteDocumentAccompanimentPopin";

export const DocumentAccCard = ({ documents, newDocument, deleteDocument, title, readOnly = false }) => {
    const [files, setFiles] = useState([{id: 1, name: "test document", url: "test"}])
    const [showDeleteDocumentPopin, setShowDeleteDocumentPopin] = useState(false);
    const [documentDeletedId, setDocumentDeletedId] = useState()
 
    const getFileUpload = (file) => {
        const fileUpload = file.file.name;
        const reader = new FileReader();
        reader.onload = () => {
            newDocument(fileUpload, reader.result)
        };
        reader.readAsDataURL(file.file);
    };

    const handleDeleteFile = (id) => {
        setShowDeleteDocumentPopin(true)
        setDocumentDeletedId(id)
    }

    const handleConfirmDeleteFile = () => {
        deleteDocument(documentDeletedId)
        setShowDeleteDocumentPopin(false)
    }

    useEffect(() => {
        setFiles(documents)
    }, [documents])

    return (
        <div className="documents-accompaniment-card">
            <IconCardTitle icon="clipboard" color="accent" label={title ?? "Documents"} />
            <div className="documents-accompaniment-content">
                {files && files.length > 0
                    ? <p>{files.length === 1 ? "Document déposé" : "Documents déposés"} ({files.length})</p>
                    : <p>Aucun document deposé</p>
                }

                {files && files.length > 0 && files.map((doc, index) => (
                    <div key={index} className="document-accompaniment">
                        <a href={doc.url} download target='_blank' key={index}>{doc.title}</a>
                        <div className="document-accompaniment-actions">
                            <CircleButton icon={"trash2"} onClick={() => handleDeleteFile(doc.id)}/>

                        </div>
                    </div>
                ))}
                {!readOnly && <DropStepZone label={'Cliquer ou glisser et déposer le document ici.'} onDrop={getFileUpload} />}
                
            </div>
            {showDeleteDocumentPopin && <DeleteDocumentAccompanimentPopin open={showDeleteDocumentPopin} onClose={() => setShowDeleteDocumentPopin(false)} handleDeleteDocument={handleConfirmDeleteFile}/>}
        </div>
    )
}
const PresentationLocale = {
    name: "Nom",
    namePlaceHolder: "Nom",
    firstName: "Prénom",
    firstNamePlaceHolder: "Prénom",
    age: "Age",
    birth: "Date de naissance",
    birthPlaceHolder: "Date de naissance",
    dayShortLabels: ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
    monthLabels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    title: "Civilité",
    groupLabel: "Groupe",
    sources: [{ value: "edof", label: "EDOF" }, { value: "incoming_phone_call", label: "Appels entrants" }, { value: "social_network", label: "Réseaux sociaux" }, { value: "seo", label: "Référencement" }, { value: "other", label: "Autre" }]
}

const ContactLocale = {
    address: "Adresse",
    addressPlaceHolder: "Addresse...",
    zipCode: "Code postal",
    postCodePlaceHolder: "Code postal",
    city: "Ville",
    cityPlaceHolder: "Ville...",
    phone: "Téléphone",
    phonePlaceHolder: "Numéro de téléphone",
    mobile: "Portable",
    mobilePlaceHolder: "Numéro de téléphone portable...",
    email: "Email",
    emailPlaceHolder: "Votre email valide",
}

const genders = [{ key: "male", label: "M" }, { key: "female", label: "Mme" }];
// const sources = [{ value: "edof", label: "EDOF" }, { value: "incoming_phone_call", label: "Appels entrants" }, { value: "social_network", label: "Réseaux sociaux" }, { value: "seo", label: "Référencement" }, { value: "other", label: "Autre" }]
// const monthLabels = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]

const educationOptions = [
    { value: "I", label: "Bac + 5 et + : Master 2 / Ecole d'ingénieur et +" },
    { value: "II", label: "Bac + 3/4 : Licence / Master 1" },
    { value: "III", label: "Bac + 2 : BTS, DUT..." },
    { value: "IV", label: "Bac général, technologique ou professionnel, BP, BT ou équivalent" },
    { value: "V", label: "Sortie après l'année de terminale de CAP ou sortie du second cycle général et technologique avant l'année de terminale (seconde ou première)" },
    { value: "Vbis", label: "Sortie en cours de cycle de l'enseignement secondaire (de la 6ème à la 3ème)" },
    { value: "VI", label: "Abandon en cours de CAP ou BEP avant l'année de terminale" },
]

const situationOptions = [
    { value: "cdd", label: "Salarié(e) CDD" },
    { value: "cdi", label: "Salarié(e) CDI" },
    { value: "jobseeker", label: "Demandeur d'emploi" },
    { value: "student", label: "Etudiant(e)" },
    { value: "other", label: "Autre" },
]

const activityOptions = [
    { value: "other", label: "Autre" },
    { value: "agriculture_forestry_fishing", label: "Agriculture, sylviculture, pêche" },
    { value: "industry", label: "Industrie" },
    { value: "building_civil_agricultural_engineering", label: "Bâtiment, génie civil et agricole" },
    { value: "trade_commercial", label: "Commerce et services marchands" },
    { value: "insurance_financial", label: "Assurances et services financiers" },
    { value: "non_market", label: "Services non marchands" },
]

const socioProfressionalCategories = [
    { value: "unskilled_worker", label: "Employé(e) / Ouvrier(e)" },
    { value: "qualified_worker", label: "Employé(e) qualifié(e)" },
    { value: "agent_control", label: "Agent de maîtrise / ETAM" },
    { value: "technician", label: "Technicien" },
    { value: "engineer", label: "Ingénieur" },
    { value: "frame", label: "Cadre" },
    { value: "assimilated_frame", label: "Assimilé cadre" },
    { value: "executive_frame", label: "Cadre dirigeant" },
    { value: "other", label: "Autre" },

]

const companySizes = [
    { value: "xs", label: "Entreprise de - de 10 salariés" },
    { value: "s", label: "10 à 49 salariés" },
    { value: "m", label: "50 à 199 salariés" },
    { value: "l", label: "200 à 499 salariés" },
    { value: "xl", label: "500 salariés ou +" },
    { value: "other", label: "Autre" },
]

const nonCompanyActivitySectors = [
    { value: "state", label: "L'Etat" },
    { value: "territorial_collectivity", label: "Collectivité territoriale" },
    { value: "public_institution", label: "Etablissement public à caractère administratif" },
    { value: "other", label: "Autre" },
]


const AccompanimentLocale = {
    accompanimentTypeLabel: "Type d'accompagnement",
    groupLabel: "Groupe",
    homeOfficeLabel: "Bureau de rattachement",
    durationLabel: "Durée",
    synchronousTimeLabel: "Durée synchrone",
    asynchronousTimeLabel: "Durée asynchrone",
    termsLabel: "Modalités",
    vaeAvailabilityLabel: "Disponibilité pour la VAE",
    vaeEligibilityDateLabel: "Date de recevabilité",
    vaeBookletDateLabel: "Date de rendu du livret 2",
    startDateLabel: "Date de début",
    endDateLabel: "Date de fin",
    bilanTypeLabel: "Type de bilan",
    bilanAvailabilityLabel: "Disponibilité pour le bilan",
    dayShortLabels: ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
    monthLabels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    accompanimentSurveyCountLabel: "Nombre d'enquête métier",
}


const accompanimentTypes = [
    { value: "appraisal", label: "Bilan de compétences" },
    { value: "outplacement_individual", label: "Outplacement Individuel" },
    { value: "vae", label: "VAE" },
    { value: "coaching", label: "Coaching" },
    { value: "collective_accompaniment", label: "Accompagnement collectif" },
    { value: "orientation_review", label: "Bilan orientation" },
    { kvalueey: "business_creation", label: "Création d'entreprise" },
    { value: "collective_scheme", label: "Dispositif collectif" },
    { value: "formation", label: "Formation" },
    { value: "other", label: "Autre" },
]

const accompanimentDurationTypes = [
    { value: "hours", label: "Heures" },
    { value: "months", label: "Mois" },
    { value: "days", label: "Jours" },
]

const appraisalTypes = [
    { value: "pole_emploi", label: "France travail" },
    { value: "formation_plan", label: "Plan de développement des compétences" },
    { value: "leave_of_absence", label: "Financement personnel" },
    { value: "cpf", label: "CPF" },
    { value: "individual", labek: "Particulier" },
    { value: "other", labek: "Autre" }
]

const appraisalAvailabilities = [
    { value: "within_work", label: "Durant le travail" },
    { value: "out_of_work", label: "Hors horaires de travail" },
]

const typeCollectiveAccompaniment = [
    { value: "reclassification_leave", label: "Congé de reclassement" },
    { value: "professional_securitization_contract", label: "Contrat de sécurisation professionnel (CSP)" },
    { value: "mobility_leave", label: "Congé de mobilité" },
    { value: "gepp", label: "GEPP" },
    { value: "pic_eic", label: "PIC/EIC" },
    { value: "antenna", label: "Antenne" },
    { value: "internal_mobility", label: "Mobilité interne" },
]

const termsList = [
    { value: 'face_to_face', label: "Présentiel" },
    { value: 'remote', label: "Distanciel" },
    { value: 'mixt', label: "Mixte" },

]

export {
    PresentationLocale,
    genders,
    ContactLocale,
    educationOptions,
    situationOptions,
    activityOptions,
    socioProfressionalCategories,
    companySizes,
    nonCompanyActivitySectors,
    AccompanimentLocale,
    accompanimentTypes,
    accompanimentDurationTypes,
    appraisalTypes,
    appraisalAvailabilities,
    typeCollectiveAccompaniment,
    termsList
};
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import VideoPopin from '../../components/organisms/VideoPopin/VideoPopin'
import AdminPageAside from '../../containers/admin/AdminPageAside/AdminPageAside'
import EditResourcePopin from '../../containers/course/EditResourcePopin/EditResourcePopin'
import Page from '../../containers/global/Page/Page'
import TopSearch from '../../containers/global/TopSearch/TopSearch'
import ResourceList from '../../containers/module/ResourceList/ResourceList'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import { selectors as NavigationSelectors } from '../../redux/NavigationRedux'
import {
  actions as ResourceTemplateActions,
  selectors as ResourceTemplateSelectors,
} from '../../redux/ResourceTemplateRedux'
import { actions as WSActions, selectors as WSSelectors } from '../../redux/WSRedux'

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  templates: ResourceTemplateSelectors.templates(state),
  saveAction: WSSelectors.action(state, 'resourceTemplate.save'),
})

const mapDispatchToProps = (dispatch) => ({
  saveTemplate: (template) => dispatch(ResourceTemplateActions.saveTemplate(template)),
  getTemplates: (filter) => dispatch(ResourceTemplateActions.getTemplates(filter)),
  resetSave: () => dispatch(WSActions.reset('resourceTemplate.save')),
})

class AdminResourcesPage extends Component {

  state = {
    published: true,
    query: '',
    resource: null,
    videoPopin: '',
    videoTitle: '',
  }

  handleAddClick = () => {
    this.setState({
      resource: {
        type: 'document',
      },
    })
  }

  handleEditPopinClose = () => {
    this.setState({ resource: null })
    this.getTemplates()
  }

  handleEditClick = (resource) => {
    this.setState({ resource })
  }

  handleEditSubmit = (resource) => {
    this.props.saveTemplate(resource)
  }

  renderVideo = () => {
    const { videoPopin, videoTitle } = this.state

    return (
      <VideoPopin
        title={videoTitle}
        videoId={videoPopin}
        onClose={this.handleCloseVideoPopin}
        open={!!videoPopin}
      />
    )
  }

  handleVideoClicked = (url, title) => {
    this.setState({ videoPopin: url, videoTitle: title })
  }

  handleCloseVideoPopin = () => {
    this.setState({ videoPopin: '', videoTitle: '' })
  }

  renderContent = () => (
    <>
      <CircleButton icon="plus" onClick={this.handleAddClick} />
      <ResourceList
        resources={this.props.templates}
        onEditClick={this.handleEditClick}
        onVideoClicked={this.handleVideoClicked}
      />
      <EditResourcePopin
        open={!!this.state.resource}
        resource={this.state.resource}
        canPublish
        onSubmit={this.handleEditSubmit}
        onClose={this.handleEditPopinClose}
      />
      {this.renderVideo()}
    </>
  )

  handleTabChange = (id) => {
    this.setState({ published: id === 'published' }, this.getTemplates)
  }

  handleSearch = (query) => {
    this.setState({ query }, this.getTemplates)
  }

  renderSearchBar() {
    return <TopSearch onSearch={this.handleSearch} />
  }

  renderTabs() {
    const { published } = this.state
    const links = [
      { id: 'published', label: "Publiés" },
      { id: 'draft', label: "Non publiés" },
    ]

    return (
      <LineMenu
        value={published ? 'published' : 'draft'}
        links={links}
        onChange={this.handleTabChange}
        components={this.renderSearchBar()}
      />
    )
  }

  getTemplates = () => {
    const { query, published } = this.state

    this.props.getTemplates({
      template_published: published,
      title: query,
    })
  }

  componentDidMount() {
    this.getTemplates()
  }

  componentDidUpdate() {
    const { saveAction } = this.props
    const { resolved } = saveAction

    if (resolved) {
      this.handleEditPopinClose()
      this.props.resetSave()
    }
  }

  render() {
    const aside = <AdminPageAside />
    const content = (
      <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
    )

    return <LeftAsideLayout aside={aside} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(AdminResourcesPage))
import React from "react";
import { Modal } from "../../../components/atoms/Modal/Modal";

import './DeleteClientPopin.scss'
export const DeleteClientPopin = ({ open, onClose, client, handleDeleteClient }) => {

    const renderContent = () => {
        if (client) {
            return (
                <div className="delete-client-popin-content">
                    <p>Êtes-vous sûr(e) de vouloir supprimer définitivement ce client <span>{client.name}</span> ?</p>
                    <p>Cette action est irréversible et le client sera définitivement supprimé de la base de données.</p>
                </div>
            )
        }
    }

    return (
        <Modal
        onClosePopin={onClose}
        openPopin={open}
        title={"Suppression d'un client"}
        content={renderContent()}
        cancelButton={"Retour"}
        actionButton={"Supprimer le client"}
        handleActionButton={handleDeleteClient}
        />
    )
}
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { actions as AppointmentActions } from '../../../redux/AppointmentRedux'
 

import { Popin } from '../../global/Popin/Popin'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'

const mapDispatchToProps = (dispatch, props) => ({
  postpone: (id) => dispatch(AppointmentActions.postpone(id)),
})

class AppointmentPostponePopin extends PureComponent {
  static propTypes = {
    id: PropTypes.number,
    postpone: PropTypes.func.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    open: false,
  }

  handlePostpone = () => {
    this.props.postpone(this.props.id)
    this.props.onClose()
  }

  render() {
    const { onClose, open } = this.props

    return (
      <Popin onClose={onClose} open={open}>
        <AlertPopin
          label={"t('module_appointment.postpone_title')"}
          text={"t('module_appointment.postpone_confirmation')"}
          labelCancelButton={"Annuler"}
          labelConfirmButton={"t('actions.postpone')"}
          onCancelButtonClick={onClose}
          onConfirmButtonClick={this.handlePostpone}
        />
      </Popin>
    )
  }
}

export default connect(null, mapDispatchToProps)(AppointmentPostponePopin)
import _pick from 'lodash/pick'
import _transform from 'lodash/transform'
import ApiService from './ApiService'

const api = ApiService

export const attribute = ({ token, beneficiary_id, consultant_id }) => {
  const url = `/beneficiaries/${beneficiary_id}/attribute`
  const data = { consultant_id }

  return api.post(url, data, { headers: { Authorization: `Bearer ${token}` } })
}

export const share = ({ token, beneficiary_id, consultant_id }) => {
  const url = `/beneficiaries/${beneficiary_id}/share`
  const data = { consultant_id, access: 'rw' }

  return api.post(url, data, { headers: { Authorization: `Bearer ${token}` } })
}

export const unshare = ({ token, beneficiary_id, consultant_id }) => {
  const url = `/beneficiaries/${beneficiary_id}/unshare`
  const data = { consultant_id }

  return api.post(url, data, { headers: { Authorization: `Bearer ${token}` } })
}

export const saveBeneficiary = ({ token, id, ...data }) => {
  const prepared = prepareForCompleteSave(data)

  return id
    ? api.patch(`/beneficiaries/${id}`, prepared, { headers: { Authorization: `Bearer ${token}` } })
    : api.post(`/beneficiaries`, prepared, { headers: { Authorization: `Bearer ${token}` } })
}

export const remove = ({ token, id }) => {
  return api.delete(`/beneficiaries/${id}`, null, { headers: { Authorization: `Bearer ${token}` } })
}

export const saveCurrentBeneficiary = ({ token, ...data }) => {
  const url = '/beneficiaries/me'
  const prepared = prepareForSave(data)

  return api.patch(url, prepared, { headers: { Authorization: `Bearer ${token}` } })
}

export const getMyConsultant = ({ token }) => {
  const url = '/consultants/primary'

  return api.get(url, null, { headers: { Authorization: `Bearer ${token}` } })
}

export const getBeneficiaries = ({ token, filter }) => {
  const query = _transform(filter, (query, value, key) => {
    if (value) {
      query[`filter[${key}]`] = value
    }
  })

  return api.get('/beneficiaries', query, { headers: { Authorization: `Bearer ${token}` } })
}

export const exportBeneficiaries = ({ token, filter }) => {
  const query = _transform(filter, (query, value, key) => {
    if (value) {
      query[`filter[${key}]`] = value
    }
  })

  return api.get('/beneficiaries/export', query, { headers: { Authorization: `Bearer ${token}` } })
}

export const getBeneficiary = ({ token, id }) => {
  return api.get(`/beneficiaries/${id}`, null, { headers: { Authorization: `Bearer ${token}` } })
}

export const getCvDesignrData = ({ token, id }) => {
  return api.get(`/beneficiaries/cvdesignr_data/${id}`, null, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const getCvDesignrAuthLink = ({ token, id, type }) => {
  const data = { type }
  return api.post(`/beneficiaries/cvdesignr_link/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const getCvDesignrPdfLink = ({ token, id, cvId }) => {
  const data = { cv_id: cvId }
  return api.post(`/beneficiaries/cvdesignr_pdf_link/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const getCvDesignrEditLink = ({ token, id, cvId }) => {
  const data = { cv_id: cvId }
  return api.post(`/beneficiaries/cvdesignr_edit_link/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const getCvDesignrCommentLink = ({ token, id, cvId }) => {
  const data = { cv_id: cvId }
  return api.post(`/beneficiaries/cvdesignr_comment_link/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const prepareForSave = (beneficiary) => {
  return _pick(beneficiary, [
    'first_name',
    'last_name',
    'consultant_id',
    'image_url',
    'gender',
    'birth_date',
    'address',
    'zip_code',
    'city',
    'phone_mobile',
    'phone_home',
    'cursus_formation',
    'cursus_professional_situation',
    'cursus_socio_professional_category',
    'diploma_name',
    'cursus_function',
    'cursus_company_activity_sector',
    'cursus_company_size',
    'cursus_non_company_activity_sector',
    'cursus_handicap_status',
    'financer_support_agreement_file',
    'cursus_handicap_file',
    'cursus_cv_file',
    'comments',
    'source',
  ])
}

export const prepareForCompleteSave = (beneficiary) => {
  return {
    ...prepareForSave(beneficiary),
    ..._pick(beneficiary, [
      'status',
      'email',
      'accompaniment_type',
      'accompaniment_duration',
      'accompaniment_duration_type',
      'accompaniment_start_date',
      'accompaniment_end_date',
      'accompaniment_survey_count',
      'accompaniment_appraisal_type',
      'accompaniment_appraisal_availability',
      'financer_state',
      'financer_type',
      'financer_opacif_type',
      'financer_name',
      'financer_support_request_file',
      'financial_ht',
      'financial_tva',
      'group_id',
      'archived_reason',
      'comments',
      'source',
    ]),
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CircleButton from '../../atoms/CircleButton/CircleButton'
import Icon from '../../atoms/Icon/Icon'
import './ClientCard.scss'
export default class ClientCard extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string.isRequired,
    region: PropTypes.string,
    imageUrl: PropTypes.string,
    beneficiaryCount: PropTypes.number.isRequired,
    consultantCount: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    onClickRemove: PropTypes.func,
    locale: PropTypes.shape({
      consultantsLabel: PropTypes.string.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    region: '',
    imageUrl: null,
    onClick: ({ id }) => undefined,
  }

  handleClick = () => {
    this.props.onClick({ id: this.props.id })
  }

  handleClickRemove = () => {
    this.props.onClickRemove({ id: this.props.id })
  }

  render() {
    const { imageUrl, label, region, beneficiaryCount, consultantCount, consultantActiveCount, locale } = this.props

    return (
      <div className="client-card">
        <div className="top-section">
          <div className="client-image" style={{ backgroundImage: `url(${imageUrl})` }} />
        </div>
        <div className="bottom-section">
          <div className="client-name">
            {label}
            &nbsp;
          </div>
          <div className="client-region">
            {region}
            &nbsp;
          </div>

          <div className="consultant-section">
            <div className="label">{locale.consultantsLabel}</div>
            
            <div className="consultant-count">{beneficiaryCount}</div>
          </div>

          <div className="consultant-section">
            <div className="label">{locale.beneficiariesLabel}</div>
            <div className="consultant-count">{consultantActiveCount}/{consultantCount}</div>
          </div>

          <div className="buttons">
            <CircleButton icon={Icon.icon.Edit} onClick={this.handleClick} />
            <CircleButton icon={Icon.icon.Trash} onClick={this.handleClickRemove} />
          </div>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import Icon from '../../components/atoms/Icon/Icon'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import RowList from '../../components/organisms/RowList/RowList'
import Page from '../../containers/global/Page/Page'
 
import ManagerBeneficiaryGroupsEdit from '../../containers/manager/ManagerBeneficiaryGroupsEdit/ManagerBeneficiaryGroupsEdit'
import ManagerBeneficiaryGroupsRemove from '../../containers/manager/ManagerBeneficiaryGroupsRemove/ManagerBeneficiaryGroupsRemove'
import ManagerPageAside from '../../containers/manager/ManagerPageAside/ManagerPageAside'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import {
  actions as BeneficiaryGroupActions,
  selectors as BeneficiaryGroupSelectors,
} from '../../redux/BeneficiaryGroupRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import { actions as OfficeActions, selectors as CabinetSelectors } from '../../redux/OfficeRedux'
import './ManagerBeneficiaryGroupsPage.scss'
const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  groups: BeneficiaryGroupSelectors.groups(state),
  office: CabinetSelectors.office(state),
})

const mapDispatchToProps = (dispatch) => ({
  getOffice: () => dispatch(OfficeActions.getCurrentUserOffice()),
  getGroups: () => dispatch(BeneficiaryGroupActions.getCurrentUserGroups()),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})


class ManagerBeneficiaryGroupsPage extends Component {


  handleRowClick = ({ id, action }) => {
    switch (action) {
      case 'edit':
        this.handleOpenEditPopin({ id })
        break
      case 'delete':
        this.handleOpenRemovePopin({ id })
        break
      default:
        console.warn('Unknown action', action)
    }
  }

  handleClosePopin = () => {
    this.props.redirect(this.props.pathname.replace(/\/groups\/.+$/, '/groups'))
  }

  handleOpenAddPopin = () => {
    this.props.redirect(`${this.props.pathname}/new`)
  }

  handleOpenEditPopin = ({ id }) => {
    this.props.redirect(`${this.props.pathname}/${id}`)
  }

  handleOpenRemovePopin = ({ id }) => {
    this.props.redirect(`${this.props.pathname}/${id}/remove`)
  }

  componentDidMount() {
    this.props.getOffice()
    this.props.getGroups()
  }

  renderTabs() {
    const links = [{ id: 'groups', label: "Les groupes" }]

    return <LineMenu value={links[0].id} links={links} />
  }

  renderEditPopin() {
    if (!/\/groups\/(new|\d+)/.test(this.props.pathname)) {
      return null
    }

    return <ManagerBeneficiaryGroupsEdit onClose={this.handleClosePopin} />
  }

  renderRemovePopin() {
    if (!/\/groups\/\d+\/remove/.test(this.props.pathname)) {
      return null
    }

    return <ManagerBeneficiaryGroupsRemove onClose={this.handleClosePopin} />
  }

  renderContent() {
    if (!this.props.groups) {
      return null
    }

    const { groups } = this.props
    const items = groups.map(({ id, name }) => ({ id, label: name }))

    return (
      <>
        <CircleButton icon={Icon.icon.Plus} onClick={this.handleOpenAddPopin} />

        <div className="beneficiary-groups">
          <RowList items={items} onAction={this.handleRowClick} />
        </div>

        {this.renderEditPopin()}
        {this.renderRemovePopin()}
      </>
    )
  }

  render() {
    const aside = <ManagerPageAside />

    const content = (
      <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
    )

    return (
      <div className="manager-beneficiary-groups-page">
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(ManagerBeneficiaryGroupsPage))
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { Link } from 'react-router-dom'
import Icon from '../../../components/atoms/Icon/Icon'
import LogoutButton from '../../../components/atoms/LogoutButton/LogoutButton'
import SideMenu from '../../../components/organisms/SideMenu/SideMenu'
import UserLeftColumnLayout from '../../../layouts/UserLeftColumnLayout/UserLeftColumnLayout'
import { selectors as OfficeSelectors } from '../../../redux/OfficeRedux'
import { actions as UserActions } from '../../../redux/UserRedux'
import { useNavigation } from '../../../hooks/useNavigation'
import { selectors as portalSelectors } from '../../../redux/PortalRedux'

const ManagerPageAside = () => {
  const dispatch = useDispatch()
  const office = useSelector(OfficeSelectors.office)
  const { pathname, redirect } = useNavigation()
  const isPortalActive = useSelector(portalSelectors.isActive)

  const logout = () => {
    dispatch(UserActions.logout())
  }

  const handleMenuChange = ({ id }) => {
    redirect(id)
  }

  const getLinks = () => {
    return [
      {
        id: '/manager/consultants',
        label: "Consultants",
        icon: Icon.icon.People,
      },
      // {
      //   id: '/manager/groups',
      //   label: "Accompagnements",
      //   icon: Icon.icon.Paperboard,
      // },
      {
        id: '/manager/beneficiaries/me',
        label: "Talents",
        icon: Icon.icon.Free,
      },
      {
        id: '/manager/statistics',
        label: "Statistiques",
        icon: Icon.icon.Piechart,
      },
      {
        id: '/manager/office',
        label: "Cabinet",
        icon: Icon.icon.Building,
      },
    ]
  }

  const renderHeader = () => {
    if (!office) {
      return null
    }

    return (
      <>
        {!isPortalActive && (
          <div className="logos">
            <img
              className="mytalents-logo"
              alt="Logo"
              src="/static/logos/logo-my-talents-dark.svg"
              width={60}
              height={'auto'}
            />
            <div className="separator" />
            {/* <img className="office-logo" src={office.image_url} alt={office.name} /> */}
            {office.image_url && <img className='office-logo' src={`${process.env.REACT_APP_ASSETS_API_STATIC_URL}${(office.image_url).replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '')}`} alt={office.name} />}
            
          </div>
        )}

      </>
    )
  }

  const renderMenu = () => {
    const links = getLinks()

    return <SideMenu links={links} pathname={pathname} onClick={handleMenuChange} />
  }

  const renderFooter = () => {
    return <LogoutButton label={"Déconnexion"} onClick={logout} />
  }

  return (
    <div className="beneficiary-page-aside">
      <UserLeftColumnLayout header={renderHeader()} menu={renderMenu()} footer={renderFooter()} />
    </div>
  )
}

export default ManagerPageAside

import React  from "react";
import { Popin } from '../../global/Popin/Popin'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'

export const RemoveRessource = ({ open, onClose, handleRemove, isPublished }) => {
  
  const alertText = isPublished
    ? "Attention cette ressource est publiée. Si vous la supprimez, elle se supprimera de votre bibliothèque de ressources."
    : "Êtes-vous sûr de vouloir supprimer cette ressource ?";
  
    return (
        <Popin onClose={onClose} open={open}>
        <AlertPopin
          label={"Supprimer votre ressource"}
          text={alertText}
          labelCancelButton={"Annuler"}
          labelConfirmButton={"Supprimer"}
          onCancelButtonClick={onClose}
          onConfirmButtonClick={handleRemove}
        />
      </Popin>
    )
}
import React, { useEffect } from 'react'
import SideResourceSelector from '../../../components/organisms/SideResourceSelector/SideResourceSelector'
import { getRessourcesType } from '../../../api/TemplateCourseApi'

const AsideModuleSelector = ({ressources, existingRessources, tabs}) => {
  const fetchRessourcesType = () => {
    getRessourcesType()
  }

  useEffect(() => {
    fetchRessourcesType();
  }, [])

  return (
    <SideResourceSelector
        id="ressources"
        existingRessources={existingRessources}
        newRessources={ressources}
        tabs={tabs}
      />
  )
} 

export default AsideModuleSelector;
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import IconProgState from '../../atoms/IconProgState/IconProgState'
import IconChevron from '../../atoms/IconChevron/IconChevron'
import IconButton from '../../atoms/IconButton/IconButton'
import Icon from '../../atoms/Icon/Icon'
import { selectors as UserSelectors } from '../../../redux/UserRedux'
import { connect } from 'react-redux'
import './ParcoursStepHeader.scss'

const mapStateToProps = (state) => ({
  isBeneficiary: UserSelectors.isBeneficiary(state),
})
const mapDispatchToProps = () => ({})

class ParcoursHeader extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    isActivated: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    collapsable: PropTypes.bool,
    progState: PropTypes.string,
    step: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    deletable: PropTypes.bool,
    isEditable: PropTypes.bool,
    isVisible: PropTypes.bool,
    onRemoveClick: PropTypes.func,
    onToggleVisibleClick: PropTypes.func,
    onCollapseClick: PropTypes.func,
    isLocked: PropTypes.bool,
  }

  static defaultProps = {
    isActivated: false,
    collapsable: true,
    progState: null,
    deletable: true,
    isEditable: true,
    onCollapseClick: ({ id, open }) => undefined,
    onRemoveClick: ({ id }) => undefined,
    isLocked: false,
  }

  handleRemoveClick = () => {
    this.props.onRemoveClick({
      id: this.props.id,
    })
  }

  handleToggleVisibleClick = () => {
    this.props.onToggleVisibleClick({
      id: this.props.id,
    })
  }

  handleToggleClick = () => {
    this.props.onCollapseClick({
      id: this.props.id,
    })
  }

  handleTitleChange = (e) => {
    this.props.onChange({ id: 'title', value: e.target.value })
  }

  handleTitleClick = (e) => {
    e.stopPropagation()
  }

  renderTitle() {
    const { isEditable, title, placeholder } = this.props

    if (isEditable) {
      return (
        <input
          type="text"
          value={title}
          className="label"
          placeholder={placeholder}
          onChange={this.handleTitleChange}
          onClick={this.handleTitleClick}
        />
      )
    }

    return <span className="label">{title}</span>
  }

  render() {
    const {
      open,
      isActivated,
      progState,
      step,
      collapsable,
      deletable,
      isEditable,
      isVisible,
      isBeneficiary,
      isLocked,
    } = this.props

    return (
      <div className="parcours-step-header">
        <div
          className={`parcours-step-header-content ${isActivated ? 'active' : 'disable'} ${
            !isEditable && 'not-editable'
          }`}
          onClick={!isLocked || (collapsable && isVisible) || !isBeneficiary ? this.handleToggleClick : null}
        >
          {progState && <IconProgState icon={progState} />}

          <span className="light">{step}</span>
          {this.renderTitle()}

          <div className="trash-chevron-wrapper">
            {deletable && (
              <IconButton
                icon={Icon.icon.Trash2}
                color={Icon.color.Grey2}
                onClick={this.handleRemoveClick}
              />
            )}

            {!isBeneficiary && <IconButton
              icon={Icon.icon.Preview}
              color={isVisible ? Icon.color.Accent : Icon.color.Grey3}
              onClick={this.handleToggleVisibleClick}
              tooltip={isVisible ? 'Actuellement visible' : 'Actuellement caché'}
            />}

            {(!isLocked || (collapsable && isVisible) || !isBeneficiary) && (
              <IconChevron open={open} onClick={collapsable ? this.handleToggleClick : null} />
            )}
          </div>
        </div>

        {open && (!isLocked || isVisible || !isBeneficiary) && this.props.children}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParcoursHeader)
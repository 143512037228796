/* eslint-disable no-unused-vars */
import React, { useState, createRef, useEffect } from 'react';
import { Modal } from '../../../components/atoms/Modal/Modal';

import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './EditCoverImagePopin.scss';

export const EditCoverImagePopin = ({ isOpen, onClose, cover_image, getCroppedFile }) => {
  // image cropper
  const [cropData, setCropData] = useState('#');
  const cropperRef = createRef();

  const handleResizeOnClick = () => {
    const imgElement = cropperRef?.current;
    const cropper = imgElement?.cropper;

    const img = cropper.getCroppedCanvas().toDataURL();
    getCroppedFile(img);
  };

  const renderContent = () => {
    return (
      <div className="edit-cover-image-popin">
        <Cropper
          style={{ height: 400, width: '100%' }}
          dragMode="move"
          ref={cropperRef}
          zoomTo={0.5}
          preview={'.img-preview'}
          initialAspectRatio={3 / 1.2}
          src={cover_image}
          viewMode={1}
          background={true}
          responsive={true}
          checkOrientation={false}
          guides={true}
          cropBoxResizable={true}
        />
      </div>
    );
  };
  return (
    <Modal
      onClosePopin={onClose}
      openPopin={isOpen}
      title={"Recadrer l'image de couverture"}
      content={renderContent()}
      cancelButton={'Annuler'}
      actionButton={'Recadrer'}
      handleActionButton={handleResizeOnClick}
      disabledActionButton={false}
    />
  );
};

// dragMode='move',
// aspectRatio=16 / 9,
// autoCropArea=0.65,
// restore=false,
// guides=false,
// center=false,
// highlight=false,
// cropBoxMovable=false,
// cropBoxResizable=false,
// toggleDragModeOnDblclick=false

/* eslint-disable no-unused-vars */


import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './TabbedContentLayout.scss'
// import Icon from '../../components/atoms/Icon/Icon';
import { useSelector } from 'react-redux';
import { selectors as UserSelectors } from "../../redux/UserRedux";
import './TabbedContentLayout.scss';

const TabbedContentLayout = ({ header, content }) => {
  const [user, SetUser] = useState({ firstname: "", lastname: "", avatar: "" })

  const currentUser = useSelector(UserSelectors.user);

  useEffect(() => {

    SetUser({ firstname: currentUser.first_name, lastname: currentUser.last_name, avatar: currentUser.image_url })

  }, [currentUser.first_name, currentUser.image_url, currentUser.last_name])

  return (
    <div className="layout tabbed-content-layout">
      {/* <nav className='navbar-user'>
        <div className='user-infos btn'>
          <div className='avatar'></div>
          <p>{user.firstname} {user.lastname}</p>
        </div>

        <div className='user-messages btn'>
          <Icon icon='message' color='accent' />
          <p>Messages</p>
        </div>

        <div className='user-notification btn'>
          <Icon icon='notification' color='accent' />
        </div>
      </nav> */}
      
      <header>{header}</header>
      <section>{content}</section>
    </div>
  )
}

TabbedContentLayout.propTypes = {
  header: PropTypes.node,
  content: PropTypes.node,
}

export default TabbedContentLayout
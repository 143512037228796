import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SideMenuLinkPanelLink from './SideMenuLinkPanelLink'
import './SideMenuLinkPanel.scss'
export default class SideMenuLinkPanel extends Component {
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
    onClick: PropTypes.func.isRequired,
  }

  handleClick = ({ id }) => {
    this.props.onClick({ id })
  }

  computedStyle() {
    const { status, options } = this.props
    const countRow = options.length

    switch (status) {
      case 'entered':
      case 'entering':
        return { maxHeight: 50 * countRow, display: "block", opacity: 1 }
      case 'exiting':
      case 'exited':
        return { display: "none" }
      default:
        break
    }
  }

  render() {
    const { options, pathname } = this.props

    const links = options.map(({ id, label }) => (
      <SideMenuLinkPanelLink
        key={id}
        id={id}
        label={label}
        pathname={pathname}
        onClick={this.handleClick}
      />
    ))

    return (
      <div className="side-menu-link-panel" style={this.computedStyle()}>
        {links}
      </div>
    )
  }
}

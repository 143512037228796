import React from 'react'
import PropTypes from 'prop-types'
import CheckboxUnckeckedIcon from './icons/checkbox-unchecked.svg'
import CheckboxCkeckedIcon from './icons/checkbox-checked.svg'
import CheckboxRadioCheckedIcon from "./icons/checkbox-radio-checked.svg"
import CheckboxRadioUnCheckedIcon from "./icons/checkbox-radio-unchecked.svg"

import './IconCheckbox.scss'
const IconCheckBox = ({ id, value, onClick, radio }) => {
  const handleClick = () => {
    onClick({ id })
  }

  if(radio) {
    return value ? (
      <CheckboxRadioCheckedIcon className="icon-checkbox selected" onClick={handleClick} />
    ) : (
      <CheckboxRadioUnCheckedIcon className="icon-checkbox unselected" onClick={handleClick} />
    )
  }
  else {
    return value ? (
      <CheckboxCkeckedIcon className="icon-checkbox selected" onClick={handleClick} />
    ) : (
      <CheckboxUnckeckedIcon className="icon-checkbox unselected" onClick={handleClick} />
    )
  }
}

IconCheckBox.propTypes = {
  id: PropTypes.string,
  value: PropTypes.bool,
  radio: PropTypes.bool
}

export default IconCheckBox

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './StatsTable.scss';

export default class StatsTable extends Component {
  static propTypes = {
    title: PropTypes.string,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        title: PropTypes.string,
        selector: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        highlight: PropTypes.bool,
      }),
    ).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    dataIdSelector: PropTypes.string.isRequired,

    className: PropTypes.string,
  }

  static defaultProps = {
    title: null,
    className: '',
  }

  renderHeaderCells = () => {
    const { columns } = this.props

    const columnsCells = columns.map((col) => <th key={col.id}>{col.title}</th>)

    return <tr>{columnsCells}</tr>
  }

  renderDataCells = () => {
    const { columns, data, dataIdSelector } = this.props

    if (data.length === 0) {
      return (
        <tr>
          <td colSpan={columns.length}>{"Aucune donnée pour l'année selectionnée"}</td>
        </tr>
      )
    }

    return data.map((item) => {
      const rowCells = columns.map((col) => {
        let dataValue = item[col.selector]
        if (typeof col.selector === 'function') {
          dataValue = col.selector(item)
        }

        return (
          <td key={col.id} className={col.highlight ? 'highlight' : ''}>
            {dataValue}
          </td>
        )
      })

      return <tr key={item[dataIdSelector]}>{rowCells}</tr>
    })
  }

  render() {
    const { title, className } = this.props

    return (
      <div className={classnames('stats-table', className)}>
        {title !== null && <div className="title">{title}</div>}
        <table>
          <thead>{this.renderHeaderCells()}</thead>
          <tbody>{this.renderDataCells()}</tbody>
        </table>
      </div>
    )
  }
}

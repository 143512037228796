import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import classnames from 'classnames'
import './FieldContainer.scss'

export default (options) => (Component) => {
  return class FieldContainer extends PureComponent {
    static propType = {
      type: PropTypes.string,
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
      onChange: PropTypes.func,
    }

    static defaultProps = {
      onChange: ({ id, value }) => undefined,
    }

    renderField = ({ input, meta }) => {
      const error = (meta.touched || meta.submitFailed) && meta.error
      const className = classnames('field-container', { error })
      const handleChange = ({ id, value }) => {
        this.props.onChange({ id, value })
        input.onChange(value)
      }

      return (
        <div className={className}>
          <Component {...input} {...this.props} onChange={handleChange} />

          {error && <div className="error">{error}</div>}
        </div>
      )
    }

    render() {
      const { type, name, value } = { ...options, ...this.props }

      return <Field type={type} name={name} value={value} component={this.renderField} />
    }
  }
}

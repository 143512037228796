import { Map } from 'immutable';
import { createAction, createReducer } from 'redux-act'

const initialState = Map({
    websocket: null,
    globalWebSocket: null,
})

export const actions = {
    setWebSocket: createAction('setWebSocket', (websocket) => ({ websocket })),
    setGlobalSocket: createAction('setGlobalSocket', (globalWebsocket) => ({ globalWebsocket })),
    getWebSocket: createAction('getWebSocket'),
    getGlobalSocket: createAction('getGlobalSocket'),
}

export const reducer = createReducer(
    {
        [actions.setWebSocket]: (state, {websocket}) => state.merge({websocket}),
        [actions.setGlobalSocket]: (state, {globalWebsocket}) => state.set("globalWebsocket", globalWebsocket),
    },
    initialState,
)

export const selectors = {
    setWebSocket: (state) => state.chat.get("websocket"),
    setGlobalSocket: (state) => state.chat.get("globalWebsocket")
}

// Main accompaniment

const AccompanimentSyntheseCoLocale = {
    titleSyntheseLabel: "Titre de la synthèse", 
    dateSyntheseLabel: "Date de la Synthese",
    descriptionSyntheseLabel: "Description",
    monthLabels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    dayShortLabels: ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim'],
}

export {
    AccompanimentSyntheseCoLocale
};
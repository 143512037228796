import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export default class SideMenuLinkPanelLink extends Component {
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  handleClick = () => {
    this.props.onClick({ id: this.props.id })
  }

  render() {
    const { id, pathname, label } = this.props
    let selected = pathname.startsWith(id);

    if(pathname === "/consultant/beneficiaries/missions" && label === "Clients/Missions") {
      selected = true
    }

    const className = classnames('option', {
      selected,
    })

    return (
      <div className={className} onClick={this.handleClick}>
        {label}
      </div>
    )
  }
}
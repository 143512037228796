import React, { Component } from 'react'
import { connect } from 'react-redux'

import Page from '../../containers/global/Page/Page'

import {
  actions as BeneficiaryActions,
  selectors as BeneficiarySelectors,
} from '../../redux/BeneficiaryRedux'
import { actions as BeneficiaryGroupActions } from '../../redux/BeneficiaryGroupRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import ConsultantBeneficiaryCvsContainer from '../../containers/consultant/ConsultantBeneficiaryCvsContainer/ConsultantBeneficiaryCvsContainer'
import "./ConsultantBeneficiaryCvsPage.scss"

const mapStateToProps = (state, props) => ({
  id: props.match.params.user_id,
  pathname: NavigationSelectors.pathname(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
  cvs: BeneficiarySelectors.cvs(state),
})

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  getBeneficiary: (id) => dispatch(BeneficiaryActions.getBeneficiary(id)),
  getCurrentUserGroups: () => dispatch(BeneficiaryGroupActions.getCurrentUserGroups()),

  resetGroups: () => dispatch(BeneficiaryGroupActions.resetGroups()),
})

class ConsultantBeneficiaryCvsPage extends Component {
  componentDidMount() {
    if (!/\/(cvs)$/.test(this.props.pathname)) {
      this.props.redirect(`${this.props.pathname}/courses`)
    }

    this.props.getBeneficiary(this.props.id)
  }

  render() {
    const { cvs, beneficiary } = this.props

    if (beneficiary === null || !cvs) {
      return null
    }

    return <ConsultantBeneficiaryCvsContainer cvs={cvs} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(ConsultantBeneficiaryCvsPage))
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { actions as UserActions } from '../../redux/UserRedux'
import { actions as NavigationActions, selectors as NavigationSelectors } from '../../redux/NavigationRedux'
import { selectors as WSSelectors } from '../../redux/WSRedux'
import ResetPasswordPopin from '../../components/organisms/ResetPasswordForm/ResetPasswordPopin'
import Logo from '../../static/assets/logo-my-talents-white.svg';
import config from '../../config/AppConfig'
import './ResetPasswordPage.scss';

const mapStateToProps = (state) => ({
  isNew: NavigationSelectors.query(state, 'is_new') === '1',
  pending: WSSelectors.pending(state, 'auth.resetPassword'),
  code: NavigationSelectors.query(state, 'code'),
  userId: NavigationSelectors.query(state, 'user_id'),
})

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(NavigationActions.push('/auth/login')),
  resetPassword: (userId, code, password) =>
    dispatch(UserActions.resetPassword(userId, code, password)),
})

class ResetPasswordPage extends Component {
  static propTypes = {
    resetPassword: PropTypes.func,
  }

  static defaultProps = {
    isNew: false,
  }

  handleSubmit = ({ password }) => {
    const { code, userId } = this.props
    this.props.resetPassword(userId, code, password)
  }

  handleClose = () => {
    this.props.close()
  }

  render() {
    const { pending, isNew } = this.props

    return (
      <div className="reset-password-page">
        <Logo className="logo" />

        <ResetPasswordPopin
          onSubmit={this.handleSubmit}
          onClose={this.handleClose}
          disabled={pending}
          termsUrl={`${config.API_STATIC_URL}/documents/cgu_beneficiaire.pdf`}
          isNew={isNew}
          locale={{
            title: "Choisissez à présent votre nouveau mot de passe",
            description: "Renseignez-le ci-dessous et le tour est joué !",
            cta: "Valider mon nouveau mot de passe",
            passwordLabel: "Votre mot de passe",
            terms: "J'ai lu et j'accepte les conditions générales d'utilisation",
          }}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage)
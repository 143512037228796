import React, { useEffect, useState } from "react";

import { TalentClientCard } from "../../../components/organisms/TalentClientCard/TalentClientCard";

import BigCombo from "../../../components/molecules/BigCombo/BigCombo";
import Icon from "../../../components/atoms/Icon/Icon";
import SearchField from "../../../components/molecules/SearchField/SearchField";

// import { useNavigation } from "../../../hooks/useNavigation";

import './ClientTalentsContainer.scss';

export const ClientTalentsContainer = ({ client, talentsProps }) => {
    // const { redirect } = useNavigation();
    const [talents, setTalents] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        talentsProps.length > 0 ? setTalents(talentsProps) : setTalents([])
    }, [talentsProps])


    const handleSearchTalentOnChange = (search) => {
        setSearchQuery(search.value);
        const lowerCaseQuery = search.value.toLowerCase();
        const filteredTalents = talentsProps.filter(talent => talent.first_name.toLowerCase().includes(lowerCaseQuery) || talent.last_name.toLowerCase().includes(lowerCaseQuery));
        setTalents(filteredTalents);
    }
    const handleFilterMissionOnChange = (dropdown) => {
        const { value } = dropdown;

        if (value.value !== "all") {

            const filteredTalents = talentsProps.filter((talent) =>
                talent.missions.some((mission) => mission.id === value.value)
            );
            setTalents(filteredTalents)
        }
        else setTalents(talentsProps)

    }


    const nb_talents = talents ? talents.length : 0;

    let missions = []
    if(client.missions) {

        missions = client.missions.map(mission => {
            return {
                value: mission.id,
                label: mission.name
            }
        })
    
        const all = { value: "all", label: "Tous" };
        missions.push(all);

    }
    return (
        <div className="client-talents-container">
            <div className="client-talents-container-header">
                <h2>Les talents ({nb_talents})</h2>
                <div className="client-talents-filters">
                    <Icon icon={"sliders"} color={'accent'} />
                    <SearchField placeholder={"Rechercher par nom ou prénom"} value={searchQuery} onChange={handleSearchTalentOnChange} />
                    <BigCombo id={'missions'} title={''} options={missions} value={""} onChange={handleFilterMissionOnChange} />
                </div>
            </div>
            <div className="all-client-talents">
                {talents.map((talent, index) => (
                    <TalentClientCard talent={talent} key={index} mission={talent.missions} />
                ))}
                {talents.length === 0 && <p>Aucun talent ne correspond à vos critères de recherches...</p>}
            </div>
        </div>
    )
}
import React from "react";
import moment from 'moment';
import 'moment/locale/fr';
import './AgendaDate.scss';

export const AgendaDate = ({date}) => {
    const month = moment(date).format('MMMM');
    const day = moment(date).date();
    const nameOfDay = moment(date).format('dddd');
    
    return (
        <div className="agenda-date">
            <div className="agenda-date-month"><p>{month}</p></div>
            <div className="agenda-date-content">
                <h1 className="agenda-date-day">{day}</h1>
                <p className="agenda-date-name-of-day">{nameOfDay}</p>
            </div>
        </div>
    )
}
import React from 'react'
import PropTypes from 'prop-types'
import IconCheckbox from '../../atoms/IconCheckbox/IconCheckbox'
import './CheckBoxField.scss'

const CheckBoxField = ({ id, name, label, iconProgState, value = false, onChange, inputProps, radio, disabled }) => {
  const handleChange = (e) =>
    inputProps?.onChange
      ? inputProps.onChange(e)
      : onChange({
          id,
          value: !value,
          iconProgState,
        })

  return (
    <div className="check-box-field">
      <input
        type="checkbox"
        id={name}
        defaultChecked={value ?? inputProps?.value ?? inputProps?.defaultValue}
        {...inputProps}
      />

      <label htmlFor={name} onClick={!disabled ? handleChange : undefined} >
        <IconCheckbox
          radio={radio}
          value={value ?? inputProps?.value ?? inputProps?.defaultValue}
          onClick={!disabled ? handleChange : undefined}
        />
        <span className={disabled ? 'label disabled' : 'label'}>{label}</span>
      </label>
    </div>
  )
}

CheckBoxField.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  iconProgState: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}

export default CheckBoxField

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { actions as UserActions, selectors as UserSelectors } from '../../redux/UserRedux'
import LogoutButton from '../../components/atoms/LogoutButton/LogoutButton'
import Logo from '../../static/assets/logo-my-talents.svg'
import './DeviceOptimizationPage.scss';

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(UserActions.logout()),
})

class DeviceOptimizationPage extends Component {

  render() {
    const { user } = this.props

    return (
      <div className="device-optimization-page">
        <Logo className="logo" />

        <div className="content">
          <div className="title">
           Bonjour, {user.first_name} {user.last_name}
          </div>
          <div className="message">Message</div>
          <LogoutButton label={"Déconnexion"} onClick={this.props.logout} />
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceOptimizationPage)
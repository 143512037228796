import { axiosInstance } from "./baseApi";

export const getAllClients = async(user_id) => {
    const response = await axiosInstance.get(`clients/user/${user_id}/all`);
    return response.data;
}

export const getClient = async(client_id) => {
    const response = await axiosInstance.get(`clients/${client_id}`);
    return response.data;
}

export const getClientsByUserId = async(user_id) => {
    const response = await axiosInstance.get(`clients/user/${user_id}`);
    return response.data;
}

export const addClient = async(new_client) => {
    const response = await axiosInstance.post(`clients/add`, new_client);
    return response.data;
}


export const updateClient = async (client_id, client_updated) => {
    const response = await axiosInstance.put(`clients/${client_id}/update`, client_updated);
    return response.data;
}

export const deleteClient = async (client_id) => {
    const response = await axiosInstance.delete(`clients/${client_id}/delete`);
    return response.data;
}

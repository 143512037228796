import React from "react";
import { Modal } from "../../../components/atoms/Modal/Modal";

import './DeleteMissionPopin.scss'
export const DeleteMissionPopin = ({ open, onClose, mission, handleDeleteMission }) => {

    const renderContent = () => {
        if (mission) {
            return (
                <div className="delete-mission-popin-content">
                    <p>Êtes-vous sûr(e) de vouloir supprimer définitivement cette mission <span>{mission.name}</span> affectée au client <span>{mission.client_name}</span> ?</p>
                    <p>Cette action est irréversible et la mission sera définitivement supprimé de la base de données.</p>
                </div>
            )
        }
    }

    return (
        <Modal
        onClosePopin={onClose}
        openPopin={open}
        title={"Suppression d'une mission"}
        content={renderContent()}
        cancelButton={"Retour"}
        actionButton={"Supprimer la mission"}
        handleActionButton={handleDeleteMission}
        />
    )
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _keyBy from 'lodash/keyBy'

 

import StatsTable from '../../../components/organisms/StatsTable/StatsTable'

import { selectors as StatsSelectors } from '../../../redux/StatsRedux'

const mapStateToProps = (state) => ({
  managerOfficeStats: StatsSelectors.managerOfficeStats(state),
})

class ManagerBeneficiaryByAgeAndBilanStatistics extends Component {
  static propTypes = {

    managerOfficeStats: PropTypes.objectOf(PropTypes.any),
  }

  static defaultProps = {
    managerOfficeStats: null,
  }

  render() {
    const { managerOfficeStats } = this.props

    const columns = [
      {
        id: 'label',
        title: '',
        selector: 'label',
        highlight: true,
      },
      {
        id: 'age_16_25',
        title: "16 - 25 ans",
        selector: 'age_16_25',
      },
      {
        id: 'age_26_44',
        title: "26 - 44 ans",
        selector: 'age_26_44',
      },
      {
        id: 'age_45_100',
        title: "45 ans et +",
        selector: 'age_45_100',
      },
    ]

    let data = []
    if (managerOfficeStats.beneficiaryCountByAgeAndBilan.length > 0) {
      const appraisalTypes = ["France travail", "Plan de développement des compétences", "Financement personnel", "CPF", "Particulier", "Autre"]
      const dataValues = _keyBy(managerOfficeStats.beneficiaryCountByAgeAndBilan, 'label')
      const defaultData = {
        age_16_25: 0,
        age_26_44: 0,
        age_45_100: 0,
      }
      data = Object.keys(appraisalTypes).map((appraisalTypeKey, index) => {
        let values = defaultData
        if (dataValues[appraisalTypeKey]) {
          values = {
            ...values,
            ...dataValues[appraisalTypeKey],
          }
        }

        return {
          ...values,
          id: index,
          label: appraisalTypes[appraisalTypeKey],
        }
      })
    }

    return (
      <StatsTable
        className="statsTable"
        title={"Age selon le type de bilan"}
        columns={columns}
        data={data}
        dataIdSelector="id"
      />
    )
  }
}

export default connect(mapStateToProps, null)(ManagerBeneficiaryByAgeAndBilanStatistics)
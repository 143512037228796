import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ActionPopin from '../ActionPopin/ActionPopin';
import ComboField from '../../molecules/ComboField/ComboField';
import SearchField from '../../molecules/SearchField/SearchField';
import ParcoursAttribRow from '../../molecules/ParcoursAttribRow/ParcoursAttribRow';
import './ParcoursAttribPopin.scss';

export default class ParcoursAttribPopin extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onBack: PropTypes.func,
    searchValue: PropTypes.string.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    locale: PropTypes.shape({
      title: PropTypes.string.isRequired,
      cta: PropTypes.string.isRequired,
      selectLabel: PropTypes.string,
      step: PropTypes.shape({
        step: PropTypes.string.isRequired,
        steps: PropTypes.string.isRequired,
      }).isRequired,
      module: PropTypes.shape({
        module: PropTypes.string.isRequired,
        modules: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    disableButton: PropTypes.bool.isRequired,
    courses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        isSelected: PropTypes.bool,
        title: PropTypes.string.isRequired,
        steps: PropTypes.array.isRequired,
        onClick: () => {},
      }),
    ),
  };

  static defaultProps = {
    onBack: () => undefined,
    onSubmit: ({ id }) => undefined,
    isSelected: false,
  };

  state = {
    value: '',
  };

  handleSelect = (e, id) => {
    this.props.onCourseSelect(id);
  };

  renderCourses = () => {
    const { courses, locale, selectedCourse } = this.props;
    const { step, module } = locale;

    return courses?.map((course, i) => {
      return (
        <ParcoursAttribRow
          key={i}
          id={course.id}
          isSelected={selectedCourse === course.id}
          label={course.title}
          stepNumber={course.nb_steps}
          moduleNumber={course.nb_modules}
          onClick={this.handleSelect}
          locale={{ step, module }}
        />
      );
    });
  };

  handleChange = (option) => {
    this.props.onComboChange({ value: option && option.value });
  };

  handleSubmit = () => {
    this.props.onSubmit({ id: this.state.value });
  };

  static getDerivedStateFromProps({ options }, state) {
    if (state.value) {
      return null;
    }

    return options[0] || null;
  }

  render() {
    const { locale, options, onBack, searchValue, onSearchChange, comboValue, disableButton } =
      this.props;
    return (
      <div className="parcours-attrib-popin">
        <ActionPopin
          title={locale.title}
          labelButton={locale.cta}
          onBack={onBack}
          onSubmit={this.handleSubmit}
          disabled={disableButton}
          noFooter={true}
        >
          <SearchField showLine onChange={onSearchChange} value={searchValue} />
          <ComboField
            name="attrib-combo"
            options={options}
            onChange={this.handleChange}
            value={comboValue}
          />
          {this.renderCourses()}
        </ActionPopin>
      </div>
    );
  }
}

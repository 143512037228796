import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'
import "./AvatarCircle.scss"

class AvatarCircle extends Component {
  static propTypes = {
    photoUrl: PropTypes.string,
  }


  render() {
    const { photoUrl } = this.props
    // const style = {
    //   backgroundImage: `url('${photoUrl}')`,
    // }


    return (
      <div className="avatar-circle">
        {photoUrl ? (
          
          // eslint-disable-next-line jsx-a11y/img-redundant-alt
          <img className='picture' src={`${process.env.REACT_APP_ASSETS_API_STATIC_URL}${photoUrl.replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '')}`} alt='avatar-picture'/> 
          )
      : (
        <div className="no-picture">
          <Icon icon={Icon.icon.Person} color={"accent"}/> 
        </div>
      )
      }
      </div>
    )
  }
}

export default AvatarCircle

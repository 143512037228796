
const financerTypeLocale = {
    title: "Ses financements",
    financerTypeLabel: "Type de financement",
    financerStateLabel: "Etat de financement",
    OpacifValue: "Opacif",
    financerOpacifTypeLabel: "Type de financement",
    financerNameLabel: "Nom du financeur",
    financerSupportRequestFileLabel: "Fichier de demande",
    financerSupportAgreementFileLabel: "Fichier d'accord",
    financialHTLabel: "Montant HT",
    financialTVALabel: "Montant TVA",
    totalTVALabel: "Total TVA",
    totalTTCLabel: "Total TTC",
}

const financerStateList = [
    {
        id: "aucun",
        value: " ",
        label: "Aucun",
    },
    {
        id: "valide",
        value: "valide",
        label: "Validé",
    },
    {
        id: "en_attente",
        value: "en_attente",
        label: "En attente ",
    },
]

const financerTypeList = [
    { id: 'aucun', value: " ", label: "Aucun" },
    {
        id: "pole_emploie",
        value: "pole_emploie",
        label: "France travail",
    },
    {
        id: "cpf",
        value: "cpf",
        label: "CPF",
    },
    {
        id: "entreprise",
        value: "entreprise",
        label: "Entreprise",
    },
    {
        id: "cofinancement_cpf_et_entreprise",
        value: "cofinancement_cpf_et_entreprise",
        label: "Cofinancement CPF et entreprise",
    },
    {
        id: "etat",
        value: "etat",
        label: "Etat",
    },
    {
        id: "opco",
        value: "opco",
        label: "OPCO",
    },
    {
        id: "collectivite_territoriale",
        value: "collectivite_territoriale",
        label: "Collectivité Territoriale",
    },
    {
        id: "etablissement_public_a_caractere_administratif",
        value: "etablissement_public_a_caractere_administratif",
        label: "Etablissement public à caractère administratif",
    },
    {
        id: "autre",
        value: "autre",
        label: "Autre",
    }
]

const financerOpacifTypeList = [
    {
        id: "1",
        value: "1",
        label: "Cotisation",
    },
    {
        id: "2",
        value: "2",
        label: "Formation",
    }
]

const financialTVAList = [
    {
        id: "20%",
        value: "20%",
        label: "20%",
    },
    {
        id: "non_assujetti_a_la_taxe",
        value: "non_assujetti_a_la_taxe",
        label: "Non assujetti à la taxe",
    }
]

export {
    financerStateList,
    financerTypeList,
    financerOpacifTypeList,
    financialTVAList,
    financerTypeLocale
};
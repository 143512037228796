import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { isValid, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import _pick from 'lodash/pick'

import { asyncValidate } from '../../../helpers/form/FormValidator/FormValidator'

import InputField from '../../molecules/InputField/InputFieldContainer'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import './LoginForm.scss'
import { getThemeColor } from '../../../api/officeApi'
import Icon from '../../atoms/Icon/Icon'
import CheckBoxField from '../../molecules/CheckBoxField/CheckBoxField'
import config from '../../../config/AppConfig'


const mapStateToProps = (state, props) => ({
  valid: isValid('login')(state),
  initialValues: _pick(props, ['email', 'password', 'persist']),
})


const LoginForm = ({ disabled, valid, locale, onSubmit, handleSubmit }) => {
  const [passwordType, setPasswordType] = useState('password')
  const [cgu, setCgu] = useState(false)

  const handleSubmitForm = (values) => {
    handleSubmit(onSubmit)(values)

    getThemeColor()
  }

  const handleTogglePassword = () => {
    setPasswordType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };

  return (
    <form onSubmit={handleSubmit} className="login-form" noValidate>
      <InputField
        id="email"
        name="email"
        placeholder={locale.emailPlaceholder}
        title={locale.email}
        type="email"
        required
        disabled={disabled}
      />

      <div className='login-form-password-container'>
        <InputField
          id="password"
          name="password"
          placeholder={locale.passwordPlaceholder}
          title={locale.password}
          type={passwordType}
          required
          disabled={disabled}
        />
        <span className='view-password-btn' onClick={handleTogglePassword}>
          <Icon icon='preview2' color={"grey1"} />
        </span>
      </div>
      { (
            <div className="field-container cgu-container">
              {/* <CheckBoxField name="terms" onChange={() => setCgu(!cgu)} value={cgu} /> */}

              <a href={`${config.API_STATIC_URL}/documents/cgu_beneficiaire.pdf`} target="_blank">
                Conditions générales d'utilisation
              </a>
            </div>
          )}

      <Link className="forgot-password" to="/auth/forgot-password">
        <p>Mot de passe oublié ?</p>
      </Link>

      {/* <CheckBoxField name="persist" label={locale.persist} disabled={disabled} /> */}

      <PrimaryButton
        label={locale.cta}
        id="connect"
        type="submit"
        onClick={handleSubmitForm}
        disabled={disabled || !valid}
      />
    </form>
  )
}

const ConnectedComponent = connect(mapStateToProps, null)(
  reduxForm(
    {
      form: 'login',
      asyncBlurFields: ['email', 'password'],
      asyncValidate: (values) =>
        asyncValidate(values, {
          email: { rule: 'required|email' },
          password: { rule: 'required' },
        }),
    })(LoginForm)
)


export default ConnectedComponent;

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  actions as NotificationActions,
  selectors as NotificationSelectors,
} from '../../../redux/NotificationRedux'
import InfoPage from '../../../components/molecules/InfoPage/InfoPage'

const mapStateToProps = (state) => ({
  ttl: NotificationSelectors.ttl(state),
})

const mapDispatchToProps = (dispatch, props) => ({
  remove: () => dispatch(NotificationActions.remove(props.id)),
})


class Notification extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['info', 'success', 'error']),
  }

  static defaultProps = {
    type: 'info',
  }

  timeout = null

  clearTimeout() {
    clearTimeout(this.timeout)
  }

  dispose = () => {
    this.clearTimeout()
    this.props.remove()
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.dispose()
    }, this.props.ttl)
  }

  componentWillUnmount() {
    this.clearTimeout()
  }

  render() {
    const { type, text } = this.props

    return <InfoPage text={text} type={type} onClick={this.dispose} />
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Notification)

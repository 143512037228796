import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Popin } from '../../global/Popin/Popin'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'

class RemoveStepModulePopin extends PureComponent {
  static propTypes = {
    stepIndex: PropTypes.number,
    moduleIndex: PropTypes.number,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    isPublished: PropTypes.bool,
  }

  static defaultProps = {
    open: false,
  }


  render() {
    const { onClose, open, handleRemove, isPublished } = this.props;
    return (
      <Popin onClose={onClose} open={open}>
        <AlertPopin
          label={"Supprimer votre module"}
          text={
            isPublished
              ? "Attention cette ressource est publiée. Si vous la supprimez, elle se supprimera de votre bibliothèque de ressources."
              : "Êtes-vous sûr de vouloir supprimer ce module ?"
          }
          labelCancelButton={"Annuler"}
          labelConfirmButton={"Supprimer"}
          onCancelButtonClick={onClose}
          onConfirmButtonClick={handleRemove}
        />
      </Popin>
    )
  }
}

export default RemoveStepModulePopin
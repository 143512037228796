import React, { Component } from 'react'
import PropTypes from 'prop-types'
import QuizStep from '../QuizStep/QuizStep'
import "./QuizQcmRow.scss"
export default class QuizQcmRow extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    stepLabel: PropTypes.string.isRequired,
    stepOutline: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
  }

  onClick = () => {
    const { id, isSelected } = this.props
    this.props.onClick({ id, value: !isSelected })
  }

  activeRowClass() {
    const classes = ['quiz-qcm-row']


    if (this.props.isSelected) {
      classes.push('active')
    }

    if (this.props.disabled) {
      classes.push('disabled')
    }

    return classes.join(' ')
  }

  render() {
    const { stepLabel, stepOutline, label } = this.props

    return (
      <div className={this.activeRowClass()} onClick={this.onClick}>
        <QuizStep stepLabel={stepLabel} stepOutline={stepOutline} label={label} />
      </div>
    )
  }
}

import React from "react";
import Icon from "../Icon/Icon";
import { PropTypes } from "prop-types";
import './NewTag.scss'

export const NewTag = ({ title, color, icon, iconColor, onClickIcon }) => {

    let colorBackground;
    let colorText;

    switch (color) {
        case "main-color":
            colorBackground = `var(--${color}-lighter)`;
            colorText = `var(--${color})`;
            break;
        case "green":
            colorBackground = `var(--${color}-light)`;
            colorText = `var(--${color})`;
            break;
        case "red":
            colorBackground = `var(--${color}-light)`;
            colorText = `var(--${color})`;
            break;
        case "orange":
            colorBackground = `var(--${color}-light)`;
            colorText = `var(--${color})`;
            break;
        case "grey":
            colorBackground = `var(--${color}-light)`;
            colorText = `var(--text-secondary)`;
            break;
        default:
            colorBackground = `var(--main-color-lighter)`;
            colorText = `var(--main-color)`;
            break;
    }

    return (
        <button className="new-tag" style={{ backgroundColor: colorBackground }} onClick={onClickIcon}>
            <p style={{ color: colorText }}>
                {title}
            </p>
            {icon &&
                <div className="new-tag-icon">
                    <Icon icon={icon} color={iconColor} />
                </div>
            }
        </button>
    )
}

NewTag.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    onClickIcon: PropTypes.func
};
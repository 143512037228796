import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { actions as NavigationActions } from '../../redux/NavigationRedux';
import { actions as UserActions } from '../../redux/UserRedux';
import { selectors as WSSelectors } from '../../redux/WSRedux';
import './BeneficiaryTallySheetSign.scss';
import { validateTallysheetAppointment, getAppointmentById } from '../../api/AppointmentsApi';
import Logo from '../../static/assets/logo-my-talents-white.svg';
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton';
import backgroundImage from '../../static/images/background-login.webp';

const BeneficiaryTallySheetSign = (props) => {
  const [status, setStatus] = React.useState(200);
  const [message , setMessage] = React.useState('');
  const [appointment, setAppointment] = React.useState(null);
  useEffect(() => {
        validateTallysheetAppointment(props.match.params.tallysheet_id)
          .then((res) => {
            setStatus(200);
            setMessage(res.message);
          })
          .catch((error) => {
            setStatus(error.response.status);
            setMessage(error.response.data.message);
          });
  }, []);

  return (
    <div className="beneficiary-tally-sheet-page">
      <div className="img-wrapper">
        <img src={backgroundImage} alt="presentation-my-talents" />
        <div></div>
      </div>
      <div className="tallyshett-wrapper">
        <Logo className="logo" />

        <div className="modal-tallysheet">
          {status === 200 && message ? (
            <h1 className="modal-tallysheet-title-success">
              {message}
            </h1>
          ) : status === 400 && message ? (
            <h1 className="modal-tallysheet-title-warning">
              {message}
            </h1>
          )  : (
            <h1 className="modal-tallysheet-title-error">Une erreur est survenue</h1>
          )}
          <p>Retrouvez votre rendez-vous sur votre espace MyTalents </p>
          <PrimaryButton label="Mon espace" onClick={() => props.history.push('/')}>
            Mon espace
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pending: WSSelectors.pending(state, 'auth.login'),
  error: WSSelectors.error(state, 'auth.login'),
});

const mapDispatchToProps = (dispatch) => ({
  evaluate: () => dispatch(NavigationActions.evaluate()),
  login: (email, password, persist) => dispatch(UserActions.login(email, password, persist)),
  sendInvitation: (id, token) => dispatch(UserActions.sendInvitation(id, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryTallySheetSign);

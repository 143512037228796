import React from "react";
import Icon from "../../atoms/Icon/Icon";
import './ButtonsGroup.scss';

export const ButtonsGroup = ({ onClickPrevious, onClickNext, disabledNext, disabledPrevious }) => {
    return (
        <div className="buttons-group">
            <button onClick={onClickPrevious} disabled={disabledPrevious} className={disabledPrevious ? "disabled" : ""}>
                <Icon icon="back" color={"white"} />
            </button>
            <button onClick={onClickNext} disabled={disabledNext} className={disabledNext ? "disabled" : ""}>
                <Icon icon="back" color={"white"} />
            </button>

        </div>
    )
}
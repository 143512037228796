import 'moment/locale/fr'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { isValid, reduxForm } from 'redux-form'
import {
  getValidationRules,
  profileFieldsForConsultant,
} from '../../../helpers/beneficiaryProfileForm'
import { asyncValidate } from '../../../helpers/form/FormValidator/FormValidator'
import { selectors as NavigationSelectors } from '../../../redux/NavigationRedux'
import { selectors as UserSelectors } from '../../../redux/UserRedux'
import { selectors as BeneficiarySelectors } from '../../../redux/BeneficiaryRedux';
import Page from '../../global/Page/Page'

import ConsultantBeneficiary from '../ConsultantBeneficiary/ConsultantBeneficiary'
import ConsultantBeneficiaryAside from '../ConsultantBeneficiaryAside/ConsultantBeneficiaryAside'

import { getUserCVDesignrInfos } from '../../../api/CVDesignrApi'
import './ConsultantBeneficiaryMaestrosContainer.scss'
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton'

import IllustrationJobs from "../../../static/illustrations/illustration_jobs.svg" 
import IllustrationApplication from "../../../static/illustrations/illustration_jobs_application.svg"

const mapStateToProps = (state, props) => ({
  valid: isValid('beneficiary-profile')(state),
  user: UserSelectors.user(state),
  pathname: NavigationSelectors.pathname(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
})

const mapDispatchToProps = (dispatch) => ({})

const ConsultantBeneficiaryMaestrosContainer = ({ beneficiary, user }) => {
  const [talentID, setTalentID] = useState(null);

  useEffect(() => {
    beneficiary && setTalentID(beneficiary.id)
  }, [beneficiary])


  const userCVDesignr = (destination) => {

    getUserCVDesignrInfos(talentID, destination).then((response) => {

      if (response.magic_link) {

        window.open(response.magic_link, "_blank", "noreferrer");
      }

      return false;
    })

    return false;
  }

  const renderAside = () => {
    return <ConsultantBeneficiaryAside />
  }


  const renderContent = () => {
    return (
      <div className="beneficiary-maestro-container">
        <div className="button-job">
          <div className='illustration illu-offers' alt='illu-offers'>
          
              <IllustrationJobs/>
        
          </div>
          <div className="job-info">
            <div className="title-div-job">
              <h4>Ses offres d'emploi</h4>
            <p className="description-job">Découvrez l'ensemble des offres d'emploi</p>
            </div>
            <PrimaryButton onClick={() => userCVDesignr("job-listing")} label={"Consulter"}/>

          </div>
        </div>
        <div className="button-job">
          <div className='illustration illu-jobs' alt='illu-jobs'>
            <IllustrationApplication/>
          </div>
          <div className="job-info">
            <div className="title-div-job">
              <h4>Son suivi de candidatures</h4>
            <p className="description-job">Modifiez et corrigez le tableau de suivi de candidature de votre talent</p>
            </div>
            <PrimaryButton onClick={() => userCVDesignr("maestro")} label={"Consulter"}/>
          </div>
        </div>
      </div>
    )
  }

  return (
    <ConsultantBeneficiary renderAside={renderAside} disableSave>
      <div className="consultant-beneficiary-maestros-page">
        {renderContent()}
      </div>
    </ConsultantBeneficiary>
  )
}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(
  Page(reduxForm(
    {
      form: 'beneficiary-profile',
      asyncBlurFields: profileFieldsForConsultant,
      asyncChangeFields: profileFieldsForConsultant,
      asyncValidate: (values, dispatch, props) => {
        const validationRules = getValidationRules(props.beneficiary, values, true)
        return asyncValidate(values, validationRules)
      },
    })(ConsultantBeneficiaryMaestrosContainer)
  )
)

export default ConnectedComponent;

import React, { useEffect, useState } from "react";

import { BackgroundIcon } from "../../../components/atoms/BackgroundIcon/BackgroundIcon";
import { Modal } from "../../../components/atoms/Modal/Modal";

import './ReportAppointmentPopin.scss';

export const ReportAppointmentPopin = ({ open, onClose, handleReportAppointment }) => {
    const [comment, setComment] = useState('');
    const [disabledReportButton, setDisabledReportButton] = useState(true);

    useEffect(() => {
        comment && comment.length > 0 && setDisabledReportButton(false)
    }, [comment])

    const renderContent = () => {
        return (
            <div className="report-appointment-popin">
                <div className="appointment-popin-section-title">
                    <BackgroundIcon icon={'calendar'} />
                    <p>Motif de report de rendez-vous et propositions d'autres dates et horaires</p>
                </div>
                <span>Veuillez indiquer les raisons du report et proposer de nouvelles dates et horaires dans la zone de texte ci-dessous.</span>
                <textarea onChange={(event) => setComment(event.target.value)} />
            </div>
        )
    }

    return (
        <Modal
            onClosePopin={onClose}
            openPopin={open}
            title={"Demande de reporter le rendez - vous"}
            content={renderContent()}
            cancelButton={"Retour"}
            actionButton={"Reporter le rendez - vous"}
            disabledActionButton={disabledReportButton}
            handleActionButton={() => handleReportAppointment(comment)}
        />
    )
}
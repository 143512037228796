import React from "react";

import './AccompCoSynthesisCard.scss';

export const AccompCoSynthesisCard = () => {
    return (
        <p>
            
        </p>
    )
}
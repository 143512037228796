import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _keyBy from 'lodash/keyBy'

 

import SectionTitle from '../../../components/molecules/SectionTitle/SectionTitle'
import StatsTable from '../../../components/organisms/StatsTable/StatsTable'

import { selectors as StatsSelectors } from '../../../redux/StatsRedux'

const mapStateToProps = (state) => ({
  managerOfficeStats: StatsSelectors.managerOfficeStats(state),
})

class ManagerGlobalStatistics extends Component {
  static propTypes = {

    managerOfficeStats: PropTypes.objectOf(PropTypes.any),
  }

  static defaultProps = {
    managerOfficeStats: null,
  }

  renderGlobalRevenueAmount = () => {
    const { managerOfficeStats } = this.props

    const columns = [
      {
        id: 'consultant',
        title: "Consultant",
        selector: 'label',
      },
      {
        id: 'revenue_ht',
        title: "Hors taxe (€)",
        selector: 'tax_excluded',
      },
      {
        id: 'revenue_tva',
        title: "Toute taxe comprise (€)",
        selector: 'tax_included',
      },
    ]

    const defaultData = {
      consultant: '',
      revenue_ht: 0,
      revenue_tva: 0,
    }

    const data = managerOfficeStats.revenueByConsultant.map((item) => ({
      ...defaultData,
      ...item,
    }))

    return (
      <StatsTable
        title={"Montant du chiffre d'affaires global"}
        columns={columns}
        data={data}
        dataIdSelector="id"
      />
    )
  }

  renderGlobalRevenueByAccompaniment = () => {
    const { managerOfficeStats } = this.props

    const columns = [
      {
        id: 'label',
        title: "Type d'accompagnement",
        selector: 'label',
        highlight: true,
      },
      {
        id: 'accompaniment_count',
        title: "Total accompagnement",
        selector: 'accompaniment_count',
      },
      {
        id: 'tax_excluded',
        title: "Hors taxe (€)",
        selector: 'tax_excluded',
      },
      {
        id: 'taxable',
        title: "Soumise à TVA (€)",
        selector: 'taxable',
      },
      {
        id: 'tax_free',
        title: "Non soumis à TVA",
        selector: 'tax_free',
      },
    ]

    let data = []

    if (managerOfficeStats.revenueByAccompaniment.length > 0) {
      const accompanimentTypes = ["Bilan de compétences", "Outplacement individuel", "VAE", "Coaching", "Bilan orientation", "Création d'entreprise", "Dispositif collectif", "Outplacement collectif", "Formation", "Autre type d'accompagnement"]
      const dataValues = _keyBy(managerOfficeStats.revenueByAccompaniment, 'label')
      const defaultData = {
        accompaniment_count: 0,
        tax_excluded: 0,
        taxable: 0,
        tax_free: 0,
      }
      data = Object.keys(accompanimentTypes).map((opacifTypeKey, index) => {
        let values = defaultData
        if (dataValues[opacifTypeKey]) {
          values = {
            ...values,
            ...dataValues[opacifTypeKey],
          }
        }

        return {
          ...values,
          id: index,
          label: accompanimentTypes[opacifTypeKey],
        }
      })
    }

    return (
      <StatsTable
        className="statsTable"
        title={"Montant de chiffre d'affaires par activité"}
        columns={columns}
        data={data}
        dataIdSelector="label"
      />
    )
  }

  render() {
    return (
      <div className="statistics-section">
        <SectionTitle label={"Statistiques générales"} />
        {this.renderGlobalRevenueAmount()}
        {this.renderGlobalRevenueByAccompaniment()}
      </div>
    )
  }
}

export default connect(mapStateToProps)(ManagerGlobalStatistics)
import React, { useState } from "react";
import { useHistory } from 'react-router-dom';

import { IconBack } from "../../../components/atoms/IconBack/IconBack";
import { ClientContainer } from "../ClientContainer/ClientContainer";
import PopinLayout from "../../../layouts/PopinLayout/PopinLayout";
import PrimaryButton from "../../../components/atoms/PrimaryButton/PrimaryButton";

import './AddClientLayout.scss';
import { addClient } from "../../../api/ClientsApi";

export const AddClientLayout = () => {
    const [isDisabledButtonSave, setIsDisabledButtonSave] = useState();
    const [newClient, setNewClient] = useState();
    const history = useHistory();

    const handleSaveClientOnClick = () => {
        addClient(newClient).then(() => {
            history.push({ pathname: '/consultant/clients-missions/clients', state: { newClient} });
        })
    }

    const handleCreateClient = (isComplete) => {
        setIsDisabledButtonSave(!isComplete)
    }

    const getNewClient = (client) => {
        setNewClient(client)
    }

    const renderContent = () => {
        return (
            <ClientContainer clientProps={{}} isClientCompleted={handleCreateClient} sendClient={getNewClient}/>
        )
    }

    const renderHeader = () => {
        return (
            <div className="client-header">
                <div className="client-title">
                    <IconBack iconColor={"accent"}/>
                    <p>Nouveau client</p>
                </div>
                <div className="client-actions">
                    <PrimaryButton id={"save"} label={"Sauvegarder"} onClick={handleSaveClientOnClick} disabled={isDisabledButtonSave}/>
                </div>
            </div>
        )
    }

    return (
        <PopinLayout header={renderHeader()} content={renderContent()} />
    )
}
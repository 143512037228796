import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { actions as UserActions } from '../../redux/UserRedux'

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(UserActions.logout()),
})

 class LogoutPage extends PureComponent {
  componentDidMount() {
    this.props.logout()
  }

  render() {
    return null
  }
}

export default connect(null, mapDispatchToProps)(LogoutPage)